import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


import { VoucherService } from '@app/core/services/voucher.service';



@Component({
  selector: 'voucher-status',
  templateUrl: './voucher-status.component.html',
  styleUrls: ['./voucher-status.component.css']
})
export class VoucherStatusComponent implements OnInit {
    
  itemCount:number = 0;
  displayVisible:boolean = false;

  constructor(private router: Router,
              private voucherService : VoucherService) {
  }

  ngOnInit() {

    this.voucherService.getVouchers(true).subscribe(
      data=>{
        this.itemCount = data.length;
        if (this.itemCount> 0 )
           this.displayVisible = true;
        else   
           this.displayVisible = false;
      }, errorResponse=>{
      })    
  }

  gotoCart(){
    // if (this.cartData.items.length > 0)
    //   this.router.navigate(['/store/cart'])
    // else  
    //   this.router.navigate(['/store'])
  }
}

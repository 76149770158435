import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PageSettingsModel, SelectionSettingsModel, GridComponent, RowSelectEventArgs, RowDropSettingsModel } from '@syncfusion/ej2-angular-grids';

import { select, Store } from '@ngrx/store';
import * as clientStore from '@app/core/store/client'

import { UserService } from '@app/core/services/user.service';
import { UserModel } from '@app/core/models/usermodels/user.model';
import { PermissionsModel } from '@app/core/models/usermodels/permissions.model';
import { BeneficiaryModel } from '@app/core/models/accountmodels/beneficiary.model';

@Component({
  selector: 'account-beneficiaries',
  templateUrl: './account-beneficiaries.component.html',
  styleUrls:['./account-beneficiaries.component.css']
})
export class AccountBeneficiariesComponent implements OnInit, OnDestroy {

  clientData: ClientModel;
  userData:UserModel;
  userPermissions:PermissionsModel;
  loading:boolean = true;
  readOnly:boolean = false;
  beneficiaryData : Array<BeneficiaryModel>;
  submitted = false;
  submitting = false;
  


  public pageSettings: PageSettingsModel;
  public selectionOptions: SelectionSettingsModel;

  accountsShown = false;

  @Input('accountdata') set accountdata(value){
    if (!value)
       return;
    this.userData = value;
  }

  @Input('permissions') set permissions(value){
    if (!value)
       return;
    this.userPermissions   = value;
    this.readOnly = this.userPermissions.canEditDiet;
  }

  constructor(private toastr: ToastrService,
              private userService:UserService,
              private store: Store<any>){
  }

  ngOnInit() {
    let that = this;
    this.selectionOptions = { type: 'Single' };
    this.store.pipe(
      select(clientStore.getClient)
    ).subscribe(
      (data: ClientModel) => {
        if ((data == null) || (data == undefined))
           return;
        that.clientData = data;
        //console.log(" Get Beneficiaries ..........................");
        that.loadBeneficiaries();
      });
  }

  ngOnDestroy(){
  }

  loadBeneficiaries(){
    let that = this;
    this.userService.getBeneficiaries(this.clientData.clientId)
     .subscribe(data=>{
      if (data == null){
        return;
      }
       that.beneficiaryData = data;
     },
     errorResponse=>{
       alert(errorResponse);
     });
  }

  onUpdateBeneficiary($event){
    this.loadBeneficiaries();
  }

  formVisible($event){
    if (this.accountsShown != $event)
       this.accountsShown = $event;
  }

  onAdd(){
    this.accountsShown = true;
  }

  onDelete(e: any, data: any){
    let that = this;
    this.userService.deleteBeneficiary(this.clientData.clientId, data.beneficiaryId).subscribe(
      data=>{
        this.loadBeneficiaries();
      },
      errorResponse=>{
        this.loadBeneficiaries();
      }
    )
  }
}

import { EconomyDetailModel } from '@app/core/models/ecomonymodels/economydetail.model';
import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable } from "rxjs";
import { ClientService } from "./client.service";

import { config } from "../../core/smartadmin.config";

import { UserModel } from "../../core/models/usermodels/user.model";
import { ClientPermissionsModel } from '../../core/models/clientmodels/client-permissions.model';
import { MessageTemplateResponse } from "../models/accountmodels/messagetemplateresponse.model";
import { TemplateCloneModel } from "../models/accountmodels/templateclone.model";
import { NotificationModel } from '../models/accountmodels/notification.model';
import { NotificationsResponse } from '../models/accountmodels/notificationsreponse.model';
import { RoleModel } from '../models/accountmodels/role.model';
import { PrinterModel } from '../models/device/printer.model';
import { SettingsModel } from '../models/usermodels/settings.model';
import { MessageTemplateModel } from '../models/accountmodels/messagetemplate.model';
import { ClientDetailModel } from '../models/clientmodels/clientdetail.model';
import { SystemDocumentModel } from '../models/system/systemdocument.model';
import { AccountLookupModel } from '../models/accountmodels/account-lookup.model';
import { EventAttendeeModel } from '../models/events/eventattendee.model';
import { AttendeeLogModel } from '../models/events/attendeelog.model';
import { VendorSettlementCompleteModel, VendorSettlementModel, VendorSettlementResultModel, VendorSettlementsModel } from '../models/vendormodels/vendorsettlement.model';




@Injectable()
export class AdminService implements OnDestroy {

  constructor(private http: HttpClient,
              private router: Router,
              private clientService: ClientService) {
  }


  ngOnDestroy() {
  }


  public updateAccount(accountData: UserModel) {
    var urlString = "/api/admin/updateaccount";
    return new Observable<UserModel>(subscriber => {
      this.http.post<UserModel>(config.TRIOSAPI + urlString, accountData)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateAccountRoles(userId:string, roleArray: Array<RoleModel>) {
    var urlString = "/api/admin/updateadminroles?userId=" + userId;
    return new Observable<Array<RoleModel>>(subscriber => {
      this.http.post<Array<RoleModel>>(config.TRIOSAPI + urlString, roleArray)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public lookupEconomies(filter: string) {
    var urlString = "/api/admin/lookupEconomies?filter=" + filter;
    return new Observable<Array<EconomyDetailModel>>(subscriber => {
      this.http.get<Array<EconomyDetailModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public lookupClients(filter: string) {
    var urlString = "/api/admin/lookupclients?filter=" + filter;
    return new Observable<Array<ClientDetailModel>>(subscriber => {
      this.http.get<Array<ClientDetailModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public lookupAccounts(clientId:number, filter: string) {
    var urlString = "/api/admin/lookupAccounts?clientId=" + clientId + "&filter=" + filter;
    return new Observable<AccountLookupModel>(subscriber => {
      this.http.get<AccountLookupModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public lookupAccountByCardId(clientId:number, cardId: string) {
    var urlString = "/api/admin/loadAccountbycardid?clientId=" + clientId + "&cardId=" + cardId;
    return new Observable<UserModel>(subscriber => {
      this.http.get<UserModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public lookupAccountByAccountNumber(clientId:number, accountNumber: string) {
    var urlString = "/api/admin/loadAccountbyaccountnumber?clientId=" + clientId + "&accountNumber=" + accountNumber;
    return new Observable<UserModel>(subscriber => {
      this.http.get<UserModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public loadAccount(clientId :number, userId :string) {
    var urlString = "/api/admin/loadaccount?clientId=" + clientId + "&userId=" + userId;
    return new Observable<UserModel>(subscriber => {
      this.http.get<UserModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadRoles(userId: string) {
    var urlString = "/api/admin/loadroles?userId=" + userId;
    return new Observable<Array<RoleModel>>(subscriber => {
      this.http.get<Array<RoleModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadAdminRoles() {
    var urlString = "/api/admin/loadadminroles";
    return new Observable<Array<RoleModel>>(subscriber => {
      this.http.get<Array<RoleModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadClientPermissions(clientId: number, userId: string) {
    var urlString = "/api/admin/loadclientpermissions?userId=" + userId + "&clientId=" + clientId;
    return new Observable<ClientPermissionsModel>(subscriber => {
      this.http.get<ClientPermissionsModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public saveAccountClientPermissions(permissions :ClientPermissionsModel) {
    var urlString = "/api/admin/saveclientpermissions"
    return new Observable<ClientPermissionsModel>(subscriber => {
      this.http.post<ClientPermissionsModel>(config.TRIOSAPI + urlString, permissions)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public loadMessageTemplates(clientId: number) {
    var urlString = "/api/admin/loadmessagetemplates?clientId=" + clientId;
    return new Observable<MessageTemplateResponse>(subscriber => {
      this.http.get<MessageTemplateResponse>(config.TRIOSAPI + urlString)
        .subscribe(
          (data: MessageTemplateResponse) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public cloneMessageTemplate(data: TemplateCloneModel) {
    var urlString = "/api/admin/clonemessagetemplate";
    return new Observable<MessageTemplateResponse>(subscriber => {
      this.http.post<MessageTemplateResponse>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data: MessageTemplateResponse) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public deleteMessageTemplate(data: TemplateCloneModel) {
    var urlString = "/api/admin/deletemessagetemplate";
    return new Observable<MessageTemplateResponse>(subscriber => {
      this.http.post<MessageTemplateResponse>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data: MessageTemplateResponse) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateMessageTemplate(data: MessageTemplateModel) {
    var urlString = "/api/admin/updatemessagetemplate";
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadNotifications(clientId: number) {
    var urlString = "/api/admin/loadnotifications?clientId=" + clientId;
    return new Observable<NotificationsResponse>(subscriber => {
      this.http.get<NotificationsResponse>(config.TRIOSAPI + urlString)
        .subscribe(
          (data: NotificationsResponse) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateNotification(data: NotificationModel) {
    var urlString = "/api/admin/updatenotification";
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateAccountSettings(data: SettingsModel) {
    var urlString = "/api/admin/updatesettings";
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getDevices(clientId:number){
    return new Observable<Array<PrinterModel>>(subscriber => {
      this.http.get<Array<PrinterModel>>(config.TRIOSAPI + '/api/device/getprinters?clientId=' + clientId)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getDocuments(){
    return new Observable<Array<SystemDocumentModel>>(subscriber => {
      this.http.get<Array<SystemDocumentModel>>(config.TRIOSAPI + '/api/admin/getsystemdocuments')
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public addDocument(document: SystemDocumentModel){
    return new Observable<SystemDocumentModel>(subscriber => {
      this.http.post<SystemDocumentModel>(config.TRIOSAPI + '/api/admin/addsystemdocument', document)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public deleteDocument(document: SystemDocumentModel){
    return new Observable<Array<SystemDocumentModel>>(subscriber => {
      this.http.post<Array<SystemDocumentModel>>(config.TRIOSAPI + '/api/admin/deletesystemdocument', document)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getEventAttendee(attendeeId : string) {
    var urlString = "/api/events/getattendee?attendeeId=" + attendeeId;
    return new Observable<EventAttendeeModel>(subscriber => {
      this.http.get<EventAttendeeModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public postAtendeeLog(attendeeLog : AttendeeLogModel) {
    var urlString = "/api/events/postEventLog";
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, attendeeLog)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getSettlements(search, page, pageSize) {
    var urlString = "/api/admin/getsettlements?search=" + search + "&page=" + page + "&itemsPerPage=" + pageSize;
    return new Observable<VendorSettlementsModel>(subscriber => {
      this.http.get<VendorSettlementsModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getVendorSettlement(vendorId: number) {
    var urlString = "/api/admin/getvendorsettlement?vendorId=" + vendorId;
    return new Observable<VendorSettlementModel>(subscriber => {
      this.http.get<VendorSettlementModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getVendorSettlementLedger(vendorId: number, settlementId: string ) {
    var urlString = "/api/admin/getvendorsettlementLedger?vendorId=" + vendorId + "&settlementId=" + settlementId;
    return new Observable<VendorSettlementResultModel>(subscriber => {
      this.http.get<VendorSettlementResultModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public updateVendorSettlement(vendorId: number, value: VendorSettlementModel) {
    var urlString = "/api/admin/updatevendorsettlement?vendorId=" + vendorId;
    return new Observable<VendorSettlementModel>(subscriber => {
      this.http.post<VendorSettlementModel>(config.TRIOSAPI + urlString, value)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public calculateSettltment(vendorId: number, value: VendorSettlementModel) {
    var urlString = "/api/admin/calculatesettlement?vendorId=" + vendorId;
    return new Observable<VendorSettlementResultModel>(subscriber => {
      this.http.post<VendorSettlementResultModel>(config.TRIOSAPI + urlString, value)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getSettlementCSV(settlementId : string) {
    var urlString = "/api/admin/getsettlementcsv?settlementId=" + settlementId;
    return new Observable<any>(subscriber => {
      this.http.get(config.TRIOSAPI + urlString, {
        responseType: 'blob' // <-- changed to blob
      })
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getAllVendorSettlements(vendorId : number){
    var urlString = "/api/admin/getallvendorsettlements?vendorId=" + vendorId;
    return new Observable<Array<VendorSettlementResultModel>>(subscriber => {
      this.http.get<Array<VendorSettlementResultModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public completeSettltment(settlementId : string){
    var urlString = "/api/admin/completeSettltment?settltmentId=" + settlementId;
    return new Observable<VendorSettlementCompleteModel>(subscriber => {
      this.http.post<VendorSettlementCompleteModel>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }
}

import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CartActions } from '@app/core/store/cart/cart.actions';

import { CartOrderModel } from '@app/core/models/cart/cart-order.model';
import * as cartStore from '@app/core/store/cart'
import * as clientStore from '@app/core/store/client'

@Component({
  selector: 'cart-status',
  templateUrl: './cart-status.component.html',
  styleUrls: ['./cart-status.component.css']
})
export class CartStatusComponent implements OnInit {
  
  cartData: CartOrderModel; 
  clientData: ClientModel;
  itemCount:number = 0;
  storelink = '/store';
  public onlineStoreEnabled = false;

  constructor(private router: Router,
              private store: Store<any>,
              private cartActions: CartActions) {
    this.store.pipe(
      select(cartStore.getCartOrder)
    ).subscribe(
      data=> {
        this.cartData = data;
        this.itemCount = data.totalItemCount == null?0:data.totalItemCount;
      });  
  }

  ngOnInit() {
    let that = this;
    this.store.pipe(
      select(clientStore.getClient)
    ).subscribe(
      (data: ClientModel) => {
        if ((data == null) || (data == undefined))
          return;
        that.clientData = data;
        that.onlineStoreEnabled = this.clientData.onlineStoreEnabled === true;
        

        switch (this.clientData.economy.economyStore.storeType.toLowerCase()) {
          case "vendor store":
            that.storelink = '/store_vendor';         
            break;
          default:
            that.storelink = '/store';         
            break;
        }
         
      });
    
  }

  gotoCart(){

    if (this.cartData.totalItemCount > 0)
      this.router.navigate([this.storelink +'/cart'])
    else  
      this.router.navigate([this.storelink])
  }
}


import { ProductCategoryModel } from '@app/core/models/product/productcategory.model';
import { Brand } from '@app/core/models/product/brand.model';
import { Review } from '@app/core/models/product/review.model';
import { RatingOption } from '@app/core/models/product/rating-option.model';
import { VendorModel } from '@app/core/models/vendormodels/vendor.model';

export class ProductActions {
  static GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
  static GET_ALL_VENDOR_PRODUCTS = 'GET_ALL_VENDOR_PRODUCTS';
  static GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
  static GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
  static GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
  static CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT';
  static GET_ALL_TAXONOMIES = 'GET_ALL_TAXONOMIES';
  static GET_ALL_TAXONOMIES_SUCCESS = 'GET_ALL_TAXONOMIES_SUCCESS';
  static GET_ALL_PRODUCTS_SEARCH_SUCCESS = 'GET_ALL_PRODUCTS_SEARCH_SUCCESS';
  static GET_RELATED_PRODUCT = 'GET_RELATED_PRODUCT';
  static GET_RELATED_PRODUCT_SUCCESS = 'GET_RELATED_PRODUCT_SUCCESS';
  static GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
  static GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
  // static GET_ALL_BRANDS = 'GET_ALL_BRANDS';
  // static GET_ALL_BRANDS_SUCCESS = 'GET_ALL_BRANDS_SUCCESS';

  static GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
  static GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';

  static GET_ALL_VENDORS = 'GET_ALL_VENDORS';
  static GET_LOCATION_VENDORS = 'GET_LOCATION_VENDORS';
  static GET_ALL_VENDORS_SUCCESS = 'GET_ALL_VENDORS_SUCCESS';

  static GET_FEATURED_VENDORS = 'GET_FEATURED_VENDORS';
  static GET_LOCATION_FEATURED_VENDORS = 'GET_LOCATION_FEATURED_VENDORS';
  static GET_FEATURED_VENDORS_SUCCESS = 'GET_FEATURED_VENDORS_SUCCESS';


  static WRITE_PRODUCT_REVIEW = 'WRITE_PRODUCT_REVIEW';
  static WRITE_REVIEW_SUCCESS = 'WRITE_REVIEW_SUCCESS';
  static GET_RATING_OPTIONS = 'GET_RATING_OPTIONS';
  static GET_RATING_OPTIONS_SUCCESS = 'GET_RATING_OPTIONS_SUCCESS';

  static GET_PRODUCT_V2 = 'GET_PRODUCT_V2';
  static GET_PRODUCT_V2_SUCCESS = 'GET_PRODUCT_V2_SUCCESS';
  static CLEAR_SELECTED_PRODUCTV2 = 'CLEAR_SELECTED_PRODUCTV2';

  getAllProducts(clientId: number, locationId: string, departmentId: string, pageNumber = 1) {
    return {
      type: ProductActions.GET_ALL_PRODUCTS,
      payload: {clientId: clientId, locationId: locationId, departmentId: departmentId, pageNumber: pageNumber}
    };
  }

  getAllVendorProducts(vendorId: number, locationId: string, departmentId: string, pageNumber = 1) {
    return {
      type: ProductActions.GET_ALL_VENDOR_PRODUCTS,
      payload: {vendorId: vendorId, locationId: locationId, departmentId: departmentId, pageNumber: pageNumber}
    };
  }

  getProductV2(id: number) {
    return {
      type: ProductActions.GET_PRODUCT_V2,
      payload: id
    };
  }

  getProductV2Success(product: any) {
    return {
      type: ProductActions.GET_PRODUCT_V2_SUCCESS,
      payload: product
    };
  }


  getProductDetail(id: string) {
    return {
      type: ProductActions.GET_PRODUCT_DETAIL,
      payload: id
    };
  }

  getAllProductsSuccess(products: any) {
    return {
      type: ProductActions.GET_ALL_PRODUCTS_SUCCESS,
      payload: products
    };
  }

  getProductDetailSuccess(data: { product }) {
    return {
      type: ProductActions.GET_PRODUCT_DETAIL_SUCCESS,
      payload: data
    };
  }

  clearSelectedProduct() {
    return { type: ProductActions.CLEAR_SELECTED_PRODUCT };
  }

 clearSelectedProductV2() {
    return { type: ProductActions.CLEAR_SELECTED_PRODUCTV2 };
  }

  getAllTaxonomies() {
    return { type: ProductActions.GET_ALL_TAXONOMIES };
  }

  getAllTaxonomiesSuccess(taxonomies: any) {
    return {
      type: ProductActions.GET_ALL_TAXONOMIES_SUCCESS,
      payload: taxonomies
    };
  }

  getRelatedProduct(product_id: any) {
    return {
      type: ProductActions.GET_RELATED_PRODUCT,
      payload: product_id
    };
  }

  getRelatedProductSuccess(products: any) {
    return {
      type: ProductActions.GET_RELATED_PRODUCT_SUCCESS,
      payload: products
    };
  }

  getProductReviews(productId: number) {
    return {
      type: ProductActions.GET_PRODUCT_REVIEWS,
      payload: productId
    };
  }

  getProductReviewsSuccess(reviews: Array<Review>) {
    return {
      type: ProductActions.GET_REVIEWS_SUCCESS,
      payload: reviews
    };
  }

  // getBrands(clientId: number) {
  //   return {
  //     type: ProductActions.GET_ALL_BRANDS
  //   };
  // }

  // getBrandsSuccess(brands: Array<Brand>) {
  //   return {
  //     type: ProductActions.GET_ALL_BRANDS_SUCCESS,
  //     payload: brands
  //   };
  // }

  getCategories(clientId: number, locationId: string, filter: string) {
    return {
      type: ProductActions.GET_ALL_CATEGORIES,
      payload: { clientId: clientId, locationId: locationId, filter: filter }
    };
  }
  
  getCategoriesSuccess(categories: Array<ProductCategoryModel>) {
    return {
      type: ProductActions.GET_ALL_CATEGORIES_SUCCESS,
      payload: categories
    };
  }

  getAllVendors(clientId: number, locationId: string) {
    return {
      type: ProductActions.GET_ALL_VENDORS,
      payload: { clientId: clientId, locationId: locationId }
    };
  }
  
  getLocationVendors(clientId: number, locationId: string) {
    return {
      type: ProductActions.GET_LOCATION_VENDORS,
      payload: { clientId: clientId, locationId: locationId }
    };
  }
  
  getAllVendorsSuccess(vendors: Array<VendorModel>) {
    return {
      type: ProductActions.GET_ALL_VENDORS_SUCCESS,
      payload: vendors
    };
  }

  getAllFeaturedVendors(clientId: number, locationId: string) {
    return {
      type: ProductActions.GET_FEATURED_VENDORS,
      payload: { clientId: clientId, locationId: locationId }
    };
  }
  
  getLocationFeaturedVendors(clientId: number, locationId: string) {
    return {
      type: ProductActions.GET_LOCATION_FEATURED_VENDORS,
      payload: { clientId: clientId, locationId: locationId }
    };
  }
  
  getFeaturedVendorsSuccess(vendors: Array<VendorModel>) {
    return {
      type: ProductActions.GET_FEATURED_VENDORS_SUCCESS,
      payload: vendors
    };
  }
  
  writeProductReview(reviewParams: Object) {
    return {
      type: ProductActions.WRITE_PRODUCT_REVIEW,
      payload: reviewParams
    };
  }

  writeProductReviewSuccess(review: Review) {
    return {
      type: ProductActions.WRITE_REVIEW_SUCCESS,
      payload: review
    };
  }

  getRatingsOptions(ratingCategoryId: number) {
    return {
      type: ProductActions.GET_RATING_OPTIONS,
      payload: ratingCategoryId
    };
  }

  getRatingsOptionsSuccess(ratingOptions: Array<RatingOption>) {
    return {
      type: ProductActions.GET_RATING_OPTIONS_SUCCESS,
      payload: ratingOptions
    };
  }
}

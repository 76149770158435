import { Map, Record, List } from 'immutable';

import { CartOrderModel } from '@app/core/models/cart/cart-order.model';
import { OrderResponseModel } from './../../models/cart/order-response.model';

export interface CartState extends Map<string, any> {
  selectedOrder: CartOrderModel;
  orderState: OrderResponseModel;
  error: string;
}

export const CartStateRecord = Record({
  orderState: null,
  error: null,
  selectedOrder: {}  
});
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable, Subject, ReplaySubject } from "rxjs";
import { Subscription } from "rxjs/Subscription";
import { map } from "rxjs/operators";
import { config } from "@app/core/smartadmin.config";
import { VoucherModel } from "../models/vouchermodels/voucher.model";
import { CartOrderModel } from "../models/cart/cart-order.model";
import { TaskModel } from "../models/tasks/task.model";
import { UserTaskModel } from "../models/tasks/usertask.model";

@Injectable()
export class TaskService {

  public subscribed: boolean;
  
  constructor(private router: Router,
    private http: HttpClient) {
    this.subscribed = false;
  }



  public getUserTasks(clientId: number) {
    return new Observable<UserTaskModel>(subscriber => {
      this.http.get<UserTaskModel>(config.TRIOSAPI + '/api/task/getusertasks?clientId=' + clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

}


import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";

import { UtilsModule } from "@app/shared/utils/utils.module";
import { PipesModule } from "@app/shared/pipes/pipes.module";
import { I18nModule } from "@app/shared/i18n/i18n.module";
import { UserModule } from "@app/shared/user/user.module";
import { VoiceControlModule } from "@app/shared/voice-control/voice-control.module";
import { PopoverModule } from "ngx-bootstrap/popover";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { UseAddressComponent } from "./useaddress";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    VoiceControlModule,
    BsDropdownModule,
    UtilsModule,
    PipesModule, 
    I18nModule, 
    UserModule, 
    PopoverModule,
  ],
  declarations: [
    UseAddressComponent,
  ],
  exports: [
    UseAddressComponent
  ]
})
export class StoreToolbarModule{}

import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocationModel } from '@app/core/models/common/location.model';
import { CartService, GeocodingService, GeolocationService, GoogleAPIService, MapStyleService, UserService } from '@app/core/services';
import { select, Store } from '@ngrx/store';
import { Subscription, timer } from 'rxjs';

import * as userStore from '@app/core/store/user';
import * as clientStore from '@app/core/store/client';

import { UserModel } from '@app/core/models/usermodels/user.model';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { AddressModel } from '@app/core/models/addressmodels/address.model';

declare var $: any;

@Component({
  selector: 'apstore-toolbar',
  templateUrl: './use-address.component.html',
  styleUrls: ['./use-address.component.css']
})
export class UseAddressComponent implements OnInit, OnDestroy {

  @ViewChild('inputRef', { static: false }) inputRef: ElementRef;
  @ViewChild('addressInput', { static: false }) addressInput: ElementRef;
  @ViewChild('modeselect', { static: false }) modeselect: ElementRef;

  public isToolbarVisible: boolean = false;
  public clientDisplayToolbar: boolean = false;
  private subscription: Subscription;
  private timerSubscription: Subscription = null;
  // public google: any;
  public activeStyle: any;
  public map: any;
  public geocoder: any;
  public infowindow: any;
  public marker: any;
  locationData: LocationModel;
  defaultLocation = { latitude: 0, longitude: 0 };
  public myResults = [];
  private userData: UserModel;
  private clientData: ClientModel;
  private userAddress: string = null;
  private lastResult: any;
  public showMap: boolean = false;

  constructor(private styleService: MapStyleService,
    private apiService: GoogleAPIService,
    private geoService: GeolocationService,
    private geoCode: GeocodingService,
    private cdRef: ChangeDetectorRef,
    private cartService: CartService,
    private userService: UserService,
    private store: Store<any>,) {

    this.locationData = new LocationModel();
    this.locationData.latitude = -26.20514783873005;
    this.locationData.longitude = 28.01547084960938;
  }

  ngOnInit() {

    let that = this;
    this.subscription = new Subscription()
    this.subscription.add(this.store.pipe(
      select(clientStore.getClient)
    )
      .subscribe(
        data => {
          if (data == null){
            return;
          }
          this.clientData = data;
        }
      ));

    this.subscription.add(this.store.pipe(
      select(userStore.getUser)
    )
      .subscribe(
        data => {
          if (data == null){
            return;
          }
          this.userData = data;
          this.loadUser();
        }
      )
    );
    
  }

  

  loadUser() {
    let that = this;
    this.subscription.add(this.cartService.cartConfigSubject.subscribe(data => {
      that.clientDisplayToolbar = that.clientData.deliveryToolbar == true;
      that.isToolbarVisible = data.displayMode === 'toolbar' ;
      if (!that.isToolbarVisible) {
        this.hideDialog();
        this.map = null;
      }
      else {
        this.cdRef.detectChanges();
        try{
          if (this.clientData.deliveryToolbar == true){
             this.modeselect.nativeElement.checked = data.workflowMode === 'delivery';
          }
          else {
             this.modeselect.nativeElement.checked = data.workflowMode === 'pickup';
          }
          this.addressInput.nativeElement.value = this.userData.address.formattedAddress;
          this.locationData.latitude = this.userData.address.latitude;
          this.locationData.longitude = this.userData.address.longitude;
        }catch(e){}

      }
    }));
  }

  ngOnDestroy() {

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  showDialog() {
    this.showMap = true;
    this.cdRef.detectChanges();
    $('#addressDialog').modal({ backdrop: 'static', keyboard: false });
    this.loadMap();
  }

  hideDialog() {
    this.showMap = false;
    this.cdRef.detectChanges();
    $('#addressDialog').modal('hide');
  }

  startTimer() {

    if (this.timerSubscription)
      return;

    var updateTimer = timer(100, 100);
    this.timerSubscription = updateTimer.subscribe(x => {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
      this.geoCode.codeAddress(this.inputRef.nativeElement.value).forEach(
        (results: google.maps.GeocoderResult[]) => {
          //console.log(results);
          this.myResults = results;
          this.lastResult = results[0];
        }).then(() => {
          //console.log("Done");
          // console.log('Geocoding service: completed.');
        })
        .catch((error: google.maps.GeocoderStatus) => {
          //console.log(error);
          if (error === google.maps.GeocoderStatus.ZERO_RESULTS) {
            console.log(error);
          }
        });
    });
  }


  geocodeLatLng(latitude: number, longitude: number) {

    if (!this.infowindow) {
      this.infowindow = new google.maps.InfoWindow();
    }

    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }

    const latlng = {
      lat: latitude,
      lng: longitude,
    };

    this.geocoder.geocode({ location: latlng })
      .then((response) => {
        if (response.results[0]) {
          this.infowindow.setContent(response.results[0].formatted_address);
          this.infowindow.open(this.map, this.marker);
          this.inputRef.nativeElement.value = response.results[0].formatted_address;
          this.lastResult = response.results[0];
          //console.log(this.lastResult);
        } else {
          window.alert("No results found");
        }
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  }

  onUseAddress(address: any) {
    //console.log(address);
    this.locationData.latitude = address.geometry.location.lat();
    this.locationData.longitude = address.geometry.location.lng();
    this.marker.setPosition(new google.maps.LatLng(this.locationData.latitude, this.locationData.longitude));
    this.map.panTo(new google.maps.LatLng(this.locationData.latitude, this.locationData.longitude));
    this.map.setZoom(20);
    this.inputRef.nativeElement.value = address.formatted_address;
    this.myResults = [];
  }


  getCurrentLocation() {
    this.geoService.getCurrentPosition()
      .subscribe(
        data => {
          this.locationData.latitude = data.coords.latitude;
          this.locationData.longitude = data.coords.longitude;
          this.marker.setPosition(new google.maps.LatLng(this.locationData.latitude, this.locationData.longitude));
          this.map.panTo(new google.maps.LatLng(this.locationData.latitude, this.locationData.longitude));
          this.map.setZoom(18);
          this.geocodeLatLng(this.locationData.latitude, this.locationData.longitude);
        },
        errorResponse => {
        }
      );
  }

  onLookupLocation() {

  }

  onEditAddress($event) {
    this.showDialog();
  }

  onModeChange($event) {
    this.cartService.setWorkflow($event.target.checked);
    if (this.isToolbarVisible && $event.target.checked && (!this.addressInput.nativeElement.value && this.addressInput.nativeElement.value.length < 10)) {
      this.showDialog();
    }
  }

  onCancel() {
    this.hideDialog();
  }

  doKeyUp($event) {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    this.startTimer();
  }

  loadMap() {
    let that = this;
    this.map = new google.maps.Map(document.getElementById('map-canvas'), {
      center: { lat: this.locationData.latitude, lng: this.locationData.longitude },
      zoom: 8
    });
    var uluru = { lat: this.locationData.latitude, lng: this.locationData.longitude };
    this.marker = new google.maps.Marker({ position: uluru, map: this.map, draggable: true });
    //this.fetchStyle(this.activeStyle);
    this.map.addListener('dblclick', function () {
      
    });
    this.marker.addListener('click', function () {
      this.map.setZoom(18);
      this.map.setCenter(this.marker.getPosition());
    });
    this.marker.addListener('dragend', function () {
      var lat = this.position.lat();
      var lng = this.position.lng();
      that.geocodeLatLng(lat, lng);
    });
  }

  onUserAddress() {
    var result = this.inputRef.nativeElement.value;
    if (result.length > 30) {

      //console.log(this.lastResult);
      var addressModel = new AddressModel();
      addressModel.line1 = this.getAddresspart(this.lastResult.address_components, 'street_number');
      addressModel.line1 += " ";
      addressModel.line1 += this.getAddresspart(this.lastResult.address_components, 'route');
      addressModel.suburb = this.getAddresspart(this.lastResult.address_components, 'political');
      addressModel.city = this.getAddresspart(this.lastResult.address_components, 'locality');
      addressModel.postCode = this.getAddresspart(this.lastResult.address_components, 'postal_code');
      addressModel.country = this.getAddresspart(this.lastResult.address_components, 'country');
      addressModel.countryCode = this.getAddresspartShort(this.lastResult.address_components, 'country');
      
      //addressModel.suburb = addressComponents.find(x => x.types[0] == 'neighborhood');
      addressModel.latitude = this.locationData.latitude;
      addressModel.longitude = this.locationData.longitude;
      addressModel.addressType = "street";
      addressModel.formattedAddress = result;
      this.cartService.setCartAddress(result);
      this.userService.setAddress(this.clientData.clientId, addressModel).subscribe();
      this.addressInput.nativeElement.value = result;
      $('#addressDialog').modal('hide');
    }
  }
  

  getAddresspart(addressParts: any, part: string) {
    try {
      var res = addressParts.find(x => { return ((x.types[0] == part) || (x.types[1] == part)); });
      return res.long_name;
    }
    catch (ex) {
      return "";
    }
  }

  getAddresspartShort(addressParts: any, part: string) {
    try {
      var res = addressParts.find(x => { return ((x.types[0] == part) || (x.types[1] == part)); });
      return res.short_name;
    }
    catch (ex) {
      return "";
    }
  }



}


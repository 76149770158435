
import { Component, OnInit } from '@angular/core';
import { config } from "@app/core/smartadmin.config";
import { Store } from '@ngrx/store';

import * as moment from "moment";

import * as clientStore from '@app/core/store/client'
import { UserService } from '@app/core/services/user.service';
import { NotificationService } from '@app/core/services/notification.service';

import { UserModel } from '../../../core/models/usermodels/user.model';
import { ClientModel } from '@app/core/models/clientmodels/client.model';


@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  myConfig = config;
  displayFooter = config.displayFooter;
  userData:UserModel;
  clientData: ClientModel;
  latestVersion:string = '';
  footerVisible = false;
  footerColor = "#ff1111ff";
  footerBackColor = "#ffffff";
  footerText = "";
  footerURL = "#";
  displayVersion ="";

  constructor(private notificationService : NotificationService,
              private userService:UserService,
              private store: Store<any>) {
  }

  ngOnInit() {
    let that = this;
    this.displayVersion = config.displayVersion;
    this.store.select(clientStore.getClient)
      .subscribe(
        (data) => {
          if ((data == null) || (data == undefined))
             return;
          that.clientData = data;
          var version = (config.production == true)?that.clientData.productionVersion:that.clientData.testVersion;
          if (version !== config.productVersion)
          {
            that.latestVersion = version;
          }
          that.footerVisible = that.clientData.economy.theme.footerVisible == true;
          that.footerColor = "#" + that.clientData.economy.theme.footerColor;
          that.footerBackColor = "#" + that.clientData.economy.theme.footerBackColor;
          if (that.clientData.economy.theme.footerText != null){
             that.footerText = that.clientData.economy.theme.footerText;
             that.footerURL = that.clientData.economy.theme.footerURL;
          }
        },
        errorResponse => { 
        }
      );

    this.userService.getUser().subscribe( 
      (data:UserModel)=>{
        if((data == null) || (data == undefined))
           return;
        this.userData = data;
        this.userData.lastActivityDate = moment.utc(this.userData.lastActivityDate).toDate()
      });


      
  }

  onUpdate(){
    this.notificationService.smartMessageBox({
         title: "<i class='fa fa-refresh' style='color:green'></i> Clear Local Storage",
         content: `Your version of this website is out of date! 
         Please select Yes to refresh. <br> Note this will clear local storage and reset any widgets. <br> 
         Please press Ctrl and F5 or Command + Shift + R on Mac if this message does not go away. <br> 
         Not having the latest version can compromise functionality. <br> 
         If this does not work for you, <br><br>
         Clear this wesite cash in your browser <br> 
         <br> 
         Would you like to refresh ?`,
         buttons: '[No][Yes]'
       }, (ButtonPressed) => {
         if (ButtonPressed == "Yes" && localStorage) {
           localStorage.clear();
           location.reload()
         }
      });
  }

}

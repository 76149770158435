import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { map } from 'rxjs/operators';
import * as moment from 'moment'
import { config } from '@app/core/smartadmin.config';
import { PageSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

import { LayoutService } from '@app/core/services/layout.service';
import { UserService } from '@app/core/services/user.service';
import { PermissionsModel } from '@app/core/models/usermodels/permissions.model';
import { ClientPermissionsModel } from '@app/core/models/clientmodels/client-permissions.model';
import { UserModel } from '@app/core/models/usermodels/user.model';

const pipe_$ = { transform: '1' };

@Component({
  selector: 'orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  @ViewChild('grid', {static: false})
  public grid: GridComponent;

  public displayMode = 'grid';
  ordersData = [];
  userData: UserModel;
  userPermissions: PermissionsModel = new PermissionsModel();
  clientPermissions: ClientPermissionsModel = new ClientPermissionsModel();
  statementVisible: boolean = false;

  private isMobile = false;
  public pageSettings: PageSettingsModel;
  public selectionOptions: SelectionSettingsModel;
  public wrapSettings = { wrapMode: 'Content' };
  private mobileVisible = [true, false, true, false, false, false, true, true];
  private mobileWidth = [9, 0, 7, 5, 10, 10, 10, 5];
  public columnWidth = [9, 14, 8, 70, 10, 10, 10, 5];
  public columnVisible = [true, true, true, true, true, true, true, true];

  public serviceUrl: string;
  public serverUrl: string;
  public reportPath: string;
  public processingMode;
  public parameters: any;

  startDate;
  endDate;
  maxDate;
  minDate;
  dateRangeValue = [new Date(), new Date()];


  constructor(private userService: UserService,
    private currencyPipe: CurrencyPipe,
    public layoutService: LayoutService) {
    
      this.serviceUrl = config.TRIOSAPI + '/api/ReportViewer';
      //this.processingMode = ej.ReportViewer.ProcessingMode.Remote;
      this.serverUrl = config.REPORTSERVER;
      this.reportPath = "/SystemReports/invoice";

      this.startDate = this.addDays(new Date(),-365);
      this.endDate = this.addDays(new Date(),1);
      this.minDate = this.addDays(new Date(),-365);
      this.dateRangeValue = [new Date(this.addDays(new Date(),-30)), new Date(this.addDays(new Date(),1))];

  }

  ngOnInit() {
    this.isMobile = this.layoutService.isMobile();
    if (this.isMobile) {
      this.columnWidth = this.mobileWidth;
      this.columnVisible = this.mobileVisible;
    }

    this.selectionOptions = { type: 'Single' };
    this.pageSettings = { pageSize: 6 };

    //this.loadData(this.startDate, this.endDate);
  }


  @Input('accountdata') set accountdata(value) {
    if ((value === null) || (value === undefined))
      return;
    let that = this;
    this.userData = value;
    
  }

  @Input('permissions') set permissions(value: PermissionsModel) {
    if ((value === null) || (value === undefined))
      return;
    this.userPermissions = value;
    this.statementVisible = value.balanceVisible === true;
  }

  @Input('clientpermissions') set clientpermissions(value: ClientPermissionsModel) {
    if ((value === null) || (value === undefined))
      return;

    this.clientPermissions = value;
    this.statementVisible = value.canViewBalance === true;
  }

  loadData(startDate:Date, endDate: Date){
    let that = this;
    const sDate = moment(startDate).format('DD MMM YYYY');
    const eDate = moment(endDate).format('DD MMM YYYY');
    this.userService.loadOrders(this.userData.userId, sDate, eDate)
      .pipe(
        map(data => {
          data.orderLine.forEach(element => {
              element.amount = this.currencyPipe.transform(element.amount, 'ZAR', "R");
          });
          return data;
        })
      )
      .subscribe((data) => {
        that.ordersData = data.orderLine;
      });
  }

  dataBound() {
    this.grid.autoFitColumns(['description']);
  }

  customSort() {
    //alert('a');
  }

  onDateChange(event){
    this.loadData(this.dateRangeValue[0], this.dateRangeValue[1]);
  }
 
  rowSelected($event){
  }

  onReportClose(){
    this.displayMode = 'grid';
  }


  onShowInvoice($event, data){
    this.parameters = [{
			name: 'OrderId', 
			labels: [data.orderId], 
      values: [data.orderId], 
      value: data.orderId,
			nullable: false 
			}];
    this.displayMode = 'invoice';
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString();
  }

}

export class DietModel {
    public accountId:string; 
    public dietNotes:string;
    public dietSpend:number;
    public dietKosher:boolean;
    public dietHalal:boolean;
    public dietOther:boolean;
    public dietGluten:boolean;
    public dietNuts:boolean;
    public dietSoy:boolean;
    public dietSesame:boolean;
    public dietWheat:boolean;
    public dietMilk:boolean;
    public dietEgg:boolean;
    public dietFish:boolean;
    public dietShellfish:boolean;
    public dietSugar:boolean;
}


import { NgModule, ModuleWithProviders} from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginInfoComponent } from "./login-info/login-info.component";
import { LogoutComponent } from "./logout/logout.component";
import { SwitchToMainComponent } from "./switchtomain/switchtomain.component";
import { SwitchToMainButtonComponent } from "./switchtomain/switchtomainbutton.component";


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LoginInfoComponent, 
    LogoutComponent,
    SwitchToMainComponent,
    SwitchToMainButtonComponent,
  ],
  exports: [
    LoginInfoComponent, 
    LogoutComponent,
    SwitchToMainComponent,
    SwitchToMainButtonComponent,
  ]
})
export class UserModule{}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { AuthenticationGuard } from 'microsoft-adal-angular6';

import { ClientService } from '../services/client.service'
import { AuthService } from '../services/auth.service'
import { UserService } from '../services/user.service'


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(//private authGuard: AuthenticationGuard,
    private clientService: ClientService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router) {

    if (this.authService.isAuthenticated()) {
      if (((this.userService.userData === null) || (this.userService.userData === undefined)) && (this.router.url != 'dashboard/analytics')) {
        // alert('Get User')
        this.userService.getUser();
        //this.router.navigate(["/splash"]);
        //this.router.navigate(["/dashboard/analytics"]);
      }
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.clientService.isADAuth() && (this.authService.isLoggedIn === false)) {
      console.log("canAvtivate false");
      return false; //this.authGuard.canActivate(route, state);
    }
    else {
      if (this.authService.isAuthenticated()) {
        if (this.userService.userData === undefined){
          console.log("canAvtivate false1");
          //this.router.navigate(["/auth/login"]);
          return true;
        }
        var role = route.paramMap.get('role');
        if ( role === null){
          console.log("canAvtivate false2");
           return true;
        }
       
        var roles = this.userService.userData.roles;
        var retval = roles.find(te => te.roleName == role);
        var retval1 = (!(retval == null) || (retval == undefined));
        console.log("canAvtivate false3");
        return retval1;
      }
    }
    console.log("canAvtivate false4");
    this.router.navigate(["/auth/login"]);
    return false;
  }
  
}

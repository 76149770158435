import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA  } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";


import { SmartadminLayoutModule } from "./layout";

import { I18nModule} from "./i18n/i18n.module";
import { BootstrapModule } from "@app/shared/bootstrap.module";
import { VoiceControlModule} from "./voice-control/voice-control.module";

import { SmartadminWidgetsModule} from "./widgets/smartadmin-widgets.module";

import { UtilsModule} from "./utils/utils.module";
import { PipesModule} from "./pipes/pipes.module";
import { ChatModule} from "./chat/chat.module";

import { InlineGraphsModule} from "./graphs/inline/inline-graphs.module";
import { SmartadminFormsLiteModule} from "./forms/smartadmin-forms-lite.module";
import { SmartProgressbarModule} from "./ui/smart-progressbar/smart-progressbar.module";
import { CalendarComponentsModule } from "@app/shared/calendar/calendar-components.module";
import { ClientModule } from "./client/client.module";
import { AccountModule } from "./account/account.module";

import { ImagePreloadDirective } from "./directives/image-preload.directive";
import { AppPasswordDirective } from "./directives/app-password.directive";
import { InputRestrictionDirective } from "./directives/specialchar.directive";
import { SliderModule } from '@syncfusion/ej2-angular-inputs';
import { RangeNavigatorModule } from '@syncfusion/ej2-angular-charts';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SmartadminLayoutModule,
    BootstrapModule,
    SliderModule,
    RangeNavigatorModule
  ],
  declarations: [
    ImagePreloadDirective,
    AppPasswordDirective,
    InputRestrictionDirective,
  ],
  exports: [
    ImagePreloadDirective,
    AppPasswordDirective,
    InputRestrictionDirective,
    CommonModule,
    FormsModule,
    RouterModule,
    
    SmartadminLayoutModule,
    BootstrapModule,
    I18nModule,

    UtilsModule,
    PipesModule,

    SmartadminFormsLiteModule,

    SmartProgressbarModule,

    InlineGraphsModule,

    SmartadminWidgetsModule,

    ChatModule,

    AccountModule,

    VoiceControlModule,

    CalendarComponentsModule,

    ClientModule,
    AccountModule,
  ],
  providers: [
  ],
})
export class SharedModule {
}

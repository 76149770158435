import { StartSplashScreenComponent } from './features/splash/preloginsplash/startsplashscreen.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import { ResponsiveModule } from 'ngx-responsive'
import { MomentModule } from 'ngx-moment';
import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { CountryPickerModule } from 'ngx-country-picker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory, StompConfig } from '@stomp/ng2-stompjs';
import { config } from './core/smartadmin.config';
import { environment } from './../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import * as Sentry from "@sentry/angular";
import { GlobalErrorHandler } from './core/interceptors/globalerrorhandler';
import { StartSplashScreenModule } from './features/splash/preloginsplash/startsplashscreen.module';
import { DatePipe } from '@angular/common';
import { AuthService, ClientService } from './core/services';
import * as SF from "./shared/factories/stomp-config.factory";
import { trxStompServiceFactory } from './shared/factories/stomp-sf.factory';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "red",
  "bgsOpacity": 0.8,
  "bgsPosition": "center-right",
  "bgsSize": 60,
  "bgsType": "three-strings",
  "blur": 5,
  //"delay": 0,
  "fgsColor": "#be1d2d",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "rectangle-bounce-pulse-out",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  // "logoUrl": "assets/img/splashlogo.png",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  //"maxTime": -1,
  //"minTime": 500
};


const reactConfig = {
  breakPoints: {
    xs: { max: 600 },
    sm: { min: 601, max: 959 },
    md: { min: 960, max: 1279 },
    lg: { min: 1280, max: 1919 },
    xl: { min: 1920 }
  },
  debounceTime: 100
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    //domain: "clientportal.allxs.co.za" // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    domain: location.hostname
  },
  palette: {
    popup: {
      background: "#000"
    },
    button: {
      background: "#9e0000"
    }
  },
  theme: "edgeless",
  type: "opt-out",
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messageLink}}{{compliance}}'
  },
  elements: {
    messageLink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" rel="noopener" target="_blank">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" rel="noopener" target="_blank">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" rel="noopener" target="_blank">{{tosLink}}</a>
    </span>
    `,
  },
  content: {
    message: 'By using our site, you acknowledge that you have read and understand our ',

    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: 'https://allxs.co.za/cookiepolicy',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'https://allxs.co.za/privacy.html',

    tosLink: 'Terms of Service',
    tosHref: 'https://allxs.co.za/terms-and-conditions/',
  }
};

const adAuth = {
  tenant: environment.TennantId,
  clientId: environment.ApplicationId,
  redirectUri: window.location.origin,
  endpoints: {
    'api': environment.TennantId
  },
  navigateToLoginRequestUrl: false,
  cacheLocation: 'localStorage',
};

const absaAdAuth = {
  tenant: environment.ABSATennantId,
  clientId: environment.ABSAApplicationId,
  redirectUri: window.location.origin,
  endpoints: {
    'api': environment.TennantId
  },
  navigateToLoginRequestUrl: false,
  cacheLocation: 'localStorage',
};

export function getAuth() {
  if (window.location.origin.toLowerCase().includes('absa')) {
    return absaAdAuth;
  }
  else {
    return adAuth;
  }
}

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    BsDatepickerModule.forRoot(),  
    NgcCookieConsentModule.forRoot(cookieConfig),
    ToastrModule.forRoot(),
    StartSplashScreenModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFirestoreModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
    //ServiceWorkerModule.register('ngsw-worker.js', { scope: '/app/', enabled: environment.production }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: "registerImmediately" }),
    MomentModule,
    ResponsiveModule.forRoot(reactConfig),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    //NgxUiLoaderRouterModule, // import this module for showing loader automatically when navigating between app routes
    NgxUiLoaderHttpModule,
    //MsAdalAngular6Module.forRoot(absaAdAuth),
    CountryPickerModule.forRoot(),
    MatFormFieldModule,
    MsalModule.forRoot({
      auth: {
        clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6',
        authority: 'https://login.microsoftonline.com/common/',
        validateAuthority: true,
        redirectUri: 'http://localhost:4200/',
        postLogoutRedirectUri: 'http://localhost:4200/',
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
    {
      popUp: !isIE,
      consentScopes: [
        'user.read',
        'openid',
        'profile',
        'api://a88bb933-319c-41b5-9f04-eff36d985612/access_as_user'
      ],
      unprotectedResources: ['https://www.microsoft.com/en-us/'],
      protectedResourceMap,
      extraQueryParameters: {}
    })
  ],
  providers: 
  
  [
    BsDatepickerConfig,
    DatePipe,
    Title,
    CookieService,
    //AuthenticationGuard,
    {
      provide: InjectableRxStompConfig,
      useClass: SF.Stomp,
      deps: [ClientService]
    },
    {
       provide: RxStompService,
       useFactory: rxStompServiceFactory,
       deps: [InjectableRxStompConfig]
     },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      useValue: Sentry.createErrorHandler({
          showDialog: true, //environment.debug,
       }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useFactory: (): MatFormFieldDefaultOptions => ({
        appearance: 'fill',
      }),
    },
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [

  ]
})
export class AppModule {
   constructor(){
     
   }
}



import { Action } from "@ngrx/store";
import { Alert } from "selenium-webdriver";

export class ClientActions {

  static GET_CLIENT = "GET_CLIENT";
  static GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
  static GET_CLIENT_ROLES = "GET_CLIENT_ROLES";
  static GET_CLIENT_ROLES_SUCCESS = "GET_CLIENT_ROLES_SUCESS";
  static GET_CLIENT_GROUPS = "GET_CLIENT_GROUPS";
  static GET_CLIENT_GROUPS_SUCCESS = "GET_CLIENT_GROUPS_SUCESS";
  static UNLOAD_CLIENT = "UNLOAD_CLIENT";


  getClient() {
    return {
      type: ClientActions.GET_CLIENT,
    }
  }

  unloadClient(){
    return {
      type: ClientActions.UNLOAD_CLIENT,
      payload: null
    }
  }

  getClientSuccess(clientState: any) {
    return {
      type: ClientActions.GET_CLIENT_SUCCESS,
      payload: clientState
    };
  }


  getClientRoles() {
    return {
      type: ClientActions.GET_CLIENT_ROLES,
    }
  }

  getClientRolesSuccess(roles: any) {
    return {
      type: ClientActions.GET_CLIENT_ROLES_SUCCESS,
      payload: roles
    };
  }

  getClientGroups() {
    return {
      type: ClientActions.GET_CLIENT_GROUPS,
    }
  }

  getClientGroupsSuccess(groups: any) {
    return {
      type: ClientActions.GET_CLIENT_GROUPS_SUCCESS,
      payload: groups
    };
  }

}

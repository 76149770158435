import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


import { Store } from '@ngrx/store';
import * as clientStore from '@app/core/store/client'

import { UserService } from '@app/core/services/user.service';
import { UserModel } from '@app/core/models/usermodels/user.model';

import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { DataService } from '@app/core/services/data.service';
import { config } from "@app/core/smartadmin.config";


class Login {
  password: string;
  userid: string;
}


@Component({
  selector: 'viewinvoice',
  templateUrl: './viewinvoice.component.html',
  styleUrls: ['./viewinvoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {

  clientData: ClientModel;
  submitted = false;
  submitting = false;

  public serviceUrl: string;
  public serverUrl: string;
  public reportPath: string;
  public processingMode;

  constructor(private router: Router,
    private userService: UserService,
    private dataService: DataService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private http: HttpClient,
    private store: Store<any>) {
  }

  ngOnInit() {
    this.store.select(clientStore.getClient)
      .subscribe(
        (data: ClientModel) => {
          this.clientData = data;
        });
    this.serviceUrl = config.TRIOSAPI + '/api/ReportViewer';
    //this.processingMode = ej.ReportViewer.ProcessingMode.Remote;
    //this.serviceUrl = "http://clientapidev.allxs.co.za/api/ReportViewer";
    this.serverUrl = "http://localhost/reportserver";
    this.reportPath = "/welcome";
    //this.reportServer = "https://reports.allxs.co.za/reportserver";
  }

}
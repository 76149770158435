import { PosActions } from './pos.actions';
import { PosState, PosStateRecord } from './pos.state';

export const initialState: PosState = (new PosStateRecord() as unknown) as PosState;

export function posReducer(state = initialState, { type, payload }: any): PosState {

  switch (type) {

    case PosActions.GET_WAITRON_SUCCESS:
      return state.merge({
        waitronState: payload
      }) as PosState;

    default:
      return state;
  }
}

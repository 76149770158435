import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@app/core/services/layout.service';
import { Store } from '@ngrx/store';

import * as userStore from '@app/core/store/user'
import { UserModel } from '@app/core/models/usermodels/user.model';

@Component({

  selector: 'sa-login-info',
  templateUrl: './login-info.component.html',
  styleUrls:['login-info.component.css']
})
export class LoginInfoComponent implements OnInit {

  userData: UserModel;
  private userName = "...";
  private firstName = "...";
  private lastName = "...";
  private userPhoto = "./assets/img/avatars/sunny.png";

  constructor(private layoutService: LayoutService,
    private store: Store<any>) {
  }

  ngOnInit() {
    
    this.store.select(userStore.getUser).subscribe(
      (data: UserModel) => {
        if ((data == null) || (data == undefined))
          return;
        this.userData = data;
        this.userName = this.userData.userAccountNumber.toString();
        if ((data.userDetail != null) && (data.userDetail != undefined)) {
          if ((data.userDetail.firstName != null) && (data.userDetail.firstName != undefined))
            this.firstName = data.userDetail.firstName;
          if ((data.userDetail.lastName != null) && (data.userDetail.lastName != undefined))
            this.lastName = data.userDetail.lastName;
        }

        if (data.userPhoto != null)
          this.userPhoto = data.userPhoto;

      }
    );
  }

  toggleShortcut() {
    this.layoutService.onShortcutToggle()
  }

}

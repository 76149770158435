import { Injectable, Inject, OnInit, OnDestroy } from "@angular/core";
import { DatePipe, DOCUMENT, formatDate } from "@angular/common";

import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment'

import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { config } from "@app/core/smartadmin.config";

import { AuthService } from "@app/core/services/auth.service";

import { LocationOnlyModel } from '@app/core/models/location/locationonly.model';
import { ProductModel } from '@app/core/models/productmodels/product.model';
import { ProductDetailModel } from '@app/core/models/productmodels/productdetail.model';
import { VendorSalesModel } from '@app/core/models/vendormodels/vendorsales.model';
import { VendorModel } from '@app/core/models/vendormodels/vendor.model';
import { VendorDetailModel } from '@app/core/models/vendormodels/vendordetail.model';
import { WorkflowModel } from '@app/core/models/workflow/workflow.model';
import { FileManagerModel } from "../models/filemanagermodels/filemanager.model";
import { VendorStoreSettingsModel } from './../models/vendormodels/vendorstoresettings.model';
import { ProductCategoryModel } from "../models/product/productcategory.model";
import { ProductCategoryDetailModel } from "../models/product/productcategorydetail.model";
import { CollectionOrderModel } from "../models/usermodels/collection-order.model";
import { MenuModel } from "../models/preorder/menu.model";
import { DiscountModel } from "../models/discountmodels/discount.model";
import { PreorderDetailModel } from "../models/preorder/preorderdetail.model";
import { PrinterModel } from "../models/printermodels/printer.model";
import { VoucherModel } from './../models/vouchermodels/voucher.model';
import { ProductV2Model } from "../models/productmodels/productv2.model";
import { VendorWorkflowModel } from "../models/vendormodels/vendorworkflow.model";
import { WorkflowItemModel } from "../models/workflow/workflowitem.model";
import { VendorDeliveryModel } from "../models/vendormodels/vendordelivery.model";
import { VendorIntegrationModel } from "../models/vendormodels/vendorIntegration.model";
import { AddressModel } from "../models/addressmodels/address.model";
import { TransactionOrderModel } from "../models/transaction/transaction_order.model";
import { IndegoPaymentRequestModel } from "../models/vendorindego/indego_paymentrequesrt.model";
import { IndegoPaymentResponseModel } from "../models/vendorindego/indego_paymentresponse.model";
import { DeviceModel } from "../models/device/device.model";
import { VenueModel } from "../models/venuemodels/venue.model";
import { SeatConfigModel } from "../models/venuemodels/seatconfig.model";
import { EventModel } from "../models/events/event.model";
import { HoldSeatModel } from "../models/events/holdseat.model";
import { EventTicketModel } from "../models/events/eventticket.model";
import { VendorSettlementModel, VendorSettlementResultModel } from "../models/vendormodels/vendorsettlement.model";
import { VendorDefaultWorkflowModel } from "../models/vendormodels/vendordefaultworkflow.model";
import { ImageModel } from "../models/image/image.model";
import { VendorThemeModel } from "../models/vendormodels/vendortheme.model";
import { TaskLookupModel } from "../models/tasks/tasklookup.model";
import { TaskModel } from "../models/tasks/task.model";
import { TaskBaseModel } from "../models/tasks/taskbase.model";
import { TimeSlotInventoryDataModel } from "../models/inventory/timeslotinventorydata.model";
import { TimeSlotConfigInventoryModel } from "../models/inventory/timeslotconfiginventory.model";
import { TimeSlotInventoryModel } from "../models/inventory/timeslotinventory.model";
import { InventoryModel } from "../models/inventory/inventory.model";

@Injectable()
export class VendorService implements OnInit, OnDestroy {
  public subscribed: boolean;

  public vendorsSubject = new BehaviorSubject<Array<VendorModel>>(null);
  public productsSubject = new BehaviorSubject<Array<ProductModel>>(null);
  public currentVendors: Array<VendorModel>;
  private hasVendors = false;
  private hasProducts = false;
  private productFilter: string = "";

  constructor(@Inject(DOCUMENT) private document,
    private router: Router,
    private datepipe: DatePipe,
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<any>) {
    this.subscribed = false;

    this.authService.loginState.subscribe(data => {
      if (data) {

      }
      else {
        this.vendorsSubject.observers.forEach(obs => {
          obs.complete();
        });
        this.hasVendors = false;
      }

    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.currentVendors = [];
    this.hasVendors = false;
    this.hasProducts = false;
    this.vendorsSubject.next(this.currentVendors);
    this.productsSubject.next(null);
  }

  public getVendors(clientId: number): Observable<Array<VendorModel>> {
    if (!this.hasVendors) {
      this.getVendorsByUserId(clientId);
    }
    return this.vendorsSubject.asObservable();
  }


  public getVendorsByUserId(clientId: number) {
    let that = this;
    that.hasVendors = true;
    that.productsSubject.next(null);
    this.http.get<any>(config.TRIOSAPI + '/api/client/getvendorsbyuserid?clientId=' + clientId)
      .pipe(
        map(response => {
          return response;
        }
        ))
      .subscribe(
        data => {
          that.hasVendors = true;
          that.currentVendors = data;
          that.vendorsSubject.next(that.currentVendors);
        },
        response => {
          this.vendorsSubject.error(response);
        }
      );
  }


  public getVendor(vendorId: number) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendor?vendorId=" + vendorId;
    return new Observable<VendorModel>(subscriber => {

      this.http.get<VendorModel>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getVendorDetail(vendorId: number) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendordetail?vendorId=" + vendorId;
    this.hasProducts = false;
    this.productsSubject.next([]);
    return new Observable<VendorDetailModel>(subscriber => {

      this.http.get<VendorDetailModel>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getVendorDetailFromOwnerId() {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendordetailfromownerid";
    return new Observable<VendorDetailModel>(subscriber => {

      this.http.get<VendorDetailModel>(urlString)
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public updateVendorDetail(clientId: number, vendorData: VendorDetailModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/updatevendordetail?clientId=" + clientId;
    return new Observable<VendorDetailModel>(subscriber => {

      this.http.post<VendorDetailModel>(urlString, vendorData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateVendorAddress(clientId: number, vendorData: VendorDetailModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/updatevendoraddress?clientId=" + clientId;
    return new Observable<VendorDetailModel>(subscriber => {

      this.http.post<VendorDetailModel>(urlString, vendorData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateVendorDefaultWorkflow(clientId: number, vendorData: VendorDefaultWorkflowModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/updatevendorDefaultWorkflow?clientId=" + clientId;
    return new Observable<VendorDefaultWorkflowModel>(subscriber => {

      this.http.post<VendorDefaultWorkflowModel>(urlString, vendorData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public getVendorSales(vendorId: number) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorsales?vendorId=" + vendorId;
    return new Observable<VendorSalesModel>(subscriber => {

      this.http.get<VendorSalesModel>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getVendorCollections(vendorId: number, searchDate: Date, filter: string) {
    var date = this.datepipe.transform(searchDate, 'dd MMM yyyy');
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorcollections?vendorId=" + vendorId + "&searchDate=" + date + "&filter=" + filter;
    return new Observable<Array<CollectionOrderModel>>(subscriber => {

      this.http.get<Array<CollectionOrderModel>>(urlString)
        .pipe(
          map(response => {
            response.forEach(item => {
              item.orderDate = moment(item.orderDate).format("DD MMM YYYY")
            });
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getPrinters(clientId: number, vendorId: number) {
    return new Observable<Array<PrinterModel>>(subscriber => {
      this.http.get<Array<PrinterModel>>(config.TRIOSAPI + '/api/vendor/getprinters?clientId=' + clientId + "&vendorId=" + vendorId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public printDeliveryNote(clientId: number, vendorId: number, orderId: string, printerId: string, preorderDetail: PreorderDetailModel) {
    return new Observable<any>(subscriber => {
      let url = config.TRIOSAPI + '/api/vendor/printdeliverynote?clientId=' + clientId + "&vendorId=" + vendorId;
      url += "&orderId=" + orderId + "&printerId=" + printerId;
      this.http.post<any>(url, preorderDetail)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public printDeliveryNotes(clientId: number, vendorId: number, printerId: string, orderId: Array<string>) {
    return new Observable<any>(subscriber => {
      let url = config.TRIOSAPI + '/api/vendor/printdeliverynotes?clientId=' + clientId + "&vendorId=" + vendorId;
      url += "&printerId=" + printerId;
      this.http.post<any>(url, orderId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public printTest(clientId: number, vendorId: number, printerId: string) {
    return new Observable<any>(subscriber => {
      let url = config.TRIOSAPI + '/api/vendor/printtest?clientId=' + clientId + "&vendorId=" + vendorId;
      url += "&printerId=" + printerId;
      this.http.post<any>(url, "")
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getVendorOrder(clientId: number, vendorId: number, search: any) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendororder?clientId=" + clientId + "&vendorId=" + vendorId + "&search=" + search;
    return new Observable<Array<any>>(subscriber => {

      this.http.get<Array<any>>(urlString)
        .pipe(
          map(response => {
            response.forEach(item => {
              item.orderDate = moment.utc(item.orderDate).toDate();
            });
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getVendorPreorders(clientId: number, vendorId: number, searchDate: Date, filter: string) {
    var dateParam = formatDate(searchDate, 'dd MMM yyyy', 'en');
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorpreorders?clientId=" + clientId + "&vendorId=" + vendorId + "&date=" + dateParam + "&filter=" + filter;
    return new Observable<Array<PreorderDetailModel>>(subscriber => {

      this.http.get<Array<PreorderDetailModel>>(urlString)
        .pipe(
          map(response => {
            response.forEach(item => {
              item.orderDate = moment.utc(item.orderDate).toDate();
            });
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getVendorOrders(clientId: number, vendorId: number, searchDate: Date, filter: string) {
    var dateParam = formatDate(searchDate, 'dd MMM yyyy', 'en');
    var urlString = config.TRIOSAPI + "/api/vendor/getvendororders?clientId=" + clientId + "&vendorId=" + vendorId + "&date=" + dateParam + "&filter=" + filter;
    return new Observable<Array<TransactionOrderModel>>(subscriber => {

      this.http.get<Array<TransactionOrderModel>>(urlString)
        .pipe(
          map(response => {
            response.forEach(item => {
              item.orderDate = moment.utc(item.orderDate).toDate().toString();
            });
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public cancelPreorder(clientId: number, vendorId: number, orderId: string, printerId: string, preorderDetail: PreorderDetailModel) {
    return new Observable<any>(subscriber => {
      let url = config.TRIOSAPI + '/api/vendor/printdeliverynote?clientId=' + clientId + "&vendorId=" + vendorId;
      url += "&orderId=" + orderId + "&printerId=" + printerId;
      this.http.post<any>(url, preorderDetail)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public setCollected(vendorId: number, orderId: string) {
    var urlString = config.TRIOSAPI + "/api/vendor/setcollected?vendorId=" + vendorId + "&orderid=" + orderId;
    return new Observable<Array<CollectionOrderModel>>(subscriber => {
      this.http.post<Array<CollectionOrderModel>>(urlString, "")
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public addNewVendorWorkflow(vendorId: number, workflowType: string) {
    var urlString = config.TRIOSAPI + "/api/vendor/addnewvendorworkflow?vendorId=" + vendorId;
    return new Observable<Array<VendorWorkflowModel>>(subscriber => {
      this.http.post<Array<VendorWorkflowModel>>(urlString, workflowType)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getVendorWorkflows(vendorId: number) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorworkflows?vendorId=" + vendorId + '&filter=';
    return new Observable<Array<VendorWorkflowModel>>(subscriber => {
      this.http.get<Array<VendorWorkflowModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public addVendorWorkflowStart(vendorId: number, workflowType: string) {
    var urlString = config.TRIOSAPI + "/api/vendor/addvendorworkflowStart?vendorId=" + vendorId + "&workflowType=" + workflowType;
    return new Observable<any>(subscriber => {
      this.http.post<any>(urlString, null)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public assignWorkflowToProduct(vendorId: number, workflowType: string, workflowId: string, useAll: boolean) {
    var urlString = config.TRIOSAPI + "/api/vendor/assignvendorworkflowtoProduct?vendorId=" + vendorId + "&workflowType=" + workflowType +
      "&workflowId=" + workflowId + "&useAll=" + useAll;
    console.log(urlString);
    return new Observable<any>(subscriber => {
      this.http.post<any>(urlString, null)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateWorkflow(vendorId: number, workflow: VendorWorkflowModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/updateWorkflow?vendorId=" + vendorId;
    return new Observable<Array<VendorWorkflowModel>>(subscriber => {
      this.http.post<Array<VendorWorkflowModel>>(urlString, workflow)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getVendorWorkflow(vendorId: number, workflowId: string) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorworkflow?vendorId=" + vendorId + "&workflowId=" + workflowId;
    return new Observable<Array<VendorWorkflowModel>>(subscriber => {
      this.http.get<Array<VendorWorkflowModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public addVendorWorkflow(vendorId: number, workflowData: WorkflowModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/addvendorworkflow?vendorId=" + vendorId;
    return new Observable<Array<VendorWorkflowModel>>(subscriber => {
      this.http.post<Array<VendorWorkflowModel>>(urlString, workflowData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public resetHasProducts() {
    this.hasProducts = false;
  }

  public vendorProducts(vendorId: number, filter: string, topN: string) {
    return new Observable<Array<ProductModel>>(subscriber => {
      var urlString = config.TRIOSAPI + "/api/vendor/getvendorproductsN?vendorId=" + vendorId + '&filter=' + filter + "&topN=" + topN;
      this.http.get<Array<ProductModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getVendorProducts(vendorId: number, filter: string) {
    if (!this.hasProducts || filter != this.productFilter) {
      this.fetchVendorProducts(vendorId, filter);
    }
    return this.productsSubject;
  }


  public fetchVendorProducts(vendorId: number, filter: string) {
    this.productFilter = filter;
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorproducts?vendorId=" + vendorId + '&filter=' + filter;
    this.http.get<Array<ProductModel>>(urlString)
      .pipe(
        map(response => {
          return response;
        })
      )
      .subscribe(
        data => {
          if ((data == null) || (data == undefined))
            return;
          this.hasProducts = true;
          this.productsSubject.next(data);
        },
        response => {
          this.hasProducts = false;
          this.productsSubject.error(response);
        }
      );
  }


  public fetchVendorProductsBrief(vendorId: number, filter: string) {
    this.productFilter = filter;
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorproducts?vendorId=" + vendorId + '&filter=' + filter;
    this.http.get<Array<ProductModel>>(urlString)
      .pipe(
        map(response => {
          return response;
        })
      )
      .subscribe(
        data => {
          if ((data == null) || (data == undefined))
            return;
          this.hasProducts = true;
          this.productsSubject.next(data);
        },
        response => {
          this.hasProducts = false;
          this.productsSubject.error(response);
        }
      );
  }
  

  public getVendorCategories(vendorId: number, filter: string) {
    return new Observable<Array<ProductCategoryModel>>(subscriber => {
      this.productFilter = filter;
      var urlString = config.TRIOSAPI + "/api/vendor/getvendorcategories?vendorId=" + vendorId + '&filter=' + filter;
      this.http.get<Array<ProductCategoryModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getVendorProduct(vendorId: number, productId: number) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorproduct?vendorId=" + vendorId + '&productId=' + productId;
    return new Observable<ProductDetailModel>(subscriber => {

      this.http.get<ProductDetailModel>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getVendorCategory(vendorId: number, categoryId: number): Observable<ProductCategoryDetailModel> {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorcategory?vendorId=" + vendorId + '&categoryId=' + categoryId;
    return new Observable<ProductCategoryDetailModel>(subscriber => {

      this.http.get<ProductCategoryDetailModel>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getVendorDiscounts(vendorId: number, filter: string) {
    return new Observable<Array<DiscountModel>>(subscriber => {
      this.productFilter = filter;
      var urlString = config.TRIOSAPI + "/api/vendor/getvendordiscounts?vendorId=" + vendorId + '&filter=' + filter;
      this.http.get<Array<DiscountModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public updateDiscount(vendorId: number, discount: DiscountModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/updatevendordiscount?vendorId=" + vendorId;
    return new Observable<DiscountModel>(subscriber => {
      this.http.post<DiscountModel>(urlString, discount)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public deleteDiscount(vendorId: number, discount: DiscountModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/deletevendordiscount?vendorId=" + vendorId;
    return new Observable<Array<DiscountModel>>(subscriber => {
      this.http.post<Array<DiscountModel>>(urlString, discount)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  postGalleryFile(vendorId: number, fileToUpload: File, fileType: String, sizeX: number, sizeY: number) {
    return new Observable<any>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('vendorId', vendorId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<FileManagerModel>(config.TRIOSAPI + "/api/vendor/uploadVendorGalleryImage?sizeX=" + sizeX + "&sizeY=" + sizeY, formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  postIconFile(vendorId: number, fileToUpload: File, fileType: String, sizeX: number, sizeY: number) {
    return new Observable<any>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('vendorId', vendorId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<FileManagerModel>(config.TRIOSAPI + "/api/vendor/uploadVendorIconImage?sizeX=" + sizeX + "&sizeY=" + sizeY, formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  public getGallery(vendorId: number) {
    var urlString = config.TRIOSAPI + "/api/vendor/getgallery?vendorId=" + vendorId;
    return new Observable<any>(subscriber => {
      this.http.get<any>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public removeFileFromGallery(vendorId: number, fileId: string) {
    var urlString = config.TRIOSAPI + "/api/vendor/removefilefromgallery?vendorId=" + vendorId + "&fileId=" + fileId;
    return new Observable<any>(subscriber => {
      this.http.delete<any>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public updateAddress(clientId: number, vendorId: number, data: AddressModel) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/vendor/updateaddress?clientId=' + clientId + '&vendorId=' + vendorId, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  postFeaturedHeaderImageFile(vendorId: number, fileToUpload: File, fileType: String) {
    return new Observable<VendorDetailModel>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('vendorId', vendorId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<VendorDetailModel>(config.TRIOSAPI + "/api/vendor/uploadVendorFeaturedImage", formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  postCardImageFile(vendorId: number, fileToUpload: File, fileType: String) {
    return new Observable<VendorDetailModel>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('vendorId', vendorId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<VendorDetailModel>(config.TRIOSAPI + "/api/vendor/uploadVendorCardImage", formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  public updateStoreSettings(vendorId: number, data: VendorStoreSettingsModel) {
    return new Observable<VendorStoreSettingsModel>(subscriber => {
      this.http.post<VendorStoreSettingsModel>(config.TRIOSAPI + '/api/vendor/updatestoresettings?vendorId=' + vendorId, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateStoreListing(vendorId: number, data: VendorStoreSettingsModel) {
    return new Observable<VendorStoreSettingsModel>(subscriber => {
      this.http.post<VendorStoreSettingsModel>(config.TRIOSAPI + '/api/vendor/updatestorelisting?vendorId=' + vendorId, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getVendorStoreSettings(vendorId: number) {
    var urlString = config.TRIOSAPI + "/api/vendor/getstoresettings?vendorId=" + vendorId;
    return new Observable<VendorStoreSettingsModel>(subscriber => {
      this.http.get<VendorStoreSettingsModel>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public getMenus(vendorId: number, userId: string, startDate: Date, endDate: Date) {
    var urlString = config.TRIOSAPI + "/api/preorder/getmenus?vendorId=" + vendorId + "&userId=" + userId + "&startDate=" + moment(startDate).format("DD MMM YYYY") + "&endDate=" + moment(endDate).format("DD MMM YYYY");
    return new Observable<Array<MenuModel>>(subscriber => {
      this.http.get<Array<MenuModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getVendorPreorderMenus(clientId: number, vendorId: number, startDate: Date, endDate: Date, active: boolean) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendormenus?clientId=" + clientId + "&vendorId=" + vendorId + "&startDate=" + moment(startDate).format("DD MMM YYYY") + "&endDate=" + moment(endDate).format("DD MMM YYYY");
    urlString += "&onlyActive=" + active;
    return new Observable<Array<MenuModel>>(subscriber => {
      this.http.get<Array<MenuModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public updatePreorderMenu(vendorId: number, menuData: MenuModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/updatevendormenu?vendorId=" + vendorId;
    return new Observable<DiscountModel>(subscriber => {
      this.http.post<DiscountModel>(urlString, menuData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public getVendorStoreMenus(clientId: number, vendorId: number, startDate: Date, endDate: Date, active: boolean) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorstoremenus?clientId=" + clientId + "&vendorId=" + vendorId + "&startDate=" + moment(startDate).format("DD MMM YYYY") + "&endDate=" + moment(endDate).format("DD MMM YYYY");
    urlString += "&onlyActive=" + active;
    return new Observable<Array<MenuModel>>(subscriber => {
      this.http.get<Array<MenuModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public updateStoreMenu(vendorId: number, menuData: MenuModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/updatestorewmenu?vendorId=" + vendorId;
    return new Observable<DiscountModel>(subscriber => {
      this.http.post<DiscountModel>(urlString, menuData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public getVendorVouchers(clientId: number, vendorId: number, active: boolean) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendorvouchers?clientId=" + clientId + "&vendorId=" + vendorId;
    urlString += "&onlyActive=" + active;
    return new Observable<Array<VoucherModel>>(subscriber => {
      this.http.get<Array<VoucherModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public updateVoucher(clientId: number, vendorId: number, voucherData: VoucherModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/updatevendorvoucher?clientId=" + clientId + "&vendorId=" + vendorId;
    return new Observable<VoucherModel>(subscriber => {
      this.http.post<VoucherModel>(urlString, voucherData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public updateVariants(clientId: number, vendorId: number, productId: number, variantList: Array<ProductV2Model>) {
    var urlString = config.TRIOSAPI + "/api/vendor/updateproductvariants?clientId=" + clientId + "&vendorId=" + vendorId + '&productId=' + productId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(urlString, variantList)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            // if ((data == null) || (data == undefined))
            //   return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateIncludes(clientId: number, vendorId: number, productId: number, variantList: Array<ProductV2Model>) {
    var urlString = config.TRIOSAPI + "/api/vendor/updateproductincludes?clientId=" + clientId + "&vendorId=" + vendorId + '&productId=' + productId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(urlString, variantList)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            // if ((data == null) || (data == undefined))
            //   return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }
  public updateSides(clientId: number, vendorId: number, productId: number, variantList: Array<ProductV2Model>) {
    var urlString = config.TRIOSAPI + "/api/vendor/updateproductsides?clientId=" + clientId + "&vendorId=" + vendorId + '&productId=' + productId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(urlString, variantList)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            // if ((data == null) || (data == undefined))
            //   return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getStateMachineWork(clientId: number, vendorId: number, stateMachineId: string) {
    var urlString = config.TRIOSAPI + "/api/vendor/getstatemachinework?clientId=" + clientId + "&vendorId=" + vendorId + '&stateMachineId=' + stateMachineId;
    return new Observable<Array<WorkflowItemModel>>(subscriber => {
      this.http.get<Array<WorkflowItemModel>>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public manualWorkflowChange(clientId: number, vendorId: number, orderId: string, stateId: string, newStateMachineId) {
    var urlString = config.TRIOSAPI + "/api/vendor/manualworkflowchange?clientId=" + clientId + "&vendorId=" + vendorId +
      '&orderId=' + orderId + '&stateId=' + stateId + '&newStateMachineId=' + newStateMachineId;
    return new Observable<Array<WorkflowItemModel>>(subscriber => {
      this.http.post<Array<WorkflowItemModel>>(urlString, "")
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public statemachinePass(clientId: number, workflowStateItem: WorkflowItemModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/statemachinepass?clientId=" + clientId;
    return new Observable<Array<WorkflowItemModel>>(subscriber => {
      this.http.post<Array<WorkflowItemModel>>(urlString, workflowStateItem)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public statemachineFail(clientId: number, workflowStateItem: WorkflowItemModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/statemachinefail?clientId=" + clientId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(urlString, workflowStateItem)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public getDeliverySettings(clientId: number, vendorId: number) {
    var urlString = config.TRIOSAPI + "/api/vendor/getdeliverysettings?clientId=" + clientId + "&vendorId=" + vendorId;
    return new Observable<VendorDeliveryModel>(subscriber => {
      this.http.get<any>(urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getIntegration(vendorId: number, integrationType: string) {
    let that = this;
    return new Observable<VendorIntegrationModel>(subscriber => {
      this.http.get<VendorIntegrationModel>(config.TRIOSAPI + '/api/vendor/getvendorIntegration?vendorId=' + vendorId + '&integrationtype=' + integrationType)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            if ((data === null) || (data === undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public updateIntegration(vendorId: number, integrationType: string, data: VendorIntegrationModel) {
    return new Observable<VendorIntegrationModel>(subscriber => {
      this.http.post<VendorIntegrationModel>(config.TRIOSAPI + '/api/vendor/updatevendorIntegration?vendorId=' + vendorId + '&integrationtype=' + integrationType, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });

  }

  public executeBulkAction(clientId: number, vendorId: number, action: string, actionData: string) {
    return new Observable<VendorIntegrationModel>(subscriber => {
      this.http.post<VendorIntegrationModel>(config.TRIOSAPI + '/api/vendor/executebulkaction?clientId=' + clientId + '&vendorId=' + vendorId + '&action=' + action, actionData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });

  }

  getOrderDetail(clientId: number, vendorId: number, orderId: string) {
    return new Observable<TransactionOrderModel>(subscriber => {
      this.http.get<TransactionOrderModel>(config.TRIOSAPI + '/api/vendor/getorderbyorderid?clientId=' + clientId + '&vendorId=' + vendorId + '&orderid=' + orderId)
        .pipe(
          map(response => {
            response.orderDate = moment.utc(response.orderDate).toDate().toString();
            return response;
          }
          )).subscribe(
            data => {
              subscriber.next(data);
            },
            response => {
              subscriber.error(response);
            }
          );
    });
  }

  public postOrderRefund(clientId: number, vendorId: number, data: TransactionOrderModel) {
    return new Observable<TransactionOrderModel>(subscriber => {
      this.http.post<TransactionOrderModel>(config.TRIOSAPI + '/api/vendor/postRefund?clientId=' + clientId + '&vendorId=' + vendorId, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public indegoRequest(clientId: number, vendorId: number, data: IndegoPaymentRequestModel) {
    return new Observable<IndegoPaymentResponseModel>(subscriber => {
      this.http.post<IndegoPaymentResponseModel>(config.TRIOSAPI + '/api/vendor/indegopaymentrequest?clientId=' + clientId + '&vendorId=' + vendorId, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });

  }

  public addDevice(clientId: number, vendorId: number, deviceRegistrationId: string) {
    return new Observable<Array<DeviceModel>>(subscriber => {
      this.http.post<Array<DeviceModel>>(config.TRIOSAPI + '/api/vendor/adddevice?clientId=' + clientId + '&vendorId=' + vendorId + '&deviceId=' + deviceRegistrationId, "")
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public getDevices(clientId: number, vendorId: number, searchString: string) {
    return new Observable<Array<DeviceModel>>(subscriber => {
      this.http.get<Array<DeviceModel>>(config.TRIOSAPI + '/api/vendor/getdevices?clientId=' + clientId + '&vendorId=' + vendorId + '&search=' + searchString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public updateDevice(clientId: number, vendorId: number, deviceData: DeviceModel) {
    return new Observable<Array<DeviceModel>>(subscriber => {
      this.http.post<Array<DeviceModel>>(config.TRIOSAPI + '/api/vendor/updatedevice?clientId=' + clientId + '&vendorId=' + vendorId, deviceData)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }



  public getVenues(clientId: number, vendorId: number, searchString) {
    return new Observable<Array<VenueModel>>(subscriber => {
      this.http.get<Array<VenueModel>>(config.TRIOSAPI + '/api/events/getvenues?clientId=' + clientId + '&vendorId=' + vendorId + '&search=' + searchString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public updateVenue(clientId: number, venueData: VenueModel) {
    var urlString = config.TRIOSAPI + "/api/events/updatevenue?clientId=" + clientId;
    return new Observable<VenueModel>(subscriber => {

      this.http.post<VenueModel>(urlString, venueData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateVenueSeating(clientId: number, venueSeatingData: SeatConfigModel) {
    var urlString = config.TRIOSAPI + "/api/events/updatevenueseating?clientId=" + clientId;
    return new Observable<VenueModel>(subscriber => {

      this.http.post<VenueModel>(urlString, venueSeatingData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getEvents(clientId: number, vendorId: number, searchString) {
    return new Observable<Array<EventModel>>(subscriber => {
      this.http.get<Array<EventModel>>(config.TRIOSAPI + '/api/events/getevents?clientId=' + clientId + '&vendorId=' + vendorId + '&search=' + searchString)
        .pipe(
          map(x => {
            x.forEach(y => moment(moment.utc(y.eventDate).toDate()).format("DD MMM YYYY HH:mm"))
            return x;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getEvent(eventId: number) {
    return new Observable<EventModel>(subscriber => {
      this.http.get<EventModel>(config.TRIOSAPI + '/api/events/getevent?eventId=' + eventId)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public holdSeats(clientId: number, seats: HoldSeatModel) {
    return new Observable<EventModel>(subscriber => {
      this.http.post<EventModel>(config.TRIOSAPI + '/api/events/holdseats?clientId=' + clientId, seats)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public updateEvent(clientId: number, venueData: EventModel) {
    var urlString = config.TRIOSAPI + "/api/events/updateevent?clientId=" + clientId;
    return new Observable<EventModel>(subscriber => {

      this.http.post<EventModel>(urlString, venueData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateEventImage(eventData: EventModel, fileToUpload: File, sizeX: number, sizeY: number, fileType: String,) {
    return new Observable<EventModel>(subscriber => {

      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append("sizeX", sizeX.toString());
      formData.append("sizeY", sizeY.toString());
      formData.append("eventData", JSON.stringify(eventData));

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }
      var urlString = config.TRIOSAPI + "/api/events/uploadImage";
      return this.http.post<EventModel>(urlString, formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }



  public getVendorTickets(vendorId: number, eventId: number, filter: string) {
    var urlString = config.TRIOSAPI + "/api/vendor/getvendortickets?vendorId=" + vendorId + '&eventId=' + eventId + '&filter=' + filter;
    return new Observable<Array<EventTicketModel>>(subscriber => {
      this.http.get<Array<EventTicketModel>>(urlString).subscribe(
        data => {
          if ((data == null) || (data == undefined))
            return;
          subscriber.next(data);
        },
        response => {
          subscriber.error(response);
        }
      );
    });
  }

  public deleteTicket(vendorId: number, eventId: number, ticketId: string, withRefund: boolean) {
    var urlString = config.TRIOSAPI + "/api/vendor/deleteticket?vendorId=" + vendorId + "&eventId=" + eventId + "&ticketId=" + ticketId + "&withRefund=" + withRefund;
    return new Observable<Array<EventTicketModel>>(subscriber => {
      this.http.post<Array<EventTicketModel>>(urlString, null).subscribe(
        data => {
          subscriber.next(data);
        },
        response => {
          subscriber.error(response);
        }
      );
    });
  }

  public emailTicket(vendorId: number, userId: string, ticketData: EventTicketModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/emailtickets?vendorId=" + vendorId + '&userId=' + userId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(urlString, ticketData).subscribe(
        data => {
          if ((data == null) || (data == undefined))
            return;
          subscriber.next(data);
        },
        response => {
          subscriber.error(response);
        }
      );
    });
  }

  public getSettlementConfig(vendorId: number) {
    var urlString = config.TRIOSAPI + "/api/vendor/getsettlementconfig?vendorId=" + vendorId;
    return new Observable<VendorSettlementModel>(subscriber => {
      this.http.get<VendorSettlementModel>(urlString).subscribe(
        data => {
          if ((data == null) || (data == undefined)) {
            subscriber.error("nosettlement");
          }
          else {
            subscriber.next(data);
          }
        },
        response => {
          subscriber.error(response);
        }
      );
    });
  }


  public applyForSettlement(vendorId: number, data: VendorSettlementModel) {
    var urlString = config.TRIOSAPI + "/api/vendor/applyforsettlement?vendorId=" + vendorId;
    return new Observable<VendorSettlementModel>(subscriber => {
      this.http.post<VendorSettlementModel>(urlString, data).subscribe(
        data => {
          if ((data == null) || (data == undefined)) {
            subscriber.error("nosettlement");
          }
          else {
            subscriber.next(data);
          }
        },
        response => {
          subscriber.error(response);
        }
      );
    });
  }

  public getAllVendorSettlements(vendorId: number) {
    var urlString = "/api/vendor/getallvendorsettlements?vendorId=" + vendorId;
    return new Observable<Array<VendorSettlementResultModel>>(subscriber => {
      this.http.get<Array<VendorSettlementResultModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getVendorTheme(vendorId: number) {
    var urlString = "/api/vendor/getvendortheme?vendorId=" + vendorId;
    return new Observable<VendorThemeModel>(subscriber => {
      this.http.get<VendorThemeModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public addVendorImage(formData: FormData) {
    let that = this;
    return new Observable<VendorThemeModel>(subscriber => {
      var url = config.TRIOSAPI + '/api/vendor/addimage';
      that.http.post<VendorThemeModel>(url, formData)
        .subscribe({
          next: (data: VendorThemeModel) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }

        }
        );
    });
  }

  public updateTheme(vendorId: number, themeData: VendorThemeModel) {
    let that = this;
    return new Observable<VendorThemeModel>(subscriber => {
      var url = config.TRIOSAPI + '/api/vendor/updateTheme?vendorId=' + vendorId;
      that.http.post<VendorThemeModel>(url, themeData)
        .subscribe({
          next: (data: VendorThemeModel) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }

        }
        );
    });
  }

  public removeVendorImage(vendorId: number, imageData: ImageModel) {
    let that = this;
    return new Observable<VendorThemeModel>(subscriber => {
      that.http.post<VendorThemeModel>(config.TRIOSAPI + '/api/vendor/removeimage?vendorId=' + vendorId, imageData)
        .subscribe({
          next: (data: VendorThemeModel) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }

        }
        );
    });
  }

  public lookupTasks(vendorId: number, userId: string, filter: string) {
    var urlString = "/api/task/lookupTasks?vendorId=" + vendorId + "&userId=" + userId + "&filter=" + filter;
    return new Observable<TaskLookupModel>(subscriber => {
      this.http.get<TaskLookupModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getTask(vendorId: number, taskId: string) {
    var urlString = "/api/task/getVendorTask?vendorId=" + vendorId + "&taskId=" + taskId;
    return new Observable<TaskBaseModel>(subscriber => {
      this.http.get<TaskBaseModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateTask(vendorId: number, taskData: TaskModel) {
    var urlString = "/api/task/updateTask?vendorId=" + vendorId;
    return new Observable<TaskModel>(subscriber => {
      this.http.post<TaskModel>(config.TRIOSAPI + urlString, taskData)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public deleteTask(vendorId: number, taskId: string) {
    var urlString = "/api/task/deleteTask?vendorId=" + vendorId + "&taskId=" + taskId;
    return this.http.delete<TaskModel>(config.TRIOSAPI + urlString)
  }


  public createTimeSlotInventory(data: TimeSlotConfigInventoryModel): Observable<TimeSlotConfigInventoryModel>{
    var urlString = "/api/vendorinventory/createtimeslotinventory";
    return this.http.post<TimeSlotConfigInventoryModel>(config.TRIOSAPI + urlString, data)
  }

  public updateTimeSlotInventory(data: TimeSlotConfigInventoryModel): Observable<TimeSlotConfigInventoryModel>{
    var urlString = "/api/vendorinventory/updatetimeslotinventory";
    return this.http.post<TimeSlotConfigInventoryModel>(config.TRIOSAPI + urlString, data)
  }

  public refundTimeSlotInventory(data: InventoryModel): Observable<any>{
    var urlString = "/api/vendorinventory/refundtimeslotinventory";
    return this.http.post<any>(config.TRIOSAPI + urlString, data)
  }

  public deleteTimeSlotInventory(data: TimeSlotConfigInventoryModel): Observable<TimeSlotConfigInventoryModel>{
    var urlString = "/api/vendorinventory/deletetimeslotinventory";
    return this.http.post<TimeSlotConfigInventoryModel>(config.TRIOSAPI + urlString, data)
  }

  public getTimeSlotinventory(vendorId: number) : Observable<Array<TimeSlotInventoryDataModel>>{
    var url = config.TRIOSAPI + "/api/vendorinventory/gettimeslotInventory?vendorId="
    return this.http.get<Array<TimeSlotInventoryDataModel>>(url + vendorId)
  }

  public getSelectedTimeSlotinventory(vendorId: number, id: string) : Observable<TimeSlotInventoryDataModel>{
    var url = config.TRIOSAPI + "/api/vendorinventory/getselectedtimeslotInventory?vendorId=" + vendorId + "&inventoryId=" + id
    return this.http.get<TimeSlotInventoryDataModel>(url )
  }

  public updateSelectedTimeSlotinventory(vendorId: number, id: string,  data: TimeSlotInventoryDataModel ) : Observable<TimeSlotInventoryDataModel>{
    var url = config.TRIOSAPI + "/api/vendorinventory/updateselectedtimeslotInventory?vendorId=" + vendorId + "&inventoryId=" + id;
    return this.http.post<TimeSlotInventoryDataModel>(url,data)
  }
  // public getProductrTimeSlotinventory(productId: number) : Observable<Array<TimeSlotInventoryDataModel>>{
  //   var url = config.TRIOSAPI + "/api/vendorinventory/getproductinventory?productId="
  //   return this.http.get<Array<TimeSlotInventoryDataModel>>(url + productId)
  // }


  public addProductToTimeSlotinventory(vendorId: number, timeSlotInventoryId: string,  productId: number, type: string)
  {
    var url = config.TRIOSAPI + "/api/vendorinventory/addproducttotimeslotInventory"
    return this.http.post<Array<TimeSlotInventoryDataModel>>(url,{vendorId: vendorId, timeSlotId: timeSlotInventoryId, productId: productId, type: type})
  }


  public removeProductFromTimeSlotinventory(vendorId: number, timeSlotInventoryId: string,  productId: number, type: string)
  {
    var url = config.TRIOSAPI + "/api/vendorinventory/removeproductfromtimeslotInventory"
    return this.http.post<Array<TimeSlotInventoryDataModel>>(url,{vendorId: vendorId, timeSlotId: timeSlotInventoryId, productId: productId, type: type})
  }

}

import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '@app/core/services/user.service';
import { UserModel } from '../../../core/models/usermodels/user.model';
import { PermissionsModel } from '../../../core/models/usermodels/permissions.model';

import * as moment from "moment";


@Component({
  selector: 'account-pinpad',
  templateUrl: './pinpad.component.html',
  styleUrls: ['./pinpad.component.css']
})
export class AccountPinPadComponent implements OnInit, AfterViewInit {

  userData: UserModel;
  userPermissions: PermissionsModel;
  _pinCode: string;
  apinCode = "";

  @Input('accountdata') set accountdata(value) {
    this.userData = value;
  }

  @Input('visible') set visible(value: boolean) {
    if (value)
      $('#pinpadModal').modal('show');
    else
      $('#pinpadModal').modal('hide');
  }

  @Input()
  set pincode(value) {
    this._pinCode = value;
    this.pincodeChange.emit(this.pincode);
    if (this._pinCode.length > 4)
      $('#pinpadModal').modal('hide');
  }
  get pincode() {
    return this._pinCode;
  }


  @Output()
  pincodeChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService) {
  }

  ngAfterViewInit() {

  }


  ngOnInit() {

  }




  onSubmit() {
    $('#pinpadModal').modal('hide');
    if (this.apinCode === this.userData.security.pin){
        this.pincodeChange.emit("success");
    }
    else{
      this.pincodeChange.emit("fail");
    }    
    this.apinCode = "";
  }

  onCancel() {
    $('#pinpadModal').modal('hide');
    this.pincodeChange.emit("");
    this.apinCode = "";
  }

  onKeyPress(key)
  {
    if(key === "#")
       this.apinCode = this.apinCode.substring(0, this.apinCode.length-1);
    else
      this.apinCode += key;

  }
}

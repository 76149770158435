import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LocalService } from '@app/core/services/local.service';
import { UserService } from '@app/core/services/user.service';
import { UserModel } from '@app/core/models/usermodels/user.model';

import * as userStore from '@app/core/store/user'
import { Subscription } from 'rxjs';

@Component({
  selector: 'golfkiosk-layout',
  templateUrl: './golfkiosk-layout.component.html',
  styles: ['./golfkiosk-layout.component.css']
})
export class GolfKioskLayoutComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;

  constructor(private localService: LocalService,
              private userService: UserService,
              private store: Store<any>) { 
    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.localService.control.subscribe(data => {
        //console.log(JSON.stringify(data));
      })
    );

    this.subscriptions.add(
      this.localService.tapMessage.subscribe(data => {
        //console.log(JSON.stringify(data));
      })
    );

    this.localService.connect();
  }

  ngOnDestroy(){
    if(this.subscriptions){
      this.subscriptions.unsubscribe();
    }
       
  }

}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from '@angular/router';
//import { AuthenticationGuard } from 'microsoft-adal-angular6';

import { ClientService } from '../services/client.service'
import { AuthService } from '../services/auth.service'
import { UserService } from '../services/user.service'
import { Observable } from 'rxjs';
import { DialogUtility } from '@syncfusion/ej2-popups';

function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
}

function getConfiguredUrl(route: ActivatedRouteSnapshot): string {
  return '/' + route.pathFromRoot
    .filter(v => v.routeConfig)
    .map(v => v.routeConfig!.path)
    .join('/');
}

@Injectable()
export class LocationGuard implements CanActivateChild {

  public DialogObj;

  constructor(
    private clientService: ClientService,
    private userService: UserService,
    private router: Router) { }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.clientService.currentClient == null) {
      return false;
    }
    try {
      if (this.clientService.currentClient.economy.economyRules.locationEnabled && this.clientService.currentClient.economy.economyRules.locationRequired) {
        const currentRoute = getResolvedUrl(route);
        if (currentRoute.includes('store')) {
          //alert("You need to activate and save a location!")
          var storedLocation = this.userService.getStoredLocation();
          if (storedLocation == null) {
            this.DialogObj = DialogUtility.alert({
              title: 'Notice!',
              content: "You need to activate and save a location <br/> on the dashboard! <br/><br/> Before using the store.'",
              okButton: { text: 'OK', click: this.okClick.bind(this) },
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' }
            });
            return false;
          }
          return true;
        }
        else if (currentRoute.includes('clientadmin/tasks')) {
          if (this.userService.isUserinRole("TaskAdmin") || this.userService.isUserinRole("ClientAdmin") || this.userService.isUserinRole("Masteradmin"))   {
            return true;
          }
          return false;
       }
       else if (currentRoute.includes('clientadmin')) {
           if (this.userService.isUserinRole("ClientAdmin") 
           || this.userService.isUserinRole("MasterAdmin") 
           || (this.userService.isUserinRole("TaskAdmin") && currentRoute == '//clientadmin')){
             return true;
           }
           return false;
        }
        
        return true;
      }
    }
    catch (e) {

    }
    return true;

  }

  private okClick(): void {
    this.router.navigate(["/dashboard/analytics"]);
    this.DialogObj.hide();
  }

}

import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
declare var ga: Function;

@Injectable()
export class GoogleAnalyticsService {


  private requiredSet = false;

  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      try {
        if (typeof ga === 'function') {
          if (event instanceof NavigationEnd) {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
            // console.log('%%% Google Analytics page view event %%%');
          }
        }
      } catch (e) {
        // console.log(e);
      }
    });

  }


  /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} eventLabel
   * @param {number} eventValue
   */
  public emitEvent(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
     if (typeof ga === 'function') {
       ga('send', 'event', {
         eventCategory: eventCategory,
         eventLabel: eventLabel,
         eventAction: eventAction,
         eventValue: eventValue
       });
     }
   }
 
   public addImpression(vendorName: string, category:string, pId: number, pName: string, pPrice: number, pQuantity: number, position: number) {
    if (typeof ga === 'function') {
      if (!this.requiredSet){
       ga('require', 'ec');
       ga('set', 'currencyCode', 'ZAR');
       this.requiredSet = true;
      }
      ga('ec:addImpression', {
        id: pId,
        name: pName,
        list: "general",
        brand: vendorName,
        category: category,
        variant: "",
        price: pPrice,                  
        position: position         
      });
    }
  }


   public addProduct(vendorName: string, category:string, pId: number, pName: string, pPrice: number, pQuantity: number, position: number) {
     if (typeof ga === 'function') {
       if (!this.requiredSet){
        ga('require', 'ec');
        ga('set', 'currencyCode', 'ZAR');
        this.requiredSet = true;
       }
       ga('ec:addProduct', {
         id: pId,
         name: pName,
         brand: vendorName,
         category: category,
         variant: "",
         price: pPrice,                  
         quantity: pQuantity,
         position: position         
       });
       ga("ec:setAction", "add");
       ga("send", "event", "detail view", "click", "addToCart");
     }
   }

   public addTransactionItem(vendorName: string, category:string, pId: number, pName: string, pPrice: number, pQuantity: number, position: number) {
     if (typeof ga === 'function') {
       if (!this.requiredSet){
        ga('require', 'ec');
        ga('set', 'currencyCode', 'ZAR');
        this.requiredSet = true;
       }
       ga('ec:addProduct', {
         id: pId,
         name: pName,
         brand: vendorName,
         category: category,
         variant: "",
         price: pPrice,                  
         quantity: pQuantity,
         position: position         
       });
     }
   }

   public addTransaction(orderId: string, vendorName:string, revenue: number, tax: number) {
    if (typeof ga === 'function') {
      if (!this.requiredSet){
       ga('require', 'ec');
       ga('set', 'currencyCode', 'ZAR');
       this.requiredSet = true;
      }
      ga("ec:setAction", "purchase", {
        id: orderId,
        affiliation: vendorName,
        revenue: revenue,
        tax: tax,
        shipping: 0,
        coupon: ""
      });
      ga('send','event','Ecommerce','Purchase','revenue');
    }
  }

}
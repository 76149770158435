
import * as moment from 'moment'


export const events =  [{
    "id": "544c4183be624ef013bb009a",
    "title": "All Day Event",
    "start": moment().subtract(15, 'day').startOf('day').add(21, 'hours'),
    "description": "long description",
    "icon": "fa-check",
    "className": ["event", "bg-color-greenLight"]
} ];


export const samples = [
  {
      id: 'ee1',
      title: "Office Meeting",
      description: "Currently busy",
      className: "bg-color-darken txt-color-white",
      icon: "fa-time"
  }];

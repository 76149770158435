import { Component, OnInit, Input } from '@angular/core';
import * as moment from "moment";

@Component({
  selector: 'account-activity-item',
  templateUrl: './account-activityitem.component.html',
})
export class AccountActivityItemComponent{

  _activity;
  activityTime;
  activityDay;

  @Input('activity') set activity(value){
    this._activity = value
    this.activityTime = moment(this._activity.activityTime, 'DD MMM YYYY HH:mm')
    this.activityDay = this.activityTime.format('D');
  }
  
  constructor() {}

  ngOnInit(){

  }
}

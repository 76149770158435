
import { Injectable } from '@angular/core';
import { AuthActionTypes } from './../auth/auth.actions';

import { Observable } from 'rxjs';
import { map, mapTo, tap, switchMap, } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';

import { UserModel } from '@app/core/models/usermodels/user.model';
import { UserPurchasesModel } from '@app/core/models/usermodels/userpurchases.model';
import { VendorModel } from '@app/core/models/vendormodels/vendor.model';
import { UserActions } from './user.actions';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { UserStatsService } from '@app/core/services/userstats.service';

@Injectable()
export class UserEffects {

  @Effect()
  GetUserAuth$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.LoginAction),
    switchMap((res : any) =>{
      if (res.payload !== false)
         return [this.userActions.getUser()];
      return  []; 
    })
  );


  @Effect()
  GetUser$: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.GET_USER),
    switchMap((action: any) => this.userService.getUser()),
    map((data: UserModel) => this.userActions.getUserSuccess(data))
  );

  @Effect()
  GetUserPurchases$: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.GET_PURCHASES),
    switchMap((action: any) => this.userStatsService.getPurchasesThisWeek()),
    map((data: UserPurchasesModel) => this.userActions.getUserPurchasesSuccess(data))
  );

  @Effect()
  GetVendors$: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.GET_VENDORS),
    switchMap<Action, Observable<Array<VendorModel>>>((action: any) => {
      return this.userService.getVendors(action.payload);
    }),
    map((data: VendorModel[]) => this.userActions.getUserVendorsSuccess(data))
  );

  @Effect()
  GetLinkedAccounts$: Observable<Action> = this.actions$.pipe(
    ofType(UserActions.GET_LINKED_ACCOUNTS),
    switchMap((action: any) => this.userService.getLinkedAccounts()),
    map((data: UserModel[]) => this.userActions.getLinkedAccountsSuccess(data))
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private userService: UserService,
    private userStatsService: UserStatsService,
    private userActions: UserActions,
    private store: Store<any>,
  ) {

  }
}

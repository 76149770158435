import { Map, Record, List } from 'immutable';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { RoleModel } from '@app/core/models/accountmodels/role.model';
import { UserGroupModel } from '@app/core/models/clientmodels/usergroup.model';


export interface ClientState extends Map<string, any> { 
    clientState: ClientModel;
    clientRoles: Array<RoleModel>;
    clientGroups: Array<UserGroupModel>;
 }

export const ClientStateRecord = Record({ 
    clientState:null,
    clientRoles:List([]),
    clientGroups:List([]),
});
import { WaitronByCardIdRequest } from '@app/core/models/pos/waitronbycardid-request.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Observer } from 'rxjs/Observer';
import { map, flatMap, catchError, finalize } from 'rxjs/operators';
import { webSocket } from "rxjs/webSocket";

import { Socket } from '../interfaces/socket.interface';
import { Subscriber, timer } from 'rxjs';

import { config } from '@app/core/smartadmin.config';
import { UserContractRequest } from '../models/pos/usercontract-request.model';


@Injectable()
export class LocalService {
  private subject;
  private subscription;
  private keepAlive: boolean = true;
  private serviceAddress: string = "wss://localhost:8181";
  observer: Observer<any>;
  private connectTimer;

  public tapMessage: Subject<any> = new Subject<any>();
  public control: Subject<any> = new Subject<any>();
  private userId: string;
  responseDict: { [id: string]: Subject<any>; } = {};

  constructor() {
  }


  connect() {

    if (config.useDeviceService == false)
      return;
    
      if (this.subject != null)
       return;

    // console.log("LocalService connecting to :" + this.serviceAddress)
    this.subject = webSocket(this.serviceAddress);

    this.subscription = this.subject.subscribe(
      msg => {
        // console.log('Local Service message received: ' + msg.messageType);
        var responseObs = this.responseDict[msg.messageType];
        if (responseObs != null){
          responseObs.next(msg);
          //responseObs.complete();
        }
        else {
          switch (msg.messageType) {
            case "ContractMessage":
              //this.signContractWithController(userId);
              break;

            case "NFCTapMessage":
              this.tapMessage.next(msg.CardId);
              break;

            case "POSStart":
                this.control.next(msg.CardId);
                break;   

            case "ControlMessage":
                  this.control.next(msg);
                  break;
            default:
              alert(msg);
              break;
          }
        }

      },
      err => {
        alert(err);
        if (this.keepAlive)
          this.reconnectTimer()
        // console.log('Local Service error', err);
      }, // Called if at any point WebSocket API signals some kind of error.
      () => {
        // console.log('Local Service complete') // Called when connection is closed (for whatever reason).
        alert("complete");
        if (this.keepAlive) {
          this.connect();
        }
      }
    );
  }

  reconnectTimer() {

    if (this.connectTimer)
      clearTimeout(this.connectTimer);

    this.connectTimer = setTimeout(
      () => {
        this.connect();
      },
      1000);
  }

  sendMessage() {
    this.subject.next({ message: 'some message' });
  }


  lookupWaitron(cardId: string) {
    var request = new WaitronByCardIdRequest();
    request.cardId = cardId;
    this.subject.next(request);
    if (this.responseDict["WaitronByCardIdResponse"] == null)
       this.responseDict["WaitronByCardIdResponse"] = new Subject<any>();
    return this.responseDict["WaitronByCardIdResponse"].asObservable();
  }

  signContractWithController(userId: string) {
    var request = new UserContractRequest();
    request.userId = userId;
    request.key = "123244542353124234";
    request.secret = "werwer efwer99wer"
    this.subject.next(request);
  }
}
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { NotificationService } from "../../../../../core/services/notification.service";


@Component({
  selector: '[activitiesMessage]',
  templateUrl: './activities-message.component.html',
})
export class ActivitiesMessageComponent implements OnInit {

  public _item:any;
  @Input() set item (value: any)
  {
      this._item = value;
      this._item.image = "assets/img/message-icon.png"
  }

  constructor(private notifyService :NotificationService){

  }

  ngOnInit() {
  }

  onItemClick(event){
    //alert("Small Click " + JSON.stringify(this.item.Id));
    this.notifyService.setMessageAsRead(this._item.id).subscribe();
  }

}

import { map, tap, switchMap } from 'rxjs/operators';
import { PosActions } from './pos.actions';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { PosService } from '@app/core/services/pos.service';
import { Action } from '@ngrx/store';

import { ProductModel } from '@app/core/models/productmodels/product.model';

@Injectable()
export class PosEffects {
  @Effect()
  GetWaitron$: Observable<Action> = this.actions$.pipe(
    ofType(PosActions.GET_WAITRON),
    switchMap((action: any) => this.posService.getWaitronByCardId(action.payload)),
    map((data: any) => this.posActions.getWaitronSuccess({ waitron: data }))
  );


  constructor(
    private actions$: Actions,
    private posService: PosService,
    private posActions: PosActions
  ) { }
}

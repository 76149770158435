import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SmartadminDatatableModule } from '@app/shared/ui/datatable/smartadmin-datatable.module';
import { ClientInfoComponent } from './clientinfo/client-info.component';
import { ClientBannerComponent } from './clientbanner/client-banner.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SmartadminDatatableModule,
    PipesModule,
  ],
  declarations: [
    ClientInfoComponent,
    ClientBannerComponent,
  ],
  exports: [
    ClientInfoComponent,
    ClientBannerComponent
  ],
  providers: []
})
export class ClientModule { }

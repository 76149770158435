import { AppState } from '../index';
import { ClientState } from './client.state';
import { createSelector } from 'reselect';

import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { RoleModel } from '@app/core/models/accountmodels/role.model';
import { UserGroupModel } from '@app/core/models/clientmodels/usergroup.model';

// Base product state selector function
const getClientState = (state: AppState): ClientState => state.client;
const fetchClientState = (state: ClientState) => (state.clientState as unknown) as ClientModel;
const fetchClientRoles = (state: ClientState) => (state.clientRoles as unknown) as Array<RoleModel>;
const fetchClientGroups = (state: ClientState) => (state.clientGroups as unknown) as Array<UserGroupModel>;


// *************************** PUBLIC API's ****************************
export const getClient = createSelector( getClientState, fetchClientState );

export const getClientRoles = createSelector( getClientState, fetchClientRoles );

export const getClientGroups = createSelector( getClientState, fetchClientGroups );


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, EMPTY, Subscription } from 'rxjs';
import { map, tap, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action, select } from '@ngrx/store';

import { AuthActionTypes } from '../auth/auth.actions';

import { PreorderCartActions } from './preorder-cart.actions';

import { UserService } from '@app/core/services/user.service';
import { PreorderService } from '@app/core/services/preorder.service';
import * as clientStore from '@app/core/store/client'
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { DataService } from '@app/core/services';

@Injectable()
export class PreorderCartEffects {

  private subscriptions = new Subscription();
  private clientData: ClientModel = null;

  @Effect()
  POGetAuthLogin$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.LoginAction),
    switchMap((res: any) => {
      // console.log("Whats up ", res);
      //if (res.payload !== false)
      //  return [this.cartActions.getCart(null)]
      return [];
    })
  );


  @Effect()
  POGetCartOrder$: Observable<Action> = this.actions$.pipe(
    ofType(PreorderCartActions.GET_CART),
    switchMap((action: any) => this.cartService.getCart(action.payload)),
    map((data: any) => {
      return this.cartActions.getCartSuccess({ preorderOrders: data });
    })
  );

  @Effect()
  POAddItemToCart$: Observable<Action> = this.actions$.pipe(
    ofType(PreorderCartActions.ADD_ITEM_TO_CART),
    switchMap((action: any) => this.cartService.addItemToCart(action.payload)),
    map((data: any) => {
      return this.cartActions.addItemToCartSuccess({ preorderOrders: data });
    }),
  );


  @Effect()
  PORemoveItemFromCart$: Observable<Action> = this.actions$.pipe(
    ofType(PreorderCartActions.REMOVE_ITEM_FROM_CART),
    switchMap((action: any) =>
      this.cartService.removeItemFromCart(action.payload)
    ),
    map((data: any) => this.cartActions.removeItemFromCartSuccess({ preorderOrders: data })),
    catchError(err => {
      return EMPTY;
    })
  );

  @Effect()
  POPlaceOrder$: Observable<Action> = this.actions$.pipe(
    ofType(PreorderCartActions.PLACE_ORDER),
    switchMap((action: any) => this.cartService.placeWebOrder(action.payload)),
    map((data: any) => this.cartActions.placeCardOrderSuccess(data)),
  );

  @Effect()
  POPlaceCardOrder$: Observable<Action> = this.actions$.pipe(
    ofType(PreorderCartActions.PLACE_CARD_ORDER),
    switchMap((action: any) => this.cartService.placeCardOrder()),
    map((data: any) => this.cartActions.placeCardOrderSuccess(data)),
  );

  @Effect()
  POEmptyCart$: Observable<Action> = this.actions$.pipe(
    ofType(PreorderCartActions.EMPTY_CART),
    switchMap((action: any) => this.cartService.emptyCart(action.payload)),
    map((data: any) => this.cartActions.emptyCartSuccess({ order: data })),
    tap(() => [
      this.navigatePODash()
    ])
  );

  @Effect({ dispatch: false })
  POPlaceOrderSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(PreorderCartActions.PLACE_ORDER_SUCCESS),
    map(_ => _)
  );

  @Effect({ dispatch: false })
  POPlaceOrderFail$: Observable<Action> = this.actions$.pipe(
    ofType(PreorderCartActions.PLACE_CARD_ORDER_FAIL),
    map(_ => _)
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private cartService: PreorderService,
    private cartActions: PreorderCartActions,
    private userService: UserService,
    private dataService: DataService,
    private toastr: ToastrService,
    private store: Store<any>
  ) {
    this.subscriptions.add(
      this.store.pipe(
        select(clientStore.getClient)
      ).subscribe(
        (data: ClientModel) => {
          if (data == null)
            return;
          this.clientData = data;
        }));
  }

  navigatePODash() {
    if (this.dataService.preorderMode === "kiosk") {
      this.router.navigate(['/kiosk/dash', { role: 'PREORDERUSER' }]);
    }
    else {
      if (this.clientData.preorderType === "advmenu") {
        this.router.navigate(['/preorderv2/dashboard', { role: 'PREORDERUSER' }]);
      }
      else {
        this.router.navigate(['/preorder/dashboard', { role: 'PREORDERUSER' }]);
      }
    }
  }
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { UserService } from '@app/core/services/user.service';
import { UserModel } from '../../../core/models/usermodels/user.model';
import { PermissionsModel } from '../../../core/models/usermodels/permissions.model';

import * as moment from "moment";
import { Subscription } from 'rxjs';

@Component({
  selector: 'account-activity',
  templateUrl: './account-activity.component.html',
  styleUrls:['./account-activity.component.css']
})
export class AccountActivityComponent implements OnInit, OnDestroy {

  userData:UserModel;
  userPermissions:PermissionsModel;
  activities: any[];
  


  @Input('accountdata') set accountdata(value){
    this.userData = value;
    this.loadActivity();
  }

  @Input('permissions') set permissions(value){
    this.userPermissions   = value;
  }

  constructor(private userService:UserService) {}

  ngOnInit() {
    
  }

  loadActivity(){
    this.userService.loadActivities().subscribe(activityData=>{
      this.activities = activityData;
    });  
  }

  ngOnDestroy(){
  
  }
}

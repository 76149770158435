import { Injectable, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import * as moment from 'moment'
import { config } from '../../core/smartadmin.config';

import { UserModel } from '../models/usermodels/user.model';


declare var $: any;

@Injectable()
export class NotificationService implements OnInit {
  
  private userData: UserModel = null;


  private subscription: Subscription;
  private hasNotify = false;
  private notifySubject = new BehaviorSubject<any>(null);
  public notifyData:any;
  private subscriptions: Subscription[] = [];
  
  constructor(private http: HttpClient,
              private router: Router) {    
  }
  
  public init(userData:UserModel){
    this.userData = userData;    
  }

  public deInit(){
    this.userData = null; 
    this.hasNotify = false;
    this.notifySubject.observers.forEach(obs => {
      obs.complete();
    });
    this.notifySubject.next(null);
  }

  smallBox(data, cb?) {
    $.smallBox(data, cb)
  }

  bigBox(data, cb?) {
    $.bigBox(data, cb)
  }

  smartMessageBox(data, cb?) {
    $.SmartMessageBox(data, cb)
  }

  ngOnInit() {
  }


  public notify(message){
    this.fetchNotifications();
  }

  public getNotifications(): Observable<any> {
    let that = this;
    if (!that.hasNotify) {
       that.fetchNotifications();
    }
    return this.notifySubject.asObservable();
  }


  public fetchNotifications(): void {
    this.hasNotify = true;
    var urlString = "/api/notifications/LoadNotifications";
    this.http
      .get<any>(config.TRIOSAPI + urlString)
      .pipe(
        map(response => {
          response.msgs.data.forEach(element => {
            element.time = moment(moment.utc(element.time).toDate()).format("DD MMM YYYY HH:mm")
          });
          return response;
        })
      )
      .subscribe(
        data => {
          if ((data == null) || (data == undefined))
             return;
          this.notifyData = data;
          this.notifySubject.next(data);              
        },
        errorResponse => {
          this.hasNotify = false;
          this.notifySubject.error(errorResponse);
        }
      );
  }

  public setMessageAsRead(messageId:string){
    var urlString = "/api/notifications/setmessageasread?messageId=" + messageId; 
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            this.notifyData = data;
            this.notifySubject.next(data);  
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public deleteAllMessages(){
    var urlString = "/api/notifications/deleteallmessages"; 
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, "")
        .subscribe(
          data => {
            this.notifyData = data;
            this.notifySubject.next(data);  
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }
}

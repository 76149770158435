import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { config } from "../../core/smartadmin.config";
import { FileManagerModel } from './../models/filemanagermodels/filemanager.model';

@Injectable()
export class FileManagerService {

  constructor(private http: HttpClient) {
  }



  postFile(userId: String, fileToUpload: File, fileType: String, sizeX: number, sizeY: number){
    return new Observable<any>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('userId', userId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<FileManagerModel>(config.TRIOSAPI + "/api/filemanager/uploadimage?sizeX=" + sizeX + "&sizeY=" + sizeY, formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

}

import { OrderResponseModel } from '@app/core/models/cart/order-response.model';
import { POMenuOrderModel } from '@app/core/models/preorder-cart/pomenuorder.model';
import { Map, Record, List } from 'immutable';


export interface PreorderCartState extends Map<string, any> {
  orderState: OrderResponseModel;
  error: string;
  preorderOrders: Array<POMenuOrderModel>;
}

export const PreorderCartStateRecord = Record({
  orderState: null,
  error: null,
  preorderOrders: {}  
});
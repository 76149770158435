import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable, Subject, ReplaySubject } from "rxjs";
import { Subscription } from "rxjs/Subscription";
import { map } from "rxjs/operators";
import { config } from "@app/core/smartadmin.config";
import { VoucherModel } from "./../models/vouchermodels/voucher.model";
import { CartOrderModel } from "../models/cart/cart-order.model";

@Injectable()
export class VoucherService {

  public subscribed: boolean;
  hasVouchers: boolean = false;
  public voucherSubject = new Subject<Array<VoucherModel>>();

  constructor(private router: Router,
    private http: HttpClient) {
    this.subscribed = false;
  }

  public getVouchers(refresh: boolean): Observable<Array<VoucherModel>> {

    if (refresh)
      this.hasVouchers = false;

    if (!this.hasVouchers) {
      this.getUserVouchers("none");
    }
    return this.voucherSubject.asObservable();
  }

  public getUserVouchers(userId: string) {
    let that = this;
    that.hasVouchers = true;
    this.http.get<Array<VoucherModel>>(config.TRIOSAPI + '/api/voucher/getvouchers?userId=' + userId)
      .pipe(
        map(response => {
          return response;
        }
        ))
      .subscribe(
        data => {
          that.hasVouchers = true;
          that.voucherSubject.next(data);
        },
        response => {
          this.voucherSubject.error(response);
        }
      );
  }


  public deleteVoucher(voucherId: string) {
    return new Observable<any>(subscriber => {
      this.http.delete<any>(config.TRIOSAPI + '/api/voucher/deleteVoucher?userId=none&voucherId=' + voucherId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
            this.getVouchers(true);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getUserMenuVouchers(userId: string) {
    let that = this;
    return new Observable<Array<VoucherModel>>(subscriber => {
      this.http.get<Array<VoucherModel>>(config.TRIOSAPI + '/api/voucher/getmenuvouchers?userId=' + userId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            this.voucherSubject.error(response);
          }
        );
    });
  }

  public checkCartForMealVouchers(userId: string, cartData: CartOrderModel) {
    let that = this;
    return new Observable<Array<VoucherModel>>(subscriber => {
      this.http.post<Array<VoucherModel>>(config.TRIOSAPI + '/api/voucher/getmealvouchers?userId=' + userId, cartData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });

  }


  public getTicket(ticketId: string)
    : Observable<Blob> {
    //   return this.http.get(`${this.basePath}/document/${id}`, {responseType: 'blob'});
    // }
   // return new Observable<any>(subscriber => {
      // this.http.get<any>(config.TRIOSAPI + '/api/ticket/getticketfile?id=' + ticketId)
      //   .subscribe((resultBlob: Blob) => {
      //     var downloadURL = URL.createObjectURL(resultBlob);
      //     window.open(downloadURL);
      //     },
      //     response => {
      //       subscriber.error(response);
      //     }
      //   );
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf');
      return this.http.get(config.TRIOSAPI + '/api/ticket/getticketfile?id=' + ticketId, { headers: headers, responseType: 'blob' });
   // });
  }
}

import { CartVoucherModel } from './../../models/vouchermodels/cartvoucher.model';

import { OrderResponseModel } from './../../models/cart/order-response.model';
import { PaymentInfoModel } from '@app/core/models/banking/paymentinfo.model';
import { CartOrderOptionsModel } from '@app/core/models/cart/cartorderoptions.model';

export class CartActions {
  static GET_CART = 'GET_CART';
  static GET_CART_SUCCESS = 'GET_CART_SUCCESS';
  static ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
  static ADD_ITEM_TO_CART_SUCCESS = 'ADD_ITEM_TO_CART_SUCCESS';
  static ADD_ITEM_TO_CART_FAIL = 'ADD_ITEM_TO_CART_FAIL';
  static REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
  static REMOVE_ITEM_FROM_CART_SUCCESS = 'REMOVE_ITEM_FROM_CART_SUCCESS';
  static PLACE_ORDER = 'PLACE_ORDER';
  static PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
  static PLACE_ORDER_FAIL = 'PLACE_ORDER_FAIL';
  static PLACE_CARD_ORDER = 'PLACE_CARD_ORDER';
  static PLACE_CARD_ORDER_SUCCESS = 'PLACE_CARD_ORDER_SUCCESS';
  static PLACE_CARD_ORDER_FAIL = 'PLACE_CARD_ORDER_FAIL';
  static EMPTY_CART = 'EMPTY_CART';
  static EMPTY_CART_SUCCESS = 'EMPTY_CART_SUCCESS';

  getCart() {
    return {
      type: CartActions.GET_CART,
    };
  }

  getCartSuccess(order: any) {
    return {
      type: CartActions.GET_CART_SUCCESS,
      payload: order
    };
  }

  addItemToCart(action: any) {
    return {
      type: CartActions.ADD_ITEM_TO_CART,
      payload: action
    };
  }

  

  addItemToCartSuccess(item: any) {
    return {
      type: CartActions.ADD_ITEM_TO_CART_SUCCESS,
      payload: item
    };
  }

  addItemToCartFail(err: any) {
    // console.log(err);
    return {
      type: CartActions.ADD_ITEM_TO_CART_FAIL,
      payload: err
    };
  }


  removeItemFromCart(action: any) {
    return {
      type: CartActions.REMOVE_ITEM_FROM_CART,
      payload: action
    };
  }

  removeItemFromCartSuccess(action: any) {
    return {
      type: CartActions.REMOVE_ITEM_FROM_CART_SUCCESS,
      payload: action
    };
  }

  placeOrder(options: CartOrderOptionsModel) {
    return {
      type: CartActions.PLACE_ORDER,
      payload: options
    };
  }

  placeCardOrder() {
    return {
      type: CartActions.PLACE_CARD_ORDER,
    };
  }
  
  placeCardOrderSuccess(orderResponse:OrderResponseModel) {
    return {
      type: CartActions.PLACE_CARD_ORDER_SUCCESS,
      payload: orderResponse
    };
  }

  placeCardOrderFail(err:any) {
    return {
      type: CartActions.PLACE_CARD_ORDER_FAIL,
      payload: err
    };
  }


  placeOrderSuccess(orderResponse:OrderResponseModel) {
    return {
      type: CartActions.PLACE_ORDER_SUCCESS,
      payload: orderResponse
    };
  }


  placeOrderFail(orderResponse:OrderResponseModel) {
    return {
      type: CartActions.PLACE_ORDER_FAIL,
      payload: orderResponse
    };
  }


  emptyCart() {
    return {
      type: CartActions.EMPTY_CART,
    };
  }

  emptyCartSuccess(action: any) {
    return {
      type: CartActions.EMPTY_CART_SUCCESS,
      payload: action
    };
  }

}

import { UserModel } from '@app/core/models/usermodels/user.model';
export class UserDetailModel {
  gender: string;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  lowBalanceAlertAmount: number;
  lowBalanceAlertEmail: string;
  bio: string;
  paidAccount: boolean;
  paidAmount: number;
  bankBranchCode: string;
  bankAccountNumber: string;
  accountType: string;
  internship: boolean;
  rewardsProgram: string;
  referalProgram: string;

  coach: UserModel;

  public static genderName(gender:string):string {
    switch (gender) {
      case 'M':
        return "Male";
      case 'F':
        return "Female";
      case 'O':
        return "Other";
      default:
        return "Unknown";
    }
  }
}
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { VoucherStatusComponent } from './voucher-status.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ],
  declarations: [
    VoucherStatusComponent
  ],
  exports: [
    VoucherStatusComponent
  ],
  providers: []
})

export class VoucherModule {
}

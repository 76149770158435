import { CartActions } from './cart.actions';
import { CartState, CartStateRecord } from './cart.state';

export const initialState: CartState = (new CartStateRecord() as unknown) as CartState;


export function cartReducer(state = initialState, { type, payload }: any): CartState {
  switch (type) {

    case CartActions.GET_CART:
      return state.merge({
        ...state,
        error: null,
        selectedOrder: payload
      }) as CartState;

    case CartActions.GET_CART_SUCCESS:
      return state.merge({
        ...state,
        error: null,
        selectedOrder: payload.order
      }) as CartState;

    case CartActions.EMPTY_CART_SUCCESS:
        return state.merge({
          ...state,
          error: null,
          selectedOrder: payload.order
        }) as CartState;
  
    case CartActions.ADD_ITEM_TO_CART_SUCCESS:
      return state.merge({
        ...state,
        error: null,
        selectedOrder: payload.order
      }) as CartState;

    case CartActions.ADD_ITEM_TO_CART_FAIL:
        return state.merge({
          ...state,
          error: payload
        }) as CartState;
  

    case CartActions.REMOVE_ITEM_FROM_CART_SUCCESS:
      return state.merge({
        ...state,
        error: null,
        selectedOrder: payload.order
      }) as CartState;

    case CartActions.PLACE_ORDER_SUCCESS:
      return state.merge({
        ...state,
        error: null,
        orderState: payload,
      }) as CartState;

    default:
      return state;
  }
}

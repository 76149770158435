import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { map, filter, scan, timestamp } from "rxjs/operators";
import { Observable } from 'rxjs/Observable';

import { config } from "@app/core/smartadmin.config";

import { RulesModel } from '../models/rules/rules.model';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { ReportModel } from '@app/core/models/reportmodels/report.model';


@Injectable()
export class ReportService implements OnInit, OnDestroy {

  constructor(private http: HttpClient,
    private store: Store<any>) {
  }

  ngOnInit() {


  }

  ngOnDestroy() {
  }
  
  getAdminReports() {
    let that = this;
    return new Observable<Array<ReportModel>>(subscriber => {
      var urlString = "/api/reports/adminreports";
      this.http
        .get<Array<ReportModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  getClientReports(clientId: number) {
    let that = this;
    return new Observable<Array<ReportModel>>(subscriber => {
      var urlString = "/api/reports/clientreports?clientId=" + clientId;
      this.http
        .get<Array<ReportModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  getVendorReports(vendorId: number) {
    let that = this;
    return new Observable<Array<ReportModel>>(subscriber => {
      var urlString = "/api/reports/vendorreports?vendorId=" + vendorId;
      this.http
        .get<Array<ReportModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  getVentureReports(vendorId: number) {
    let that = this;
    return new Observable<Array<ReportModel>>(subscriber => {
      var urlString = "/api/reports/venturereports?vendorId=" + vendorId;
      this.http
        .get<Array<ReportModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

}

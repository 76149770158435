import { UserModel } from '@app/core/models/usermodels/user.model';
//import { UserAction } from '@app/core/models/cart/cart-action.model';

export class UserActions {
  static GET_USER = 'GET_USER';
  static GET_USER_SUCCESS = 'GET_USER_SUCCESS';
  static GET_PURCHASES = 'GET_PURCHASES';
  static GET_PURCHASES_SUCCESS = 'GET_PURCHASES_SUCCESS';
  static GET_LINKED_ACCOUNTS = 'GET_LINKED_ACCOUNTS';
  static GET_LINKED_ACCOUNTS_SUCCESS = 'GET_LINKED_ACCOUNTS_SUCCESS';
  static GET_VENDORS = 'GET_VENDORS';
  static GET_VENDORS_SUCCESS = 'GET_VENDORS_SUCCESS';

  getUser() {
    return {
      type: UserActions.GET_USER,
    };
  }

  getUserSuccess(userData: any) {
    return {
      type: UserActions.GET_USER_SUCCESS,
      payload: userData
    };
  }
  
  getUserPurchases() {
    return {
      type: UserActions.GET_PURCHASES,
    };
  }

  getUserPurchasesSuccess(purchasesData: any) {
    return {
      type: UserActions.GET_PURCHASES_SUCCESS,
      payload: purchasesData
    };
  }

  getUserVendors(clientId: any) {
    return {
      type: UserActions.GET_VENDORS,
      payload:clientId
    };
  }

  getUserVendorsSuccess(vendorData: any) {
    return {
      type: UserActions.GET_VENDORS_SUCCESS,
      payload: vendorData
    };
  }


  getLinkedAccounts() {
    return {
      type: UserActions.GET_LINKED_ACCOUNTS,
    };
  }

  getLinkedAccountsSuccess(linkedAccountData: any) {
    return {
      type: UserActions.GET_LINKED_ACCOUNTS_SUCCESS,
      payload: linkedAccountData
    };
  }
  
}

import { state } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { AuthActionTypes } from './../auth/auth.actions';

import { Observable, of, EMPTY } from 'rxjs';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';

import { CartActions } from './cart.actions';
import { CartService } from '@app/core/services/cart.service';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { DataService } from '@app/core/services';

@Injectable()
export class CartEffects {

  @Effect()
  GetAuthLogin$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.LoginAction),
    switchMap((res: any) => {
      // console.log("Whats up ", res);
      if (res.payload !== false)
        return [this.cartActions.getCart()]
      return [];
    })
  );


  @Effect()
  GetCartOrder$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.GET_CART),
    switchMap((action: any) => this.cartService.getCart()),
    map((data: any) => this.cartActions.getCartSuccess({ order: data }))
  );

  @Effect()
  AddItemToCart$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.ADD_ITEM_TO_CART),
    switchMap((action: any) => this.cartService.addItemToCart(action.payload)),
    map((data: any) => { return this.cartActions.addItemToCartSuccess({ order: data }) })
  );

  

  @Effect()
  RemoveItemFromCart$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.REMOVE_ITEM_FROM_CART),
    switchMap((action: any) =>
      this.cartService.removeItemFromCart(action.payload)
    ),
    map((data: any) => this.cartActions.removeItemFromCartSuccess({ order: data }))
  );

  @Effect()
  PlaceOrder$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.PLACE_ORDER),
    switchMap((action: any) => this.cartService.placeWebOrder(action.payload)),
    map((data: any) => {
      return this.cartActions.placeOrderSuccess(data);
    } ),
  );

  @Effect()
  PlaceCardOrder$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.PLACE_CARD_ORDER),
    switchMap((action: any) => this.cartService.placeCardOrder()),
    map((data: any) => this.cartActions.placeCardOrderSuccess(data),
      (error: any) => this.cartActions.placeCardOrderFail(error)),
  );

  @Effect()
  EmptyCart$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.EMPTY_CART),
    switchMap((action: any) => this.cartService.emptyCart()),
    map((data: any) => this.cartActions.emptyCartSuccess({ order: data })),
    tap(() => [
      this.router.navigate(['/store'])
    ])
  );

  @Effect({ dispatch: false })
  PlaceOrderSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.PLACE_ORDER_SUCCESS),
    map(_ => _),
    tap(() => [
      this.test(),
      this.router.navigate(['/store/orderstate']), 
      //this.userService.fetchUserDefered(false)
    ])
  );


  test(){
     if (this.dataService.purchaseCompleteEvent){
        this.dataService.purchaseCompleteEvent.emit("Hello");
     }
  }

  @Effect({ dispatch: false })
  PlaceOrderFail$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.PLACE_ORDER_FAIL),
    map(_ => _),
    tap(() => [
      this.router.navigate(['/store']), 
      this.userService.fetchUserDefered(false)
    ]),
    
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private cartService: CartService,
    private cartActions: CartActions,
    private userService: UserService,
    private toastr: ToastrService,
    private store: Store<any>,
    private dataService: DataService,
  ) {

  }
}

import { Message } from './../../features/dashboard/analytics/analytics.component';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ClientService } from '@app/core/services/client.service';
import { UserService } from '@app/core/services/user.service';
import { config } from "../../core/smartadmin.config";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  //constructor(private userService: UserService) { }
  constructor(private router: Router,
    private clientService: ClientService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if ([0].indexOf(err.status) !== -1 ) {
        localStorage.removeItem('currentToken');
        localStorage.removeItem('currentRoles')
        if (err.url.includes('/api/client/getclient?uri=')) {
          this.router.navigate(['/auth/maint']);
        }
        else {
          this.router.navigate([config.defaultSigninPage]);
        }
        return;
      }

      if ([401, 403].indexOf(err.status) !== -1) {
        localStorage.removeItem('currentToken');
        localStorage.removeItem('currentRoles')
        this.router.navigate(['/auth/login']);
        return;
      }

      else if ([0, 502].indexOf(err.status) !== -1) {
        if (config.debug == true)
          alert(JSON.stringify(err));
      }
      var tempError = err;
      if (err.error != null) {
        tempError = err.error;
      }
      if (tempError.message !== undefined) {
        tempError = err.message;
      }
      else if (tempError.Message !== undefined)
        tempError = err.error.Message;
      //var tempError = err.error.Message || err.statusText || err.error;
      // try {
      //   tempError += '<br/>' + err.error.ModelState.ErrorMessage[0];
      // }
      // catch (ex) { }
      const error = tempError;
      return throwError(error);
    }))
  }
}
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '@app/core/services/user.service';
import { UserModel } from '../../../core/models/usermodels/user.model';
import { DietModel } from '../../../core/models/usermodels/diet.model';
import { PermissionsModel } from '../../../core/models/usermodels/permissions.model';
import * as moment from "moment";
import { UserDetailComponent } from '@app/features/dashboard/registration/school-registration/userdetail/userdetail.component';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'account-messages',
  templateUrl: './account-messages.component.html',
  styleUrls:['./account-messages.component.css']
})
export class AccountMessagesComponent implements OnInit, OnDestroy {

  userData:UserModel;
  userPermissions:PermissionsModel;
  loading:boolean = true;
  dietForm:FormGroup;
  dietData:DietModel = null;
  readOnly:boolean = false;
  
  @Input('accountdata') set accountdata(value){
    if (!value)
       return;
    this.userData = value;
  }

  @Input('permissions') set permissions(value){
    if (!value)
       return;
    this.userPermissions   = value;
    this.readOnly = this.userPermissions.canEditDiet;
  }

  constructor(private toastr: ToastrService,
              private fb: FormBuilder,
              private userService:UserService){
  
  }

  ngOnInit() {
  }

  ngOnDestroy(){
  }

  

  

}

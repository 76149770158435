import { BeneficiaryModel } from '../../../core/models/accountmodels/beneficiary.model';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SelectionSettingsModel, GridComponent, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';

import { Store } from '@ngrx/store';
import * as clientStore from '@app/core/store/client'

import { UserService } from '@app/core/services/user.service';



@Component({
  selector: 'accounts-beneficiary-dialog',
  templateUrl: './accountbeneficiarydialog.component.html',
})
export class AccountBeneficiaryDialogComponent implements OnInit {

  clientData: ClientModel;
  submitted = false;
  submitting = false;
  
  private _showModal = false;
  private _beneficiary:BeneficiaryModel;
  
  public beneficiaryForm: FormGroup;
  
  public selectionOptions: SelectionSettingsModel;
  

  @Output("isvisible") modalChange = new EventEmitter();
  @Input('showmodal')
  get showmodal() {
    return this._showModal;
  }
  set showmodal(value) {
    this._showModal = value;
    if (value)
      this.showModal();
    else
      this.hide()
    this.modalChange.emit(this._showModal)
  }

  @Output() beneficiaryChange = new EventEmitter<BeneficiaryModel>();    
  constructor(private userService: UserService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private store: Store<any>) {

    this.beneficiaryForm = this.createForm();

  }

  ngOnInit() {

    this.store.select(clientStore.getClient)
    .subscribe(
      (data: ClientModel) => {
        this.clientData = data;
      });
  }

  createForm() {
    return this.fb.group(
      {
        beneficiaryName: ["", [Validators.required]],
        beneficiaryAccountNumber:["", [Validators.required]],
      }
    );
  }

  get f() {
    return this.beneficiaryForm.controls;
  }


  loadForm() {
  }

  cancelForm() {
    this.hide();
  }


  showModal() {
    $('#accountsDialog').on('shown.bs.modal', function (e) { });
    $('#accountsDialog').modal({ show: true, backdrop: 'static', keyboard: false });
  }

  hide() {
    this._showModal = false;
    $('#accountsDialog').modal('hide')
    this.modalChange.emit(this._showModal)
  }

  onAddBeneficiary(){
    this.submitting = true;
    var beneficiaryModel = new BeneficiaryModel();
    beneficiaryModel.accountNumber = this.f.beneficiaryAccountNumber.value;
    beneficiaryModel.accountDescription = this.f.beneficiaryName.value;
    this.userService.updateBeneficiary(this.clientData.clientId, beneficiaryModel)
    .subscribe(data =>{
      this.submitting = false;
      this.submitted = true;
      this.beneficiaryChange.emit(beneficiaryModel);
      this.hide();
    }, errorResponse => {
      this.submitting = false;
      alert(errorResponse);
    });
    

  }

  onCancel(){
    this.hide();
  }

}

import { environment } from './../environments/environment';
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { PosLayoutComponent } from "./shared/layout/app-layouts/pos-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuard } from './core/guards/auth.guard'

import { KioskLayoutComponent } from './shared/layout/app-layouts/kiosk-layout.component';
import { GolfKioskLayoutComponent } from './shared/layout/app-layouts/golfkiosk-layout.component';
import { EmptyLayoutComponent } from './shared/layout/app-layouts/empty-layout.component';
import { LocationGuard } from './core/guards/location.guard';

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle: "Home" },
    canActivate: [AuthGuard],  
    canActivateChild: [LocationGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboard/analytics",
        pathMatch: "full"
      },
      {
        path: "dashboard",
        redirectTo: "dashboard/analytics",
        pathMatch: "full"
      },
      {
        path: "account",
        loadChildren: () => import('./features/account/accountview/accountview.module').then(m => m.AccountViewModule),
        // pathMatch: "full"
      },      
      {
        path: "accountedit",
        loadChildren: () => import('./features/account/accountedit/accountedit.module').then(m => m.AccountEditModule),
        pathMatch: "full"
      },   
      {
        path: "orders",
        loadChildren: () => import('./features/account/orders/orders.module').then(m => m.OrdersModule),
        pathMatch: "full"
      },   
      {
        path: "conference",
        loadChildren: () => import('./features/conference/conference.module').then(m => m.ConferenceModule),
      }, 
      {
        path: "economyadmin",
        loadChildren: () => import('./features/economyadmin/economy-admin.module').then(m => m.EconomyAdminModule),
      },      
      {
        path: "clientadmin",
        loadChildren: () => import('./features/clientadmin/client-admin.module').then(m => m.ClientAdminModule),
      },   
      {
        path: "vendoradmin",
        loadChildren: () => import('./features/vendor/vendor.module').then(m => m.VendorModule),
      },      
      {
        path: "admin",
        loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule),
      },      
      {
        path: "vendor",
        loadChildren: () => import('./features/vendor/vendor.module').then(m => m.VendorModule),
      },   
      {
        path: "store",
        loadChildren: () => import('./features/app-store/appstore.module').then(m => m.AppStoreModule),
      },      
      {
        path: "store_vendor",
        loadChildren: () => import('./features/vendorstore/vendorstore.module').then(m => m.VendorStoreModule),
      },   
      {
        path: "covid19",
        loadChildren: () => import('./features/covid19/covid19.module').then(m => m.Covid19Module),
      },   
      {
        path: "eventAttendance",
        loadChildren: () => import('./features/eventattendance/ea.module').then(m => m.EAModule),
      },   
      
      {
        path: "preorder",
        loadChildren: () => import('./features/preorder/preorder.module').then(m => m.PreorderModule),
      },  
      {
        path: "preorderv2",
        loadChildren: () => import('./features/preorderv2/preorder.module').then(m => m.PreorderModule),
      },  
      {
        path: "registration",
        redirectTo: "dashboard/registration",
        pathMatch: "full"
      }, 
      {
        path: "document",
        redirectTo: "dashboard/document",
        pathMatch: "full"
      },
      {
        path: "app-views",
        loadChildren: () => import('./features/app-views/app-views.module').then(m => m.AppViewsModule),
        data: { pageTitle: "App Views" }
      },
      {
        path: "dashboard",
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { pageTitle: "Dashboard" }
      },
      {
        path: "e-commerce",
        loadChildren: () => import('./features/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
        data: { pageTitle: "E-commerce" }
      },
      {
        path: "forms",
        loadChildren:
          () => import('./features/forms/forms-showcase.module').then(m => m.FormsShowcaseModule),
        data: { pageTitle: "Forms" }
      },
      {
        path: "graphs",
        loadChildren:
          () => import('./features/graphs/graphs-showcase.module').then(m => m.GraphsShowcaseModule),
        data: { pageTitle: "Graphs" }
      },
      {
        path: "maps",
        loadChildren: () => import('./features/maps/maps.module').then(m => m.MapsModule),
        data: { pageTitle: "Maps" }
      },
      {
        path: "miscellaneous",
        loadChildren:
          () => import('./features/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule),
        data: { pageTitle: "Miscellaneous" }
      },
      {
        path: "outlook",
        loadChildren: () => import('./features/outlook/outlook.module').then(m => m.OutlookModule),
        data: { pageTitle: "Outlook" }
      },
      {
        path: "smartadmin",
        loadChildren:
          () => import('./features/smartadmin-intel/smartadmin-intel.module').then(m => m.SmartadminIntelModule),
        data: { pageTitle: "Smartadmin" }
      },
      {
        path: "tables",
        loadChildren: () => import('./features/tables/tables.module').then(m => m.TablesModule),
        data: { pageTitle: "Tables" }
      },
      {
        path: "ui",
        loadChildren:
          () => import('./features/ui-elements/ui-elements.module').then(m => m.UiElementsModule),
        data: { pageTitle: "Ui" }
      },
      {
        path: "widgets",
        loadChildren:
          () => import('./features/widgets/widgets-showcase.module').then(m => m.WidgetsShowcaseModule),
        data: { pageTitle: "Widgets" }
      }
    ]
  },
  {
    path: "eventAttendanceDisplay",
    component: EmptyLayoutComponent,
      loadChildren: () => import('./features/eventattendance/ea.module').then(m => m.EAModule),
  },
  {
    path: "splash",
    component: EmptyLayoutComponent,
    loadChildren: () => import('./features/splash/postloginsplash/splashscreen.module').then(m => m.SplashscreenModule)
  },
  {
    path: "purchasesplash",
    component: EmptyLayoutComponent,
    loadChildren: () => import('./features/splash/postpurchasesplash/postpurchase_splashcreen.module').then(m => m.PostPurchaseSplashcreenModule)
  },
  {
    path: "pos",
    component: PosLayoutComponent,
    loadChildren: () => import('./features/pos/pos.module').then(m => m.PosModule)
  },
  {
    path: "kiosk",
    component: KioskLayoutComponent,
    loadChildren: () => import('./features/kiosk/kiosk.module').then(m => m.KioskModule)
  },
  {
    path: "golfkiosk",
    component: GolfKioskLayoutComponent,
    loadChildren: () => import('./features/golfkiosk/golfkiosk.module').then(m => m.GolfKioskModule)
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  { path: "**", redirectTo: "miscellaneous/error404" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    useHash: true, 
    onSameUrlNavigation: 'reload', 
    enableTracing: environment.log.routing,
    //preloadingStrategy: PreloadAllModules
   })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export class AddressModel {
    public clientId: number;
    public addressType: string;
    public line1 : string; 
    public line2 : string; 
    public suburb : string; 
    public city : string; 
    public province : string; 
    public postCode : string; 
    public country : string; 
    public countryCode: string;
    public latitude: number;
    public longitude: number;
    public formattedAddress: string;
}
import { ProductV2Model } from '../../models/productmodels/productv2.model';
import { ProductModel } from '@app/core/models/productmodels/product.model';
import { ProductCategoryModel } from '@app/core/models/product/productcategory.model';
import { Brand } from '@app/core/models/product/brand.model';
import { AppState } from '../index';
import { ProductState } from './product.state';
import { createSelector } from 'reselect';
import { Taxonomy } from '@app/core/models/product/taxonomy.model';
import { Review } from '@app/core/models/product/review.model';
import { RatingOption } from '@app/core/models/product/rating-option.model';
import { VendorModel } from '@app/core/models/vendormodels/vendor.model';

// Base product state selector function
const getProductState = (state: AppState): ProductState => state.product;

// ******************** Individual selectors ***************************
export function fetchProducts(state: ProductState): ProductModel[] {
  const ids = state.productIds;
  const productEntities = state.productEntities;
  return (ids.map(id => productEntities[id]) as unknown) as ProductModel[];
}


const fetchProductV2 = (state: ProductState) => (state.productV2 as unknown) as ProductV2Model;
const fetchAllTaxonomies = (state: ProductState) => (state.taxonomies as unknown) as Taxonomy[];
const fetchSelectedProduct = (state: ProductState) => state.selectedProduct;
const fetchAllProductSearch = (state: ProductState) => (state.showAllProducts as unknown) as ProductModel[];
const fetchReletedProducts = (state: ProductState) => (state.relatedProducts as unknown) as ProductModel[];
const fetchProductReviews = (state: ProductState) => (state.productReviews as unknown) as Review[];
const fetchRootTaxonId = (state: ProductState) => state.rootTaxonomyId;
const fetchBrands = (state: ProductState) => (state.brands as unknown) as Brand[];
const fetchCategories = (state: ProductState) => (state.categories as unknown) as ProductCategoryModel[];
const fetchVendors = (state: ProductState) => (state.vendors as unknown) as VendorModel[];
const fetchFeaturedVendors = (state: ProductState) => (state.featuredVendors as unknown) as VendorModel[];
const fetchProductRatingOptions = (state: ProductState) => (state.productRatingOptions as unknown) as RatingOption[];
const fetchIsReviewSubmitted = (state: ProductState) => state.isReviewSubmitted;

// *************************** PUBLIC API's ****************************
 export const getSelectedProduct = createSelector(
   getProductState,
   fetchSelectedProduct
 );
 
export const getProducts = createSelector(
  getProductState,
  fetchProducts
);

export const getProductV2 = createSelector(
  getProductState,
  fetchProductV2
);

export const getTaxonomies = createSelector(
  getProductState,
  fetchAllTaxonomies
);
export const showAllProducts = createSelector(
  getProductState,
  fetchAllProductSearch
);
export const relatedProducts = createSelector(
  getProductState,
  fetchReletedProducts
);
export const productReviews = createSelector(
  getProductState,
  fetchProductReviews
);
export const rootTaxonomyId = createSelector(
  getProductState,
  fetchRootTaxonId
);
export const getBrands = createSelector(
  getProductState,
  fetchBrands
);
export const getCategories = createSelector(
  getProductState,
  fetchCategories
);
export const getVendors = createSelector(
  getProductState,
  fetchVendors
);
export const getFeaturedVendors = createSelector(
  getProductState,
  fetchFeaturedVendors
);

export const getProductRatingOptions = createSelector(
  getProductState,
  fetchProductRatingOptions
);
export const getIsReviewSubmitted = createSelector(
  getProductState,
  fetchIsReviewSubmitted
);

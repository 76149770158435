import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { config } from "@app/core/smartadmin.config";

import { WaitronModel } from '../models/pos/waitron.model';

@Injectable()
export class PosService {

  waitron: Subject<WaitronModel> = null;

  constructor(private http: HttpClient) {
    this.waitron = new Subject<WaitronModel>();
  }

  getWaitronByCardId(cardId: string): Observable<WaitronModel> {
    this.http.get<WaitronModel>(config.SLAVEAPI + '/api/client/getclient')
      .pipe(
        map(response => {
          return response;
        }
        ))
      .subscribe(
        data => {
          return this.waitron;
        },
        response => {
          this.waitron.error(response);
        }
      );
    return this.waitron;
  }


}

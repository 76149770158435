import { UserActions } from './user.actions';
import { UserState, UserStateRecord } from './user.state';

export const initialState: UserState = (new UserStateRecord() as unknown) as UserState;


export function userReducer(state = initialState, { type, payload }: any): UserState {

  switch (type) {

    case UserActions.GET_USER:
      return state.merge({
        ...state,
        userData: payload
      }) as UserState;

    case UserActions.GET_USER_SUCCESS:
      return state.merge({
        ...state,
        userData: payload
      }) as UserState;

    case UserActions.GET_PURCHASES_SUCCESS:
      return state.merge({
        ...state,
        purchasesData: payload
      }) as UserState;

    case UserActions.GET_VENDORS_SUCCESS:
        return state.merge({
          ...state,
          vendorsData: payload
        }) as UserState;
  
    case UserActions.GET_LINKED_ACCOUNTS_SUCCESS:
      return state.merge({
        ...state,
        linkedAccounts: payload
      }) as UserState;

    default:
      return state;
  }
}

import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { NgModule, APP_INITIALIZER, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IonicStorageModule } from '@ionic/storage';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import * as fromStore from './store';
import { AuthGuard } from './guards/auth.guard';
import { LocationGuard } from './guards/location.guard';

import { services, AuthTokenFactory, AuthTokenService } from '@app/core/services'
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { ProductActions } from './store/product';
import { CartActions } from './store/cart';
import { ClientActions } from './store/client';
import { UserActions } from './store/user';
import { PosActions } from './store/pos';
import { PreorderCartActions } from './store/preorder-cart';
import { config } from './smartadmin.config';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { environment } from '@env/environment';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    // HotkeysModule.forRoot(),

    StoreModule.forRoot(fromStore.reducers, {
      metaReducers: fromStore.metaReducers
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([...fromStore.effects, AppEffects])
  ],
  exports: [],
  providers: [
    AuthGuard,
    LocationGuard,
    ProductActions,
    CartActions,
    PreorderCartActions,
    ClientActions,
    UserActions,
    PosActions,
    ...services,
    ...fromStore.services,
    {
      provide: APP_INITIALIZER,
      useFactory: AuthTokenFactory,
      deps: [AuthTokenService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
      //deps: [ UserService ]
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CacheInterceptor,
    //   multi: true,
    //   //deps: [ UserService ]
    // },
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

}

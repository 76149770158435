import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { ClientService } from '../services/client.service';
import { Observable } from 'rxjs/Observable';
import { mergeMap } from 'rxjs/operators';
//import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth : AuthService,
    public clientService : ClientService){}
    //public adalService: MsAdalAngular6Service) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if ((this.auth.isLoggedIn === false) && (this.clientService.isADAuth() === true)) {
    //   const resource = this.adalService.GetResourceForEndpoint(request.url);
    //   if (!resource || !this.adalService.isAuthenticated) {
    //     return next.handle(request);
    //   }


    //   return this.adalService.acquireToken(resource).pipe(
    //     mergeMap((token: string) => {
    //       const authorizedRequest = request.clone({
    //         headers: request.headers.set('Authorization', `Bearer ${token}`),
    //       });
    //       return next.handle(authorizedRequest);
    //     }));
    // }
    // else {
      var token = this.auth.getToken();
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      return next.handle(request);
    //}
  }
}

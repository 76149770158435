
import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';

import { RulesModel } from './../models/rules/rules.model';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { UserModel } from '@app/core/models/usermodels/user.model';


@Injectable()
export class RulesService implements OnInit, OnDestroy {

  
  public  rules : RulesModel;

  private rulesChangeSubject:BehaviorSubject<RulesModel>;

  constructor(private store: Store<any>) {
    this.rules = new RulesModel();
    this.rulesChangeSubject = new BehaviorSubject<RulesModel>(null);
  }

  ngOnInit() {


  }

  ngOnDestroy() {
  }

  public onRulesChange(): Observable<RulesModel> {
    return this.rulesChangeSubject.asObservable();
  }

  public setClientRules(clientData:ClientModel){

      try{
        this.rules.axCoinEnabled = clientData.economy.economyRules.axCoinEnabled;
      }
      catch(e){}
      // console.log("Rules Change client", this.rules);
      this.rulesChangeSubject.next(this.rules);
  }

  public setUserRules(userData:UserModel){

    try{

      this.rules.isStudentVenture = userData.isStudentVenture;

    }
    catch(e){}

    try{

      this.rules.hasStudentVenture = userData.hasStudentVenture;

    }
    catch(e){}

    try{

      this.rules.isCoachAccount = userData.isCoach;

    }
    catch(e){}
    // console.log("Rules Change", this.rules);
    this.rulesChangeSubject.next(this.rules);
    
}

  public clearRules(){
    this.rules.axCoinEnabled = false;
    this.rules.hasStudentVenture = false;
    this.rules.isStudentVenture = false;
    this.rules.hasStudentVenture = false;
    this.rulesChangeSubject.next(this.rules);
    // console.log("Rules Clear");
  }

}

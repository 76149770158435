import { OrderResponseModel } from '../../models/cart/order-response.model';
import { AppState } from '../index';
import { PreorderCartState } from './preorder-cart.state';
import { createSelector } from 'reselect';
import { PreorderCartOrderModel } from '@app/core/models/preorder-cart/preordercartorder.model';
import { POMenuOrderModel } from '@app/core/models/preorder-cart/pomenuorder.model';

// Base product state selector function
const getPOCartState = (state: AppState): PreorderCartState => state.preorderCart;

const fetchPOCartOrder = (state: PreorderCartState) => (state.preorderOrders as unknown) as PreorderCartOrderModel;
const fetchPOOrderState = (state: PreorderCartState) => (state.orderState as unknown) as OrderResponseModel;
const fetchPOCartError = (state: PreorderCartState) => (state.error as unknown) as string;

// *************************** PUBLIC API's ****************************
export const getPOCartOrder = createSelector(
  getPOCartState,
  fetchPOCartOrder
);

export const getPOOrderState = createSelector(
  getPOCartState,
  fetchPOOrderState
);

export const addPOToCartErrorState = createSelector(
  getPOCartState,
  fetchPOCartError
);

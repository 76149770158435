import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { map } from 'rxjs/operators';
import * as moment from 'moment'
import { config } from '@app/core/smartadmin.config';
import { PageSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

import { LayoutService } from '@app/core/services/layout.service';
import { UserService } from '@app/core/services/user.service';
import { PermissionsModel } from '@app/core/models/usermodels/permissions.model';
import { ClientPermissionsModel } from '@app/core/models/clientmodels/client-permissions.model';
import { UserModel } from '@app/core/models/usermodels/user.model';
import { NumericTextBox } from '@syncfusion/ej2-angular-inputs';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { InstallmentPaymentModel } from '@app/core/models/usermodels/installmentpayment.model';

const pipe_$ = { transform: '1' };

@Component({
  selector: 'installments',
  templateUrl: './installments.component.html',
  styleUrls: ['./installments.component.css']
})
export class InstallmentsComponent implements OnInit {

  @ViewChild('grid', { static: false })
  public grid: GridComponent;
  @ViewChild('detailgrid', { static: false })
  public detailgrid: GridComponent;

  @ViewChild('paymentAmount', { static: false })
  public paymentAmount: NumericTextBox;

  public displayMode = 'grid';
  accountData = [];
  installmentDetailData = [];
  installmentPurchases = [];
  installmentPayments = [];

  userData: UserModel;
  userPermissions: PermissionsModel = new PermissionsModel();
  clientPermissions: ClientPermissionsModel = new ClientPermissionsModel();
  statementVisible: boolean = false;

  private isMobile = false;
  public pageSettings: PageSettingsModel;
  public selectionOptions: SelectionSettingsModel;
  public wrapSettings = { wrapMode: 'Content' };
  private mobileVisible = [true, false, true, false, false, false, true, true];
  private mobileWidth = [3, 0, 7, 5, 5];
  public columnWidth = [3, 8, 8, 7, 5];
  public columnVisible = [true, true, true, true, true];

  public serviceUrl: string;
  public serverUrl: string;
  public reportPath: string;
  public processingMode;
  public parameters: any;
  public valueHeader = "CR";
  public installmentDetail: any;
  public DialogObj;

  constructor(private userService: UserService,
    private currencyPipe: CurrencyPipe,
    public layoutService: LayoutService) {

    this.serviceUrl = config.TRIOSAPI + '/api/ReportViewer';
    //this.processingMode = ej.ReportViewer.ProcessingMode.Remote;
    this.serverUrl = config.REPORTSERVER;
    this.reportPath = "/SystemReports/invoice";
  }

  ngOnInit() {
    this.isMobile = this.layoutService.isMobile();
    if (this.isMobile) {
      this.columnWidth = this.mobileWidth;
      this.columnVisible = this.mobileVisible;
    }

    this.selectionOptions = { type: 'Single' };
    this.pageSettings = { pageSize: 6 };

    this.loadData();
  }


  @Input('accountdata') set accountdata(value) {
    if ((value === null) || (value === undefined))
      return;
    let that = this;
    this.userData = value;

  }

  @Input('permissions') set permissions(value: PermissionsModel) {
    if ((value === null) || (value === undefined))
      return;
    this.userPermissions = value;
    this.statementVisible = value.balanceVisible === true;
  }

  @Input('clientpermissions') set clientpermissions(value: ClientPermissionsModel) {
    if ((value === null) || (value === undefined))
      return;

    this.clientPermissions = value;
    this.statementVisible = value.canViewBalance === true;
  }

  loadData() {
    let that = this;
    this.userService.loadInstallments(this.userData.userId)
      .pipe(
        map(data => {
          data.forEach(element => {
            element.amount = this.currencyPipe.transform(element.amount, 'ZAR', "R");
          });
          return data;
        })
      )
      .subscribe((data) => {
        that.accountData = data;
      });
  }

  loadInstallmentDetail(orderNo: string) {
    let that = this;
    this.userService.loadInstallmentDetail(orderNo)
      .pipe(
        map(data => {
          data.forEach(element => {
            element.amount = this.currencyPipe.transform(element.amount, 'ZAR', "R");
          });
          return data;
        })
      )
      .subscribe((data) => {
        that.accountData = data;
      });
  }


  dataBound() {
    this.grid.autoFitColumns(['description']);
  }

  customSort() {
    //alert('a');
  }


  rowSelected($event) {
  }

  onInstallmentDetailClose() {
    this.loadData();
    this.displayMode = 'grid';
  }


  onShowInstallment($event, data) {
    this.userService.loadInstallmentDetail(data.orderId).subscribe(data => {
      this.installmentDetail = data;
      this.installmentPurchases = data.purchasedItems;
      this.installmentPayments = data.payments;
      this.displayMode = 'data';
    });
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString();
  }


  onPayInstallment() {
    this.DialogObj = DialogUtility.confirm({
      title: ' Confirmation',
      content: "You want to pay R " + this.paymentAmount.value + ' toward the total of R ' + this.installmentDetail.outstandingTotal + " ?",
      okButton: { text: 'Yes', click: this.okClick.bind(this) },
      cancelButton: { text: 'Cancel', click: this.cancelClick.bind(this) },
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' }
    });
  }

  private okClick(): void {
    let that = this;
    this.DialogObj.hide();
    var installmentPayment = new InstallmentPaymentModel();
    installmentPayment.userId = this.userData.userId;
    installmentPayment.orderId = this.installmentDetail.orderId;
    installmentPayment.amount = this.paymentAmount.value;
    this.userService.payInstallment(installmentPayment)
    .subscribe( data =>{
      this.userService.fetchUserDefered(false);
      this.installmentDetail = data;
      this.installmentPurchases = data.purchasedItems;
      this.installmentPayments = data.payments;
      this.displayMode = 'data';
    }
    ,onError=>{
      alert("error");
    })
  }

  private cancelClick(): void {
    this.DialogObj.hide();
  }
}

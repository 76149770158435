
import { Map, Record, List } from 'immutable';


import { AuthActionTypes } from '@app/core/store/auth';


import { ProductActions } from './product.actions';
import { AuthActions } from './../auth/auth.actions';
import { ProductState, ProductStateRecord } from './product.state';

import { ProductModel } from '@app/core/models/productmodels/product.model';


export const initialState: ProductState = (new ProductStateRecord() as unknown) as ProductState;

export function productReducer(state = initialState, { type, payload }: any): ProductState {

  switch (type) {

    case AuthActionTypes.LogoutAction:
      return state.merge({
        // productIds: List([]),
        // productEntities: Map({}),
        // selectedProductId: null,
        // selectedProduct: Map({}),
        // taxonomies: List([]),
        // showAllProducts: List([]),
        // relatedProducts: List([]),
        // productReviews: List([]),
        // rootTaxonomyId: 0,
        // vendors: List([]),
         categories: List([]),
        // brands: List([]),
        // productRatingOptions: List([]),
        // isReviewSubmitted: false
      }) as ProductState;
      break;

    case ProductActions.GET_PRODUCT_DETAIL_SUCCESS:
      return state.merge({
        selectedProduct: payload
      }) as ProductState;

    case ProductActions.GET_PRODUCT_V2_SUCCESS:
      return state.merge({        
        productV2 : payload.productV2
      }) as ProductState;

    case ProductActions.GET_ALL_PRODUCTS_SUCCESS:
      const _products: ProductModel[] = payload.products;
      const _showAllProducts: ProductModel[] = payload.products;
      const productIds: number[] = _products.map(product => product.productId);
      const productEntities = _products.reduce(
        (products: { [id: number]: ProductModel }, product: ProductModel) => {
          return Object.assign(products, {
            [product.productId]: product
          });
        },
        {}
      );

      return state.merge({
        productIds: productIds,
        productEntities: productEntities,
        showAllProducts: _showAllProducts
      }) as ProductState;

    case ProductActions.GET_ALL_TAXONOMIES_SUCCESS:
      const _taxonomies = payload.taxonomies.taxonomies;
      return state.merge({
        taxonomies: _taxonomies
      }) as ProductState;

    case ProductActions.GET_RELATED_PRODUCT_SUCCESS:
      const relatedProducts: ProductModel[] = payload.products;

      return state.merge({
        relatedProducts: relatedProducts
      }) as ProductState;

    case ProductActions.GET_REVIEWS_SUCCESS:
      const _productReviews = payload;
      return state.merge({
        productReviews: _productReviews
      }) as ProductState;

    // case ProductActions.GET_ALL_BRANDS_SUCCESS:
    //   const _brands = payload;
    //   return state.merge({
    //     brands: _brands
    //   }) as ProductState;

    case ProductActions.GET_ALL_CATEGORIES_SUCCESS:
      const _categories = payload;
      return state.merge({
        categories: _categories
      }) as ProductState;

    case ProductActions.GET_ALL_VENDORS_SUCCESS:
      const _vendors = payload;
      return state.merge({
        vendors: _vendors
      }) as ProductState;

    case ProductActions.GET_FEATURED_VENDORS_SUCCESS:
        const _featuredVendors = payload;
        return state.merge({
          featuredVendors: _featuredVendors
        }) as ProductState;
  
    case ProductActions.GET_RATING_OPTIONS_SUCCESS:
      const _productRatingOptions = payload.rating_options;
      return state.merge({
        productRatingOptions: _productRatingOptions,
        isReviewSubmitted: false
      }) as ProductState;

    case ProductActions.WRITE_REVIEW_SUCCESS:
      return state.merge({
        isReviewSubmitted: true
      }) as ProductState;

    default:
      return state;
  }
}

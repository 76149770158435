import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { config } from "@app/core/smartadmin.config";

@Injectable()
export class ConferenceService implements OnInit, OnDestroy {

    constructor(private http: HttpClient) {

    }

    ngOnInit() {
        // console.log("Loading UserService");
    }

    ngOnDestroy() {
    }


    createConference() {
        return new Observable<any>(subscriber => {
            var urlString = config.TRIOSAPI + "/api/conference/createconference";
            this.http.get(urlString).subscribe((data)=>{
            //     var joinURL = api.urlFor('join', params);
            //     subscriber.next(joinURL);
            // }, errorResponse =>{
            //     subscriber.error(errorResponse);
            // });
            });

        });
    }





}

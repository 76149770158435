import { Action } from '@ngrx/store';
import { ClientActions } from './client.actions';
import { ClientState, ClientStateRecord } from './client.state';

export const initialState: ClientState = (new ClientStateRecord() as unknown) as ClientState;


export function clientReducer(state = initialState, { type, payload }: any): ClientState {
  
  
  switch (type) {
    
    
    case ClientActions.UNLOAD_CLIENT:
      
      return state.merge({
        ...state,
        clientState: {}
      }) as ClientState;

    case ClientActions.GET_CLIENT_SUCCESS:
    return state.merge({
      ...state,
      clientState: payload.clientState
    }) as ClientState;

    case ClientActions.GET_CLIENT_ROLES_SUCCESS:
      return state.merge({
        ...state,
        clientRoles: payload.clientRoles
      }) as ClientState;

    case ClientActions.GET_CLIENT_GROUPS_SUCCESS:
        return state.merge({
          ...state,
          clientGroups: payload.clientGroups
        }) as ClientState;
  
    default:
      return state;
  }
}

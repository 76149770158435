import { SettingsModel } from '@app/core/models/usermodels/settings.model';
import { MessageTemplateModel } from '@app/core/models/accountmodels/messagetemplate.model';
import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable } from "rxjs";
import { ClientService } from "./client.service";

import { config } from "@app/core/smartadmin.config";

import { UserModel } from "@app/core/models/usermodels/user.model";
// import { ChildModel } from '../../core/models/usermodels/child.model';
// import { DietModel } from '../../core/models/usermodels/diet.model';
// import { AccountLookupModel } from '../../core/models/accountmodels/account-lookup.model';
// import { PermissionsModel } from '../../core/models/usermodels/permissions.model';
import { ClientPermissionsModel } from '../../core/models/clientmodels/client-permissions.model';

import { UserOTPRequestModel, UserOTPResponseModel } from "../../core/models/usermodels/userotp-request.model";
import { ClientInfoModel } from "../../core/models/clientmodels/clientinfo.model";
import { MessageTemplateResponse } from "../models/accountmodels/messagetemplateresponse.model";


// import { AngularFireMessaging } from '@angular/fire/messaging';
import { TemplateCloneModel } from "../models/accountmodels/templateclone.model";
import { NotificationModel } from '../models/accountmodels/notification.model';
import { NotificationsResponse } from '../models/accountmodels/notificationsreponse.model';
import { AccountLookupModel } from '../models/accountmodels/account-lookup.model';
import { EmailSettingsModel } from '../models/usermodels/emailsettings.model';
import { PaymentModel } from '../models/banking/payment.model';
import { PaymentResponseModel } from '../models/banking/paymentResponse.model';
import { EventAttendeeModel } from '../models/events/eventattendee.model';



@Injectable()
export class AccountService implements OnDestroy {

  constructor(private http: HttpClient,
              private router: Router,
              private clientService: ClientService) {
  }


  ngOnDestroy() {
  }


  public updateAccount(accountData: UserModel) {
    var urlString = "/api/accountmanager/updateaccount";
    return new Observable<UserModel>(subscriber => {
      this.http.post<UserModel>(config.TRIOSAPI + urlString, accountData)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateAccountRoles(user : UserModel) {
    user.clientId = this.clientService.currentClient.clientId;
    var urlString = "/api/accountmanager/updateroles";
    return new Observable<UserModel>(subscriber => {
      this.http.post<UserModel>(config.TRIOSAPI + urlString, user)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }
  

  public lookupAccounts(clientId:number, filter: string) {
    var urlString = "/api/accountmanager/lookupAccounts?clientId=" + clientId + "&filter=" + filter;
    return new Observable<AccountLookupModel>(subscriber => {
      this.http.get<AccountLookupModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public lookupAccountByCardId1(clientId: number, cardId: string) {
    var urlString = "/api/accountmanager/loadAccount?clientId=" + clientId + "&cardId=" + cardId;
    return new Observable<any>(subscriber => {
      this.http.get<UserModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  

  public loadAccount(clientId: number, userId: string) {
    var urlString = "/api/accountmanager/loadaccount?clientId=" + clientId + "&userId=" + userId;
    return new Observable<UserModel>(subscriber => {
      this.http.get<UserModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadClientPermissions(clientId: number, userId: string) {
    var urlString = "/api/accountmanager/loadclientpermissions?userId=" + userId + "&clientId=" + clientId;
    return new Observable<ClientPermissionsModel>(subscriber => {
      this.http.get<ClientPermissionsModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public saveAccountClientPermissions(permissions :ClientPermissionsModel) {
    var urlString = "/api/accountmanager/saveclientpermissions"
    return new Observable<ClientPermissionsModel>(subscriber => {
      this.http.post<ClientPermissionsModel>(config.TRIOSAPI + urlString, permissions)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public loadMessageTemplates(clientId: number) {
    var urlString = "/api/accountmanager/loadmessagetemplates?clientId=" + clientId;
    return new Observable<MessageTemplateResponse>(subscriber => {
      this.http.get<MessageTemplateResponse>(config.TRIOSAPI + urlString)
        .subscribe(
          (data: MessageTemplateResponse) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public cloneMessageTemplate(data: TemplateCloneModel) {
    var urlString = "/api/accountmanager/clonemessagetemplate";
    return new Observable<MessageTemplateResponse>(subscriber => {
      this.http.post<MessageTemplateResponse>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data: MessageTemplateResponse) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public deleteMessageTemplate(data: TemplateCloneModel) {
    var urlString = "/api/accountmanager/deletemessagetemplate";
    return new Observable<MessageTemplateResponse>(subscriber => {
      this.http.post<MessageTemplateResponse>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data: MessageTemplateResponse) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateMessageTemplate(data: MessageTemplateModel) {
    var urlString = "/api/accountmanager/updatemessagetemplate";
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadNotifications(clientId: number) {
    var urlString = "/api/accountmanager/loadnotifications?clientId=" + clientId;
    return new Observable<NotificationsResponse>(subscriber => {
      this.http.get<NotificationsResponse>(config.TRIOSAPI + urlString)
        .subscribe(
          (data: NotificationsResponse) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateNotification(data: NotificationModel) {
    var urlString = "/api/accountmanager/updatenotification";
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateAccountSettings(data: SettingsModel) {
    var urlString = "/api/accountmanager/updatesettings";
    return new Observable<UserModel>(subscriber => {
      this.http.post<UserModel>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateAccountEmailSettings(data: EmailSettingsModel) {
    var urlString = "/api/accountmanager/updateemailsettings";
    return new Observable<UserModel>(subscriber => {
      this.http.post<UserModel>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public bankPayment(data: PaymentModel) {
    var urlString = "/api/accountmanager/bankdeposit";
    return new Observable<PaymentResponseModel>(subscriber => {
      this.http.post<PaymentResponseModel>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public bankWithdrawal(data: PaymentModel) {
    var urlString = "/api/accountmanager/bankwithdrawal";
    return new Observable<PaymentResponseModel>(subscriber => {
      this.http.post<PaymentResponseModel>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public bankPaymentAx(data: PaymentModel) {
    var urlString = "/api/accountmanager/bankdepositax";
    return new Observable<PaymentResponseModel>(subscriber => {
      this.http.post<PaymentResponseModel>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public bankWithdrawalAx(data: PaymentModel) {
    var urlString = "/api/accountmanager/bankwithdrawalax";
    return new Observable<PaymentResponseModel>(subscriber => {
      this.http.post<PaymentResponseModel>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

}
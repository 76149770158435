import { Component, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment'

import { UserService } from '../../../core/services/user.service';
import { PermissionsModel } from '../../../core/models/usermodels/permissions.model';
import { ClientPermissionsModel } from '../../../core/models/clientmodels/client-permissions.model';
import { UserModel } from '@app/core/models/usermodels/user.model';

const pipe_$ = {transform: '1'};

@Component({
  selector: 'account-ax-statement',
  templateUrl: './account-ax-statement.component.html',
  styleUrls:['./account-ax-statement.component.css']
})
export class AccountAxStatementComponent implements OnInit {
  
  
  userData:UserModel;
  userPermissions:PermissionsModel = new PermissionsModel();
  clientPermissions:ClientPermissionsModel = new ClientPermissionsModel();
  statementVisible: boolean = false;
  constructor(private userService: UserService,
              private currencyPipe: CurrencyPipe){}

    ngOnInit(){
    }

    @Input('accountdata') set accountdata(value){
      if ((value === null) || (value === undefined))
         return;

      this.userData = value;
    }
  
    @Input('permissions') set permissions(value:PermissionsModel){
      if ((value === null) || (value === undefined))
         return;
      this.userPermissions   = value;
      this.statementVisible = value.balanceVisible === true;
    }

    @Input('clientpermissions') set clientpermissions(value:ClientPermissionsModel){
      if ((value === null) || (value === undefined))
         return;

      this.clientPermissions   = value;
      this.statementVisible = value.canViewBalance === true;
    }

    customSort(){
      //alert('a');
    }

    sort(){
      $('tr').sort(function(a,b){
        return new Date($(a).find('input').val()).getTime() < new Date($(b).find('input').val()).getTime() 
      }).appendTo('tbody')
      alert('sort')
    }

    
    options = {
      dom: "Bfrtip",
      ajax: (data, callback, settings) => {
        this.userService.loadAXStatement(this.userData.userId, "1 jan 2019", "1 dec 2099")
          .pipe(
          )
          .subscribe((data)=> {
            data.transactionLine .forEach(element => {
              if(element.cr === 0)
                 element.cr = null;
              if(element.dr === 0)
                 element.dr = null;
              if(element.dayBalance === 0)
                 element.dayBalance = '';
              //element.transactionDate = moment(moment.utc(element.transactionDate).toDate()).format("DD MMM YYYY HH:mm")
            });
            callback({
              aaData: data.transactionLine     
            });
           });
      },
      columns: [
        { data: "lineNo", orderable: true  },
        { data: "transactionDate", orderable: false },
        { data: "description", orderable: false },
        { data: "quantity", orderable: false },
        { data: "cr", orderable: false, className: "text-right", 
          render : ( data, type, row, meta ) => { 
             if(row.cr != null){
               return row.cr; 
             } /*this.currencyPipe.transform(data, 'USD': true: '1.0-0');*/  
             return null;    
          }
        },
        { data: "dr" , orderable: false, className: "text-right",
          render : ( data, type, row, meta ) => { 
            if(row.dr != null){
                return row.dr; 
            } /*this.currencyPipe.transform(data, 'USD': true: '1.0-0');*/  
            return null;    
          }
        },
        { data: "dayBalance" , orderable: false, className: "text-right",
          render : ( data, type, row, meta ) => { 
            if(row.dr != null){
              return row.dayBalance;
            } /*this.currencyPipe.transform(data, 'USD': true: '1.0-0');*/  
            return null;    
          }
        },
      ],
      buttons: [
        'copy', 'excel', 'pdf', 'print'
        ],
      order: [[0,'asc']]
    };
}
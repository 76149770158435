import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ClientService } from '@app/core/services/client.service';
import { DataService } from '@app/core/services/data.service';

@Component({
  selector: 'resync-account',
  templateUrl: './resyncaccount.component.html',
})
export class ResyncAccountComponent implements OnInit {

  resyncAccountForm: FormGroup;
  submitted = false;
  submitting = false;

  constructor(private router: Router,
              private dataService: DataService,
              private clientService: ClientService,
              private fb: FormBuilder,
              private toastr: ToastrService) {
    this.resyncAccountForm  = this.createForm();
  }

  ngOnInit() {
    
  }

  createForm(): FormGroup{
    return this.fb.group({
      userName: ["", [Validators.required, Validators.email]],
    });
  }

  get f() {
    return this.resyncAccountForm.controls;
  }

  resyncAllAccounts(){
    event.preventDefault();
    this.submitted = true;
    this.submitting = true;
    if (this.dataService.client == null) {
       this.resyncAllAccountsExec(this.clientService.currentClient.clientId);
    } else {
      this.resyncAllAccountsExec(this.dataService.client.clientId);
    }
  }

  resyncAllAccountsExec(clientId: number){
    this.clientService.resyncAllAccounts(clientId)
    .subscribe(
      data=>{
        this.submitting = false;
        this.submitted = false;
        this.toastr.success("Account Sync All Sent!", "Account Management");
      },
      errorResponse=>{
        try{
          this.toastr.error(errorResponse, "Account Management");
        }
        catch(ex){
          this.toastr.error("Error!", "Account Management");
        }
        this.submitted = false;
        this.submitting = false;
      })
  }


  resyncAccount(event){
    event.preventDefault();
    this.submitted = true;
    if(this.resyncAccountForm.invalid){
       return;
    }
    this.submitting = true;
    if (this.dataService.client == null){
        this.resyncAccountExec(this.clientService.currentClient.clientId, this.f.userName.value);
    } else {
      this.resyncAccountExec(this.dataService.client.clientId, this.f.userName.value);
    }
  }

  resyncAccountExec(clientId: number, userName: string){
    this.clientService.resyncAccount(clientId, userName)
    .subscribe(
      data=>{
        
        this.f.userName.setValue("");
        this.submitting = false;
        this.submitted = false;
        this.toastr.success("Account Sync Sent!", "Account Management");
      },
      errorResponse=>{
        try{
          this.f.userName.setValue("");
          this.toastr.error(errorResponse, "Account Management");
        }
        catch(ex){
          this.toastr.error("Error!", "Account Management");
        }
        this.submitted = false;
        this.submitting = false;
      })
  }
}

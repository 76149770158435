import { Injectable, Inject, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { config } from "@app/core/smartadmin.config";

import { ClientService } from "./client.service";
import { ClientModel } from '@app/core/models/clientmodels/client.model';

@Injectable()
export class MessagingService implements OnInit, OnDestroy {

//  private messages: Observable<Message>;
  private statsSubscription: Subscription;
  public statusMessage = new BehaviorSubject<any>(null);
  public clientData: ClientModel;

  constructor(private router: Router,
              private http: HttpClient,
              private clientService: ClientService) {

    // this.messages = this._stompService..subscribe("/exchange/AX.topic/AX.*");
    // if (this.statsSubscription == null) {
    //   this.statsSubscription = this.messages.subscribe((data) => {
    //     var resultData = JSON.parse(data.body);
    //     this.statusMessage.next(resultData);
    //   });
    // }

    this.clientService.getClient()
      .subscribe(
        data => {
          if (!data)
            return;
          this.clientData = data;
        });
  }

  ngOnInit() {


  }

  ngOnDestroy() {

  }


  // public sendTopic(topic: string, message) {
  //   this._stompService.publish({ destination: '/exchange/AX.DataExchange/' + topic, body: message });
  // }



  public sendGroupMessage(groupId, message) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/messaging/sendGroupMessage?clientId=' + this.clientData.clientId + '&groupId=' + groupId, message)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public sendUserMessage(userId, message) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/messaging/sendUserMessage?clientId=' + this.clientData.clientId + '&userId=' + userId, message)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  } 

}

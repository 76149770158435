import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { select, Store } from '@ngrx/store';
import * as clientStore from '@app/core/store/client'

import { LayoutService } from "@app/core/services/layout.service";
import { LoginInfoComponent } from "../../user/login-info/login-info.component";
import { config } from '@app/core/smartadmin.config';
import { UserService } from '@app/core/services/user.service';
import { AuthService } from '@app/core/services/auth.service';
import { DataService } from '@app/core/services/data.service';
import { RulesService } from '@app/core/services/rules.service';
import { VendorService } from '@app/core/services/vendor.service';
import { UserActions } from '@app/core/store/user/user.actions';

import { UserModel } from '@app/core/models/usermodels/user.model';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { VendorModel } from '@app/core/models/vendormodels/vendor.model';

@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit, OnDestroy {

  debug = true;
  private userData: UserModel;
  public clientData: ClientModel;


  adminVisible: Boolean = false;
  simulatorVisible: Boolean = false;
  setupVisible: Boolean = false;
  clientVisible: Boolean = false;
  miscVisible: Boolean = false;
  chatVisible: Boolean = false;
  smartAdminVisible: Boolean = false;
  outlookVisible: Boolean = false;
  onlineStoreEnabled = false;
  dashboardVisible: Boolean = false;
  clientDashboardVisible: Boolean = false;
  vendors: Array<VendorModel> = [];
  showVendors = false;
  accountType = "";
  ShopName = "Shop";
  economyPreorder = false;
  preorderAvailable = false;
  public systemOk: boolean = true;
  public eventAttendance: boolean = false;

  public covid19RouterLink: string = '/covid19';
  public eventAttendanceRouterLink: string = '/eventAttendance';
  public eventAttendanceDisplayRouterLink: string = '/eventAttendanceDisplay';
  
  public economyAdminRouterLink: string = '/economyadmin';
  public economyDashboardLink: string = '/economyadmin/dashboard';
  public economyAdminLink: string = '/economyadmin/admin';
  public economyDepartmentLink: string = '/economyadmin/departments';
  public economyAccountsRouterLink: string = '/economyadmin/accounts';
  public economyClientsLink: string = '/economyadmin/clients';
  public economyVendorsLink: string = '/economyadmin/vendors';
  public economyThemeLink: string = '/economyadmin/theme';


  public profileRouterLink: string = '/app-views/profile';
  public accountRouterLink: string = '/account';
  public dashboardRouterLink: string = '/dashboard/analytics';
  public mailRouterLink: string = '/outlook';
  //public storeRouterLink: string = '/store';
  public storeRouterLink: string = '/store_vendor';
  
  public clientAdminRouterLink: string = '/clientadmin';
  public clientDashboardLink: string = '/clientadmin/dashboard';
  public clientReportRouterLink: string = '/clientadmin/reports';
  public clientReportDesignerRouterLink: string = '/clientadmin/reportdesigner';
  public messageTemplatesRouterLink: string = '/clientadmin/messagetemplates';
  public clientAccountsRouterLink: string = '/clientadmin/accounts';
  public messageNotificationRouterLink: string = '/clientadmin/notification';
  public clientGroupsRouterLink: string = '/clientadmin/usergroups';
  public clientLocationsRouterLink: string = '/clientadmin/locations';
  public clientVendorsLink: string = '/clientadmin/vendors';
  public clientDeviceRouterLink: string = '/clientadmin/devices';
  public clientVouchersRouterLink: string = '/clientadmin/vouchers';
  public clientPromoRouterLink: string = '/clientadmin/promo';
  public clientEventRouterLink: string = '/clientadmin/event';
  
  public vendorRouterLink: string = '/vendor';
  public vendorDashboardLink: string = '/vendor/dashboard';
  public vendorWorkflowLink: string = '/vendor/workflows';


  public adminRouterLink: string = '/admin/accounts';
  public adminClientsRouterLink: string = '/admin/clients';
  public adminAccountsRouterLink: string = '/admin/accounts';
  public adminEconomiesRouterLink: string = '/admin/economies';
  public adminReportRouterLink: string = '/admin/reports';
  public adminReportDesignerRouterLink: string = '/admin/reportdesigner';
  public adminDeviceRouterLink: string = '/admin/devices';
  public adminMessageNotificationRouterLink: string = '/admin/notification';
  public adminMessageTemplatesRouterLink: string = '/admin/messagetemplates';
  public adminAboutRouterLink: string = '/admin/about';
  public adminLogsRouterLink: string = '/admin/logs';
  public adminDocumentsRouterLink: string = '/admin/documents';
  public adminSettlementsRouterLink: string = '/admin/settlements';
  
  


  public conferenceLink: string = '/conference';


  public preorderRouterLink: string = '/preorder/dashboard';

  public isStudentVenture: boolean = false;
  private subscriptions: Subscription[] = [];

  public activeLink = "";
  constructor(private router: Router,
    public authService: AuthService,
    private rulesService: RulesService,
    public userService: UserService,
    private dataService: DataService,
    private layoutService: LayoutService,
    public vendorService: VendorService,
    private userActions: UserActions,
    private store: Store<any>) {
    this.preorderAvailable = config.usePreorders == true;
    this.debug = config.debug;
    this.showVendors = true;
  }



  ngOnInit() {
    let that = this;
    this.userService.documentSubject.subscribe(
      data => {
        if (data != null && data.length > 0)
        {
          this.systemOk = false;
        }
        else {
          this.systemOk = true;
        }
      }
    )
    this.subscriptions.push(this.rulesService.onRulesChange().subscribe(
      data => {
        if (data == null)
          return;
        // console.log('Rules NAV', data);
        this.isStudentVenture = data.isStudentVenture;
      }));

    this.subscriptions.push(
      this.store.pipe(
        select(clientStore.getClient)
      ).subscribe(
        (data: ClientModel) => {
          if (data == null) {
            return;
          }

          that.clientData = data;
          if (that.clientData.preorderType === "advmenu")
             that.preorderRouterLink = '/preorderv2/dashboard';
          that.loadVendors();
          if (this.isUserInRole("VENDORMASTERADMIN")){
            this.loadClientVendors();
          }

          try {
            this.economyPreorder = that.clientData.economy.economyRules.preorderEnabled == true;
            this.eventAttendance = that.clientData.economy.economyRules.eventAttendance == true;
            switch (that.clientData.economy.economyStore.storeType.toLowerCase()) {
              case "vendor store":
                that.ShopName = "Shopping Mall";
                that.storeRouterLink = "/store_vendor";
                break;
              default:
                that.storeRouterLink = "/store";
                break;
            }
          }
          catch (e) { }
        }));

    this.subscriptions.push(this.userService.getUser().subscribe(
      (data: UserModel) => {
        if ((data == null) || (data == undefined))
          return;

        that.userData = data;
        that.accountType = that.userService.getAccountType();
        if (that.userData.registrationState != null) {
          if (that.userData.registrationState.length > 0) {
            //this.profileRouterLink = '/dashboard/registration';
            //this.accountRouterLink = '/dashboard/registration';
            //this.dashboardRouterLink = '/dashboard/registration';
            //this.shopRouterLink= '/dashboard/registration';
            switch (that.clientData.registrationType) {
              case "School":
                that.mailRouterLink = '/dashboard/registration';
                break;
              case "Event":
                that.mailRouterLink = '/dashboard/registration/event';
                break;
              default:
                that.mailRouterLink = '/dashboard/registration';
                break;
            }

          }
        }
      }));

  }

  ngOnDestroy() {
    if (this.subscriptions) {
       this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  isUserInRole(role) {
    try {
      return this.authService.isUserInRole(role);
    }
    catch (ex) {
      return false;
    }
  }

  addToUL() {
    var ul = document.getElementById("vendorsList");
    var li = document.createElement("li");
    li.appendChild(document.createTextNode("Four"));
    ul.appendChild(li);
  }

  loadVendors() {
    let that = this;
    if (that.authService.isLoggedIn) {
      that.onlineStoreEnabled = that.clientData.onlineStoreEnabled === true;
      that.userService.getVendors(that.clientData.clientId).subscribe(data => {
        that.vendors = data;
        if (data.length <= 0) {
          if (!that.isUserInRole("VENDORADMIN")){
          that.showVendors = false;
          }
        }
      },
        error => {
          if (!that.isUserInRole("VENDORADMIN")){
            that.showVendors = false;
            }
        });
    }
  }

  loadClientVendors() {
    let that = this;
    if (that.authService.isLoggedIn) {
      that.onlineStoreEnabled = that.clientData.onlineStoreEnabled === true;
      that.userService.getClientVendors(that.clientData.clientId).subscribe(data => {
        that.showVendors = true;
        data.forEach(vendor => {
          if (that.vendors.find(x => x.vendorId == vendor.vendorId) == null){
            that.vendors.push(vendor);
          }
        });

        that.vendors.concat(data);
        if (that.vendors.length <= 0) {
          that.showVendors = false;
        }
      },
        error => {
        });
    }
  }


  loadMyAccount() {
    
    if (!this.systemOk){
      return;
    }

    this.dataService.userData = this.userService.userData;
    this.activeLink = this.accountRouterLink;
    this.router.navigate([this.accountRouterLink]);
    if (this.layoutService.store.mobileViewActivated) {
      this.layoutService.onCollapseMenu()
    }
  }

  navigateTo(destination, role) {
    
    if (!this.systemOk){
      return;
    }

    if (destination == undefined)
       return;

    this.activeLink = destination;
    if (destination == this.dashboardRouterLink)   
       this.router.navigate([destination]);
    else   
       this.router.navigate([destination, { role: role }]);
    if (this.layoutService.store.mobileViewActivated) {
      this.layoutService.onCollapseMenu()
    }    
  }

  clientNavigateTo(destination, role) {
    
    if (!this.systemOk){
      return;
    }
    this.activeLink = destination;
    this.dataService.client = null;
    if (destination == undefined)
       return;
    if (destination == this.dashboardRouterLink)   
       this.router.navigate([destination]);
    else   
       this.router.navigate([destination, { role: role }]);
    if (this.layoutService.store.mobileViewActivated) {
      this.layoutService.onCollapseMenu()
    }    
  }

  navigateToVendor(role, vendor = null) {
    if (!this.systemOk){
      return;
    }
    if (vendor != null) {
      let dest = this.vendorRouterLink + '/vendor/' + vendor.vendorId;
      this.dataService.vendor = vendor;
      this.activeLink = dest;
      this.router.navigate([this.vendorRouterLink + '/vendor/' + vendor.vendorId, { role: role }]);
      if (this.layoutService.store.mobileViewActivated) {
        this.layoutService.onCollapseMenu()
      }
    }
    else {
      
      this.dataService.vendor = this.vendors[0];

      let dest = this.vendorRouterLink + '/vendor/' + this.dataService.vendor.vendorId
      this.router.navigate([this.vendorRouterLink + '/vendor/' + this.dataService.vendor.vendorId, { role: role }]);
      if (this.layoutService.store.mobileViewActivated) {
        this.layoutService.onCollapseMenu()
      }
    }
  }

  navigateToSVVendorDash(role, vendor = null) {

    if (!this.systemOk){
      return;
    }

    this.dataService.vendor = vendor;
    if (vendor == null)
      this.dataService.vendor = this.vendors[0];

    this.router.navigate([this.vendorRouterLink + '/vendor/' + this.dataService.vendor.vendorId, { role: role }]);
    if (this.layoutService.store.mobileViewActivated) {
      this.layoutService.onCollapseMenu()
    }
  }

  navigateToSVVendorSettings(role, vendor = null) {
    if (!this.systemOk){
      return;
    }

    this.dataService.vendor = vendor;
    if (vendor == null)
      this.dataService.vendor = this.vendors[0];
    this.router.navigate([this.vendorRouterLink + '/settings', { role: role }]);
    if (this.layoutService.store.mobileViewActivated) {
      this.layoutService.onCollapseMenu()
    }
  }

  navigateToSVVendorProducts(role, vendor = null) {
    if (!this.systemOk){
      return;
    }

    this.dataService.vendor = vendor;
    if (vendor == null)
      this.dataService.vendor = this.vendors[0];
    this.router.navigate([this.vendorRouterLink + '/products', { role: role }]);
    if (this.layoutService.store.mobileViewActivated) {
      this.layoutService.onCollapseMenu()
    }
  }


  navigateToNoCollapse(destination, role) {

    if (!this.systemOk){
      return;
    }

    this.router.navigate([destination, { role: role }]);
  }

}


import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import { timer } from 'rxjs';


import { UserService } from '@app/core/services/user.service';

import { UserModel } from '@app/core/models/usermodels/user.model';
import { UserOTPRequestModel } from '@app/core/models/usermodels/userotp-request.model';
import { PermissionsModel } from '@app/core/models/usermodels/permissions.model';
import { ClientPermissionsModel } from '@app/core/models/clientmodels/client-permissions.model';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import * as clientStore from '@app/core/store/client'


@Component({
  selector: 'account-info',
  templateUrl: './account-info.component.html',
})
export class AccountInfoComponent implements OnInit, OnDestroy {

  clientData:ClientModel;

  userData: UserModel = new UserModel();
  userPermissions: PermissionsModel = new PermissionsModel();
  clientPermissions: ClientPermissionsModel = new ClientPermissionsModel();

  linkedAccounts: UserModel[];
  parentAccounts: UserModel[];
  userPhoto: string = "assets/img/avatars/sunny-big.png";
  userName: string = "";
  loading: boolean = true;  

  otpSubmitted = false;
  otpSubmitting = false;
  otpNumber;
  otpForm:FormGroup;
  linkedAccountsAvailable = false;
  canEditProfile = false;

  @Input('accountdata') set accountdata(value){
    
    if(!value)
       return;
    this.userData = value;
    this.userName = this.userData.userName;
    if(this.userData.userPhoto != null)
       this.userPhoto = this.userData.userPhoto;

    if (this.userData.linkedAccounts > 0)
       this.loadLinkedAccounts();
    if (this.userData.parentAccounts > 0)
       this.loadParentAccounts();
  }

  @Input('permissions') set permissions(value){
    this.userPermissions   = value;
    this.canEditProfile =  this.userPermissions.canEditProfile == true;
  }

  @Input('adminaccountdata') set adminaccountdata(value){
    if (!value)
      return;

    this.userData = value;    
    if(this.userData.userPhoto != null)
       this.userPhoto = this.userData.userPhoto;

    if (this.userData.linkedAccounts > 0)
       this.loadLinkedAccounts();
    if (this.userData.parentAccounts > 0)
       this.loadParentAccounts();
  }

  @Input('clientpermissions') set clientpermissions(value:ClientPermissionsModel){
    if ((value === null) || (value === undefined))
       return;

    this.clientPermissions   = value;
    //this.statementVisible = value.canViewBalance === true;
  }


  @Output() editAccount = new EventEmitter();

  constructor(private fb:FormBuilder,
              private toastr:ToastrService,
              private router:Router,
              private userService:UserService,
              private store: Store<any>){
              this.otpForm   = this.createVerifyPinForm();
  }

  ngOnInit() {
    let that = this;
    this.store.pipe(
      select(clientStore.getClient)
    ).subscribe(
      (data: ClientModel) => {
        if ((data == null) || (data == undefined))
          return;
        this.clientData = data;
        this.linkedAccountsAvailable = data.economy.economyRules.linkedAccountsEnabled == true;
      });
  }

  ngOnDestroy(){
  }

  createVerifyPinForm(): FormGroup{
    return this.fb.group(
      {
        otpPin: ["", [Validators.required, Validators.minLength(6)]]
     }
    );
  }

  loadLinkedAccounts(){
    this.loading = true;
    this.userService.getLinkedAccounts()
      .subscribe(
        (data)=>{
          this.loading = false;
          this.linkedAccounts = data;
      });
   }

  loadParentAccounts(){
    this.loading = true;
    this.userService.getParentAccounts()
      .subscribe(
        (data)=>{
           this.loading = false;
           this.parentAccounts = data;
        });
  }

  editClick(){
    this.editAccount.emit(this.accountdata);
  }

  get vf() {
    return this.otpForm.controls;
  }

  showPin(){
    $('#changeChildrenModal').modal({backdrop: 'static', keyboard: false });
    $('#aiotpPin').focus();
  }

  hidePin(){
    $('#changeChildrenModal').modal('hide')
    // $('body').removeClass('modal-open');
    // $('.modal-backdrop').remove();
  }

  verifyPin(event){
    let that = this;
    this.otpSubmitted = true;
    event.preventDefault();
    
    if(this.otpForm.invalid){
      return;
    }   
    
    if (this.vf.otpPin.value === this.otpNumber)
    {
        this.otpSubmitting = true;
        this.hidePin();
        this.userService.addChild(that.clientData.clientId).subscribe( data =>{
          that.userData = data;
          this.toastr.success("You have added a child to your account.<br/>You can now go to dashboard and complete a child lookup!", "Congratulations!");
          switch (that.clientData.registrationType) {
            case "School":
              that.router.navigate(['/dashboard/registration']);
              break;
            case "Event":
              that.router.navigate(['/dashboard/registration/event']);
              break;
            default:
              that.router.navigate(['/dashboard/registration']);
              break;
          }
        })
    }
  }

  addChild(){
    let that = this;
    if (this.clientData.childOTPPinRequired == true)   
        this.resendPin();
     else {
      this.userService.addChild(that.clientData.clientId).subscribe( data =>{
        that.userData = data;
        this.toastr.success("You have added a child to your account.<br/>You can now go to dashboard and complete a child lookup!", "Congratulations!");
        switch (that.clientData.registrationType) {
          case "School":
            that.router.navigate(['/dashboard/registration']);
            break;
          case "Event":
            that.router.navigate(['/dashboard/registration/event']);
            break;
          default:
            that.router.navigate(['/dashboard/registration']);
            break;
        }
      });
    }
  }
  
  resendPin(){
    var otpRequest:UserOTPRequestModel = new UserOTPRequestModel();
    otpRequest.userId = this.userData.userId;
    otpRequest.phoneNumber = this.userData.phoneNumber;
    this.userService.requestUserOTP(otpRequest).subscribe(
      (data)=>{
        this.otpNumber = data.otpPin;
        this.vf.otpPin.setValue("");
        $('#changeChildrenModal').on('shown.bs.modal', function (e) {
          //alert("I want this to appear after the modal has opened!");
          $('#otpPin').focus();
        });
        $('#changeChildrenModal').modal({show: true, backdrop: 'static', keyboard: false });
      },
      (errorResponse)=>{
        this.toastr.error("Something went wrong", "Ho No!");
      });

  }
  
  closeTimer(){
    var _closeTimer = timer(3000,0);
    var _subscription = _closeTimer.subscribe(x => {
      $('#changeChildrenModal').modal('hide');
      _subscription.unsubscribe();
    });
  }
  
  cancelPin(){
    $('#changeChildrenModal').modal('hide');
  }
}

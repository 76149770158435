import { ToastrService } from 'ngx-toastr';
import { CartLineItem } from './../models/cart/cart-line-item.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { config } from "@app/core/smartadmin.config";

import { ClientService } from './client.service';

import { CartOrderModel } from './../models/cart/cart-order.model';
import { OrderResponseModel } from './../models/cart/order-response.model';
import { CartVoucherModel } from '../models/cart/cart-voucher.model';
import { CartConfigModel } from '../models/cart/cartconfig.model';



@Injectable()
export class CartService {


  private cartConfig: CartConfigModel;
  public cartConfigSubject = new BehaviorSubject<CartConfigModel>(null);

  constructor(private toastr: ToastrService,
    private clientService: ClientService,
    private http: HttpClient) {
      this.cartConfig = new CartConfigModel();
      this.cartConfig.displayMode = "hidden";
      this.cartConfig.workflowMode = "pickup";
      this.cartConfigSubject.next(this.cartConfig);
  }
  // tslint:disable-next-line:member-ordering
  success: any;
  // tslint:disable-next-line:member-ordering
  error: any;

  getCart(): Observable<CartOrderModel> {
    var clientData = this.clientService.currentClient;
    return this.http.get<CartOrderModel>(config.TRIOSAPI + '/api/cart/getcart?clientId=' + clientData.clientId)
      .pipe(
        catchError((error) => {
          // console.log("Error", error);
          return EMPTY
        }),
        map((resp) => {
          resp.vendorOrder.forEach((order) => {

            var array: Array<CartLineItem> = [];
            order.items.forEach((lineItem) => {
              array.push(lineItem);
            });
            order.items = array;
            //console.log("Cart Contents", resp);
          });
          return resp;
        }
        ));
  }


  addItemToCart(item: any): Observable<CartOrderModel> {
    var clientData = this.clientService.currentClient;
    return this.http.post<CartOrderModel>(config.TRIOSAPI + '/api/cart/additemtocart?clientId=' + clientData.clientId, item)
      .pipe(
        catchError((error) => {
          // console.log("Error", error);
          this.toastr.warning(error, "Add to cart error!");
          return EMPTY
        }),
        map((resp) => {
          // var array: Array<CartLineItem> = [];
          // resp.items.forEach((lineItem) => {
          //   array.push(lineItem);
          // });
          // resp.items = array;
          return resp;
        }
        ))
  }

  removeItemFromCart(item: any): Observable<CartOrderModel> {
    var clientData = this.clientService.currentClient;
    return this.http.post<CartOrderModel>(config.TRIOSAPI + '/api/cart/removeitemfromcart?clientId=' + clientData.clientId, item)
      .pipe(
        catchError((error) => {
          // console.log("Error", error);
          return EMPTY
        }),
        map((resp) => {

          resp.vendorOrder.forEach((vendorOrder) => {
            var array: Array<CartLineItem> = [];
            vendorOrder.items.forEach((lineItem) => {
              array.push(lineItem);
            });
            vendorOrder.items = array;
          });
          return resp;
        }
        ));
  }


  placeWebOrder(payload: any): Observable<OrderResponseModel> {
    var clientData = this.clientService.currentClient;
    var url = config.TRIOSAPI + '/api/cart/placeweborder?clientId=' + clientData.clientId;
    return this.http.post<any>(url, payload)
      .pipe(
          catchError((error) => { 
            return EMPTY
          }),
        map((resp) => {
          return resp;
        }
        ));
  }


  placeCardOrder(): Observable<OrderResponseModel> {
    var clientData = this.clientService.currentClient;
    return this.http.post<any>(config.TRIOSAPI + '/api/cart/placecardorder?clientId=' + clientData.clientId, null)
      .pipe(
        catchError((error) => {
          // console.log("Error", error);
          return EMPTY
        }),
        map((resp) => {
          return resp;
        }
        ));
  }

  emptyCart(): Observable<any> {
    var clientData = this.clientService.currentClient;
    return this.http.post<any>(config.TRIOSAPI + '/api/cart/emptyCart?clientId=' + clientData.clientId, null)
      .pipe(
        catchError((error) => {
          alert(error);
          // console.log("Error", error);
          return EMPTY
        }),
        map((resp) => {
          return resp;
        }
        ));
  }


  checkVouchers(cartOrder: CartOrderModel): Observable<Array<CartVoucherModel>> {
    var clientData = this.clientService.currentClient;
    return this.http.post<Array<CartVoucherModel>>(config.TRIOSAPI + '/api/cart/checkVouchers?clientId=' + clientData.clientId, cartOrder)
      .pipe(
        catchError((error) => {
          alert(error);
          // console.log("Error", error);
          return EMPTY
        }),
        map((resp) => {
          return resp;
        }
        ));
  }

  getCartConfig(){
    return this.cartConfig;
  }

  setCartMode(mode: string){
    
    this.cartConfig.displayMode = mode;
    this.cartConfigSubject.next(this.cartConfig);
  }

  setCartAddress(formattedAddress: string){
    this.cartConfig.address = formattedAddress;
    this.cartConfigSubject.next(this.cartConfig);
  }

  setWorkflow(mode: boolean){
    if (mode)
    {
      this.cartConfig.workflowMode = "delivery";
    }
    else {
      this.cartConfig.workflowMode = "pickup";
    }
    this.cartConfigSubject.next(this.cartConfig);

  }
  
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from '@app/core/services/client.service';
import { DataService } from '@app/core/services/data.service';

@Component({
  selector: 'delete-account',
  templateUrl: './deleteaccount.component.html',
})
export class DeleteAccountComponent implements OnInit {

  removeAccountForm: FormGroup;
  submitted = false;
  submitting = false;

  constructor(private router: Router,
    private dataService: DataService,
    private clientService: ClientService,
    private fb: FormBuilder,
    private toastr: ToastrService) {
    this.removeAccountForm = this.createRemoveAccountForm();
  }

  ngOnInit() {

  }

  createRemoveAccountForm(): FormGroup {
    return this.fb.group({
      userName: ["", [Validators.required, Validators.email]],
    });
  }

  get f() {
    return this.removeAccountForm.controls;
  }

  deleteAccount(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.removeAccountForm.invalid) {
      return;
    }
    this.submitting = true;
    if (this.dataService.client == null) {
      this.deleteAccountExec(this.clientService.currentClient.clientId, this.f.userName.value);
    } else {
      this.deleteAccountExec(this.dataService.client.clientId, this.f.userName.value);
    }
  }

  deleteAccountExec(clientId: number, userName: string) {
    this.clientService.deleteAccount(clientId, userName)
      .subscribe(
        data => {

          this.f.userName.setValue("");
          this.submitting = false;
          this.submitted = false;
          this.toastr.success("Account Deleted!", "Account Management");
        },
        errorResponse => {
          try {
            this.f.userName.setValue("");
            this.toastr.error(errorResponse, "Account Management");
          }
          catch (ex) {
            this.toastr.error("Error!", "Account Management");
          }
          this.submitted = false;
          this.submitting = false;
        })
  }
}

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '@app/core/services/user.service';
import { PermissionsModel } from '@app/core/models/usermodels/permissions.model';
import { UserModel } from '@app/core/models/usermodels/user.model';




@Component({
  selector: 'account-permissions',
  templateUrl: './account-permissions.component.html',
})
export class AccountPermissionsComponent implements OnInit {


  permissionsForm: FormGroup;
  private _showModal = false;
  private _accountData: UserModel;
  submitted = false;
  submitting = false;


  @Output("isvisible") isvisible = new EventEmitter();
  @Input('showmodal')
  get showmodal() {
    return this._showModal;
  }
  set showmodal(value) {
    if (value == null)
      return;
    this._showModal = value;
     if (value)
       this.showModal();
     else
       this.hide()
    //this.modalChange.emit(this._showModal)
  }

  @Input('accountdata')
  set accountdata(value: UserModel) {
    if (value == null)
      return;
    this._accountData = value;
    this.loadForm();
  }
  get accountdata(): UserModel {
    return this._accountData;
  }



  constructor(private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService) {
    this.permissionsForm = this.createPermisstionsForm();
  }

  ngOnInit() {

  }


  loadForm() {
    this.f.canUnlinkAccount.setValue(this._accountData.accountPermissions.canUnlinkAccount);
    this.f.canSetPermissions.setValue(this._accountData.accountPermissions.canSetPermissions);
    this.f.canEditProfile.setValue(this._accountData.accountPermissions.canEditProfile);
    this.f.canViewProfile.setValue(this._accountData.accountPermissions.canViewProfile);
    this.f.canEditDiet.setValue(this._accountData.accountPermissions.canEditDiet);
    this.f.balanceVisible.setValue(this._accountData.accountPermissions.balanceVisible);
  }


  createPermisstionsForm(): FormGroup {
    return this.fb.group(
      {
        canUnlinkAccount: [],
        canSetPermissions: [],
        canEditProfile: [],
        canViewProfile: [],
        canEditDiet: [],
        balanceVisible: [],
      });
  }


  get f() {
    return this.permissionsForm.controls;
  }

  cancelForm() {
    this.hide();
  }

  submitForm() {
    this.submitted = true;
    var permissions = new PermissionsModel();
    permissions.accountId = this._accountData.userId;
    permissions.canUnlinkAccount = this.f.canUnlinkAccount.value;
    permissions.canEditProfile = this.f.canEditProfile.value;
    permissions.canViewProfile = this.f.canViewProfile.value;
    permissions.canEditDiet = this.f.canEditDiet.value
    permissions.balanceVisible = this.f.balanceVisible.value
    permissions.canSetPermissions = this.f.canSetPermissions.value

    this.submitting = true;
    this.userService.setAccountPermissions(permissions).subscribe(
      data => {
        this.submitting = false;
        this.toastr.success("Permissions Changed", "Awesome");
        this.hide();
      },
      errorResponse => {
        this.submitting = false;
        this.toastr.error(errorResponse, "OhNo!");
      });
  }

  showModal() {
    this._showModal = true;
    $("#accountPermission").on('shown.bs.modal', function (e) {
    });
    $("#accountPermission").modal({ show: true, backdrop: 'static', keyboard: false });
  }

  hide() {
    this._showModal = false;
    $("#accountPermission").modal('hide')
    this.isvisible.emit(false);
  }
}

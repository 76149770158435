

export const languages: any = [
  {
    "key": "us",
    "alt": "United States",
    "title": "English (US)"
  },
  {
    "key": "za",
    "alt": "South Africa",
    "title": "English"
  }
];

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '@app/core/services/user.service';
import { UserModel } from '../../../core/models/usermodels/user.model';
import { PermissionsModel } from '../../../core/models/usermodels/permissions.model';

import * as moment from "moment";


@Component({
  selector: 'account-preferences',
  templateUrl: './account-preferences.component.html',
})
export class AccountPreferencesComponent implements OnInit {

  userData: UserModel;
  userPermissions: PermissionsModel;
  preferencesForm: FormGroup;
  updateTimer;

  @Input('accountdata') set accountdata(value){
    this.userData = value;
  }

  @Input('permissions') set permissions(value){
    this.userPermissions = value;
  }

  constructor(private fb: FormBuilder,
              private userService:UserService,
              private toastr: ToastrService) {
    this.preferencesForm = this.createPreferencesForm();
  }

  ngOnInit() {
   
  }

  get f() {
    return this.preferencesForm.controls;
  }


  createPreferencesForm(): FormGroup{
    return this.fb.group({
    });
  }


  changeLbaAmount(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.lowBalanceAmount = $("#lbaAmount").val();
      this.submitPreferences();
      return true;
    }
  }

  changeEmailNotification(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.emailNotification = !this.userData.preferences.emailNotification;
      this.submitPreferences();
      return true;
    }
    return false;
  }


//  public lowBalanceAmount:number;  

  changeNotifyParent(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.notifyParent = !this.userData.preferences.notifyParent;
      this.submitPreferences();
      return true;
    }
    return false;
  }

  changeAlertNotification(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.alertNotification = !this.userData.preferences.alertNotification;
      this.submitPreferences();
      return true;
    }
    return false;
  }

  changeDepositNotification(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.depositNotification = !this.userData.preferences.depositNotification;
      this.submitPreferences();
      return true;
    }
    return false;
  }

  changeOfferNotification(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.canAcceptOffers = !this.userData.preferences.canAcceptOffers;
      this.submitPreferences();
      return true;
    }
    return false;
  }

  changeOrderNotification(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.orderNotification = !this.userData.preferences.orderNotification;
      this.submitPreferences();
      return true;
    }
    return false;
  }


  changePushNotification(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.pushNotification = !this.userData.preferences.pushNotification;
      this.submitPreferences();
      return true;
    }
    return false;
  }

  changeTextNotification(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.textNotification = !this.userData.preferences.textNotification;
      this.submitPreferences();
      return true;
    }
    return false;
  }

  changeLbaNotification(){
    if(this.userPermissions.canEditProfile){
      this.userData.preferences.lbaNotification = !this.userData.preferences.lbaNotification;
      this.submitPreferences();
      return true;
    }
    return false;
  }


  submitPreferences(){
    let that = this;
    if (this.updateTimer)
       clearTimeout(this.updateTimer);
    this.updateTimer = setTimeout(()=>{
      that.toastr.warning("Updating preferences");
      this.userService.setPreferences(this.userData)
      .subscribe((data)=>{
        that.userData = data;
        that.toastr.success("Preferences sucessfully changed", "Change preferences");
      });  
    }, 2000);

    
  }
}

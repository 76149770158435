// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  debug: true,
  version: "1.0.0.109D",
  displayVersion: "2.0.5.0",
  systemVersion: '2.0.5.0',
  preorders: true,
  preorderType: 'menu1',
  //portalurl: "qia.allxs.co.za",
  //portalurl: "triosdev.allxs.co.za",
  portalurl: "demonew.allxs.co.za",
  
  nedbankAuthPage: 'https://portal.nedsecure.co.za/Lite/Authorise.aspx',
  firebase: {
     apiKey: "AIzaSyCru-p5FW_bitdrnPqjXAcgrjZpGLvPpGo",
     authDomain: "trios-1253.firebaseapp.com",
     databaseURL: "https://trios-1253.firebaseio.com",
     projectId: "trios-1253",
     storageBucket: "trios-1253.appspot.com",
     messagingSenderId: "674163863489",
     appId: "1:674163863489:web:49902b21d1d6aac4"
   },
  
  syncfusionkey:'MTA4ODI3QDMxMzcyZTMxMmUzMEZuTnZxUWVJcHBNVjhaMEJvUE0yS0VDUzZsalozVkdCeWhQSHBoNzFxS1U9;MTA4ODI4QDMxMzcyZTMxMmUzMEZuTnZxUWVJcHBNVjhaMEJvUE0yS0VDUzZsalozVkdCeWhQSHBoNzFxS1U9',

  // apiportal: 'https://clientapi.allxs.co.za',
  // apiportal: 'https://clientapi.allxs.ca',
   //apiportal: 'https://clientapivg.allxs.co.za',
  // apiportal: 'http://localhost',
  apiportal: 'https://allxsserver.ngrok.io',
  //apiportal: 'https://clientapiprod.sa2.allxs.co.za',
  // apiportal: 'https://clientapiold.allxs.co.za',
  //apiportal: 'https://clientapidev.allxs.co.za',
  //apiportal: 'https://clientapidev.contextpos.com',
  //apiportal: 'https://clientapiprod.allxs.co.za',
  rewardsurl: 'https://allxs.co.za/rewards/',
  internurl: 'https://allxs.co.za/internship/',
  //reportServer: 'http://192.168.1.2/reportserver', 
  //reportServer: 'http://localhost/reportserver', 
  // reportServer: 'http://localhost/reportserver',  
  reportServer: 'http://reports.allxs.co.za/reportserver',  
  
  log: {
    routing: false, 
    auth: false,
    store: false,
  }, 

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },

  googleAnalyticsCode : 'UA-65320423-3',
  TennantId: "5be1f46d-495f-465b-9507-996e8c8cdcb6",
  ApplicationId: "6a203037-d9ed-4b6c-8770-537af0558dec",
  ABSATennantId: "5be1f46d-495f-465b-9507-996e8c8cdcb6",
  ABSAApplicationId: "3b763284-8cce-4133-b6e6-24ee32cc7754",
  
  tU: "",
  tUP: "",
};


import { map, tap, switchMap } from 'rxjs/operators';

import { ProductActions } from './product.actions';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ProductService } from '@app/core/services/product.service';
import { Action } from '@ngrx/store';
import { Brand } from '@app/core/models/product/brand.model';
import { ProductCategoryModel } from '@app/core/models/product/productcategory.model';
import { Review } from '@app/core/models/product/review.model';
import { RatingOption } from '@app/core/models/product/rating-option.model';
import { dataBound } from '@syncfusion/ej2-grids';

import { ProductModel } from '@app/core/models/productmodels/product.model';
import { ProductV2Model } from '../../models/productmodels/productv2.model';

@Injectable()
export class ProductEffects {


  @Effect()
  GetAllProducts$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.GET_ALL_PRODUCTS),
    switchMap((action: any) => {
      return this.productService.getProducts(action.payload.clientId, action.payload.locationId, action.payload.departmentId, action.payload.pageNumber)
    }),
    map((data: any) => this.productActions.getAllProductsSuccess({ products: data }))
  );

  @Effect()
  GetAllVendorProducts$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.GET_ALL_VENDOR_PRODUCTS),
    switchMap((action: any) => {
      return this.productService.getVendorProducts(action.payload.vendorId, action.payload.locationId, action.payload.departmentId, action.payload.pageNumber)
    }),
    map((data: any) => this.productActions.getAllProductsSuccess({ products: data }))
  );
  // @Effect()
  // GetBrands$ = this.actions$.pipe(
  //   ofType(ProductActions.GET_ALL_BRANDS),
  //   switchMap<Action, Observable<Array<Brand>>>(_ => {
  //     return this.productService.getBrands(26);
  //   }),
  //   map(brands => this.productActions.getBrandsSuccess(brands))
  // );

  //VG GetCategories
  @Effect()
  GetCategories$ = this.actions$.pipe(
    ofType(ProductActions.GET_ALL_CATEGORIES),
    switchMap<Action, Observable<Array<ProductCategoryModel>>>((action: any) => {
      return this.productService.getCategories(action.payload.clientId, action.payload.locationId, action.payload.filter);
    }),
    map(categories => this.productActions.getCategoriesSuccess(categories))
  );

  @Effect()
  GetVendors$ = this.actions$.pipe(
    ofType(ProductActions.GET_ALL_VENDORS),
    //switchMap<Action, Observable<Array<Vendor>>>((action: any) => this.productService.getVendorsLocation(action.payload.clientId, action.payload.locationId)),
    switchMap((action: any) => {
      return this.productService.getVendors(action.payload.clientId);
    }),
    map(vendors => this.productActions.getAllVendorsSuccess(vendors))
  );

  @Effect()
  GetLocationVendors$ = this.actions$.pipe(
    ofType(ProductActions.GET_LOCATION_VENDORS),
    switchMap((action: any) => {
      return this.productService.getVendorsLocation(action.payload.clientId, action.payload.locationId);
    }),
    map(vendors => this.productActions.getAllVendorsSuccess(vendors))
  );

  @Effect()
  GetFeaturedVendors$ = this.actions$.pipe(
    ofType(ProductActions.GET_FEATURED_VENDORS),
    //switchMap<Action, Observable<Array<Vendor>>>((action: any) => this.productService.getVendorsLocation(action.payload.clientId, action.payload.locationId)),
    switchMap((action: any) => {
      return this.productService.getFeaturedVendors(action.payload.clientId);
    }),
    map(vendors => this.productActions.getFeaturedVendorsSuccess(vendors))
  );

  @Effect()
  GetLocationFeaturedVendors$ = this.actions$.pipe(
    ofType(ProductActions.GET_LOCATION_FEATURED_VENDORS),
    switchMap((action: any) => {
      return this.productService.getFeaturedVendorsLocation(action.payload.clientId, action.payload.locationId);
    }),
    map(vendors => this.productActions.getFeaturedVendorsSuccess(vendors))
  );

  @Effect()
  GetProductV2$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.GET_PRODUCT_V2),
    switchMap((action: any) => this.productService.getProductV2(action.payload)),
    map((data: any) =>
      this.productActions.getProductV2Success({ productV2: data })
    )
  );

@Effect()
  ClearProductV2$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.CLEAR_SELECTED_PRODUCTV2),
    map((data: any) =>
      this.productActions.getProductV2Success({ productV2: null })
    )
  );
  //   @Effect()
  //   GetAllTaxonomies$: Observable<Action> = this.actions$.pipe(
  //     ofType(ProductActions.GET_ALL_TAXONOMIES),
  //     switchMap((action: any) => this.productService.getTaxonomies()),
  //     map((data: any) =>
  //       this.productActions.getAllTaxonomiesSuccess({ taxonomies: data })
  //     )
  //   );

  //   @Effect()
  //   GetProductDetail$: Observable<Action> = this.actions$.pipe(
  //     ofType(ProductActions.GET_PRODUCT_DETAIL),
  //     switchMap((action: any) => this.productService.getProduct(action.payload)),
  //     map((product: Product) =>
  //       this.productActions.getProductDetailSuccess({ product })
  //     )
  //   );

  //   @Effect()
  //   GetRelatedProducts$: Observable<Action> = this.actions$.pipe(
  //     ofType(ProductActions.GET_RELATED_PRODUCT),
  //     switchMap((action: any) =>
  //       this.productService.getRelatedProducts(action.payload)
  //     ),
  //     map((products: Product[]) =>
  //       this.productActions.getRelatedProductSuccess({ products })
  //     )
  //   );

  // //   @Effect()
  // //   GetProductReviews$ = this.actions$.pipe(
  // //     ofType(ProductActions.GET_PRODUCT_REVIEWS),
  // //      switchMap<Action & { payload }, Array<Review>>(action => {
  // //        return this.productService.getProductReviews(action.payload);
  // //      }),
  // //      map(reviewsList =>
  // //     //   //this.productActions.getProductReviewsSuccess(reviewsList)
  // //      )
  // //   );


  //   @Effect()
  //   WriteProductReview$ = this.actions$.pipe(
  //     ofType(ProductActions.WRITE_PRODUCT_REVIEW),
  //     // switchMap<Action & { payload }, Review>(action => {
  //     //   return this.productService.writeProductReview(action.payload);
  //     // }),
  //     // map(review => this.productActions.writeProductReviewSuccess(review))
  //   );

  //   @Effect()
  //   GetProductRatingOptions$ = this.actions$.pipe(
  //     ofType(ProductActions.GET_RATING_OPTIONS),
  //     // switchMap<Action & { payload }, Array<RatingOption>>(action => {
  //     //   return this.productService.getProductRatingOptions(action.payload);
  //     // }),
  //     // map(ratingOption =>
  //     //   this.productActions.getRatingsOptionsSuccess(ratingOption)
  //     // )
  //   );

  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private productActions: ProductActions
  ) { }
}

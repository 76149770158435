import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class AppStoreService {
  constructor(private store : Store<any>) {
  }

  public getAllState():Observable<any>{
      return this.store.select('storeReducer');
  }
}
import { LayoutService } from '@app/core/services/layout.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as clientStore from '@app/core/store/client';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { SplashAnimationType } from "./splash-animationtype";
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, timer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'splash-screen',
  templateUrl: './startsplashscreen.component.html',
  styleUrls: ['./startsplashscreen.component.css']
})
export class StartSplashScreenComponent implements OnInit {

  clientData: ClientModel;
  windowWidth: string;
  splashTransition: string;
  opacityChange: number = 1;
  showSplash = false;
  splashBackground: any;
  bkUrl = {};  
  _finished: boolean = false;

  @Input() animationDuration: number = 0.5;
  @Input() duration: number = 5;
  @Input() animationType: SplashAnimationType = SplashAnimationType.SlideLeft;
  
  @Output() finishedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input('finished')
  set finished(value: boolean) {
    if (!value){
      return;
    }
    this._finished = value;
    //this.finishedChange.emit(this._finished);
  }

  get finished() {
    return this._finished;
  }

  constructor(private router: Router,
              private store: Store<any>,
              protected _sanitizer: DomSanitizer,
              private layoutService: LayoutService) {}

  ngOnInit() {
    if (this.router.url === '/auth/maint'){
      this.finished = true;
    }
    let that = this;
    this.store.pipe(
      select(clientStore.getClient)
    ).subscribe(
      (data: ClientModel) => {
        if ((data == null) || (data == undefined))
          return;
        that.clientData = data;
        this.bkUrl = this.getBkUrl();
        if (this.bkUrl == null){
          that.finished = true;
          return;
        }
        else {
           this.showSplash = true;
           this.doSplash();
        }
      });
  }

  doSplash(){
    setTimeout(() => {
      let transitionStyle = "";
      switch (this.animationType) {
        case SplashAnimationType.SlideLeft:
          this.windowWidth = "-" + window.innerWidth + "px";
          transitionStyle = "left " + this.animationDuration + "s";
          break;
        case SplashAnimationType.SlideRight:
          this.windowWidth = window.innerWidth + "px";
          transitionStyle = "left " + this.animationDuration + "s";
          break;
        case SplashAnimationType.FadeOut:
          transitionStyle = "opacity " + this.animationDuration + "s";
          this.opacityChange = 0;
      }
      this.splashTransition = transitionStyle;

      setTimeout(() => {
        this.showSplash = !this.showSplash;
        this.finished = true;
      }, this.animationDuration * 1000);
    }, this.duration * 1000);
  }

  getBackground(){
    return  "url('" + this.clientData.splashScreen.splash + "')";
  }
  
  private time: Observable<Date> = timer(0, 1000)
  .pipe(
    map(tick => new Date()),
    shareReplay(1)
  );
  
  private hours$: Observable<number> = 
  this.time.pipe(
    map((now: Date) => now.getHours()) 
  );

  getBkUrl() {
    var splashUrl = "";
    if (this.layoutService.isMobile()){
      splashUrl = this.clientData.splashScreen.mobileSplash
    }
    else {
      splashUrl = this.clientData.splashScreen.splash
    }
    if (splashUrl == null || splashUrl == "")
    {
      return null;
    }

    const styles = {
      'background-image': "url(" + splashUrl + ")",
      'left': 'windowWidth', 
      'opacity': 'opacityChange', 
      'transition': 'splashTransition'
    };
    return styles;
  }

}

import { map } from 'rxjs/operators';
import { Taxonomy } from '../models/product/taxonomy.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductModel } from '../models/productmodels/product.model';

import { Observable } from 'rxjs';
import { Brand } from '../models/product/brand.model';
import { ProductCategoryModel } from '../models/product/productcategory.model';

import { VendorModel } from '@app/core/models/vendormodels/vendor.model';
import { VendorImageModel } from '@app/core/models/vendormodels/vendorimage.model';

import { RatingOption } from '../models/product/rating-option.model';
import { Review } from '../models/product/review.model';
import { config } from "@app/core/smartadmin.config";
import { ProductInventoryModel } from '../models/productmodels/productinventory.model';
import { FileManagerModel } from '../models/filemanagermodels/filemanager.model';
import { ProductDetailModel } from '@app/core/models/productmodels/productdetail.model';
import { ProductImageModel } from '../models/productmodels/productimage.model';
import { ProductCategoryDetailModel } from '../models/product/productcategorydetail.model';
import { ProductV2Model } from '../models/productmodels/productv2.model';
import { DietModel } from '../models/usermodels/diet.model';
import { ProductAvailabilityComponent } from '@app/features/vendor/product/productdetail/availability/availability.component';
import { ProductAvailabilityModel } from '../models/product/productavailability.model';
import { TimeSlotInventoryDataModel } from '../models/inventory/timeslotinventorydata.model';

@Injectable()
export class ProductService {

  success: any;
  error: any;

  constructor(private http: HttpClient, ) { }

  
  getProductV2(productId: number): Observable<ProductV2Model> {
    return new Observable<ProductV2Model>(subscriber => {
     this.http.get<ProductV2Model>(config.TRIOSAPI + `/api/productv2/get/${productId}`)
      .subscribe( data =>{
        subscriber.next(data);
        },
        errorResponse =>{
          subscriber.error(errorResponse);
        })
    });
  }

  getProduct(productId: number): Observable<ProductModel> {
    return this.http.get<{ data: ProductModel }>(config.TRIOSAPI + `/api/products/get?id=${productId}`)
      .pipe(map(resp => resp.data));
  }

  getProductReviews(productId: string): Observable<Array<Review>> {
    return this.http
      .get<{ data: Array<Review> }>(`api/v1/product/${productId}/reviews`)
      .pipe(map(resp => resp.data));
  }

  getProductRatingSummery(productId: string): Observable<any> {
    return this.http
      .get<any>('api/v1/product/${productId}/rating-summary')
      .pipe(map(resp => resp.data));
  }

  /**
   *
   *
   * @returns {Array<Taxonomy>}
   *
   * @memberof ProductService
   */
  getTaxonomies(): any {
    return this.http.get<Array<Taxonomy>>(`api/v1/taxonomies`);
  }

  /**
   *
   *
   * @returns {Array<Product>}
   *
   * @memberof ProductService
   */
  getProducts(clientId: number, locationId: string, departmentId: string, pageNumber: number): Observable<Array<ProductModel>> {
    return this.http.get<Array<ProductModel>>(config.TRIOSAPI + '/api/client/productlist?clientId=' + clientId + '&locationId=' + locationId +
      '&departmentId=' + departmentId + '&filter=' + "")
      .pipe(
        map((response) => {
          var array: Array<ProductModel> = [];
          response.forEach((product) => {
            array.push(product);
          });
          return array;
        })
      )
  }
  
  getVendorProducts(vendorId: number, locationId: string, departmentId: string, pageNumber: number): Observable<Array<ProductModel>> {
    return this.http.get<Array<ProductModel>>(config.TRIOSAPI + '/api/vendor/productlist?vendorId=' + vendorId + '&locationId=' + locationId +
      '&departmentId=' + departmentId + '&filter=' + "")
      .pipe(
        map((response) => {
          var array: Array<ProductModel> = [];
          response.forEach((product) => {
            array.push(product);
          });
          return array;
        })
      )
  }
  
  markAsFavorite(id: number): Observable<{}> {
    return this.http.post<{}>(`favorite_products`, { id: id });
  }

  removeFromFavorite(id: number): Observable<{}> {
    return this.http.delete<{}>(`favorite_products/${id}`);
  }

  getFavoriteProducts(): Observable<Array<ProductModel>> {
    return this.http
      .get<{ data: Array<ProductModel> }>(
        `favorite_products.json?per_page=20&data_set=small`
      )
      .pipe(map(res => res.data));
  }

  getUserFavoriteProducts(): Observable<Array<ProductModel>> {
    return this.http
      .get<{ data: Array<ProductModel> }>(
        `spree/user_favorite_products.json?data_set=small`
      )
      .pipe(map(res => res.data));
  }

  getProductsByTaxon(id: string): Observable<any> {
    return this.http
      .get<{ data: Array<ProductModel> }>(
        `api/v1/taxons/products?${id}&per_page=20&data_set=small`
      )
      .pipe(map(res => res.data));
  }

  getProductsByTaxonNP(id: string): Observable<Array<ProductModel>> {
    return this.http
      .get<{ data: Array<ProductModel> }>(
        `api/v1/taxons/products?id=${id}&per_page=20&data_set=small`
      )
      .pipe(map(res => res.data));
  }

  getTaxonByName(name: string): Observable<Array<Taxonomy>> {
    return this.http
      .get<{ data: Array<Taxonomy> }>(
        `api/v1/taxonomies?q[name_cont]=${name}&set=nested&per_page=2`
      )
      .pipe(map(res => res.data));
  }

  getproductsByKeyword(keywords: any): Observable<Array<ProductModel>> {
    return this.http
      .get<{ data: Array<ProductModel> }>(`api/v1/products?rows=20&o=1`, {
        params: keywords
      })
      .pipe(map(res => res.data));
  }

  getChildTaxons(
    taxonomyId: string,
    taxonId: string
  ): Observable<Array<Taxonomy>> {
    return this.http
      .get<{ data: Array<Taxonomy> }>(
        `/api/v1/taxonomies/${taxonomyId}/taxons/${taxonId}`
      )
      .pipe(map(res => res.data));
  }

  writeProductReview(params: Object): Observable<Review> {
    return this.http
      .post<{ data: Review }>(`api/v1/reviews`, params)
      .pipe(map(res => res.data));
  }

  getRelatedProducts(productId: any): Observable<Array<ProductModel>> {
    return this.http
      .get<{ data: Array<ProductModel> }>(`api/products/${productId}/relations`)
      .pipe(map(res => res.data));
  }

  getBrands(clientId: number): Observable<Array<Brand>> {
    return this.http
      .get<{ data: Array<Brand> }>(config.TRIOSAPI + '/api/client/brandlist?clientId=' + clientId + '&filter=' + "")
      .pipe(
        map((resp) => {
          var brandArray = new Array<Brand>();
          var brand = new Brand();
          brand.name = "TEST";
          brandArray.push(brand);
          return brandArray;
        }));
  }

  getCategories(clientId: number, locationId: string, filter: string): Observable<Array<ProductCategoryModel>> {
    return this.http
      .get<Array<ProductCategoryModel>>(config.TRIOSAPI + '/api/client/categorylist?clientId=' + clientId + '&locationId=' + locationId + '&filter=' + filter)
      .pipe(
        map((resp) => {
          var modelArray = new Array<ProductCategoryModel>();
          resp.forEach((category) => {
            modelArray.push(category);
          });
          return modelArray;
        })
      );
  }

  getVendors(clientId: number): Observable<Array<VendorModel>> {
    return new Observable<Array<VendorModel>>(subscriber => {
      // console.log("GetAllVendors", clientId)
      this.http.get<Array<VendorModel>>(config.TRIOSAPI + '/api/client/vendorlist?clientId=' + clientId + '&filter=' + "WITHPRODUCTS")
        .pipe(
          map((resp) => {
            //alert(JSON.stringify(resp));
            // console.log("GetAllVendors", clientId)
            var modelArray = new Array<VendorModel>();
            resp.forEach((vendor) => {
              modelArray.push(vendor);
            });
            return modelArray;
          })
        ).subscribe(
          data => {
            // console.log("Got Vendors ", data);
            subscriber.next(data);
          },
          errorResponse => {
            // console.log("Error " + errorResponse);
          }
        )
    });
  }


  getVendorsLocation(clientId: number, locationId: string): Observable<Array<VendorModel>> {
    return this.http
      .get<Array<VendorModel>>(config.TRIOSAPI + '/api/client/vendorlistlocation?clientId=' + clientId + '&locationId=' + locationId + '&filter=' + "WITHPRODUCTS")
      .pipe(
        map((resp) => {
          //alert(JSON.stringify(resp));
          var modelArray = new Array<VendorModel>();
          resp.forEach((vendor) => {
            modelArray.push(vendor);
          });
          return modelArray;
        })
      );
  }

  getFeaturedVendors(clientId: number): Observable<Array<VendorModel>> {
    return new Observable<Array<VendorModel>>(subscriber => {
      // console.log("GetAllVendors", clientId)
      this.http
        .get<Array<VendorModel>>(config.TRIOSAPI + '/api/client/vendorFeaturedlist?clientId=' + clientId + '&filter=' + "WITHPRODUCTS")
        .pipe(
          map((resp) => {
            //alert(JSON.stringify(resp));
            // console.log("GetAllVendors", clientId)
            var modelArray = new Array<VendorModel>();
            resp.forEach((vendor) => {
              modelArray.push(vendor);
            });
            return modelArray;
          })
        ).subscribe(
          data => {
            // console.log("Got Vendors ", data);
            subscriber.next(data);
          },
          errorResponse => {
            // console.log("Error " + errorResponse);
          }
        )
    });
  }

  getFeaturedVendorsLocation(clientId: number, locationId: string): Observable<Array<VendorModel>> {
    return this.http
      .get<Array<VendorModel>>(config.TRIOSAPI + '/api/client/vendorfeaturedlistlocation?clientId=' + clientId + '&locationId=' + locationId + '&filter=' + "WITHPRODUCTS")
      .pipe(
        map((resp) => {
          //alert(JSON.stringify(resp));
          var modelArray = new Array<VendorModel>();
          resp.forEach((vendor) => {
            modelArray.push(vendor);
          });
          return modelArray;
        })
      );
  }

  getProductRatingOptions(
    ratingCategoryId: number
  ): Observable<Array<RatingOption>> {
    return this.http
      .get<{ data: Array<RatingOption> }>(`api/v1/ratings/${ratingCategoryId}`)
      .pipe(map(resp => resp.data));
  }


  public updateProduct(productData: ProductDetailModel) {
    var urlString = config.TRIOSAPI + "/api/products/updateproduct";
    return new Observable<ProductDetailModel>(subscriber => {
      this.http.post<ProductDetailModel>(urlString, productData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateCategory(categoryData: ProductCategoryDetailModel) {
    var urlString = config.TRIOSAPI + "/api/products/updatecategory";
    return new Observable<ProductCategoryDetailModel>(subscriber => {
      this.http.post<ProductCategoryDetailModel>(urlString, categoryData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public deleteCategory(categoryData: ProductCategoryDetailModel) {
    var urlString = config.TRIOSAPI + "/api/products/deletecategory";
    return new Observable<ProductCategoryDetailModel>(subscriber => {
      this.http.post<ProductCategoryDetailModel>(urlString, categoryData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }
  public updateCategoryDiet(categoryId : number, dietData: DietModel) {
    var urlString = config.TRIOSAPI + "/api/products/updatecategorydiet?categoryId=" + categoryId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(urlString, dietData)
        .subscribe(
          data => {
             subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateProductInventory(productData: ProductInventoryModel) {
    var urlString = config.TRIOSAPI + "/api/products/updateproductinventory";
    return new Observable<ProductDetailModel>(subscriber => {

      this.http.post<ProductDetailModel>(urlString, productData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateProductWorkflow(productData: ProductDetailModel) {
    var urlString = config.TRIOSAPI + "/api/products/updateproductworkflow";
    return new Observable<ProductDetailModel>(subscriber => {

      this.http.post<ProductDetailModel>(urlString, productData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateProductPrinters(productData: ProductDetailModel) {
    var urlString = config.TRIOSAPI + "/api/products/updateproductprinters";
    return new Observable<ProductDetailModel>(subscriber => {
      this.http.post<ProductDetailModel>(urlString, productData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  
  public updateProductTags(productData: ProductDetailModel) {
    var urlString = config.TRIOSAPI + "/api/products/updateproducttags";
    return new Observable<ProductDetailModel>(subscriber => {
      this.http.post<ProductDetailModel>(urlString, productData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateProductFeatured(productId: number, featured: boolean) {
    var urlString = config.TRIOSAPI + "/api/products/updateproductfeatured?productId=" + productId + '&featured=' + featured;
    return new Observable<ProductDetailModel>(subscriber => {

      this.http.post<ProductDetailModel>(urlString, null)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateProductAvailability(productAvailabilityData: ProductAvailabilityModel){
    var urlString = config.TRIOSAPI + "/api/products/updateproductavailability";
    return new Observable<any>(subscriber => {
      this.http.post<any>(urlString, productAvailabilityData)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  
  public updateProductTickets(productData: ProductDetailModel) {
    var urlString = config.TRIOSAPI + "/api/products/updateproductticket";
    return new Observable<ProductDetailModel>(subscriber => {
      this.http.post<ProductDetailModel>(urlString, productData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  
  public updateProductPricing(pricingData: ProductDetailModel) {
    var urlString = config.TRIOSAPI + "/api/products/updateproductpricing";
    return new Observable<ProductDetailModel>(subscriber => {

      this.http.post<ProductDetailModel>(urlString, pricingData)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  postProductIconFile(productId: number, fileToUpload: File, fileType: String, sizeX: number, sizeY: number) {
    return new Observable<any>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('productId', productId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<FileManagerModel>(config.TRIOSAPI + "/api/products/uploadProductIconImage?sizeX=" + sizeX + "&sizeY=" + sizeY, formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  postCategoryIconFile(categoryId: number, fileToUpload: File, fileType: String, sizeX: number, sizeY: number) {
    return new Observable<any>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('categoryId', categoryId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<FileManagerModel>(config.TRIOSAPI + "/api/products/uploadCategoryIconImage?sizeX=" + sizeX + "&sizeY=" + sizeY, formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  postFeaturedHeaderImageFile(vendorId: number, fileToUpload: File, fileType: String){
    return new Observable<ProductDetailModel>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('productId', vendorId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<ProductDetailModel>(config.TRIOSAPI + "/api/products/uploadFeaturedImage", formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  postCardImageFile(vendorId: number, fileToUpload: File, fileType: String){
    return new Observable<ProductDetailModel>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('productId', vendorId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<ProductDetailModel>(config.TRIOSAPI + "/api/products/uploadCardImage", formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  

}


import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { StartSplashScreenComponent } from './startsplashscreen.component';



@NgModule({
  declarations: [
    StartSplashScreenComponent
  ],
  exports: [
    StartSplashScreenComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [],
})
export class StartSplashScreenModule {

}

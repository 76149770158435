export class  UserContactDetailModel {   
    public associationId:string;
    public telephone1:string;
    public telephone1Emergency:string;
    public telephone1SMS:boolean;
    public telephone1Verified:boolean;
    public telephone2:string;
    public telephone2Emergency:boolean;
    public telephone2SMS:boolean;
    public telephone2Verified:boolean;
   public custodian:string;
}
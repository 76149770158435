

import { Map, Record, List } from 'immutable';

import { UserModel } from '@app/core/models/usermodels/user.model';
import { UserPurchasesModel } from '@app/core/models/usermodels/userpurchases.model';
import { VendorModel } from '@app/core/models/vendormodels/vendor.model';

export interface UserState extends Map<string, any> {
  userData: UserModel;
  purchasesData: UserPurchasesModel;
  linkedAccounts: List<UserModel>;
  vendors: List<VendorModel>;
}

export const UserStateRecord = Record({
  userData: null,
  purchasesData: null,
  linkedAccounts: List([]),
});
import { StorageService } from "./storage.service";
import { AuthTokenFactory, AuthTokenService } from "./auth-token.service";
import { AuthService } from "./auth.service";
import { JsonApiService } from "./json-api.service";
import { UserService } from './user.service';
import { AccountService } from "./account.service";
import { UserStatsService } from "./userstats.service";
import { ChatService } from "./chat.service";
import { NotificationService } from "./notification.service";
import { BodyService } from "./body.service";
import { LayoutService } from "./layout.service";
import { SoundService } from "./sound.service";
import { DataService } from "./data.service";
import { FileManagerService } from "./filemanager.service";

import { AdminService } from "./admin.service";
import { EconomyService } from "./economy.service";
import { ClientService } from "./client.service";
import { VendorService } from "./vendor.service";
import { PwaService } from './pwa.service';
import { LocalService } from './local.service';
import { CheckoutService } from './checkout.service';
import { AppStoreService } from './appstore.service';
import { ProductService } from './product.service';
import { CartService } from './cart.service';
import { VoucherService } from "./voucher.service";
import { SpinnerService } from './spinner.service';
import { PosService } from './pos.service';
import { MessagingService } from './messaging.service';
import { PreorderService } from './preorder.service';


import { MapStyleService } from "./map-style.service";
import { GoogleAPIService } from "./google-api.service";
import { GeolocationService } from "./geolocation.service";
import { GeocodingService } from "./geocoding.service";
import { GoogleAnalyticsService } from "./googleanalytics.service";


import { ConferenceService } from "./conference.service";
import { RulesService } from "./rules.service";

import { OpentokService } from './opentok.service';
import { ResizeService } from './resize.service';
import { Covid19Service } from './covid19.service';
import { ReportService } from './report.service';

import * as fromVoice from './voice'

import { DayDateService } from './daydate.service';
import { TaskService } from "./task.service";

export const services = [
  StorageService,
  AuthTokenService,
  AuthService,
  PwaService,

  EconomyService,
  ClientService,
  VendorService,
  VoucherService,
  CheckoutService,
  AppStoreService,
  ProductService,
  CartService,
  SpinnerService,

  JsonApiService,

  UserService,
  
  AccountService,
  AdminService,
  UserStatsService,
  LocalService,
  ChatService,
  NotificationService,
  BodyService,
  LayoutService,
  SoundService,
  fromVoice.VoiceControlService,
  fromVoice.VoiceRecognitionService,
  DataService,  
  FileManagerService,

  PosService,
  OpentokService,
  MessagingService,

  MapStyleService,
  GoogleAPIService,
  GeolocationService,  
  GeocodingService,
  GoogleAnalyticsService,

  ConferenceService,
  RulesService,
  ResizeService,
  Covid19Service,
  ReportService,

  DayDateService,
  PreorderService,
  TaskService,
];

export * from "./spinner.service";
export * from "./economy.service";
export * from "./client.service";
export * from "./appstore.service";
export * from "./product.service";
export * from "./cart.service";
export * from "./checkout.service";
export * from "./storage.service";
export * from "./auth-token.service";
export * from "./auth.service";
export * from "./pwa.service";
export * from "./json-api.service";
export * from "./admin.service";
export * from "./vendor.service";
export * from "./voucher.service";
export * from "./local.service";
export * from "./user.service";
export * from "./account.service";
export * from "./userstats.service";
export * from "./chat.service";
export * from "./notification.service";
export * from "./body.service";
export * from "./layout.service";
export * from "./sound.service";
export * from "./voice";
export * from "./data.service";
export * from "./filemanager.service";

export * from "./pos.service";
export * from "./messaging.service";

export * from "./opentok.service";

export * from "./map-style.service";
export * from "./google-api.service";
export * from "./geolocation.service";
export * from "./geocoding.service";

export * from "./conference.service";
export * from "./rules.service";
export * from "./resize.service";
export * from "./covid19.service";
export * from "./report.service";

export * from './daydate.service';
export * from './preorder.service';
export * from './task.service';


import { OrderResponseModel } from '../../models/cart/order-response.model';
import { PaymentInfoModel } from '@app/core/models/banking/paymentinfo.model';
import { PreorderPlaceOrderModel } from '@app/core/models/preorder/preorderplaceorder-model';

export class PreorderCartActions {
  static GET_CART = 'PO_GET_CART';
  static GET_CART_SUCCESS = 'PO_GET_CART_SUCCESS';
  static ADD_ITEM_TO_CART = 'PO_ADD_ITEM_TO_CART';
  static ADD_ITEM_TO_CART_SUCCESS = 'PO_ADD_ITEM_TO_CART_SUCCESS';
  static ADD_ITEM_TO_CART_FAIL = 'PO_ADD_ITEM_TO_CART_FAIL';
  static REMOVE_ITEM_FROM_CART = 'PO_REMOVE_ITEM_FROM_CART';
  static REMOVE_ITEM_FROM_CART_SUCCESS = 'PO_REMOVE_ITEM_FROM_CART_SUCCESS';
  static PLACE_ORDER = 'PO_PLACE_ORDER';
  static PLACE_ORDER_SUCCESS = 'PO_PLACE_ORDER_SUCCESS';
  static PLACE_ORDER_FAIL = 'PO_PLACE_ORDER_FAIL';
  static PLACE_CARD_ORDER = 'PO_PLACE_CARD_ORDER';
  static PLACE_CARD_ORDER_SUCCESS = 'PO_PLACE_CARD_ORDER_SUCCESS';
  static PLACE_CARD_ORDER_FAIL = 'PO_PLACE_CARD_ORDER_FAIL';
  static EMPTY_CART = 'PO_EMPTY_CART';
  static EMPTY_CART_SUCCESS = 'PO_EMPTY_CART_SUCCESS';

  getCart(userId: string) {
    return {
      type: PreorderCartActions.GET_CART,
      payload: userId
    };
  }

  getCartSuccess(order: any) {
    return {
      type: PreorderCartActions.GET_CART_SUCCESS,
      payload: order
    };
  }

  addItemToCart(action: any) {
    return {
      type: PreorderCartActions.ADD_ITEM_TO_CART,
      payload: action
    };
  }

  addItemToCartSuccess(item: any) {
    return {
      type: PreorderCartActions.ADD_ITEM_TO_CART_SUCCESS,
      payload: item
    };
  }

  addItemToCartFail(err: any) {
    // console.log(err);
    return {
      type: PreorderCartActions.ADD_ITEM_TO_CART_FAIL,
      payload: err
    };
  }


  removeItemFromCart(action: any) {
    return {
      type: PreorderCartActions.REMOVE_ITEM_FROM_CART,
      payload: action
    };
  }

  removeItemFromCartSuccess(action: any) {
    return {
      type: PreorderCartActions.REMOVE_ITEM_FROM_CART_SUCCESS,
      payload: action
    };
  }

  placeOrder(paymentInfo: PreorderPlaceOrderModel) {
    return {
      type: PreorderCartActions.PLACE_ORDER,
      payload: paymentInfo
    };
  }

  placeCardOrder() {
    return {
      type: PreorderCartActions.PLACE_CARD_ORDER,
    };
  }
  
  placeCardOrderSuccess(orderResponse:OrderResponseModel) {
    return {
      type: PreorderCartActions.PLACE_CARD_ORDER_SUCCESS,
      payload: orderResponse
    };
  }

  placeCardOrderFail(err:any) {
    return {
      type: PreorderCartActions.PLACE_CARD_ORDER_FAIL,
      payload: err
    };
  }


  placeOrderSuccess(orderResponse:OrderResponseModel) {
    return {
      type: PreorderCartActions.PLACE_ORDER_SUCCESS,
      payload: orderResponse
    };
  }

  emptyCart(forUserId: string) {
    return {
      type: PreorderCartActions.EMPTY_CART,
      payload: forUserId
    };
  }

  emptyCartSuccess(action: any) {
    return {
      type: PreorderCartActions.EMPTY_CART_SUCCESS,
      payload: action
    };
  }

}

import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

import { config } from '../../core/smartadmin.config';

import { UserModel } from '../../core/models/usermodels/user.model';
import { UserPurchasesModel } from '../../core/models/usermodels/userpurchases.model';


@Injectable()
export class UserStatsService implements OnInit, OnDestroy {
 
  private userData: UserModel = null;

  private hasPurchasesThisWeek = false;
  public purchasesThisWeek:UserPurchasesModel;
  private purchasesThisWeekSubject = new BehaviorSubject<UserPurchasesModel>(null);

  constructor(private http: HttpClient,
              private router: Router) {
    
  }

  ngOnInit(){

  }

  ngOnDestroy() {
    this.hasPurchasesThisWeek = false;
  }

  public init(userData:UserModel){
    this.userData = userData;
    if (!this.hasPurchasesThisWeek) {
      this.fetchPurchasesThisWeek();
    }
  }

  public deInit(){
    this.hasPurchasesThisWeek = false;
    this.purchasesThisWeek = null;
    this.purchasesThisWeekSubject.observers.forEach(obs=>{
      obs.complete();
    });
  }

  public getPurchasesThisWeek(): Observable<UserPurchasesModel> {
    if (!this.hasPurchasesThisWeek) {
      this.fetchPurchasesThisWeek();
    }
    return this.purchasesThisWeekSubject.asObservable();
  }


  public fetchPurchasesThisWeek(): void {
    this.hasPurchasesThisWeek = true;
    var urlString = "/api/UserStats/LoadWeeklyPurchases";
    this.http.get<UserPurchasesModel>(config.TRIOSAPI + urlString)
      .pipe(
        map(response => {
          return response;
        })
      )
      .subscribe(
        data => {
          if (data == null)
             return;
             
          this.purchasesThisWeek = data;
          this.purchasesThisWeekSubject.next(data);
        },
        errorResponse => {
          this.hasPurchasesThisWeek = false;
          this.purchasesThisWeekSubject.error(errorResponse);
        }
      );
  }

}

import { ClientService } from '@app/core/services/client.service';
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from '@ngrx/store';

import * as fromAuth from '@app/core/store/auth';

import { NotificationService } from "@app/core/services/notification.service";
import { AuthService } from "@app/core/services/auth.service";
import { UserService } from "@app/core/services/user.service";
import { UserModel } from '../../../core/models/usermodels/user.model';

@Component({
  selector: "sa-logout",
  template: `
<div id="logout" (click)="showPopup()" class="btn-header transparent pull-right">
        <span> <a title="Sign Out"><i class="fa fa-sign-out"></i></a> </span>
    </div>
  `,
  styles: []
})
export class LogoutComponent implements OnInit {

  private userData:UserModel;
  content = "You can improve your security further after logging out by closing this opened browser";

  constructor(
    private userService: UserService,
    private clientService: ClientService,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    private store: Store<any>) {
      if(clientService.isADAuth())
      this.content = "<div class='row'><div class='col-xs-8'>Logging out at this point will log you out of your Active Directory OSS!</div></div>"
    }

  showPopup() {
    this.notificationService.smartMessageBox(
      {
        title:
          "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'><strong>" + this.userData.userName+"</strong></span> ?",
        content: this.content,
        buttons: "[No][Yes]"
      },
      ButtonPressed => {
        if (ButtonPressed == "Yes") {
          this.logout();
        }
      }
    );
  }

  logout() {
    //this.authService.logout();
    this.userService.logout();
  }

  ngOnInit() {
    this.userService.getUser().subscribe( 
      (data:UserModel)=>{
        this.userData = data;
      });

  }
}

import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser'; import { DOCUMENT } from "@angular/common";
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { SpinnerService } from '@app/core/services/spinner.service';
import * as clientStore from '@app/core/store/client'


@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.css'],
})
export class AuthLayoutComponent implements OnInit, OnDestroy, AfterViewInit {


  private subscriptions: Subscription[] = [];
  public finished1: boolean = true;
  public finished2: boolean;

  constructor(@Inject(DOCUMENT) private document,
    private titleService: Title,
    public spinnerService: SpinnerService,
    private store: Store<any>) {
  }

  ngOnInit() {
    let that = this;
    this.subscriptions.push(this.store.pipe(
      select(clientStore.getClient)
    ).subscribe(
        (data: ClientModel) => {
          if ((data == null) || (data == undefined))
            return;
          that.titleService.setTitle(data.clientName);
        }));
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  ngAfterViewInit(){
    this.finished1 = true;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldFilterPipe } from './field-filter.pipe';
import { MomentPipe } from './moment.pipe';
import { ReplaceLineBreaks } from './momentbr.pipe';
import { SystemCurrencyPipe } from './system-currency.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FieldFilterPipe,
    ReplaceLineBreaks,
    MomentPipe,
    SystemCurrencyPipe,
    SafePipe,
  ],
  exports: [
    FieldFilterPipe, 
    ReplaceLineBreaks,
    MomentPipe,
    SystemCurrencyPipe,
    SafePipe,
  ]
})
export class PipesModule { }

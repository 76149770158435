
import { UserGroupModel } from '@app/core/models/clientmodels/usergroup.model';
import { AddressModel } from '../addressmodels/address.model';
import { NotificationModel } from '@app/core/models/accountmodels/notification.model';
import { AccountModel } from './account.model';
import { PreferencesModel } from'./preferences.model';
import { UserDetailModel } from './userdetail.model';
import { UserContactDetailModel } from './usercontactdetail.model';
import { PermissionsModel } from './permissions.model';
import { ClientPermissionsModel } from '../clientmodels/client-permissions.model';
import { RoleModel } from '../accountmodels/role.model';
import { Security } from './user-security.model';
import { BeneficiaryModel } from '@app/core/models/accountmodels/beneficiary.model';

export class  UserModel {   
    userId: string;
    active: boolean;
    me:boolean;
    lockedOut: boolean;
    claimed: boolean;
    clientId: number;
    clientName: string;
    userName: string;
    userPhoto: string;
    fullName: string;
    email: string;
    emailVerified: boolean;
    phoneNumber: string;
    phoneVerified: boolean;
    createDate: any;
    lastActivityDate;
    userAccountNumber: number;
    userCardNumber: string;
    linkedAccounts: number;
    linkedAccountsData: Array<UserModel>; 
    parentAccounts: number;
    linkedAssociations: number;
    registrationState: Array<string>;
    timeZone: string;
    currencySymbol: string;
    countryCode: string;
    childAccounts: number;
    accountType: string;
    account: AccountModel;
    preferences: PreferencesModel;
    userDetail: UserDetailModel;  
    userContactDetail: UserContactDetailModel;
    accountPermissions: PermissionsModel;
    clientPermissions: ClientPermissionsModel;
    beneficiaries: number;
    beneficiaryData: Array<BeneficiaryModel>;
    isCoach: boolean;
    isStudentVenture: boolean;     
    hasStudentVenture: boolean;     
    studentVentureId: Array<string>;
    

    roles: Array<RoleModel>;
    address: AddressModel;
    security: Security;
    groups: Array<UserGroupModel>;
    coach: UserModel;
    
    constructor(){
        this.account = new AccountModel();
        this.preferences = new PreferencesModel();
        this.userDetail = new UserDetailModel();
        this.userContactDetail = new UserContactDetailModel();
        this.accountPermissions = new PermissionsModel();
        this.address = new AddressModel();
        this.security = new Security();
    }
}
export class PosActions {
  static GET_WAITRON = 'GET_WAITRON';
  static GET_WAITRON_SUCCESS = 'GET_WAITRON_SUCCESS';

  getWaitron(cardId: string) {
    return {
      type: PosActions.GET_WAITRON,
      payload: cardId
    };
  }

  getWaitronSuccess(waitron: any) {
    return {
      type: PosActions.GET_WAITRON_SUCCESS,
      payload: waitron
    };
  }


}

import { OrderResponseModel } from './../../models/cart/order-response.model';
import { CartOrderModel } from '@app/core/models/cart/cart-order.model';
import { AppState } from '../index';
import { CartState } from './cart.state';
import { createSelector } from 'reselect';

// Base product state selector function
const getCartState = (state: AppState): CartState => state.cart;

const fetchCartOrder = (state: CartState) => (state.selectedOrder as unknown) as CartOrderModel;
const fetchOrderState = (state: CartState) => (state.orderState as unknown) as OrderResponseModel;
const fetchCartError = (state: CartState) => (state.error as unknown) as string;

// *************************** PUBLIC API's ****************************
export const getCartOrder = createSelector(
  getCartState,
  fetchCartOrder
);

export const getOrderState = createSelector(
  getCartState,
  fetchOrderState
);


export const addToCartErrorState = createSelector(
  getCartState,
  fetchCartError
);

import { Injectable, NgZone, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { CookieService } from 'ngx-cookie-service';

import { Observable, of } from 'rxjs';
//import { Cookie } from 'ng2-cookies';
import { delay, tap } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';
import { ToastrService } from 'ngx-toastr';


import { AuthState } from './../store/auth/auth.reducer';
import { AddExternalLoginBindingModel } from './../models/accountmodels/externallogin.model';
import { AuthInit, LoginAction, LogoutAction } from './../store/auth/auth.actions';


import { config } from "@app/core/smartadmin.config";
//import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

//Models
import { ForgotPasswordModel } from '../../core/models/forgotpassword.model';
import { ResetPasswordModel } from '../../core/models/resetpassword.model';
import { RegistrationModel } from '../../core/models/registration.model';

@Injectable()
export class AuthService implements OnInit {

  isLoggedIn: boolean = false;
  authState: any = null;
  public loginState = new Subject<boolean>();
  authData = null;
  authStrData = null;
  roleData: Array<string>;
  // store the URL so we can redirect after logging in
  redirectUrl: string;
  adToken: any = null;
  public authNotification = new Subject<string>();
  private storeRouterLink = "/store";
  constructor(
    // public adalService: MsAdalAngular6Service,
    public afAuth: AngularFireAuth,
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    public ngZone: NgZone,
    private cookieService: CookieService,
    private store: Store<any>) {

    this.authState = 0;
    this.roleData = new Array<string>();
    if (this.cookieService.get('currentToken')) {
      //if (localStorage.getItem('currentToken')) {
      //var test = JSON.parse(localStorage.getItem('currentToken'));
      var test = JSON.parse(this.cookieService.get('currentToken'));
      this.authState = 1;
      this.authData = test;
      this.isLoggedIn = true;
      try {

        //if (localStorage.getItem('currentRoles')) {
        if (this.cookieService.get('currentRoles')) {
          //this.roleData = JSON.parse(localStorage.getItem('currentRoles'));
          this.roleData = JSON.parse(this.cookieService.get('currentRoles'));
        }
      }
      catch (ex) { }
    }
    let that = this;
    // const token = this.adalService.acquireToken('https://graph.microsoft.com').subscribe((token1: string) => {
    //   that.adToken = token1;
    // });
  }


  ngOnInit() {
    this.store.dispatch(new AuthInit());
  }

  getToken() {
    if ((!this.authData) || !this.authState)
      return '';
    return this.authData.access_token;
  }

  getStrToken() {
    return this.authStrData;
  }

  isAuthenticated(): boolean {
    return this.authState == 1;
  }

  logout(): void {
    // console.log("Auth logout");
    this.store.dispatch(new LogoutAction());
    this.isLoggedIn = false;
    this.cookieService.deleteAll();
    // localStorage.removeItem('currentToken');
    // localStorage.removeItem('currentRoles');
    localStorage.clear();
    this.authState = 0;
    this.adToken = null;
    // this.authData.access_token = null;
    // console.log("Auth logout 1");
    this.authData = null;
    // console.log("Auth logout 2");
    this.loginState.next(false);
    // console.log("Auth logout 3");
    this.router.navigate(["/auth/login"]);
    // console.log("Auth logout 4");

    this.store.dispatch(new LoginAction(false));
    // console.log("Auth logout 5");
    this.store.dispatch(new AuthInit());
    // console.log("Auth logout 6");
  }

  login(clientId: number, userName: string, password: string, rememberMe: boolean) {
    let that = this;
    return new Observable(subscriber => {
      //const body = `grant_type=${"password"}&username=${userName}&password=${password}&clientId=${clientId}&client_secret=secret`;
      const body = `grant_type=${"password"}&username=${userName}&password=${encodeURIComponent(password)}&clientId=${clientId}`;
      const headers = new HttpHeaders(
        {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
          'Authorization': "Basic " + btoa(clientId + ':' + "AllxsClient")
        });
      that.http.post<any>(config.TRIOSAPI + '/token', body, { headers: headers })
        .map(response => {
          
          // login successful if there's a jwt token in the response
          if (response.userName && response.access_token) {
            that.loginState.next(true);
            //localStorage.setItem('currentToken', JSON.stringify(response));
            //localStorage.setItem('currentRoles', response.roles);
          }
          return response;
        }).subscribe(
          data => {
            // console.log(data);
            that.authData = data;
            that.authStrData = JSON.stringify(data);
            that.roleData = JSON.parse(that.authData.roles);
            that.authState = 1;
            that.isLoggedIn = true;


            if (rememberMe == true) {
              if (JSON.stringify(data.roles).toLowerCase().includes('admin') == false) {
                this.cookieService.set('currentToken', JSON.stringify(data));
              }
            }

            if (data.userName == 'testuser@vasoft.ca') {
              this.cookieService.set('currentToken', JSON.stringify(data));
            }

            this.cookieService.set('currentRoles', data.roles);
            subscriber.next(data);
          },
          errorResponse => {
            // console.log(JSON.stringify(errorResponse));
            that.isLoggedIn = false;
            that.loginState.next(false);
            subscriber.error(errorResponse);
          }
        );
    });
  }


  doGoogleLogin(clientId: number) {
    const that = this;
    return new Observable(subscriber => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          this.ngZone.run(() => {
            const result = res as any;
            const user = JSON.parse(JSON.stringify(result.user));
            //let userData = {user.}
            // console.log(result);
            var url = config.TRIOSAPI + '/api/account/ObtainLocalAccessToken?clientId=' + clientId + '&provider=google&externalaccesstoken=' + result.credential.accessToken;
            this.http.post<any>(url, user)
              .map(response => {
                return response;
              })
              .subscribe(
                data => {
                  if (data == null) {
                    return;
                  }

                  // console.log("Hello Google Login", data);
                  this.cookieService.set('currentToken', JSON.stringify(data));
                  this.cookieService.set('currentRoles', JSON.stringify(data.roles));
                  //localStorage.setItem('currentToken', JSON.stringify(data));
                  //localStorage.setItem('currentRoles', JSON.stringify(data.roles));
                  that.authData = data;
                  that.authStrData = JSON.stringify(data);
                  that.roleData = that.authData.roles;
                  that.authState = 1;
                  that.isLoggedIn = true;

                  this.cookieService.set('currentRoles', data.roles);
                  that.router.navigate(["/dashboard/analytics"]);
                  subscriber.next(data);
                },
                errorResponse => {
                  subscriber.error({
                    error: errorResponse,
                    info: result.additionalUserInfo
                  });
                }
              );
          });
        },
        err => {
          // console.log(err);
          subscriber.error(err);
        }
      );
    });
  }

  doFacebookLogin(clientId: number) {
    const that = this;
    return new Observable(subscriber => {

      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          const result = res as any;
          // console.log(result);
          this.http.get<any>(config.TRIOSAPI + '/api/account/ObtainLocalAccessToken?clientId=' + clientId + '&provider=google&externalaccesstoken=' + result.credential.accessToken)
            .map(response => {
              return response;
            })
            .subscribe(
              data => {
                // console.log(data);
                this.cookieService.set('currentToken', JSON.stringify(data));
                this.cookieService.set('currentRoles', JSON.stringify(data.roles));
                //localStorage.setItem('currentToken', JSON.stringify(data));
                //localStorage.setItem('currentRoles', JSON.stringify(data.roles));
                that.authData = data;
                that.roleData = that.authData.roles;
                that.authState = 1;
                that.isLoggedIn = true;
                that.router.navigate(["/dashboard/analytics"]);
                subscriber.next(data);
              },
              errorResponse => {
                subscriber.error({
                  error: errorResponse,
                  info: result.additionalUserInfo
                });
              }
            );
        },
        err => {
          // console.log(err);
          subscriber.error(err);
        }
      );
    });
  }

  //   doFacebookLogin(){
  //     return new Promise<any>((resolve, reject) => {
  //       let provider = new firebase.auth.FacebookAuthProvider();
  //       this.afAuth.signInWithPopup(provider)
  //       .then(res => {
  //         resolve(res);
  //       }, err => {
  //         console.log(err);
  //         reject(err);
  //       })
  //     })
  //  }

  forgotPassword(data: ForgotPasswordModel) {
    return new Observable(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/Account/ForgotPassword', data)
        .map(response => {
          return response;
        })
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  checkADToken(clientId: number) {
    // if (this.adToken !== null)
    this.doADLogin(clientId)
    // else
    //  this.adalService.login();
  }

  doADLogin(clientId: number) {
    const that = this;
    that.http.get<any>(config.TRIOSAPI + '/api/account/ObtainLocalAccessToken?clientId=' + clientId + '&provider=microsoft&externalaccesstoken=MS14231222334')
      .map(response => {
        return response;
      })
      .subscribe(
        data => {
          console.log(data);
          this.cookieService.set('currentToken', JSON.stringify(data));
          this.cookieService.set('currentRoles', JSON.stringify(data.roles));
          //localStorage.setItem('currentToken', JSON.stringify(data));
          //localStorage.setItem('currentRoles', JSON.stringify(data.roles));
          that.authData = data;
          that.roleData = that.authData.roles;
          that.authState = 1;
          that.isLoggedIn = true;
          that.router.navigate(["/dashboard/analytics"]);
        },
        errorResponse => {
          if (errorResponse === 'External user is not registered') {
            that.authNotification.next("This is the first time this account is using the Allxs System.<br><br>We are busy building your account please wait.");
            this.http.get<any>(config.TRIOSAPI + '/api/account/RegisterAccountForExternalToken?clientId=' + clientId + '&provider=microsoft&externalaccesstoken=MS14231222334')
              .map(response => {
                return response;
              })
              .subscribe(
                data => {
                  // console.log(data);
                  that.authNotification.next("Done");
                  this.cookieService.set('currentToken', JSON.stringify(data));
                  this.cookieService.set('currentRoles', JSON.stringify(data.roles));
                  //localStorage.setItem('currentToken', JSON.stringify(data));
                  //localStorage.setItem('currentRoles', JSON.stringify(data.roles));
                  that.authData = data;
                  that.roleData = that.authData.roles;
                  that.authState = 1;
                  that.isLoggedIn = true;
                  that.router.navigate(["/dashboard/analytics"]);
                },
                errorResponse => {
                }
              );

          }
          else {
            that.adToken = null;
            that.checkADToken(clientId);
          }
        });
  }



  doInternalLogin(clientId: number, userId: string) {
    const that = this;
    return new Observable<any>(subscriber => {
      that.http.post<any>(config.TRIOSAPI + '/api/account/ObtainLocalAccessToken?clientId=' + clientId + '&provider=internal&externalaccesstoken=' + userId, "")
        .map(response => {
          return response;
        })
        .subscribe(
          data => {
            //console.log(data);
            that.authNotification.next("Done");
            this.cookieService.set('currentToken', JSON.stringify(data));
            this.cookieService.set('currentRoles', JSON.stringify(data.roles));
            //localStorage.setItem('currentToken', JSON.stringify(data));
            //localStorage.setItem('currentRoles', JSON.stringify(data.roles));
            that.authData = data;
            that.roleData = that.authData.roles;
            that.authState = 1;
            that.isLoggedIn = true;
            //that.router.navigate(["/dashboard/analytics"]);
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          });
    });

  }

  resetPassword(data: ResetPasswordModel) {
    return new Observable(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/Account/ResetPassword', data)
        .map(response => {
          return response;
        })
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          });
    });
  }

  register(registerData: RegistrationModel) {
    return new Observable(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/Account/Register', registerData)
        .map(response => { return response })
        .subscribe(
          data => {
            this.authData = data;
            this.authState = 1;
            subscriber.next(data);
          },
          errorResponse => {
            this.loginState.next(false);
            subscriber.error(errorResponse);
          });
    });
  }

  verifyEmail(token: String) {
    return new Observable(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/Account/VerifyEmail?token=' + token, "")
        .map(response => { return response })
        .subscribe(
          data => {
            this.authData = data;
            this.authState = 1;
            subscriber.next(data);
          },
          errorResponse => {
            this.loginState.next(false);
            subscriber.error(errorResponse);
          });
    });
  }

  // verifyEmail(userId, token:String) {
  //   return new Observable(subscriber => {
  //     this.http.post<any>(config.TRIOSAPI +'/api/Account/VerifyEmail?userName=' + userId + '&token=' + token, "")
  //       .map( response => { return response} )
  //       .subscribe(
  //         data => {
  //           this.authData = data;
  //           this.authState = 1;
  //           subscriber.next(data);
  //         },
  //         errorResponse => {
  //           this.loginState.next(false);
  //           subscriber.error(errorResponse);
  //         });
  //   });
  // }


  isUserInRole(verifyRole) {
    //console.log('Role Data ' + this.roleData, verifyRole, this.roleData.includes(verifyRole));
    if (this.roleData.includes(verifyRole))
      return true;
    return false;
  }

  testMicrosoft() {
    this.authState = 1;
    this.http.get<any>(config.TRIOSAPI + '/api/test/test?requestString=121212')
      .map(response => { return response })
      .subscribe(
        data => {
          this.authData = data;
          this.authState = 1;
        },
        errorResponse => {
          this.loginState.next(false);
        });
  }

}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as clientStore from '@app/core/store/client'
import * as userStore from '@app/core/store/user'

import { RulesService } from '@app/core/services/rules.service';
import { UserStatsService } from '@app/core/services/userstats.service';

import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { UserModel } from '@app/core/models/usermodels/user.model';
import { UserPurchasesModel } from '@app/core/models/usermodels/userpurchases.model';
import { UserActions } from '@app/core/store/user/user.actions';


@Component({
  selector: 'account-stats',
  templateUrl: './account-stats.component.html',
  styleUrls:['./account-stats.component.css']
})
export class AccountStatsComponent implements OnInit {

  clientData: ClientModel;
  userData: UserModel;
  axCoin: boolean = false;
  purchasesTotal:number = 0;
  balanceTotal = 0;
  axBalanceTotal = 0;
  weekData: any[]=[0,0,0,0,0,0,0];
  statsSubscription: Subscription;
  public pointsAbbr: string = "AX";

  constructor(private store: Store<any>,
              private actions: UserActions,
              private rulesService: RulesService,
              private userStatsService: UserStatsService){    
  }

  ngOnInit() {
    let that = this;
    
    this.rulesService.onRulesChange().subscribe(
      data => {
        if (data == null)
           return;
        
        this.axCoin = data.axCoinEnabled;
      });


    this.store.pipe(
      select(clientStore.getClient)
    ).subscribe( data=>{
        if (data == null)
           return;
        that.clientData = data;
        var abbr = that.clientData.economy.economyRules.allxsPointsAbbr;
        if (abbr != null){
          that.pointsAbbr = abbr;
        }
      });
    

    this.store.pipe(
      select(userStore.getUser)
    ).subscribe((data: UserModel) => {
        if ((data == null) || (data == undefined))
          return;
        this.userData = data;
        this.purchasesTotal = 0;
        this.balanceTotal = this.userData.account.balance;        
        this.axBalanceTotal = this.userData.account.axBalance;        
        this.store.dispatch(this.actions.getUserPurchases());
      });
      
      this.store.pipe(
        select(userStore.getUserPurchases)
        ).subscribe(
        (data: UserPurchasesModel) => {
          if ((data === null) || (data === undefined))
           return;
           this.weekData = [];
           data.purchases.forEach((pData)=>{
             this.purchasesTotal += Math.abs(pData.amount);
             this.weekData.push(pData.count);
           })    
           $('#purchases').attr('values', this.weekData);
      });
  }

}

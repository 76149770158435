import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';




import * as fromAuth from '@app/core/store/auth';

import { ClientService } from '@app/core/services/client.service';
import { NotificationService } from "@app/core/services/notification.service";
import { AuthService } from "@app/core/services/auth.service";
import { UserService } from "@app/core/services/user.service";
import { UserModel } from '../../../core/models/usermodels/user.model';


@Component({
  selector: "sa-switchtomain-button",
  templateUrl: './switchtomainbutton.component.html',
  styles: []
})
export class SwitchToMainButtonComponent implements OnInit {

  private userData: UserModel;
  public subAccount = false;
  public parentAccount = false;

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private clientService: ClientService,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    private store: Store<any>) {
  }


  ngOnInit() {
    this.userService.getUser().subscribe(
      (data: UserModel) => {
        if (data != null) {
          this.userData = data;          
        }
      });
      
      this.userService.switchedAccount.subscribe( data=>{
        this.subAccount = false;    
        this.parentAccount = false;
        
        var sCookie = this.cookieService.get('addAccountToken');
        if (sCookie != null){
          if (sCookie.length > 0) {
            this.subAccount = true;    
          }
        }

        var pCookie = this.cookieService.get('parentAccountToken');
        if (pCookie != null){
          if (pCookie.length > 0) {
            this.parentAccount = true;    
          }
        }
      })
  }

  switchToMain(){
    this.userService.switchToMainAccount();
  }

  switchToParent(){
    this.userService.switchToParentAccount();
  }

}



import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { HeaderModule } from './header/header.module';
import { FooterComponent } from './footer/footer.component';
import { NavigationModule } from './navigation/navigation.module';
import { RibbonComponent } from './ribbon/ribbon.component';
import { ShortcutComponent } from './shortcut/shortcut.component';
import { ToggleActiveDirective } from '../utils/toggle-active.directive';
import { LayoutSwitcherComponent } from './layout-switcher.component';
import { MainLayoutComponent } from './app-layouts/main-layout.component';
import { EmptyLayoutComponent } from './app-layouts/empty-layout.component';
import { PosLayoutComponent } from './app-layouts/pos-layout.component';
import { RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './app-layouts/auth-layout.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouteBreadcrumbsComponent } from './ribbon/route-breadcrumbs.component';
import { MomentModule } from 'ngx-moment';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { UtilsModule } from '../utils/utils.module';
import { ClientModule } from '../client/client.module';
import { SizeDetectorComponent } from './sizedetector/sizedetector.component';
import { KioskLayoutComponent } from "./app-layouts/kiosk-layout.component";
import { StoreToolbarModule } from "./store-toolbar/storetoolbar.module";
import { GolfKioskLayoutComponent } from "./app-layouts/golfkiosk-layout.component";

import { StartSplashScreenModule } from "@app/features/splash/preloginsplash/startsplashscreen.module";

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    NavigationModule,
    FormsModule,
    RouterModule,
    UtilsModule,    
    MomentModule,
    TooltipModule,
    BsDropdownModule,
    NgxUiLoaderModule,
    ClientModule,
    StoreToolbarModule,
    StartSplashScreenModule
  ],
  declarations: [
    SizeDetectorComponent,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,
    MainLayoutComponent,
    EmptyLayoutComponent,
    PosLayoutComponent,
    KioskLayoutComponent,
    GolfKioskLayoutComponent,
    AuthLayoutComponent,
    RouteBreadcrumbsComponent,
  ],
  exports:[
    HeaderModule,
    NavigationModule,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,
    GolfKioskLayoutComponent,
  ]
})
export class SmartadminLayoutModule{
}

import { LoginAction } from './../store/auth/auth.actions';
import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';

import { RxStompService } from "@stomp/ng2-stompjs";
import { Message } from "@stomp/stompjs";
import * as moment from 'moment'

import { Observable, BehaviorSubject, Subject } from "rxjs";
import { map, filter, scan, timestamp } from "rxjs/operators";
import { Subscription } from "rxjs/Subscription";

import { Store } from '@ngrx/store';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

import { RulesService } from './rules.service';
import { AuthService } from "./auth.service";
import { ClientService } from "./client.service";
import { VendorService } from "./vendor.service";
import { UserStatsService } from "./userstats.service";
import { NotificationService } from "./notification.service";
import { DataService } from "./data.service";
// import { NotificationService } from "./notification.service";
import { config } from "@app/core/smartadmin.config";


import { BeneficiaryModel } from '../models/accountmodels/beneficiary.model';
import { LocationModel } from '@app/core/models/common/location.model';
import { UserModel } from "@app/core/models/usermodels/user.model";
import { ClientModel } from "@app/core/models/clientmodels/client.model";
import { ChildModel } from '@app/core/models/usermodels/child.model';
import { DietModel } from '@app/core/models/usermodels/diet.model';
import { PermissionsModel } from '@app/core/models/usermodels/permissions.model';

import { UserOTPRequestModel, UserOTPResponseModel } from "@app/core/models/usermodels/userotp-request.model";
import { ClientInfoModel } from "../models/clientmodels/clientinfo.model";
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { NotificationsResponse } from '../models/accountmodels/notificationsreponse.model';
import { OutlookMessage } from './../../features/outlook/shared/outlook-message.class';
import { SVUserModel } from '../models/studentventures/svusermodel.model';
import { CashoutRequestModel } from '../models/cashout/cashoutrequest.model';
import { CashoutResponseModel } from '../models/cashout/cashout-response.model';
import { CollectionOrderModel } from '../models/usermodels/collection-order.model';
import { PreorderModel } from '../models/preorder/preorder.model';
import { consolelog } from './console-log';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SystemDocumentModel } from '../models/system/systemdocument.model';
import { WorkflowStateModel } from '../models/workflow/workflowstate.model';
import { ToastrService } from 'ngx-toastr';
import { TransactionOrderStateModel } from '../models/transaction/TransactionOrderState.Model';
import { WorkflowOrderUpdateMessage } from '../models/workflow/workfloworderupdate.model';
import { CartService } from '.';
import { AddressModel } from '../models/addressmodels/address.model';
import { TransactionOrderModel } from '../models/transaction/transaction_order.model';
import { VendorModel } from '../models/vendormodels/vendor.model';
import { TaskResponseModel } from '../models/tasks/taskresponse.model';
import { InstallmentPaymentModel } from '../models/usermodels/installmentpayment.model';

const defaultUser = {
  username: "Guest"
}

@Injectable()
export class UserService implements OnInit, OnDestroy {

  private _isLoggedIn: boolean = false;
  private userLoaded = false;
  private subscribed: boolean;
  private messages: Observable<Message>;
  private userMessages: Observable<Message>;
  private subscription: Subscription;
  private userSubscription: Subscription;
  private clientSubscription: Subscription;
  private topicSubscription: Subscription;
  private hasUser = false;
  private userSubject = new BehaviorSubject<UserModel>(null);
  private fetchDefered: boolean = false;
  private fcmRegistered: boolean = false;



  public currentMessage = new BehaviorSubject(null);
  public userNotification = new BehaviorSubject(null);
  public userData: UserModel;
  public clientData: ClientModel;

  private hasLinkedAccounts = false;
  public linkedAccountsData: Array<UserModel>;
  private linkedAccountsSubject = new BehaviorSubject<Array<UserModel>>(null);

  private hasParentAccounts = false;
  public parentAccountsData: UserModel[];
  private parentAccountsSubject = new BehaviorSubject<Array<UserModel>>(null);

  private hasLinkedAssociations = false;
  public linkedAssociationsData: Array<ClientInfoModel>;
  private linkedAssociationsSubject = new BehaviorSubject<Array<ClientInfoModel>>(null);

  private hasStudentVenture = false;
  private studentVenturesData: Array<SVUserModel>;
  private studentVenturesSubject = new BehaviorSubject<Array<SVUserModel>>(null);

  private clientId = -1;
  private userId = null;
  private fcmToken = null;
  private fcmRegisteredFlag = false;


  public switchedAccount = new BehaviorSubject<UserModel>(null);
  public documentSubject = new BehaviorSubject<Array<SystemDocumentModel>>(null);
  public orderStateSubject = new BehaviorSubject<WorkflowStateModel>(null);
  public orderUpdateSubject = new BehaviorSubject<WorkflowOrderUpdateMessage>(null);


  public startupPageDone: boolean = false;

  constructor(// public adalService: MsAdalAngular6Service,
    private afMessaging: AngularFireMessaging,
    private location: Location,
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    private authService: AuthService,
    private clientService: ClientService,
    private vendorService: VendorService,
    private statsService: UserStatsService,
    private dataService: DataService,
    private notificationService: NotificationService,
    private rulesService: RulesService,
    private _stompService: RxStompService,
    private toastr: ToastrService,
    private store: Store<any>) {

    this.topicSubscription = new Subscription();
    this.authService.loginState.subscribe(data => {
      if (data) {
        this._isLoggedIn = data;
        if (data == true) {
          this.registerFCM();
        }
      }
      else {
        //this.logout();
      }
    });

    let that = this;
    this.clientSubscription = this.clientService.clientSubject
      .subscribe(
        data => {
          if (!data)
            return;
          that.clientData = data;
          that.clientId = data.clientId;
          if ((that.authService !== undefined) && (that.fetchDefered === true) && that.authService.isLoggedIn) {
            that.fetchUserDefered(true);
            that.fetchDefered = false;
          }
        })
    // const registerServiceWorker = async () => {
    //   const swRegistration = await navigator.serviceWorker.register('service.js'); //notice the file name
    //   return swRegistration;
    // }
    // consolelog("hELP mE");
  }

  ngOnInit() {
    // console.log("Loading UserService");
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  isUserLoaded() {
    return this.userLoaded;
  }

  logout() {
    // console.log("user logout");

    var urlString = "/api/account/logout";
    this.http.post(config.TRIOSAPI + urlString, null)
      .subscribe(
        data => {
        },
        errorResponse => {
        }
      );

    this.dataService.userData = null;
    this.dataService.messageTemplate = null;
    this.dataService.notification = null;
    this.dataService.userGroup = null;
    this.dataService.vendor = null;

    this.fcmRegistered = false;
    this.hasUser = false;
    this.hasLinkedAccounts = false;
    this.hasParentAccounts = false;
    this.hasLinkedAssociations = false;
    this.hasStudentVenture = false;

    this.linkedAccountsSubject.observers.forEach(obs => {
      obs.complete();
    });
    this.parentAccountsSubject.observers.forEach(obs => {
      obs.complete();
    });
    this.userSubject.observers.forEach(obs => {
      obs.complete();
    });

    this.userSubject.next(null);
    this.currentMessage.next(null);
    this.userNotification.next(null);

    this.currentMessage.complete();
    this.currentMessage = new BehaviorSubject(null);

    this.userNotification.complete();
    this.userNotification = new BehaviorSubject(null);

    this.userSubject.complete();
    this.userSubject = new BehaviorSubject<UserModel>(null);

    if (this.subscription)
      this.subscription.unsubscribe();

    if (this.userSubscription)
      this.userSubscription.unsubscribe();

    // if (this.clientSubscription)
    //   this.clientSubscription.unsubscribe();


    this.subscribed = false;
    this.clientData = null;
    this.userData = null;
    this.linkedAccountsData = null
    this.parentAccountsData = null
    this.linkedAssociationsData = null;

    this.linkedAccountsSubject.next(null)
    this.parentAccountsSubject.next(null)
    this.linkedAssociationsSubject.next(null)

    this.linkedAccountsSubject.complete();
    this.parentAccountsSubject.complete();
    this.linkedAssociationsSubject.complete();

    this.hasLinkedAccounts = false;
    this.linkedAccountsSubject = new BehaviorSubject<UserModel[]>(null);

    this.hasParentAccounts = false;
    this.parentAccountsSubject = new BehaviorSubject<UserModel[]>(null);

    this.hasLinkedAssociations = false;
    this.linkedAssociationsSubject = new BehaviorSubject<ClientInfoModel[]>(null);

    this.userId = null;
    this.fcmToken = null;
    this.fcmRegistered = false;

    this.deInitServices();
    // if (this.clientService.isADAuth()) {
    //   this.adalService.logout();
    // }

    this.clientService.logout();
    this.authService.logout();
    this.rulesService.clearRules();
    window.close();    
  }


  requestPermission() {
    let that = this;
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        // console.log('Notification permission granted.');
        that.afMessaging.getToken.subscribe(
          (token) => {
            that.fcmToken = token;
            that.setFCMToken(token).subscribe(data => {
            });
          },
        );
      }
      else {
        // console.log('Unable to get permission to notify.');
      }
    });
  }


  registerFCM() {

    if (this.fcmRegisteredFlag)
      return;

    // this.afMessaging.requestToken.subscribe(
    //   (token) => {
    //     this.fcmToken = token;
    //     if (token != null) {
    //       // console.log(this.fcmToken)
    //       this.setFCMToken(this.fcmToken).subscribe();
    //       this.fcmRegistered = true;
    //     }
    //     else
    //       this.fcmRegistered = false;
    //   },
    // );

    this.afMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        this.receiveMessage();
      });

    this.fcmRegisteredFlag = true;
  }


  receiveMessage() {
    this.afMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
      })
  }

  public getUser(): Observable<UserModel> {
    if (!this.hasUser) {
      this.fetchUser();
    }
    return this.userSubject.asObservable();
  }


  public fetchUser(): void {
    if (!this.clientData) {
      this.fetchDefered = true;
    }
    else
      this.fetchUserDefered(true);
  }

  public fetchUserDefered(navigate: boolean): void {
    let that = this;
    this.hasUser = true;
    var urlString = "/api/users/userinfo?clientId=" + this.clientId;
    this.http.get<UserModel>(config.TRIOSAPI + urlString)
      .pipe(
        map(response => {
          if (response.userPhoto == null)
            response.userPhoto = 'assets/img/demo/64x64.png';
          return response;
        })
      )
      .subscribe(
        data => {
          var gotodash = false;
          if ((data == null) || (data == undefined))
            return;

          if ((that.userData == null) && (data != null))
             gotodash = true;
             
          that.userData = data;
          that.userSubject.next(data);
          that.userId = data.userId;
          that.subscribe();
         
          if (that.userData.linkedAccounts > 0) {
            that.fetchLinkedAccounts();
          }
          else {
            that.userLoaded = true;
          }
          if (that.userData.parentAccounts > 0)
            that.fetchParentAccounts();

          this.store.dispatch(new LoginAction(data));
          that.initServices();
          that.rulesService.setUserRules(data);

          if ((that.clientData.splashScreen == null) || (that.clientData.splashScreen.splash == null)) {
            that.checkDocuments(navigate);
          }
          
          if (gotodash)
             this.router.navigate(["/dashboard/analytics"]);
        },
        errorResponse => {
          that.hasUser = false;
          that.userSubject.error(errorResponse);
          that.authService.logout();
        }
      );

    this.registerFCM();
  }


  public fetchUserWait(): Observable<UserModel> {
    return new Observable<UserModel>(subscriber => {
    let that = this;
    this.hasUser = true;
    var urlString = "/api/users/userinfo?clientId=" + this.clientId;
    this.http.get<UserModel>(config.TRIOSAPI + urlString)
      .pipe(
        map(response => {
          if (response.userPhoto == null)
            response.userPhoto = 'assets/img/demo/64x64.png';
          return response;
        })
      )
      .subscribe(
        data => {
          if ((data == null) || (data == undefined))
            return;
          that.userData = data;
          subscriber.next(data)
        },
        errorResponse => {
          that.hasUser = false;
          subscriber.error(errorResponse);
        }
      );
    })
  }


  private initServices() {
    this.statsService.init(this.userData);
    this.notificationService.init(this.userData);
  }


  private deInitServices() {
    this.statsService.deInit();
    this.notificationService.deInit();
  }

  public addChild(clientId: number) {
    var urlString = "/api/users/addchild?clientId=" + clientId;
    let that = this;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            that.userData = data;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getLinkedAccounts(): Observable<UserModel[]> {
    if (!this.hasLinkedAccounts && (this.userData != null) && (this.userData != undefined)) {
      this.fetchLinkedAccounts();
    }
    return this.linkedAccountsSubject.asObservable();
  }

  public getAccount(findUserId: string): UserModel {
    if (this.hasLinkedAccounts && (this.userData != null) && (this.userData != undefined)) {
      if (this.userData.userId == findUserId) {
        return this.userData;
      } else {
        return this.userData.linkedAccountsData.find(linkedUser => {
          return linkedUser.userId == findUserId
        });
      }
    }
    return null;
  }


  public fetchLinkedAccounts(): void {
    let that = this;
    this.hasLinkedAccounts = true;
    var urlString = "/api/users/loadLinkedAccounts";
    this.http.get<Array<UserModel>>(config.TRIOSAPI + urlString)
      // .pipe(
      //   map(response => {
      //     response.forEach(acc => {
      //       if (acc != null) {
      //         acc.me = false;
      //         if (acc.userPhoto == null)
      //           acc.userPhoto = 'assets/img/demo/64x64.png';
      //       }
      //     });
      //     return response;
      //   })
      // )
      .subscribe(
        data => {
          if ((data == null) || (data == undefined))
            return;
          that.userLoaded = true;
          try {
            that.userData.linkedAccounts = data.length;
          }
          catch (e) {
            that.userData.linkedAccounts = 0;
          }
          that.userData.linkedAccountsData = data;
          that.userSubject.next(that.userData);
          that.linkedAccountsData = data;
          that.linkedAccountsSubject.next(data);
          // console.log("Fetch linked accounts response ", data)
        },
        errorResponse => {
          that.hasLinkedAccounts = false;
          that.linkedAccountsSubject.error(errorResponse);
        }
      );
  }


  public getParentAccounts(): Observable<UserModel[]> {
    if (!this.hasParentAccounts && (this.userData != null) && (this.userData != undefined)) {
      this.fetchParentAccounts();
    }
    return this.parentAccountsSubject.asObservable();
  }

  public fetchParentAccounts(): void {
    let that = this;
    this.hasParentAccounts = true;
    var urlString = "/api/users/loadParentAccounts";
    this.http
      .get<UserModel[]>(config.TRIOSAPI + urlString)
      .pipe(
        map(response => {
          response.forEach(acc => {
            if (acc != null) {
              if (acc.userPhoto == null)
                acc.userPhoto = 'assets/img/demo/64x64.png';
            }
          });
          return response;
        })
      )
      .subscribe(
        data => {
          if ((data == null) || (data == undefined))
            return;
          that.userData.parentAccounts = data.length;
          that.userSubject.next(that.userData);
          that.parentAccountsData = data;
          that.parentAccountsSubject.next(data);
          // console.log("Fetch linked accounts response ", data)
        },
        errorResponse => {
          that.hasParentAccounts = false;
          that.parentAccountsSubject.error(errorResponse);
        }
      );
  }


  public linkAssociation(clientId: number, accountType: string) {
    var urlString = "/api/users/linkClient?clientId=" + clientId + "&accountType=" + accountType;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            this.fetchLinkedAssociations();
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public syncAccount(data: UserModel) {
    var urlString = "/api/users/syncAccount";
    return new Observable<any>(subscriber => {

      this.http.post<UserModel>(config.TRIOSAPI + urlString, data)
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateUserReg(data: UserModel) {
    var urlString = "/api/users/updateUserReg";
    let that = this;
    return new Observable<any>(subscriber => {

      this.http.post<UserModel>(config.TRIOSAPI + urlString, data)
        .pipe(
          map(response => {
            if (response != null) {
              if (response.userPhoto == null)
                response.userPhoto = 'assets/img/demo/64x64.png';
            }
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            that.userData = data;
            that.userSubject.next(data);
            that.userId = data.userId;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public updateUserRegCompass(data: UserModel) {
    var urlString = "/api/users/updateUserRegCompass";
    let that = this;
    return new Observable<any>(subscriber => {

      this.http.post<UserModel>(config.TRIOSAPI + urlString, data)
        .pipe(
          map(response => {
            if (response != null) {
              if (response.userPhoto == null)
                response.userPhoto = 'assets/img/demo/64x64.png';
            }
            return response;
          })
        )
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            that.userData = data;
            that.userSubject.next(data);
            that.userId = data.userId;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getTempToken() {
    var urlString = "/api/users/getTempToken";
    return new Observable<any>(subscriber => {

      this.http.get(config.TRIOSAPI + urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public linkChildAccount(data: ChildModel) {
    var urlString = "/api/users/linkChildAccount";
    return new Observable<any>(subscriber => {

      this.http.post<UserModel>(config.TRIOSAPI + urlString, data)
        .subscribe(
          data => {
            subscriber.next(data);
            this.fetchLinkedAccounts();
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public requestUserOTP(userOtpRequest: UserOTPRequestModel) {
    var urlString = "/api/users/otpPin";
    return new Observable<UserOTPResponseModel>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, userOtpRequest)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public unlinkAssociation(clientId: number) {
    var urlString = "/api/users/unlinkClient?clientId=" + clientId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            this.fetchLinkedAssociations();
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public linkAccountOnAssocNumber(clientId: number, associationNumber: string) {
    var urlString = "/api/users/linkAccountOnAssocNumber?clientId=" + clientId + "&assocNumber=" + associationNumber;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            this.fetchLinkedAccounts();
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public setFCMToken(token: string) {
    const that = this;
    var nid = this.cookieService.get("nid");
    var urlString = "/api/users/setFCMToken?fcmToken=" + token + "&nid=" + nid;
    return new Observable<string>(subscriber => {
      this.http.post<string>(config.TRIOSAPI + urlString, "")
        .subscribe(
          data => {
            that.cookieService.set("nid", data);
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public linkAccount(accountEmail: string) {
    var urlString = "/api/users/linkAccount?accountEmail=" + accountEmail;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            this.fetchLinkedAccounts();
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }




  public transferFunds(toAccount: string, amount: number, transferType: string, note: string) {
    var urlString = "/api/users/transferFunds?toAccount=" + toAccount + "&amount=" + amount + "&note=" + note + "&transferType=" + transferType;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            this.fetchUser();
            this.fetchLinkedAccounts();
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public lookupAccount(clientId: number, admissionNumber: string, dateOfBirth: string) {
    var urlString = "/api/users/lookupAccount?clientId=" + clientId + "&admissionNumber=" + admissionNumber + "&dateOfBirth=" + dateOfBirth;
    return new Observable<any>(subscriber => {
      this.http.get<UserModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public unlinkAccount(accountId: string, parentAccount: boolean) {
    var urlString = "/api/users/unlinkAccount?accountId=" + accountId;
    urlString += '&parentAccount=' + parentAccount;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            this.fetchLinkedAccounts();
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getLinkedAssociations(): Observable<ClientInfoModel[]> {
    if (!this.hasLinkedAssociations) {
      this.fetchLinkedAssociations();
    }
    return this.linkedAssociationsSubject.asObservable();
  }


  public fetchLinkedAssociations(): void {
    // console.log("Fetch linked associations")
    this.hasLinkedAssociations = true;
    var urlString = "/api/users/loadLinkedAssociations";
    this.http
      .get<ClientInfoModel[]>(config.TRIOSAPI + urlString)
      .pipe(
        map(response => {
          response.forEach(client => {
            if (client.clientLogo == null)
              client.clientLogo = '../../../assets/img/demo/64x64.png';
          });
          return response;
        })
      )
      .subscribe(
        data => {
          this.linkedAssociationsData = data;
          this.linkedAssociationsSubject.next(data);
          // console.log("Fetch linked associations response ", data)
        },
        errorResponse => {
          this.hasLinkedAssociations = false;
          this.linkedAccountsSubject.error(errorResponse);
        }
      );
  }



  public fetchDiet(accountId: string) {
    var urlString = "/api/users/diet?accountId=" + accountId;
    return this.http
      .get<DietModel>(config.TRIOSAPI + urlString)
  }

  public updateDiet(dietData: DietModel) {
    var urlString = "/api/users/diet";
    return this.http
      .post<DietModel>(config.TRIOSAPI + urlString, dietData)
  }

  public sendEmail(clientId: number, emailType: string) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + "/api/users/sendemail?clientId=" + clientId + "&emailType=" + emailType, null)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            // console.log(response);
            subscriber.error(response);
          }
        );
    });
  }


  public loadActivities() {
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + '/api/users/loadActivities')
        .pipe(
          map(data => {
            data.forEach(activity => {
              activity.activityTime = moment(moment.utc(activity.activityTime).toDate()).format("DD MMM YYYY HH:mm")
            });
            return data;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public setAccountPermissions(permissions: PermissionsModel) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/users/setPermissions', permissions)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public setPreferences(accountData: UserModel) {
    let that = this;
    return new Observable<any>(subscriber => {
      this.http.post<UserModel>(config.TRIOSAPI + '/api/users/setPreferences', accountData)
        .subscribe(
          data => {
            if ((data === null) || (data === undefined))
              return;
            if (data.userId == this.userData.userId) {
              that.userData = data;
              that.userSubject.next(data);
            }
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public loadStatement(accountId: string, fromDate: string, toDate: string, includeArchive: boolean) {
    var urlString = "/api/users/loadStatement?accountId=" + accountId + "&fromDate=" + fromDate + "&toDate=" + toDate + "&includeArchive=" + includeArchive;
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + urlString)
        .pipe(
          map(data => {
            data.transactionLine.forEach(item => {
              item.transactionDate = moment(moment.utc(item.transactionDate).toDate()).format("DD MMM YYYY HH:mm");
            });
            return data;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadAXStatement(accountId: string, fromDate: string, toDate: string) {
    var urlString = "/api/users/loadAXStatement?accountId=" + accountId + "&fromDate=" + fromDate + "&toDate=" + toDate;
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + urlString)
        .pipe(
          map(data => {
            data.transactionLine.forEach(item => {
              item.transactionDate = moment(moment.utc(item.transactionDate).toDate()).format("DD MMM YYYY HH:mm");
            });
            return data;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadOrders(accountId: string, fromDate: string, toDate: string) {
    var urlString = "/api/users/loadOrders?accountId=" + accountId + "&fromDate=" + fromDate + "&toDate=" + toDate;
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + urlString)
        .pipe(
          map(data => {
            data.orderLine.forEach(item => {
              item.orderDate = moment(moment.utc(item.orderDate).toDate()).format("DD MMM YYYY HH:mm")
            });
            return data;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadInstallments(accountId: string) {
    var urlString = "/api/users/loadInstallments?accountId=" + accountId ;
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + urlString)
        .pipe(
          map(data => {
            data.forEach(item => {
              item.orderDate = moment(moment.utc(item.orderDate).toDate()).format("DD MMM YYYY HH:mm")
            });
            return data;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadInstallmentDetail(orderId: string) {
    var urlString = "/api/users/loadInstallmentDetail?orderId=" + orderId;
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + urlString)
        .pipe(
          map(data => {
            data.originalPurchaseDate = moment(moment.utc(data.originalPurchaseDate).toDate()).format("DD MMM YYYY HH:mm")
             data.payments.forEach(item => {
                item.TranDate  = moment(moment.utc(item.TranDate).toDate()).format("DD MMM YYYY HH:mm")
             });
             data.purchasedItems.forEach(item => {
              item.TranDate  = moment(moment.utc(item.TranDate).toDate()).format("DD MMM YYYY HH:mm")
           });
            return data;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public payInstallment(paymentDetail: InstallmentPaymentModel) {
    var urlString = "/api/users/payInstallment";
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, paymentDetail)
        .pipe(
          map(data => {
            // data.forEach(item => {
            //   item.orderDate = moment(moment.utc(item.orderDate).toDate()).format("DD MMM YYYY HH:mm")
            // });
            return data;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadNotifications() {
    var urlString = "/api/users/loadnotifications";
    return new Observable<NotificationsResponse>(subscriber => {
      this.http.get<NotificationsResponse>(config.TRIOSAPI + urlString)
        .subscribe(
          (data: NotificationsResponse) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public markNotificationAsRead(id) {
    var urlString = "/api/users/markNotificationAsRead?id=" + id;
    return new Observable<NotificationsResponse>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, "")
        .subscribe(
          (data: any) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public subscribe() {
    let that = this;
    if (this.subscribed) {
      return;
    }
    this.subscribed = true;

    this.topicSubscription.add(this._stompService.watch("/exchange/Allxs.topic/AX.broadcast.*").subscribe(x => { that.on_next(x); }
    ,(error:any) => {
      
    } ));
    this.topicSubscription.add(this._stompService.watch("/exchange/Allxs.topic/AX." + this.userData.userId + ".*").subscribe(x => { that.on_next(x); }));
    //this.userMessages = this._stompService.subscribe("/exchange/Allxs.topic/AX." + this.userData.userId + ".*");
    // this.subscription = this.messages.subscribe((data) => { });
    // this.userSubscription = this.userMessages.subscribe((data) => {
    //   that.on_next(data);
    // });
  }

  on_next(message: Message) {
    try {
      // console.log(message);
      var msg = JSON.parse(message.body);

      var attrib = this.boxAttrib(msg.NotifyType);
      var buttons = ``;
      var timeout = 5000;

      if (msg.AckRequired === true) {
        buttons = `<p class='text-align-right'><button class="btn btn-primary"><i class="fa fa-edit"></i> Ok </button></p>`;
        timeout = 500000;
      }
      switch (msg.MessageType) {

        case "WorkflowOrderUpdateMessage":
          var wsMsg = msg as WorkflowStateModel;
          this.orderUpdateSubject.next(msg);
          break;

        case "WorkflowStateMessage":
          var wsMsg = msg as WorkflowStateModel;
          this.orderStateSubject.next(msg);
          this.toastr.success("You order " + wsMsg.orderNumber + " state has changed to " + wsMsg.state);
          break;

        case "ReloadMessage":
          this.fetchUserDefered(true);
          this.router.navigate(['/dashboard/analytics']);
          break;
 
        case "NoReloadMessage":
            this.fetchUserDefered(false);
            break;
  
        case "NotificationMessage":
          //console.log("Notification Message", msg);
          this.notificationService.notify(msg);
          this.userNotification.next(msg);

          try {
            if (msg.updateUser == true) {
              this.fetchUserDefered(true);
            }
          }
          catch (e) {

          }

          try {
            if (msg.refresh == true) {
              this.router.navigate(['/dashboard/analytics']);
            }
          }
          catch (e) {

          }

          break;

        case "NotifyUpdateMessage":
          //this.fetchNotifications();
          break;

        case "NotifyMessage":
          // this.notificationService.smallBox(
          //   {
          //     title: msg.Subject,
          //     content: `${msg.Body} ${buttons}`,
          //     color: attrib.color,
          //     timeout: timeout
          //   },
          //   function(ButtonPress, Value) {}
          // );
          break;

        default:
          //alert(msg.MessageType);
          break;
      }
    } catch (ex) { }
  };


  boxAttrib(type: Number) {
    switch (type) {
      default:
        return {
          color: "#739E73",
          iconSmall: "fa fa-thumbs-up bounce animated"
        };
    }
  }

  public getMail() {
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + '/api/users/getmail')
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getFolder(folder) {
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + '/api/users/getfolder?clientId=' + this.clientData.clientId + '&folder=' + folder)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert(response);
            subscriber.error(response);
          }
        );
    });
  }

  public deleteMessages(messageIdArray) {
    return new Observable<any>(subscriber => {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        body: {
          data: messageIdArray,
        }
      }
      options.body = messageIdArray;

      this.http.delete<any>(config.TRIOSAPI + '/api/users/deleteMessages', options)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getMessage(messageId) {
    return new Observable<OutlookMessage>(subscriber => {
      this.http.get<OutlookMessage>(config.TRIOSAPI + '/api/users/getmessage?messageId=' + messageId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert(response);
            subscriber.error(response);
          }
        );
    });
  }

  public getVendors(clientId: number) {
    var urlString = config.TRIOSAPI + "/api/users/getvendors?clientId=" + clientId;
    return new Observable<Array<VendorModel>>(subscriber => {
      this.http.get<Array<VendorModel>>(urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getClientVendors(clientId: number) {
    var urlString = config.TRIOSAPI + "/api/users/getvendors?clientId=" + clientId + "&filter=VENDORADMIN";
    return new Observable<Array<VendorModel>>(subscriber => {
      this.http.get<Array<VendorModel>>(urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public setStoredLocation(data: LocationModel) {
    localStorage.setItem('currentLocation', JSON.stringify(data));
    this.cookieService.set('currentLocation', JSON.stringify(data));
  }

  public getStoredLocation(): LocationModel {
    try {
      return JSON.parse(this.cookieService.get('currentLocation'));
      //return JSON.parse(localStorage.getItem('currentLocation'));
    }
    catch (ex) {
      return null;
    }
  }

  public getBeneficiaries(clientId: number) {
    let that = this;
    return new Observable<Array<BeneficiaryModel>>(subscriber => {
      this.http.get<Array<BeneficiaryModel>>(config.TRIOSAPI + '/api/users/getbeneficiaries?clientId=' + clientId)
        .subscribe(
          data => {
            if (data == null) {
              subscriber.error("empty data");
              return;
            }
            that.userData.beneficiaries = data.length;
            that.userData.beneficiaryData = data;
            that.userSubject.next(that.userData);
            subscriber.next(data);
          },
          response => {
            alert(response);
            subscriber.error(response);
          }
        );
    });
  }

  public updateBeneficiary(clientId: number, data: BeneficiaryModel) {
    let that = this;
    return new Observable<Array<BeneficiaryModel>>(subscriber => {
      this.http.post<Array<BeneficiaryModel>>(config.TRIOSAPI + '/api/users/updatebeneficiary?clientId=' + clientId, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            that.userData.beneficiaries = data.length;
            that.userData.beneficiaryData = data;
            that.userSubject.next(this.userData);
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public deleteBeneficiary(clientId: number, beneficiaryId: string) {
    let that = this;
    return new Observable<Array<BeneficiaryModel>>(subscriber => {
      this.http.delete<Array<BeneficiaryModel>>(config.TRIOSAPI + '/api/users/deletebeneficiary?clientId=' + clientId + '&beneficiaryId=' + beneficiaryId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            if (data == null) {
              that.userData.beneficiaries = 0;
              that.userData.beneficiaryData = [];
              that.userSubject.next(that.userData);
              subscriber.next(data);
            }
            else {
              that.userData.beneficiaries = data.length;
              that.userData.beneficiaryData = data;
              that.userSubject.next(that.userData);
              subscriber.next(data);
            }

          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public payBeneficiary(beneficiaryId: string, amount: number, description) {
    var urlString = "/api/users/payBeneficiary?beneficiaryId=" + beneficiaryId + "&amount=" + amount + "&description=" + description;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, null)
        .subscribe(
          data => {
            this.fetchUser();
            this.fetchLinkedAccounts();

            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public loadStudents(clientId: number) {
    var urlString = "/api/studentventures/getstudents?clientId=" + clientId;
    return new Observable<Array<SVUserModel>>(subscriber => {
      this.http.get<Array<SVUserModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public addMember(clientId: number, svId: string, accountNumber: number) {
    var urlString = "/api/studentventures/addMember?clientId=" + clientId + "&svId=" + svId + "&accountNumber=" + accountNumber;
    return new Observable<SVUserModel>(subscriber => {
      this.http.post<SVUserModel>(config.TRIOSAPI + urlString, null)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public removeMember(clientId: number, svId: string, userId: string) {
    var urlString = "/api/studentventures/removeMember?clientId=" + clientId + "&svId=" + svId + "&userId=" + userId;
    return new Observable<SVUserModel>(subscriber => {
      this.http.post<SVUserModel>(config.TRIOSAPI + urlString, null)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getStudentVentures(clientId: number = null): Observable<Array<SVUserModel>> {
    if (clientId == null)
      clientId = this.clientId;
    if (!this.hasStudentVenture) {
      this.fetchStudentVentures(clientId);
    }
    return this.studentVenturesSubject.asObservable();
  }


  public getStudentVenture(svId: string): Observable<SVUserModel> {
    let that = this;
    return new Observable<SVUserModel>(subscriber => {
      this.http.get<SVUserModel>(config.TRIOSAPI + '/api/studentventures/getaccount?clientId=' + this.clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public fetchStudentVentures(clientId: number) {
    var urlString = "/api/studentventures/getaccounts?clientId=" + clientId;
    this.http.get<Array<SVUserModel>>(config.TRIOSAPI + urlString)
      .subscribe(
        (data) => {
          this.hasStudentVenture = true;
          this.studentVenturesData = data;
          this.studentVenturesSubject.next(data);
        },
        errorResponse => {
          this.hasStudentVenture = false;
          this.studentVenturesSubject.error(errorResponse);
        }
      );
  }


  public getAccountType() {

    //var accType = localStorage.getItem('addAccountType');
    var accType = this.cookieService.get('addAccountType')
    if ((accType == null) || (accType == undefined) || (accType == ''))
      return "general";
    return accType.toLowerCase();
  }

  public isUserinRole(role: string) {
    try {
      var roles = this.userData.roles;
      var retval = roles.find(te => te.roleName.toLowerCase() == role.toLowerCase());
      return (retval != null);
    }
    catch (e) {
      return false;
    }
  }

  public isUserinRoleClient(clientId: number, role: string) {
    var roles = this.userData.roles;
    var retval = roles.find(te => te.roleName.toLowerCase() == role.toLowerCase());
    return (retval != null);
  }


  public switchAccount(clientId: number, userId: string, fromType: string) {
    let that = this;
    return new Observable<SVUserModel>(subscriber => {
      // localStorage.removeItem('addAccountType');
      // localStorage.removeItem('addAccountToken');
      // localStorage.removeItem('addAccountRoles');
      // var testToken = localStorage.getItem('currentToken');
      // var testRoles = localStorage.getItem('currentRoles');
      // localStorage.setItem('addAccountToken', testToken);
      // localStorage.setItem('addAccountRoles', testRoles);
      // localStorage.setItem('addAccountType', fromType);
      this.cookieService.delete('addAccountType');
      this.cookieService.delete('addAccountToken');
      this.cookieService.delete('addAccountRoles');
      var testToken = this.cookieService.get('currentToken');
      var testRoles = this.cookieService.get('currentRoles');
      this.cookieService.set('addAccountToken', testToken);
      this.cookieService.set('addAccountRoles', testRoles);
      this.cookieService.set('addAccountType', fromType);
      that.hasUser = false;
      that.hasStudentVenture = false;
      that.authService.doInternalLogin(clientId, userId)
        .subscribe(data => {
          that.fetchUser();
          that.rulesService.clearRules();
          window.location.reload();
        },
          errorResponse => {
            that.rulesService.clearRules();
            that.switchToMainAccount();
          });
    });

  }

  public switchToMainAccount() {
    let that = this;

    // var testToken = localStorage.getItem('addAccountToken');
    // var testRoles = localStorage.getItem('addAccountRoles');
    // localStorage.setItem('currentToken', testToken);
    // localStorage.setItem('currentRoles', testRoles);
    // localStorage.removeItem('addAccountToken');
    // localStorage.removeItem('addAccountRoles');
    // localStorage.removeItem('addAccountType');
    var testToken = this.cookieService.get('addAccountToken');
    var testRoles = this.cookieService.get('addAccountRoles');
    this.cookieService.set('currentToken', testToken);
    this.cookieService.set('currentRoles', testRoles);
    this.cookieService.delete('addAccountToken');
    this.cookieService.delete('addAccountRoles');
    this.cookieService.delete('addAccountType');
    that.hasUser = false;
    that.hasStudentVenture = false;
    that.fetchUser();
    this.rulesService.clearRules();
    window.location.reload();
  }


  public switchToChildAccount(clientId: number, userId: string, fromType: string) {
    let that = this;
    return new Observable<UserModel>(subscriber => {


      this.cookieService.delete('parentAccountType');
      this.cookieService.delete('parentAccountToken');
      this.cookieService.delete('parentAccountRoles');
      var testToken = this.cookieService.get('currentToken');
      var testRoles = this.cookieService.get('currentRoles');
      if ((testToken == null) || (testToken.length == 0)) {
        testToken = this.authService.getStrToken();
      }
      this.cookieService.set('parentAccountToken', testToken);
      this.cookieService.set('parentAccountRoles', testRoles);
      this.cookieService.set('parentAccountType', fromType);
      this.cookieService.getAll();

      that.hasUser = false;
      that.authService.doInternalLogin(clientId, userId)
        .subscribe(data => {
          console.log("Switch Child Complete");
          that.fetchUser();
          that.rulesService.clearRules();
          window.location.reload();
        },
          errorResponse => {
            that.rulesService.clearRules();
            that.switchToParentAccount();
          });
    });

  }

  public switchToParentAccount() {
    let that = this;

    // var testToken = localStorage.getItem('parentAccountToken');
    // var testRoles = localStorage.getItem('parentAccountRoles');
    // var testAccountType = localStorage.getItem('parentAccountType');
    // localStorage.removeItem('parentAccountToken');
    // localStorage.removeItem('parentAccountRoles');
    // localStorage.removeItem('parentAccountType');
    // localStorage.setItem('currentToken', testToken);
    // localStorage.setItem('currentRoles', testRoles);

    var testToken = this.cookieService.get('parentAccountToken');
    var testRoles = this.cookieService.get('parentAccountRoles');
    var testAccountType = this.cookieService.get('parentAccountType');
    this.cookieService.delete('parentAccountToken');
    this.cookieService.delete('parentAccountRoles');
    this.cookieService.delete('parentAccountType');
    this.cookieService.set('currentToken', testToken);
    this.cookieService.set('currentRoles', testRoles);
    that.hasUser = false;
    that.hasStudentVenture = false;
    that.fetchUser();
    this.rulesService.clearRules();
    window.location.reload();
  }



  public getCollections(userId: string): Observable<Array<CollectionOrderModel>> {
    let that = this;
    return new Observable<Array<CollectionOrderModel>>(subscriber => {
      this.http.get<Array<CollectionOrderModel>>(config.TRIOSAPI + '/api/collections/getcollections?userId=' + userId)
        .pipe(
          map(response => {
            response.forEach(item => {
              item.orderDate = moment(moment.utc(item.orderDate).toDate()).format("DD MMM YYYY HH:mm")
            });
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getCashouts(clientId: number, userId: string): Observable<CashoutResponseModel> {
    let that = this;
    return new Observable<CashoutResponseModel>(subscriber => {
      this.http.get<CashoutResponseModel>(config.TRIOSAPI + '/api/users/getcashouts?clientId=' + clientId + '&userId=' + userId)
        .pipe(
          map(data => {
            data.items.forEach(item => {
              if (item.requestDate != null) {
                item.requestDate = moment(moment.utc(item.requestDate).toDate()).format("DD MMM YYYY HH:mm");
              }
              if (item.completeDate != null) {
                item.completeDate = moment(moment.utc(item.completeDate).toDate()).format("DD MMM YYYY HH:mm");
              }
              if (item.updateDate != null) {
                item.updateDate = moment(moment.utc(item.updateDate).toDate()).format("DD MMM YYYY HH:mm");
              }
              if (item.completeDate != null) {
                item.updateDate = item.completeDate;
              }
              item.amount = this.currencyPipe.transform(item.amount, 'ZAR', "R");
              if ((item.transactionId == null) || (item.transactionId == '0')) {
                item.transactionId = "NA"
              } else {
                item.transactionId = "AX_" + item.transactionId;
              }

            }) //.items...transactionLine.forEach(item => {
            //   item.transactionDate = moment(moment.utc(item.transactionDate).toDate()).format("DD MMM YYYY HH:mm");
            // });
            return data;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public requestCashout(clientId: number, data: CashoutRequestModel) {
    var urlString = "/api/users/cashout?clientId=" + clientId;
    return new Observable<SVUserModel>(subscriber => {
      this.http.post<SVUserModel>(config.TRIOSAPI + urlString, data)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getPreorders(vendorId: number, userId: string): Observable<Array<PreorderModel>> {
    let that = this;
    return new Observable<Array<PreorderModel>>(subscriber => {
      this.http.get<Array<PreorderModel>>(config.TRIOSAPI + '/api/preorder/getpreorders?vendorid=' + vendorId + '&userId=' + userId)
        .pipe(
          map(response => {
            /*response.forEach(item => {
              item.orderDate = moment.utc(item.orderDate).local().toDate();
            });*/
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getAllPreorders(userId: string): Observable<Array<PreorderModel>> {
    let that = this;
    return new Observable<Array<PreorderModel>>(subscriber => {
      this.http.get<Array<PreorderModel>>(config.TRIOSAPI + '/api/preorder/getallpreorders?userId=' + userId)
        .pipe(
          map(response => {
            response.forEach(item => {
              item.orderDate = moment.utc(item.orderDate).toDate();
            });
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  checkDocuments(navigate: boolean) {
    let that = this;
    var urlString = "/api/users/checkfordocuments?clientId=" + this.clientId;
    this.http.get<Array<SystemDocumentModel>>(config.TRIOSAPI + urlString)
      .subscribe(
        data => {
          if (data != null && data.length > 0) {
            that.dataService.documents = data;
            that.documentSubject.next(data);
            that.router.navigate(["dashboard/document"])
          }
          else {
            if (that.userData.registrationState.length > 0) {
              switch (that.clientData.registrationType.toLowerCase()) {
                case "none":
                  break;
                case "school":
                  that.router.navigate(["/dashboard/registration"]);
                  break;
                case "event":
                  let doEventReg = true;
                  // Need to look dor specific event registration strings
                  that.userData.registrationState.forEach(str => {
                    if (str.toLowerCase().includes("child")) {
                      doEventReg = false;
                    }
                  });
  
                  if (doEventReg) {
                    that.router.navigate(["/dashboard/registration/event"]);
                  }
                  break;
  
                case "compass":
                  that.router.navigate(["/dashboard/registration/compass"]);
                  break;
              }
            }
            else if (navigate) {
              that.router.navigate(["/dashboard/analytics"]);
            }
          }
        },
        errorResponse => {
          this.router.navigate(["dashboard/analytics"])
        }
      );
  }


  public acceptDocument(clientId: number, document: SystemDocumentModel) {
    var urlString = "/api/users/acceptDocument?clientId=" + clientId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, document)
        .subscribe(
          (data) => {
            if (data != null && data.length > 0) {
              this.dataService.documents = data;
              this.documentSubject.next(data);
              this.router.navigate(["dashboard/document"])
            }
            else {
              this.dataService.documents = null;
              this.documentSubject.next(null);
              this.router.navigate(["dashboard/analytics"])
            }
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  checkOrders(clientId: number) {
    var urlString = "/api/users/getorderstate?clientId=" + clientId;
    return new Observable<Array<TransactionOrderStateModel>>(subscriber => {
      this.http.get<Array<TransactionOrderStateModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {

          }
        );
    });
  }


  getOrderState(orderId: string) {
    var urlString = "/api/users/getorderstate?orderId=" + orderId;
    return new Observable<Array<TransactionOrderStateModel>>(subscriber => {
      this.http.get<Array<TransactionOrderStateModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {

          }
        );
    });
  }


  public setAddress(clientId: number, address: AddressModel) {
    var urlString = "/api/users/setaddress?clientId=" + clientId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, address)
        .subscribe(
          (data) => {
            if (data != null && data.length > 0) {
            }
            else {
            }
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  getOrderDetail(orderId: string) {
    return new Observable<TransactionOrderModel>(subscriber => {
      this.http.get<TransactionOrderModel>(config.TRIOSAPI + '/api/users/getorderbyorderid?orderId=' + orderId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }




  public completeTask(taskResponse: TaskResponseModel) {
    var urlString = "/api/users/completetask?clientId=" + this.clientData.clientId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, taskResponse)
        .subscribe(
          (data) => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

}
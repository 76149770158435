import { Component, OnInit } from '@angular/core';
import { CartService } from '@app/core/services';
import { LayoutService } from "@app/core/services/layout.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'sa-ribbon',
  templateUrl: './ribbon.component.html'
})
export class RibbonComponent implements OnInit {

  private subscription: Subscription;
  public isRibonVisible: boolean;
  constructor(private layoutService: LayoutService,
    private cartService: CartService) {}

  ngOnInit() {
   
    let that = this;
    that.isRibonVisible = true;
     this.subscription = this.cartService.cartConfigSubject.subscribe(data => {
        that.isRibonVisible = data.displayMode !== 'toolbar';
     })

  }

  ngOnDestroy(){

    if (this.subscription){
      this.subscription.unsubscribe();
    }

  }

  resetWidgets() {
    this.layoutService.factoryReset()
  }

  isMobile(){
    return this.layoutService.isMobile();
  }
}

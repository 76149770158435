import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LocalService } from '@app/core/services/local.service';
import { UserService } from '@app/core/services/user.service';
import { UserModel } from '@app/core/models/usermodels/user.model';

import * as userStore from '@app/core/store/user'

@Component({
  selector: 'kiosk-layout',
  templateUrl: './kiosk-layout.component.html',
  styles: ['./kiosk-layout.component.css']
})
export class KioskLayoutComponent implements OnInit {

  userData:UserModel;

  constructor(private localService:LocalService,
              private userService: UserService,
              private store: Store<any>) { 
    //document.body.style.overflow = "hidden";
  }

  ngOnInit() {
    this.store.pipe(
      select(userStore.getUser)
      ).subscribe(
      (data: UserModel) => {
        if ((data == null) || (data == undefined))
           return;           
        this.userData = data;
        //this.localService.connect();
      });
  }

}

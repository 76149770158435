import { UserService } from './../../../core/services/user.service';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { ClientModel } from '@app/core/models/clientmodels/client.model';

@Component({
  selector: 'client-info',
  templateUrl: './client-info.component.html',
})
export class ClientInfoComponent implements OnInit, OnDestroy {

  clientData: ClientModel;
  clientLogo = "assets/img/demo/64x64.png";
  public hasPermissions: boolean = false;

  @Input('clientdata') set clientdata(value){
    this.clientData = value;
    if(this.clientData.clientLogo !== null)
       this.clientLogo = this.clientData.clientLogo;
  }

  @Output() editClient = new EventEmitter();

  constructor(private router: Router,
      private userService: UserService ){
  }

  ngOnInit() {
    this.hasPermissions = this.userService.isUserinRole("masteradmin");
  }

  ngOnDestroy(){
  }
  
  editClick(){
    this.editClient.emit(this.clientData);
  }

}

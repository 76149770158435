import { Covid19SampleModel } from '../models/covid19/covid19.model';
import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Router } from '@angular/router';

import { RxStompService, StompState } from "@stomp/ng2-stompjs";
import { Message } from "@stomp/stompjs";
import * as moment from 'moment'

import { Observable, BehaviorSubject, Subject } from "rxjs";
import { map, filter, scan, timestamp } from "rxjs/operators";
import { Subscription } from "rxjs/Subscription";

import { config } from "@app/core/smartadmin.config";

@Injectable()
export class Covid19Service implements OnInit, OnDestroy {

  constructor(private http: HttpClient,
    private router: Router) {

  }

  ngOnInit() {
    // console.log("Loading UserService");
  }

  ngOnDestroy() {
  }


  public getTodaysSample(clientId, userId: string): Observable<Covid19SampleModel> {
    var urlString = "/api/covid19/gettodaysample?clientId=" + clientId + "&userId=" + userId;
    return new Observable<Covid19SampleModel>(subscriber => {
      this.http.get<Covid19SampleModel>(config.TRIOSAPI + urlString)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public addSample(clientId: number, data: Covid19SampleModel) {
    var urlString = "/api/covid19/addsample?clientId=" + clientId;
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + urlString, data)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as clientStore from '@app/core/store/client'
import * as userStore from '@app/core/store/user'

import { LayoutService } from '@app/core/services/layout.service';
import { RulesService } from '@app/core/services/rules.service';
import { UserService } from '@app/core/services/user.service';

import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { UserModel } from '@app/core/models/usermodels/user.model';

@Component({
  selector: 'client-banner',
  templateUrl: './client-banner.component.html',
  styleUrls: ['./client-banner.component.css']
})
export class ClientBannerComponent implements OnInit, OnDestroy {

  inBanner: boolean = false;
  vendorName: string = "";
  clientName: string = "";
  showEconomy = false;

  private subscriptions: Subscription[] = [];

  @Input('inbanner') set inbanner(value: boolean) {
    this.inBanner = value;
  }

  constructor(private layoutService: LayoutService,
    private rulesService: RulesService,
    private userService: UserService,
    private store: Store<any>) {
  }

  ngOnInit() {
    let that = this;

    this.subscriptions.push(this.rulesService.onRulesChange().subscribe(
      data => {
        if (data == null)
          return;

        if (data.isStudentVenture) {
          that.subscriptions.push(this.store.select(userStore.getUser).subscribe(
            (data: UserModel) => {
              if (data == null)
                return;
              that.userService.getStudentVenture(data.userId)
                .subscribe(
                  data => {
                    that.vendorName = data.vendor.vendorName;
                  }
                )

            }));
        }

      }));


    this.subscriptions.push(this.store.select(clientStore.getClient)
      .subscribe(
        (data: ClientModel) => {
          if (data != null) {
            that.clientName = data.clientName;
            that.showEconomy = data.economy.economyRules.economyBranding == true;
          }
        }));

  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }


  isMobile() {
    if (this.inBanner && this.layoutService.isMobile()) {
      return true
    }
    if (!this.inBanner && !this.layoutService.isMobile()) {
      return true
    }
  }
}

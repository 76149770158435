
import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { GridModule, ExcelExportService, ToolbarService, PdfExportService } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';

import { ReportViewerModule } from '@app/shared/reportViewer/reportviewer.module';
import { SmartadminDatatableModule } from '@app/shared/ui/datatable/smartadmin-datatable.module';
import { AccountPermissionsComponent } from './permissions/account-permissions.component';
import { PermissionFormComponent } from './permissions/permissionform.component';

import { AccountInfoComponent } from './accountInfo/account-info.component';
import { AccountDietComponent } from './accountdiet/account-diet.component';
import { AccountGroupsComponent } from './accountgroups/account-groups.component';
import { AccountMessagesComponent } from './accountmessages/account-messages.component';
import { AccountStatementComponent } from './accountstatement/account-statement.component';
import { AccountAxStatementComponent } from './accountaxstatement/account-ax-statement.component';
import { AccountPreferencesComponent } from './accountpreferences/account-preferences.component';
import { AccountActivityComponent } from './accountactivity/account-activity.component';
import { AccountActivityItemComponent } from './accountactivity/account-activityitem.component';
import { AccountStatsComponent } from './accountstats/account-stats.component';
import { AccountPinPadComponent } from './accountpin/pinpad.component';
import { AccountBeneficiariesComponent } from './accountbeneficiaries/account-beneficiaries.component';
import { AccountBeneficiaryDialogComponent } from '@app/shared/account/accountbeneficiary/accountbeneficiarydialog.component';
import { CollectionsComponent } from '@app/shared/account/collections/collections.component';
import { CashoutComponent } from '@app/shared/account/accountcashouts/cashouts.component';

import { DeleteAccountComponent } from './deleteaccount/deleteaccount.component';
import { ResyncAccountComponent } from './resyncaccount/resyncaccount.component';
import { DeleteCardComponent } from './deletecard/deletecard.component';

import { SystemCurrencyPipe } from '../../shared/pipes/system-currency.pipe';

import { OrdersComponent } from './orders/orders.component';
import { ViewInvoiceComponent } from './viewinvoice/viewinvoice.component';
import { InstallmentsComponent } from "./installments/installments.component";
import { NumericTextBoxModule } from "@syncfusion/ej2-angular-inputs";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SmartadminDatatableModule,
    GridModule,
    ReportViewerModule,
    DateRangePickerModule,
    NumericTextBoxModule,
    ],
  declarations: [
    AccountInfoComponent,
    AccountPermissionsComponent,
    PermissionFormComponent,
    AccountDietComponent,
    AccountGroupsComponent,
    AccountMessagesComponent,
    AccountBeneficiariesComponent,
    AccountBeneficiaryDialogComponent,
    AccountStatementComponent,
    AccountAxStatementComponent,
    AccountPreferencesComponent,
    AccountActivityComponent,
    AccountActivityItemComponent,
    AccountStatsComponent,
    AccountPinPadComponent,
    DeleteAccountComponent,
    ResyncAccountComponent,
    DeleteCardComponent,
    OrdersComponent,
    ViewInvoiceComponent,
    CollectionsComponent,
    CashoutComponent,
    InstallmentsComponent,
  ],
  exports: [
    AccountInfoComponent,
    AccountPermissionsComponent,
    PermissionFormComponent,
    AccountDietComponent,
    AccountGroupsComponent,
    AccountMessagesComponent,
    AccountBeneficiariesComponent,
    AccountBeneficiaryDialogComponent,
    AccountAxStatementComponent,
    AccountStatementComponent,
    AccountPreferencesComponent,
    AccountActivityComponent,
    AccountActivityItemComponent,
    AccountStatsComponent,
    AccountPinPadComponent,
    DeleteAccountComponent,
    DeleteCardComponent,
    ResyncAccountComponent,
    OrdersComponent,
    ViewInvoiceComponent,
    CollectionsComponent,
    CashoutComponent,
    InstallmentsComponent
  ],
  providers: [
    CurrencyPipe,
    SystemCurrencyPipe,
    PageService, 
    SortService, 
    FilterService, 
    GroupService,
    ExcelExportService, 
    PdfExportService, 
    ToolbarService,
  ]
})
export class AccountModule {}


import { Map, Record, List } from 'immutable';

import { Taxonomy } from '@app/core/models/product/taxonomy.model';

import { VendorModel } from '@app/core/models/vendormodels/vendor.model';
import { ProductCategoryModel } from '@app/core/models/product/productcategory.model';
import { Brand } from '@app/core/models/product/brand.model';
import { RatingOption } from '@app/core/models/product/rating-option.model';
import { ProductModel } from '@app/core/models/productmodels/product.model';
import { ProductV2Model } from '../../models/productmodels/productv2.model';

export interface ProductState extends Map<string, any> {
  productIds: List<number>;
  productEntities: Map<number, ProductModel>;
  selectedProductId: number;
  selectedProduct: ProductModel;
  taxonomies: List<Taxonomy>;
  showAllProducts: List<ProductModel>;
  relatedProducts: List<ProductModel>;
  productReviews: List<any>;
  rootTaxonomyId: number;
  vendors: List<VendorModel>;
  featuredVendors: List<VendorModel>;
  categories: List<ProductCategoryModel>;
  brands: List<Brand>;
  productRatingOptions: List<RatingOption>;
  isReviewSubmitted: boolean;
  productV2: ProductV2Model;
}

export const ProductStateRecord = Record({
  productIds: List([]),
  productEntities: Map({}),
  selectedProductId: null,
  selectedProduct: Map({}),
  taxonomies: List([]),
  showAllProducts: List([]),
  relatedProducts: List([]),
  productReviews: List([]),
  rootTaxonomyId: 0,
  vendors: List([]),
  featuredVendors: List([]),
  categories: List([]),
  brands: List([]),
  productRatingOptions: List([]),
  isReviewSubmitted: false,
  productV2: null
});
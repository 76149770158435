import { Component, OnInit, ElementRef, Renderer2, OnDestroy} from '@angular/core';
import { NotificationService } from '../../../../core/services/notification.service';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'sa-activities',
  templateUrl: './activities.component.html',
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  count:number;
  lastUpdate:any;
  active:boolean;
  activities:any;
  currentActivity: any;
  loading: boolean;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private notifyService : NotificationService,
              private toastr : ToastrService) {
    this.active = false;
    this.loading = false;
    this.activities = [];
    this.count = 0;
    this.lastUpdate = new Date();
  }

  ngOnInit() {
    let that = this;
    this.notifyService.getNotifications().subscribe(data=>{
      if ((data == null) && (data == undefined))
         return;
      that.activities = [data.msgs];
      if ((that.activities != null) || (that.activities != undefined))
      { 
          that.activities.forEach(
            group=>{
              if (group.name === "msgs")
              {
              group.data.forEach(
                element => {
                if(element.status == "UNREAD"){
                  
                }
                  // this.toastr.info(element.subject, element.message);
              });
            }
          }); 
      }
      this.count = this.activities.reduce((sum, it)=> sum + it.data.length, 0);
      this.currentActivity = this.activities[0];
      this.lastUpdate = new Date();
      if (this.count == 0){
        let dropdown = $('.ajax-dropdown', this.el.nativeElement);
        dropdown.fadeOut()
        this.documentUnsub()
      }
      
    });
  }

  setActivity(activity){
    this.currentActivity = activity;
  }

 

  private documentSub: any;
  onToggle() {
    let dropdown = $('.ajax-dropdown', this.el.nativeElement);
    this.active = !this.active;
    if (this.active) {
      dropdown.fadeIn()


      this.documentSub = this.renderer.listen('document', 'mouseup', (event) => {
        if (!this.el.nativeElement.contains(event.target)) {
          dropdown.fadeOut();
          this.active = false;
          this.documentUnsub()
        }
      });


    } else {
      dropdown.fadeOut()

      this.documentUnsub()
    }
  }



  update(){
    this.loading= true;
    setTimeout(()=>{
      this.lastUpdate = new Date()
      this.loading = false
    }, 1000);
    this.notifyService.fetchNotifications();
  }

  onDeleteAll(){
    this.loading= true;
    this.notifyService.deleteAllMessages().subscribe( data=>{
      this.loading = false;
     },
     errorResponse=>{
      this.loading = false;
     });
  }

  ngOnDestroy(){
    this.count = 0;
    this.activities = [];
    this.documentUnsub()
  }

  documentUnsub(){
    this.documentSub && this.documentSub();
    this.documentSub = null
  }

}

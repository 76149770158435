import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalModule } from "ngx-bootstrap/modal";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { TooltipConfig, TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownConfig, BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarConfig, ProgressbarModule } from "ngx-bootstrap/progressbar";
import { AlertConfig, AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from "ngx-bootstrap/tabs";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { PopoverConfig, PopoverModule } from "ngx-bootstrap/popover";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

var config = {
  dismissible: false
};

@NgModule({
  imports: [
    CommonModule,

    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    AlertModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),

    PopoverModule.forRoot()
  ],
  exports: [
    ModalModule,
    ButtonsModule,
    TooltipModule,
    BsDropdownModule,
    ProgressbarModule,
    AlertModule,
    TabsModule,
    AccordionModule,
    CarouselModule,
    BsDatepickerModule,

  ],
  providers: [
    AlertConfig,
    PopoverConfig,
    TooltipConfig,
    BsDropdownConfig,
    ProgressbarConfig
  ],
  declarations: []
})
export class BootstrapModule { }

import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SCREEN_SIZE } from '../screen-size.enum';

@Injectable()
export class ResizeService {

  public size: SCREEN_SIZE;

  get onResize$(): Observable<SCREEN_SIZE> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject:  BehaviorSubject<SCREEN_SIZE>;

  constructor() {
    this.resizeSubject = new BehaviorSubject<SCREEN_SIZE>(null);
  }

  onResize(size: SCREEN_SIZE) {
    this.size = size;
    this.resizeSubject.next(this.size);
  }

}
import { ActionReducer, ActionReducerMap,MetaReducer } from "@ngrx/store";

import * as auth from "./auth";
import * as notify from "./notify";
import * as layout from "./layout";
import * as calendar from "./calendar";
import * as product from "./product";
import * as cart from "./cart";
import * as preorderCart from "./preorder-cart";
import * as client from "./client";
import * as user from "./user";
import * as pos from "./pos";
import { environment } from "@env/environment";

export interface AppState {
  auth: auth.AuthState;
  notify: notify.NotifyState;
  layout: layout.LayoutState;
  calendar: calendar.CalendarState;
  client: client.ClientState;
  user: user.UserState;
  product: product.ProductState;
  cart: cart.CartState;
  pos: pos.PosState;  
  preorderCart: preorderCart.PreorderCartState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: auth.authReducer,
  notify: notify.notifyReducer,
  layout: layout.layoutReducer,
  calendar: calendar.calendarReducer,
  client: client.clientReducer,
  user: user.userReducer,
  product: product.productReducer,
  cart: cart.cartReducer,
  pos: pos.posReducer,
  preorderCart: preorderCart.preorderCartReducer,
};

// console.log all actions
export function logger(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return function(state: AppState, action: any): AppState {
    if (
      // !action.silent &&
      environment.log.store
    ) {
        //console.log("\nstate", state);
        //console.log("+ action", action);
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [logger]
  : [];

export const effects = [
  auth.AuthEffects,
  notify.NotifyEffects,
  layout.LayoutEffects,
  calendar.CalendarEffects,
  client.ClientEffects,  
  user.UserEffects,
  product.ProductEffects,
  cart.CartEffects,
  pos.PosEffects,
  preorderCart.PreorderCartEffects,
];

export const services = [notify.NotifyService];

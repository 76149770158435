import { GoogleAnalyticsService } from './../../../core/services/googleanalytics.service';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as clientStore from '@app/core/store/client'

import { routerTransition } from "@app/shared/utils/animations";
import { UserService } from '@app/core/services/user.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { ClientService } from '@app/core/services';

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styles: [],
  animations: [routerTransition]
})
export class MainLayoutComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;



  constructor(private titleService: Title,
    private ccService: NgcCookieConsentService,
    private clientService: ClientService,
    public spinnerService: SpinnerService,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private store: Store<any>) {


  }

  ngOnInit() {
    let that = this;
    this.subscriptions.push(
      this.store.pipe(
        select(clientStore.getClient)
      ).subscribe((data: ClientModel) => {
        if ((data == null) || (data == undefined))
          return;
        that.titleService.setTitle(data.clientName);
      }));
    //this.userService.fetchUser(); 
    try {
      if (this.ccService.hasConsented() || !this.clientService.isCookieAllowed)
        this.ccService.destroy();
    }
    catch(ex) {

    }
  }



  getState(outlet) {

    if (!outlet)
      return;

    if (!outlet.activated)
      return;

    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }
}

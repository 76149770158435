import { Component, OnInit, Inject  } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { SpinnerService } from './core/services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'sa';

  constructor(spinnerService: SpinnerService){
  }

  ngOnInit(){
    
  }

  
}

import { PromotionUserModel } from './../models/promotions/promotionuser.model';
import { PasswordMaintModel } from '@app/core/models/accountmodels/passwordmaint.model';
import { Integrations } from '@sentry/tracing';
import { environment } from '@env/environment';
import { Injectable, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Router } from '@angular/router';
import { StompConfig } from '@stomp/ng2-stompjs';

import { Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { Subscription } from "rxjs/Subscription";
import { map, filter, scan } from "rxjs/operators";
import { config } from "@app/core/smartadmin.config";
import * as moment from 'moment'
import { RulesService } from './rules.service';


import { ClientModel } from "../models/clientmodels/client.model";
import { ClientInfoModel } from "../models/clientmodels/clientinfo.model";
import { ClientTopupModel } from "../models/clientmodels/clienttopup.model";
import { ClientTopupResponseModel } from "../models/clientmodels/clienttopupresponse.model";
import { ProductListResponseModel } from './../models/productmodels/productlistresponse.model';
import { VendorModel } from "../models/vendormodels/vendor.model";
import { ProductModel } from "../models/productmodels/product.model";
import { UserModel } from "../models/usermodels/user.model";
import { PortalModel } from "../models/clientmodels/portal.model";
import { RoleModel } from "../models/accountmodels/role.model";
import { UserGroupModel } from "../models/clientmodels/usergroup.model";
import { LocationModel } from '../models/common/location.model';
import { VendorRegistrationStateModel } from '../models/ecomonymodels/vendorregistrationstate.model';
import { LocationLookupModel } from '../models/location/locationlookup.model';
import { CoachModel } from "../models/clientmodels/coach.model";
import { ClientVendorModel } from '../models/clientmodels/clientvendor.model';
import { PrinterModel } from '../models/printermodels/printer.model';
import { EconomyModel } from '../models/ecomonymodels/economy.model';
import { DeviceModel } from '../models/device/device.model';
import { ClientVendorStatsModel } from '../models/stats/clientvendorstats.model';
import { AccountAddModel } from '../models/accountmodels/accountadd.model';
import { VoucherModel } from '../models/vouchermodels/voucher.model';
import { ClientStatsModel } from '../models/clientmodels/clientstats.model';
import { ClientIntegrationModel } from '../models/clientmodels/clientIntegration.model';
import { UserVoucherUpdateModel } from '../models/vouchermodels/uservoucherupdate.model';
import { UserVoucherModel } from '../models/vouchermodels/uservoucher.model';
import { ClaimStateModel } from '../models/clientmodels/claimstate.model';
import { ResetPasswordModel } from '../models/resetpassword.model';
import { GroupVoucherModel } from '../models/vouchermodels/groupvoucher.model';
import { GroupVoucherUpdateModel } from '../models/vouchermodels/groupvoucherupdate.model';
import { ClientDetailModel } from '../models/clientmodels/clientdetail.model';
import { AccountLookupModel } from '../models/accountmodels/account-lookup.model';
import { PromotionModel } from '../models/promotions/promotion.model';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { PrintInfoModel } from '../models/printermodels/printinfo.model';
import { TaskLookupModel } from '../models/tasks/tasklookup.model';
import { TaskModel } from '../models/tasks/task.model';
import { GroupTaskModel } from '../models/tasks/grouptask.model';
import { GroupTaskUpdateModel } from '../models/tasks/grouptaskupdate.model';
import { TaskBaseModel } from '../models/tasks/taskbase.model';
import { UserTaskListModel } from '../models/tasks/usertastlist.model';
import { AccountTaskUpdateModel } from '../models/tasks/accounttaskupdate.model';
import { ClientEventModel } from '../models/clientmodels/client_event.model';
import { ClientThemeModel } from '../models/clientmodels/clienttheme.model';
import { ImageModel } from '../models/image/image.model';
import { env } from 'process';



@Injectable()
export class ClientService {
  public subscribed: boolean;

  private hasClient = false;
  public clientSubject = new BehaviorSubject<ClientModel>(null);
  public currentClient: ClientModel;
  public isCookieAllowed = false;

  constructor(@Inject(DOCUMENT) private document,
    private router: Router,
    private http: HttpClient,
    private rulesService: RulesService) {
    this.subscribed = false;
    // console.log("Called it");
  }


  public getEconomies() {
    var urlString = "/api/client/geteconomies";
    return new Observable<Array<EconomyModel>>(subscriber => {
      this.http.get<Array<EconomyModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  private appendGaTrackingCode(code) {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
       
        ga('create', '` + code + `', 'auto');
      `;
      var scr = document.head.getElementsByTagName("script");
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  public logout() {
    this.hasClient = false;
    this.currentClient = null;
    this.clientSubject.next(null);
    // console.log("client logout");
  }

  public getStomp(): Observable<StompConfig> {

    return new Observable<StompConfig>(subscriber => {
      this.clientSubject.subscribe(data => {
        if (data == null) {
          return;
        }
        subscriber.next();
      })
    });
  }

  public getClient(): Observable<ClientModel> {
    if (!this.hasClient) {
      this.getClientByLocalURI();
    }
    return this.clientSubject.asObservable();
  }

  public getClientByLocalURI() {
    if (environment.portalurl) {
      this.getClientbyURI(environment.portalurl)
     }
     else {
       this.getClientbyURI(this.document.location.hostname)
     }
  }

  //Cleanup anything that is not necessarye
  public cleanupConfigData() {
    this.currentClient.queueConfig = null;
  }


  public getClientbyURI(uri: string) {
    let that = this;
    that.hasClient = true;
    var url = config.TRIOSAPI + '/api/client/getclient?uri=' + uri;
    this.http.get<ClientModel>(url)
      .pipe(
        map(response => {
          return response;
        }
        ))
      .subscribe(
        data => {
          if ((data == null) || (data == undefined))
            return;

          try {
            if (data.versions.uiVersion == null || data.versions.uiVersion == '') {
              if (data.productionVersion.trim() != config.version.trim()) {
                DialogUtility.alert({
                  title: 'New App Version Available!',
                  content: "Please click  <mark text-danger>OK</mark> to update the application.<br/><br/>" +
                    "If this fails please close the browser and reopen it to load the latest version.<br/><br/>" +
                    "<small class='text-muted'>" + config.version + " <i class='fa fa-long-arrow-right'></i> " + data.productionVersion + "</small>",
                  okButton: { text: 'OK', click: () => { window.location.reload(); } },
                  showCloseIcon: true,
                  closeOnEscape: true,
                  animationSettings: { effect: 'Zoom' }
                });
              }
            }
            else {
              if (data.versions.uiVersion.trim() != config.version.trim()) {
                DialogUtility.alert({
                  title: 'New App Version Available!!',
                  content: "Please click  <mark text-danger>OK</mark> to update the application.<br/><br/>" +
                    "If this fails please close the browser and reopen it to load the latest version.<br/><br/>" +
                    "<small class='text-muted'>" + config.version + " <i class='fa fa-long-arrow-right'></i> " + data.versions.uiVersion + "</small>",
                  okButton: { text: 'OK', click: () => { window.location.reload(); } },
                  showCloseIcon: true,
                  closeOnEscape: true,
                  animationSettings: { effect: 'Zoom' }
                });
              }
            }
          }
          catch (e) {

          }
          if ((data.analyticsCode == null) || (data.analyticsCode == undefined) || (data.analyticsCode == '')) {
            that.appendGaTrackingCode(environment.googleAnalyticsCode);
          }
          else {
            //that.appendGaTrackingCode("UA-7458436-6");
            that.appendGaTrackingCode(data.analyticsCode);
          }
          config.siteURL = 'assets/img/favicon/favicon.ico';
          that.hasClient = true;
          that.currentClient = data;
          if (that.currentClient.clientLogo == null)
            that.currentClient.clientLogo = "assets/img/logo.png";
          if (that.currentClient.portal == null) {
            that.currentClient.portal = new PortalModel();
          }
          that.clientSubject.next(that.currentClient);
          that.rulesService.setClientRules(data);

          const link: any = document.querySelector(`link[rel*='icon']`) || document.createElement('link')
          link.type = 'image/x-icon'
          link.rel = 'shortcut icon'

          if (1 == 1) {
            link.href = that.currentClient.clientLogo || 'base64'
          } else {
            link.href = 'url' || 'base64'
          }
          document.getElementsByTagName('head')[0].appendChild(link)
        },
        response => {
          this.clientSubject.error(response);
        }
      );
  }


  public getClientById(clientId: number) {
    let that = this;
    return new Observable<ClientModel>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + '/api/client/getclientbyid?clientId=' + clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            if ((data === null) || (data === undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getPrintInfo(clientId: number) {
    let that = this;
    return new Observable<PrintInfoModel>(subscriber => {
      this.http.get<PrintInfoModel>(config.TRIOSAPI + '/api/client/getclientprintinfo?clientId=' + clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            if ((data === null) || (data === undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public getIntegration(clientId: number, integrationType: string) {
    let that = this;
    return new Observable<ClientIntegrationModel>(subscriber => {
      this.http.get<ClientIntegrationModel>(config.TRIOSAPI + '/api/client/getclientIntegration?clientId=' + clientId + '&integrationtype=' + integrationType)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            if ((data === null) || (data === undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public updateIntegration(clientId: number, integrationType: string, data: ClientIntegrationModel) {
    return new Observable<ClientIntegrationModel>(subscriber => {
      this.http.post<ClientIntegrationModel>(config.TRIOSAPI + '/api/client/updateclientIntegration?clientId=' + clientId + '&integrationtype=' + integrationType, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });

  }

  public getClientList(filter: String) {
    return new Observable<ClientInfoModel[]>(subscriber => {
      this.http.get<ClientInfoModel[]>(config.TRIOSAPI + '/api/client/clientlist?filter=' + filter)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            this.clientSubject.next(null);
            subscriber.error(response);
          }
        );
    });
  }



  public getClientStats() {
    return new Observable<ClientStatsModel>(subscriber => {
      this.http.get<ClientStatsModel>(config.TRIOSAPI + '/api/client/getclientstats?clientId=' + this.currentClient.clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  // public getVendorList(filter : String){
  //   return new Observable<VendorModel[]>(subscriber => {
  //     this.http.get<VendorModel[]>(config.TRIOSAPI +'/api/client/vendorlist?clientId=' + this.currentClient.clientId + '&filter=' + filter )
  //      .pipe(
  //        map(response => {
  //          return response;
  //        }
  //       ))
  //       .subscribe(
  //         data => {
  //           subscriber.next(data);
  //         },
  //         response => {
  //           subscriber.error(response);
  //         }
  //       );
  //   });
  // }

  public getProductList(filter: String) {
    return new Observable<ProductListResponseModel>(subscriber => {
      this.http.get<ProductListResponseModel>(config.TRIOSAPI + '/api/client/productlist?clientId=' + this.currentClient.clientId + '&filter=' + filter)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public geClientRoles() {
    return new Observable<Array<RoleModel>>(subscriber => {
      this.http.get<Array<RoleModel>>(config.TRIOSAPI + '/api/client/getRoles?clientId=' + this.currentClient.clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert('Got Roles error');
            subscriber.error(response);
          }
        );
    });
  }

  public geClientGroups() {
    return new Observable<Array<UserGroupModel>>(subscriber => {
      this.http.get<Array<UserGroupModel>>(config.TRIOSAPI + '/api/client/getUserGroups?clientId=' + this.currentClient.clientId + "&Search=")
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert('Got Groups error');
            subscriber.error(response);
          }
        );
    });
  }

  public updateAccountPassword(clientId: number, passwordMaint: PasswordMaintModel) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/updateaccountpassword?clientId=' + clientId, passwordMaint)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });

  }

  public updateClaim(clientId: number, userId: string, claimSet: string) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/updateaccountclaim?clientId=' + clientId + '&userId=' + userId + "&claim=" + claimSet, "")
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });

  }

  public lookupAccounts(clientId: number, filter: string) {
    var urlString = "/api/client/lookupAccounts?clientId=" + clientId + "&filter=" + filter;
    return new Observable<AccountLookupModel>(subscriber => {
      this.http.get<AccountLookupModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getRegistrationRoles() {
    return new Observable<Array<RoleModel>>(subscriber => {
      this.http.get<Array<RoleModel>>(config.TRIOSAPI + '/api/client/getAccountCreationRoles?clientId=' + this.currentClient.clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert('Got Roles error');
            subscriber.error(response);
          }
        );
    });
  }

  public updateAccountCreationRoles(roles: Array<RoleModel>) {
    return new Observable<Array<RoleModel>>(subscriber => {
      this.http.post<Array<RoleModel>>(config.TRIOSAPI + '/api/client/updateaccountcreationroles?clientId=' + this.currentClient.clientId, roles)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });

  }

  public updateGroupRoles(groupId: string, roles: Array<any>, action: string) {
    return new Observable(subscriber => {
      let sendURL = config.TRIOSAPI + '/api/client/updategrouproles?clientId=' + this.currentClient.clientId + '&groupId=' + groupId + '&action=' + action
      this.http.post(sendURL, roles)
        .subscribe(
          data => {
            subscriber.next();
          },
          response => {
            subscriber.error(response);
          }
        );
    });

  }

  public postClientTopup(topupData: ClientTopupModel) {
    return new Observable<ClientTopupResponseModel>(subscriber => {
      this.http.post<ClientTopupResponseModel>(config.TRIOSAPI + '/api/clientBanking/topupAccount', topupData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public postClientStartPayment(topupData: ClientTopupModel) {
    return new Observable<ClientTopupResponseModel>(subscriber => {
      this.http.post<ClientTopupResponseModel>(config.TRIOSAPI + '/api/clientBanking/startpayment', topupData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getClientTopupStatus(depositId: string) {
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + '/api/clientBanking/topupAccountStatus?depositId=' + depositId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateClient(clientData: ClientModel) {
    return new Observable<ClientModel>(subscriber => {
      this.http.post<ClientModel>(config.TRIOSAPI + '/api/client/updateClient', clientData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateClientPortal(clientData: ClientModel) {
    return new Observable<ClientModel>(subscriber => {
      this.http.post<ClientModel>(config.TRIOSAPI + '/api/client/updateClientPortal', clientData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public deleteAccount(clientId: number, userName: string) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/deleteaccount?clientid=' + clientId + '&userName=' + userName, null)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public resyncAccount(clientId: number, userName: string) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/resyncaccount?clientid=' + clientId + '&userName=' + userName, null)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public resyncAllAccounts(clientId: number) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/resyncallaccounts?clientid=' + clientId, null)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public removeCard(clientId: number, userName: string) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/removecard?clientid=' + clientId + '&userName=' + userName, null)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getClientUserGroups(search = "") {
    return new Observable<Array<UserGroupModel>>(subscriber => {
      this.http.get<Array<UserGroupModel>>(config.TRIOSAPI + '/api/client/getUserGroups?clientId=' + this.currentClient.clientId + '&search=' + search)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert('Got Use Groups error');
            subscriber.error(response);
          }
        );
    });
  }

  public updateUserGroup(groupData: UserGroupModel) {
    return new Observable<ClientModel>(subscriber => {
      this.http.post<ClientModel>(config.TRIOSAPI + '/api/client/updateUserGroup?clientId=' + this.currentClient.clientId, groupData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public deleteUserGroup(groupId) {
    return new Observable<any>(subscriber => {
      this.http.delete<any>(config.TRIOSAPI + '/api/client/deleteUserGroup?clientId=' + this.currentClient.clientId + '&groupId=' + groupId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public loadGroupAccounts(groupId, filter) {
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + '/api/client/getUserGroupAccounts?clientId=' + this.currentClient.clientId + '&groupId=' + groupId + '&filter=' + filter)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public loadNonGroupAccounts(groupId, filter) {
    return new Observable<any>(subscriber => {
      this.http.get<any>(config.TRIOSAPI + '/api/client/getUserNonGroupAccounts?clientId=' + this.currentClient.clientId + '&groupId=' + groupId + '&filter=' + filter)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public removeUserFromGroup(groupId, userId) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/removeUserFromGroup?clientId=' + this.currentClient.clientId + '&groupId=' + groupId + '&userId=' + userId, null)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public addUsersToGroup(groupId, users: Array<UserModel>) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/addUsersToGroup?clientId=' + this.currentClient.clientId + '&groupId=' + groupId, users)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public setusergroupactive(groupId: string, active: boolean) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/setusergroupactive?clientId=' + this.currentClient.clientId + '&groupId=' + groupId + "&active=" + active, "")
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public addUser(data: AccountAddModel) {
    return new Observable<UserModel>(subscriber => {
      this.http.post<UserModel>(config.TRIOSAPI + '/api/client/adduser', data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getClientLocations(search = "") {
    return new Observable<Array<LocationModel>>(subscriber => {
      this.http.get<Array<LocationModel>>(config.TRIOSAPI + '/api/client/getlocations?clientId=' + this.currentClient.clientId + '&search=' + search)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            alert('Got Location error');
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getClientLocation(locationId) {
    return new Observable<LocationModel>(subscriber => {
      this.http.get<LocationModel>(config.TRIOSAPI + '/api/client/getlocation?clientId=' + this.currentClient.clientId + '&locationId=' + locationId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            alert('Got Location error');
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public getPrinters() {
    return new Observable<Array<PrinterModel>>(subscriber => {
      this.http.get<Array<PrinterModel>>(config.TRIOSAPI + '/api/client/getprinters?clientId=' + this.currentClient.clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getAllPrinters(clientId: number, searchString: string) {
    return new Observable<Array<PrinterModel>>(subscriber => {
      this.http.get<Array<PrinterModel>>(config.TRIOSAPI + '/api/client/getprinters?clientId=' + clientId + "&search=" + searchString)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public updateClientPrinter(clientId: number, printerData: PrinterModel) {
    return new Observable<PrinterModel>(subscriber => {
      this.http.post<PrinterModel>(config.TRIOSAPI + '/api/client/updatePrinter?clientId=' + clientId, printerData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public deleteLocation(locationId) {
    return new Observable<Array<LocationModel>>(subscriber => {
      this.http.delete<Array<LocationModel>>(config.TRIOSAPI + '/api/client/deletelocation?clientId=' + this.currentClient.clientId + '&locationId=' + locationId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public updateLocation(data: LocationModel) {
    return new Observable<LocationModel>(subscriber => {
      this.http.post<LocationModel>(config.TRIOSAPI + '/api/client/updatelocation?clientId=' + this.currentClient.clientId, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public lookupLocation(data: LocationLookupModel) {
    return new Observable<Array<LocationModel>>(subscriber => {
      this.http.post<Array<LocationModel>>(config.TRIOSAPI + '/api/client/lookuplocation', data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public checkPendingVendorRegistrations(clientId: number) {
    return new Observable<Array<VendorRegistrationStateModel>>(subscriber => {
      this.http.get<Array<VendorRegistrationStateModel>>(config.TRIOSAPI + '/api/client/getpendingvendorregistrations?clientId=' + clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            alert('Got Location error');
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public getCoaches(clientId: number) {
    return new Observable<Array<CoachModel>>(subscriber => {
      this.http.get<Array<CoachModel>>(config.TRIOSAPI + '/api/client/getcoaches?clientId=' + clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }



  public addCoach(clientId: number, data: CoachModel) {
    return new Observable<Array<CoachModel>>(subscriber => {
      this.http.post<Array<CoachModel>>(config.TRIOSAPI + '/api/client/addcoach?clientId=' + clientId, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public deleteCoach(clientId: number, coachId: string) {
    return new Observable<Array<CoachModel>>(subscriber => {
      this.http.delete<Array<CoachModel>>(config.TRIOSAPI + '/api/client/deletecoach?clientId=' + clientId + '&coachId=' + coachId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getClientVendors(clientId: number, search: string = "") {
    return new Observable<Array<ClientVendorModel>>(subscriber => {
      this.http.get<Array<ClientVendorModel>>(config.TRIOSAPI + '/api/client/getclientvendors?clientId=' + clientId + '&search=' + search)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            alert('Get vendor error');
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getAllClientVendors(clientId: number) {
    return new Observable<Array<ClientVendorModel>>(subscriber => {
      this.http.get<Array<ClientVendorModel>>(config.TRIOSAPI + '/api/client/getclientvendors?clientId=' + clientId + '&search=all')
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            alert('Get vendor error');
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getClientVendor(clientId: number, vendorId: number) {
    return new Observable<ClientVendorModel>(subscriber => {
      this.http.get<ClientVendorModel>(config.TRIOSAPI + '/api/client/getclientvendor?clientId=' + clientId + '&vendorId=' + vendorId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            alert('Get vendor error');
            subscriber.error(errorResponse);
          }
        );
    });
  }



  public getVouchers(clientId: number, searchString: string) {
    return new Observable<Array<VoucherModel>>(subscriber => {
      this.http.get<Array<VoucherModel>>(config.TRIOSAPI + '/api/client/getvouchers?clientId=' + clientId + '&search=' + searchString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public getUserVouchers(clientId: number, userId: string, available: boolean, used: boolean): Observable<Array<UserVoucherModel>> {
    return new Observable<Array<UserVoucherModel>>(subscriber => {
      this.http.get<Array<UserVoucherModel>>(config.TRIOSAPI + '/api/client/getuservouchers?clientId=' + clientId + '&userid=' + userId + '&version=2' + '&available=' + available + '&used=' + used)
        .pipe(
          map(response => {
            response.forEach(element => {
              element.expiryDate = moment(moment.utc(element.expiryDate).toDate()).format("DD MMM YYYY HH:mm")
            });
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        )
    });
  }


  public getUserGroupVouchers(clientId: number, groupId: string, available: boolean, used: boolean): Observable<Array<GroupVoucherModel>> {
    return new Observable<Array<GroupVoucherModel>>(subscriber => {
      this.http.get<Array<GroupVoucherModel>>(config.TRIOSAPI + '/api/client/getusergroupvouchers?clientId=' + clientId + '&groupid=' + groupId + '&version=2' + '&available=' + available + '&used=' + used)
        .pipe(
          map(response => {
            response.forEach(element => {
              element.expiryDate = moment(moment.utc(element.expiryDate).toDate()).format("DD MMM YYYY HH:mm")
            });
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        )
    });
  }

  public updateUserVoucher(clientId: number, vendorId: number, data: UserVoucherUpdateModel): Observable<Array<UserVoucherModel>> {
    return new Observable<Array<UserVoucherModel>>(subscriber => {
      this.http.post<Array<UserVoucherModel>>(config.TRIOSAPI + '/api/client/updateuservoucher?clientId=' + clientId + '&vendorId=' + vendorId, data)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public executePromotion(clientId: number, userId: string, promotionId: string) {

    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/executePromotion?clientId=' + clientId + '&userid=' + userId + '&promotionId=' + promotionId, "HELLO")
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public updateUserGroupVoucher(clientId: number, vendorId: number, data: GroupVoucherUpdateModel): Observable<Array<GroupVoucherModel>> {
    return new Observable<Array<GroupVoucherModel>>(subscriber => {
      this.http.post<Array<GroupVoucherModel>>(config.TRIOSAPI + '/api/client/updateusergroupvoucher?clientId=' + clientId + '&vendorId=' + vendorId, data)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public addDevice(clientId: number, deviceRegistrationId: string) {
    return new Observable<Array<DeviceModel>>(subscriber => {
      this.http.post<Array<DeviceModel>>(config.TRIOSAPI + '/api/client/adddevice?clientId=' + clientId + '&deviceId=' + deviceRegistrationId, "")
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public getDevices(clientId: number, searchString: string) {
    return new Observable<Array<DeviceModel>>(subscriber => {
      this.http.get<Array<DeviceModel>>(config.TRIOSAPI + '/api/client/getdevices?clientId=' + clientId + '&search=' + searchString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public updateDevice(clientId: number, deviceData: DeviceModel) {
    return new Observable<Array<DeviceModel>>(subscriber => {
      this.http.post<Array<DeviceModel>>(config.TRIOSAPI + '/api/client/updatedevice?clientId=' + clientId, deviceData)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public getVendorStats(clientId: number) {
    return new Observable<ClientVendorStatsModel>(subscriber => {
      this.http.get<ClientVendorStatsModel>(config.TRIOSAPI + '/api/client/getvendorstats?clientId=' + clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getVendorProducts(vendorId: number, filter: string) {
    return new Observable<Array<ProductModel>>(subscriber => {
      var urlString = config.TRIOSAPI + "/api/vendor/getvendorproducts?vendorId=" + vendorId + '&filter=' + filter;
      this.http.get<Array<ProductModel>>(urlString)
        .subscribe(
          data => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  checkEmailClaim(clientId: number, emailAddress: string) {
    return new Observable<ClaimStateModel>(subscriber => {
      this.http.get<ClaimStateModel>(config.TRIOSAPI + '/api/client/checkemailclaim?clientId=' + clientId + '&emailAddress=' + emailAddress)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  setEmailClaim(clientId: number, claimData: ResetPasswordModel) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/setemailclaim?clientId=' + clientId, claimData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  isADAuth() {
    if ((this.currentClient === null) || (this.currentClient === undefined))
      return false;
    return this.currentClient.adLogin === true;
  }

  postSplashFile(clientId: number, fileToUpload: File, fileType: String) {
    return new Observable<ClientModel>(subscriber => {
      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append('clientId', clientId.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<ClientModel>(config.TRIOSAPI + "/api/client/uploadSplashImage?clientId=" + clientId, formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  clearSplashFile(clientId: number, fileToUpload: File, fileType: String) {
    return new Observable<ClientModel>(subscriber => {
      const formData: FormData = new FormData();
      formData.append('clientId', clientId.toString());
      formData.append("fileType", fileType.toString());

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }

      return this.http.post<ClientModel>(config.TRIOSAPI + "/api/client/uploadSplashImage?clientId=" + clientId, formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }


  public getPromotions(clientId: number, searchString: string) {
    return new Observable<Array<PromotionModel>>(subscriber => {
      this.http.get<Array<PromotionModel>>(config.TRIOSAPI + '/api/client/getpromotions?clientId=' + clientId + '&search=' + searchString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public postPromoUser(clientId: number, promoUserData: any) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/client/updatePromotionUser?clientId=' + clientId, promoUserData)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }


  public lookupTasks(clientId: number, userId: string, filter: string) {
    var urlString = "/api/task/lookupTasks?clientId=" + clientId + "&userId=" + userId + "&filter=" + filter;
    return new Observable<TaskLookupModel>(subscriber => {
      this.http.get<TaskLookupModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public lookupTaskAccounts(clientId: number, taskId: string, filter: string) {
    var urlString = "/api/task/lookuptaskaccounts?clientId=" + clientId + "&taskId=" + taskId + "&filter=" + filter;
    return new Observable<Array<UserTaskListModel>>(subscriber => {
      this.http.get<Array<UserTaskListModel>>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getTask(clientId: number, taskId: string) {
    var urlString = "/api/task/getTask?clientId=" + clientId + "&taskId=" + taskId;
    return new Observable<TaskBaseModel>(subscriber => {
      this.http.get<TaskBaseModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateTask(clientId: number, taskData: TaskModel) {
    var urlString = "/api/task/updateTask?clientId=" + clientId;
    return new Observable<TaskModel>(subscriber => {
      this.http.post<TaskModel>(config.TRIOSAPI + urlString, taskData)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public deleteTask(clientId: number, taskId: string) {
    var urlString = "/api/task/deleteTask?clientId=" + clientId + "&taskId=" + taskId;
    return new Observable<TaskModel>(subscriber => {
      this.http.delete<TaskModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateUserGroupTask(clientId: number, data: GroupTaskUpdateModel) {
    return new Observable<TaskModel>(subscriber => {
      this.http.post<TaskModel>(config.TRIOSAPI + '/api/client/updateusergrouptask?clientId=' + clientId, data)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public updateAccountTask(clientId: number, data: AccountTaskUpdateModel) {
    return new Observable<TaskModel>(subscriber => {
      this.http.post<TaskModel>(config.TRIOSAPI + '/api/client/updateaccounttask?clientId=' + clientId, data)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        );
    });
  }

  public getUserGroupTasks(clientId: number, groupId: string, available: boolean, used: boolean): Observable<Array<GroupTaskModel>> {
    return new Observable<Array<GroupTaskModel>>(subscriber => {
      this.http.get<Array<GroupTaskModel>>(config.TRIOSAPI + '/api/client/getusergrouptasks?clientId=' + clientId + '&groupid=' + groupId + '&version=2' + '&available=' + available + '&used=' + used)
        .pipe(
          map(response => {
            response.forEach(element => {
              element.startDate = moment(moment.utc(element.startDate).toDate()).format("DD MMM YYYY HH:mm")
              element.endDate = moment(moment.utc(element.endDate).toDate()).format("DD MMM YYYY HH:mm")
            });
            return response;
          })
        )
        .subscribe(
          data => {
            subscriber.next(data);
          },
          errorResponse => {
            subscriber.error(errorResponse);
          }
        )
    });
  }

  public getClientEvent(clientId) {
    var urlString = "/api/client/getclientEvent?clientId=" + clientId;
    return new Observable<ClientEventModel>(subscriber => {
      this.http.get<ClientEventModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getClientTheme(clientId: number) {
    var urlString = "/api/client/getclienttheme?clientId=" + clientId;
    return new Observable<ClientThemeModel>(subscriber => {
      this.http.get<ClientThemeModel>(config.TRIOSAPI + urlString)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }



  public updateEventImage(eventData: ClientEventModel, imageType: string, fileToUpload: File, sizeX: number, sizeY: number, fileType: String,) {
    return new Observable<ClientEventModel>(subscriber => {

      const formData: FormData = new FormData();
      formData.append("fileKey", fileToUpload, fileToUpload.name);
      formData.append("fileType", fileType.toString());
      formData.append("sizeX", sizeX.toString());
      formData.append("sizeY", sizeY.toString());
      formData.append("imageType", imageType);
      formData.append("eventData", JSON.stringify(eventData));

      const requestOptions: Object = {
        /* other options here */
        //responseType: 'text'
      }
      var urlString = config.TRIOSAPI + "/api/events/uploadImage";
      return this.http.post<ClientEventModel>(urlString, formData, requestOptions)
        .subscribe(data => {
          subscriber.next(data);
        },
          error => {
            subscriber.error(error);
          })
    });
  }

  public addClientImage(formData: FormData) {
    let that = this;
    return new Observable<ClientThemeModel>(subscriber => {
      var url = config.TRIOSAPI + '/api/client/addimage';
      that.http.post<ClientThemeModel>(url, formData)
        .subscribe({
          next: (data: ClientThemeModel) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }

        }
        );
    });
  }

  public updateTheme(clientId: number, themeData: ClientThemeModel) {
    let that = this;
    return new Observable<ClientThemeModel>(subscriber => {
      var url = config.TRIOSAPI + '/api/client/updateTheme?clientId=' + clientId;
      that.http.post<ClientThemeModel>(url, themeData)
        .subscribe({
          next: (data: ClientThemeModel) => {
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }

        }
        );
    });
  }

  public removeClientImage(clientId: number, imageData: ImageModel) {
    let that = this;
    return new Observable<any>(subscriber => {
      that.http.post<any>(config.TRIOSAPI + '/api/client/removeimage?clientId=' + clientId, imageData)

        .subscribe({
          next: (data: any) => {
            subscriber.next("true");
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }

        }
        );
    });
  }

  isMobileDevice() {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
      return true;
    }
    else {
      return false;
    }
  }


}



import { Injectable } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class PwaService {

  public promptEvent: any;

  constructor(private updateService: SwUpdate) {

    window.addEventListener('beforeinstallprompt', event => {
      event.preventDefault();
      this.promptEvent = event;
    });
    if (updateService.isEnabled) {
      interval(2 * 60 * 60).subscribe(() => {
        updateService.checkForUpdate().then(() => console.log('...up'));
      })
    }

    updateService.available.subscribe((event: UpdateAvailableEvent) => {
      console.log('...up1'  +event.type)
       if (
         event.type === 'UPDATE_AVAILABLE' &&
         confirm(
           `New version ${(event as UpdateAvailableEvent).available.hash
           } available. Load New Version...?`
         )
       ) {
        this.updateService.activateUpdate().then(() => {
          console.log('updated This  now  ');
          window.location.reload();
        });
      }
    });

  }

  // #handleUpdates() {
  //   this.updateService.available.subscribe((event: UpdateAvailableEvent) => {
  //     console.log(event);
  //     if (
  //       event.type === 'UPDATE_AVAILABLE' &&
  //       confirm(
  //         `New version ${(event as UpdateAvailableEvent).available.hash
  //         } available. Load New Version?`
  //       )
  //     ) {
  //       window.location.reload();
  //     }
  //   });
  //   const interval = setInterval(async () => {
  //     await this.updateService.checkForUpdate().then(x => {
  //        const result = this.updateService.activateUpdate();
  //        clearInterval(interval);
  //     });
       
  //   }, 60000);

  //   // this.updateService..unrecoverable.subscribe(
  //   //   (event: UnrecoverableStateEvent) => {
  //   //     alert('Error reason : ' + event.reason);
  //   //   }
  //   // );
  // } 

  askUserToUpdate() {
    return false;
  }
}
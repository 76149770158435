import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { Subscription } from "rxjs/Subscription";
import { map, filter, scan } from "rxjs/operators";
import { config } from "../smartadmin.config";

import { EconomyModel } from '../models/ecomonymodels/economy.model';
import { DepartmentModel } from '../models/department/department.model';
import { LocationModel } from '../models/common/location.model';

import { EconomyDetailModel } from '../models/ecomonymodels/economydetail.model';
import { VendorRegistrationStateModel } from "../models/ecomonymodels/vendorregistrationstate.model";
import { VendorRegistrationModel } from '../models/ecomonymodels/vendorregistration.model';
import { EconomyClientModel } from "../models/ecomonymodels/economyclient.model";
import { EconomyVendorModel } from "../models/ecomonymodels/economyvendor.model";
import { EconomyFeaturedVendorModel } from "../models/ecomonymodels/economyfeaturedvendor.model";
import { EconomyThemesModel } from "../models/ecomonymodels/economytheme.model";
import { EconomyBankingModel } from "../models/ecomonymodels/economybanking.model";
import { EconomyVendorStatsModel } from "../models/stats/economyvendorstats.model";

@Injectable()
export class EconomyService {

  private currentEconomy : EconomyDetailModel;

  constructor(@Inject(DOCUMENT) private document,
    private router: Router,
    private http: HttpClient) {

  }

  public getEconomyByOwnerId(ownerId:string) {
    return new Observable<EconomyModel>(subscriber => {
      this.http.get<EconomyModel>(config.TRIOSAPI + '/api/economy/geteconomybyownerid?ownerId=' + ownerId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert('Got error');
            subscriber.error(response);
          }
        );
    });
  }

  public getEconomyByClientId(clientId:number) {
    return new Observable<EconomyDetailModel>(subscriber => {
      this.http.get<EconomyDetailModel>(config.TRIOSAPI + '/api/economy/geteconomybyclientid?clientId=' + clientId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            this.currentEconomy = data;
            subscriber.next(data);
          },
          response => {
            alert('Got error');
            subscriber.error(response);
          }
        );
    });
  }
  

  public getEconomyBanking(economyId:string) {
    return new Observable<EconomyBankingModel>(subscriber => {
      this.http.get<EconomyBankingModel>(config.TRIOSAPI + '/api/economy/geteconomybanking?economyId=' + economyId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert('Got error');
            subscriber.error(response);
          }
        );
    });
  }
  

  public updateEconomy(economyData : EconomyDetailModel) {
    return new Observable<EconomyDetailModel>(subscriber => {
      this.http.post<EconomyDetailModel>(config.TRIOSAPI + '/api/economy/updateEconomy', economyData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }
  

  public updateEconomyBanking(economyBankingData : EconomyBankingModel) {
    return new Observable<EconomyDetailModel>(subscriber => {
      this.http.post<EconomyDetailModel>(config.TRIOSAPI + '/api/economy/updateEconomyBanking', economyBankingData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateEconomyTheme(economyThemeData : EconomyThemesModel) {
    return new Observable<EconomyDetailModel>(subscriber => {
      this.http.post<EconomyDetailModel>(config.TRIOSAPI + '/api/economy/updateEconomyTheme', economyThemeData)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public getLocations(economyId:string) {
    return new Observable<Array<LocationModel>>(subscriber => {
      this.http.get<Array<LocationModel>>(config.TRIOSAPI + '/api/economy/geteconomylocations?economyId=' + economyId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert('Got error');
            subscriber.error(response);
          }
        );
    });
  }


  public getDepartments(economyId, search = "") {
    return new Observable<Array<DepartmentModel>>(subscriber => {
      this.http.get<Array<DepartmentModel>>(config.TRIOSAPI + '/api/economy/getdepartments?economyId=' + economyId + '&search=' + search)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert('Got Location error');
            subscriber.error(response);
          }
        );
    });
  }

  public getDepartment(economyId, departmentId) {
    return new Observable<DepartmentModel>(subscriber => {
      this.http.get<DepartmentModel>(config.TRIOSAPI + '/api/economy/getdepartment?economyId=' + economyId + '&departmentId=' + departmentId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            alert('Got Department error');
            subscriber.error(response);
          }
        );
    });
  }

  public deleteDepartment(economyId, departmentId) {
    return new Observable<Array<DepartmentModel>>(subscriber => {
      this.http.delete<Array<DepartmentModel>>(config.TRIOSAPI + '/api/economy/deletedepartment?economyId=' + economyId + '&departmentId=' + departmentId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateDepartment(economyId: string, data : DepartmentModel) {
    return new Observable<DepartmentModel>(subscriber => {
      this.http.post<DepartmentModel>(config.TRIOSAPI + '/api/economy/updatedepartment?economyId=' + economyId, data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  
  public checkRegistrationState(economyId : string) {
    return new Observable<VendorRegistrationStateModel>(subscriber => {
      this.http.get<VendorRegistrationStateModel>(config.TRIOSAPI + '/api/economy/getvendorregistrationstate?economyId=' + economyId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }


  public submitRegistration(data : VendorRegistrationModel) {
    return new Observable<VendorRegistrationStateModel>(subscriber => {
      this.http.post<VendorRegistrationStateModel>(config.TRIOSAPI + '/api/economy/submitvendorregistration', data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public lookupClients(economyId:string, filter:string) {
    return new Observable<Array<EconomyClientModel>>(subscriber => {
      this.http.get<Array<EconomyClientModel>>(config.TRIOSAPI + '/api/economy/lookupclients?economyId=' + economyId + "&filter=" + filter)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public lookupVendors(economyId:string, filter:string) {
    return new Observable<Array<EconomyVendorModel>>(subscriber => {
      this.http.get<Array<EconomyVendorModel>>(config.TRIOSAPI + '/api/economy/lookupvendors?economyId=' + economyId + "&filter=" + filter)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public updateFeaturedVendor(data: EconomyFeaturedVendorModel) {
    return new Observable<any>(subscriber => {
      this.http.post<any>(config.TRIOSAPI + '/api/economy/updatefeaturedvendor', data)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  
  public getVenturesStats(economyId:string) {
    return new Observable<EconomyVendorStatsModel>(subscriber => {
      this.http.get<EconomyVendorStatsModel>(config.TRIOSAPI + '/api/economy/getventuresstats?economyId=' + economyId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }

  public getVendorStats(economyId:string) {
    return new Observable<EconomyVendorStatsModel>(subscriber => {
      this.http.get<EconomyVendorStatsModel>(config.TRIOSAPI + '/api/economy/getvendorstats?economyId=' + economyId)
        .pipe(
          map(response => {
            return response;
          }
          ))
        .subscribe(
          data => {
            subscriber.next(data);
          },
          response => {
            subscriber.error(response);
          }
        );
    });
  }
}



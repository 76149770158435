
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { config } from "@app/core/smartadmin.config";


import * as clientStore from '@app/core/store/client'
import * as userStore from '@app/core/store/user'

import { ClientService } from '@app/core/services/client.service';
import { UserService } from '@app/core/services/user.service';
import { NotificationService } from '@app/core/services/notification.service';

import { UserModel } from '@app/core/models/usermodels/user.model';
import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { CookieService } from 'ngx-cookie-service';


declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  private accountNumber = "";
  public coachAccount = false;
  public studentAccount = false;
  public childAccount = false;
  private subscription: Subscription;
  condition = true;

  userData: UserModel;
  clientData: ClientModel;
  headerColor = null;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private clientService: ClientService,
    private userService: UserService,
    private notificationService: NotificationService,
    private cookieService: CookieService,
    private store: Store<any>) { }

  ngOnDestroy() {
    if (this.subscription){
       this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    let that = this;
    this.subscription = this.store.pipe(
      select(clientStore.getClient)
    ).subscribe(
        (data) => {
          if (data == null)
            return;
          that.clientData = data;
        },
        errorResponse => {
        }
      );

    this.store.pipe(
      select(userStore.getUser)
      ).subscribe(
      (data: UserModel) => {
        if ((data == null) || (data == undefined))
          return;
        that.userData = data;
        that.accountNumber = that.userData.userAccountNumber.toString();
      });

    this.userService.currentMessage.subscribe((data) => {
      if (data != null)
        that.toastr.success(data.notification.title, data.notification.body);
    });

    this.userService.switchedAccount.subscribe( data=>{
      that.coachAccount = that.cookieService.get('addAccountType') == 'Coach';
      that.studentAccount = that.cookieService.get('addAccountType') == 'Student';
      that.childAccount = that.cookieService.get('addAccountType') == 'Child';
      // that.coachAccount = localStorage.getItem('addAccountType') == 'Coach';
      // that.studentAccount = localStorage.getItem('addAccountType') == 'Student';
      // that.childAccount = localStorage.getItem('parentAccountType') == 'Child';
      if (that.coachAccount) {
        that.headerColor = "#"+that.clientData.economy.theme.coachBarColor;
      }
      if (that.studentAccount) {
        that.headerColor = "#"+that.clientData.economy.theme.ventureBarColor;
      }
      if (that.childAccount) {
        that.headerColor = "#"+that.clientData.economy.theme.childBarColor;
      }
    })
  }


  searchMobileActive = false;

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }
}

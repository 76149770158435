import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular"
import { Integrations } from "@sentry/tracing";
import { enableRipple } from '@syncfusion/ej2-base';

enableRipple(true);

Sentry.init({
  dsn: "https://0012ec33b1314b62a56a3f77989751e2@o534469.ingest.sentry.io/5653727",
  integrations: [
    new Integrations.BrowserTracing({
      //tracingOrigins: ["localhost", environment.apiportal],
      tracingOrigins: ["localhost", "https://triosdev.allxs.co.za", "https://topochico.allxs.co.za"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));


import { createSelector } from 'reselect';
import { AppState } from '../index';
import { PosState } from './pos.state';

import { WaitronModel } from '../../models/pos/waitron.model';


// Base product state selector function
const getPosState = (state: AppState): PosState => state.pos;

// ******************** Individual selectors ***************************
const fetchWaitron = (state: PosState) => (state.waitron as unknown) as WaitronModel;

// *************************** PUBLIC API's ****************************
export const getWaitron = createSelector(
  getPosState,
  fetchWaitron
);

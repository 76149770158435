import { Component, OnInit } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-empty-layout',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.css']
})
export class EmptyLayoutComponent implements OnInit {

  constructor(private ccService: NgcCookieConsentService) { }

  ngOnInit() {
    this.ccService.destroy();
  }

}

import { Injectable } from '@angular/core';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class SpinnerService {

  config: NgxUiLoaderConfig;

  constructor(private ngxUiLoaderService: NgxUiLoaderService) {
    this.config = this.ngxUiLoaderService.getDefaultConfig();
  }


  public showSpinner(){
    this.ngxUiLoaderService.start();
  }

  public hideSpinner(){
    this.ngxUiLoaderService.stop();
  }
}

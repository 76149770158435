import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { map, filter, scan } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment'
import { ExcelExportProperties, GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { PageSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';

import { UserService } from '@app/core/services/user.service';
import { LayoutService } from '@app/core/services/layout.service';
import { PermissionsModel } from '@app/core/models/usermodels/permissions.model';
import { ClientPermissionsModel } from '@app/core/models/clientmodels/client-permissions.model';
import { UserModel } from '@app/core/models/usermodels/user.model';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';

//const pipe_$ = { transform: '1' };

@Component({
  selector: 'account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.css']
})
export class AccountStatementComponent implements OnInit {

  @ViewChild('grid', { static: false })
  public grid: GridComponent;
  private _fromAdmin: boolean = false;
  public data: object[];
  accountData = [];
  userData: UserModel;
  userPermissions: PermissionsModel = new PermissionsModel();
  clientPermissions: ClientPermissionsModel = new ClientPermissionsModel();
  statementVisible: boolean = false;
  private isMobile = false;
  public pageSettings: PageSettingsModel;
  public selectionOptions: SelectionSettingsModel;
  public toolbarOptions: ToolbarItems[];
  public wrapSettings = { wrapMode: 'Content' };
  private mobileVisible = [false, false, true, false, true, false, false, false];
  private mobileWidth = [0, 0, 7, 5, 10, 10, 10, 5];
  public columnWidth = [5, 15, 70, 5, 10, 10, 10, 5];
  public columnVisible = [true, true, true, true, true, true, true, false];
  public valueHeader = "CR";
  startDate;
  endDate;
  maxDate;
  minDate;
  dateRangeValue = [new Date(), new Date()];

  @Input('accountdata') set accountdata(value) {
    if ((value === null) || (value === undefined))
      return;

    this.userData = value;
    if ((this.startDate != null) && (this.endDate != null)){
       this.loadData(this.startDate, this.endDate);
    }
  }

  @Input('permissions') set permissions(value: PermissionsModel) {
    if ((value === null) || (value === undefined))
      return;
    this.userPermissions = value;
    this.statementVisible = value.balanceVisible === true;
  }

  @Input('clientpermissions') set clientpermissions(value: ClientPermissionsModel) {
    if ((value === null) || (value === undefined))
      return;

    this.clientPermissions = value;
    this.statementVisible = value.canViewBalance === true;
  }

  @Input('fromAdmin') set fromAdmin(value: boolean) {
    this._fromAdmin = value;
    this.minDate = this.addDays(new Date(), -10000);
  }

  constructor(private userService: UserService,
    private currencyPipe: CurrencyPipe,
    public layoutService: LayoutService) {
    this.startDate = this.addDays(new Date(), -365);
    this.endDate = this.addDays(new Date(), 2);
    this.minDate = this.addDays(new Date(), -365);
    this.dateRangeValue = [new Date(this.addDays(new Date(), -30)), new Date(this.addDays(new Date(), 1))];
    // this.dateRangeValue = [this.startDate, this.endDate];
  }

  ngOnInit() {
    let that = this;
    this.isMobile = this.layoutService.isMobile();
    this.maxDate = this.endDate;
    if (this.isMobile) {
      this.columnWidth = this.mobileWidth;
      this.columnVisible = this.mobileVisible;
      this.valueHeader = "Value";
    }

    this.selectionOptions = { type: 'Single' };
    this.pageSettings = { pageSize: 6 };
    //this.loadData(this.startDate, this.endDate);
    this.pageSettings = { pageSizes: true, pageSize: 12 };
    this.toolbarOptions = ['ExcelExport'];
  }

  excelHeader(args: any): void {
    args.gridcell
    args.cell.width = 100;
    args.style.wrapText = true;
    args.style.width = "100px";
  }


  private getExcelExportProperties(): ExcelExportProperties {
    return {
      fileName: "accountactivity" + moment(this.startDate).format('DD MMM YYYY') + "-" + moment(this.endDate).format('DD MMM YYYY') + ".xlsx"
    };
  }

  loadData(startDate: Date, endDate: Date) {
    const sDate = moment(startDate).format('DD MMM YYYY');
    const eDate = moment(endDate).format('DD MMM YYYY');
    this.userService.loadStatement(this.userData.userId, sDate, eDate, this._fromAdmin == true)
      .pipe(
        map(data => {
          data.transactionLine.forEach(element => {
            if (!this.isMobile) {
              if (element.cr === 0)
                element.cr = null;
              else {
                element.cr = this.currencyPipe.transform(element.cr, 'ZAR', "R");
              }

              if (element.dr === 0)
                element.dr = null;
              else {
                element.dr = this.currencyPipe.transform(element.dr, 'ZAR', "R");
              }

              if (element.dayBalance === 0)
                element.dayBalance = null;
              else {
                element.dayBalance = this.currencyPipe.transform(element.dayBalance, 'ZAR', "R");
              }
            }
            else {
              if (element.cr === 0)
                element.cr = this.currencyPipe.transform(element.dr, 'ZAR', "R");
              else {
                element.cr = this.currencyPipe.transform(element.cr, 'ZAR', "R");
              }

            }

          });
          return data;
        })
      )
      .subscribe((data) => {
        this.accountData = data.transactionLine;
      });

  }


  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString();
  }


  dataBound() {
    this.grid.autoFitColumns(['description']);
  }

  onDateChange(event) {
    this.loadData(this.dateRangeValue[0], this.dateRangeValue[1]);
  }

  customSort() {
    //alert('a');
  }

  sort() {
    $('tr').sort(function (a, b) {
      return new Date($(a).find('input').val()).getTime() < new Date($(b).find('input').val()).getTime()
    }).appendTo('tbody')
    //alert('sort')
  }



  onFieldClick() {
    //alert('a');
  }

  rowSelected($event) {

  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'grid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
      this.grid.columns.forEach(col => {
        if (col.index == 0) {
          col.width = 30;
        }
        else if (col.index == 1) {
          col.width = 150;
        }
        else if (col.index == 2) {
          col.width = 700;
        }
        else {
          col.width = 100;
        }
      });
      this.grid.excelExport(this.getExcelExportProperties());
    }
    else if (args.item.id === 'grid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
      this.grid.pdfExport();
    }
  }

}
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '@app/core/services/user.service';
import { UserModel } from '../../../core/models/usermodels/user.model';
import { PermissionsModel } from '../../../core/models/usermodels/permissions.model';

@Component({
  selector: 'account-groups',
  templateUrl: './account-groups.component.html',
  styleUrls:['./account-groups.component.css']
})
export class AccountGroupsComponent implements OnInit, OnDestroy {

  userData:UserModel;
  userPermissions:PermissionsModel;
  loading:boolean = true;
  readOnly:boolean = false;
  groupData = {};
  
  @Input('accountdata') set accountdata(value){
    if (!value)
       return;
    this.userData = value;
    this.loadGroups();
  }

  @Input('permissions') set permissions(value){
    if (!value)
       return;
    this.userPermissions   = value;
    this.readOnly = this.userPermissions.canEditDiet;
  }

  constructor(private toastr: ToastrService,
              private fb: FormBuilder,
              private userService:UserService){
  }

  ngOnInit() {
  }

  ngOnDestroy(){
  }

  loadGroups(){
    let that = this;
    this.groupData = that.userData.groups;
  }

}


import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ToastrService } from 'ngx-toastr';
import { PageSettingsModel, SelectionSettingsModel, GridComponent, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';


import { Store } from '@ngrx/store';
import * as clientStore from '@app/core/store/client'

import { EconomyService } from '@app/core/services/economy.service';
import { UserService } from '@app/core/services/user.service';

import { ClientModel } from '@app/core/models/clientmodels/client.model';
import { LocalService } from '@app/core/services/local.service';
import { UserModel } from '@app/core/models/usermodels/user.model';
import { PermissionsModel } from '@app/core/models/usermodels/permissions.model';




@Component({
  selector: 'account-cashouts',
  templateUrl: './cashouts.component.html',
  styleUrls: ['./cashouts.component.css']
})
export class CashoutComponent implements OnInit, OnDestroy {

  clientData: ClientModel;
  userData: UserModel;
  userPermissions;
  removeAccountForm: FormGroup;
  submitted = false;
  submitting = false;
  data: object[];
  searchTimer;
  filter;

  tapSubscription: Subscription;
  @Input('accountdata') set accountdata(value) {
    if ((value === null) || (value === undefined))
      return;

    this.userData = value;
    this.lookupSearch("");
  }

  @Input('permissions') set permissions(value: PermissionsModel) {
    if ((value === null) || (value === undefined))
      return;
    this.userPermissions = value;
  }


  public pageSettings: PageSettingsModel;
  public selectionOptions: SelectionSettingsModel;

  @ViewChild('grid', { static: false })
  public grid: GridComponent;

  constructor(private router: Router,
    private userService: UserService,
    private economyService: EconomyService,
    private localService: LocalService,
    private store: Store<any>) {
  }

  ngOnInit() {
    let that = this;
    this.store.select(clientStore.getClient)
      .subscribe(
        (data: ClientModel) => {
          that.clientData = data;
        });
    this.selectionOptions = { type: 'Single' };
    this.pageSettings = { pageSize: 20 };
    this.setFocus();
  }

  ngOnDestroy() {
    if (this.tapSubscription != null) {
      this.tapSubscription.unsubscribe();
    }
  }

  loadEconomy() {
    let that = this;
    if (this.clientData != null) {
      this.economyService.getEconomyByClientId(this.clientData.clientId)
        .subscribe(
          data => {
            // that.economyData = data;
            // if (that.economyData != null){
            //   that.lookupSearch("");
            // }
          },
          errorResponse => {

          });
    }
  }

  setFocus() {
    setTimeout(
      () => {
        $("#search").focus();
      }, 600);
  }



  lookupSearch(searchString) {

    let that = this;
    if (this.searchTimer)
      clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(
      () => {
        that.searchTimer = null;
        that.submitting = true;
        that.userService.getCashouts(that.clientData.clientId, that.userData.userId)
          .subscribe(
            data => {
              that.submitting = false;
              that.data = data.items;
              that.setFocus();
            },
            errorResponse => {
              that.submitting = false;
              that.setFocus();
            });

      }, 1000);
  }

  updateFilter(event) {
    if (event.target) {
      if (event.target.value) {
        const val = event.target.value.toLowerCase();
        this.lookupSearch(val);
      }
    }
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      if (event.target) {
        if (event.target.value) {
          const val = event.target.value.toLowerCase();
          this.updateFilter(val);
        }
      }
    }
  }

  rowSelected(args: RowSelectEventArgs) {

    // let that = this;
    // const selectedrowindex: number[] = this.grid.getSelectedRowIndexes();  // Get the selected row indexes.
    // const selectedrecords: object[] = this.grid.getSelectedRecords();  // Get the selected records.
    // //alert(JSON.stringify(selectedrecords)); // To alert the selected row indexes.
    // let objectVal = selectedrecords[0];
    // that.submitting = true;
    // this.disableGrid();
    // //that.spinner.show();   
    // this.acccountService.loadAccount(this.clientData.clientId, objectVal["userId"]).subscribe(
    //   data => {
    //     that.enableGrid();
    //     that.submitting = false;
    //     //that.spinner.hide();   
    //     that.dataService.userData = data;
    //     that.router.navigate(['/clientadmin/account']);
    //   },
    //   errorResponse => {
    //     that.enableGrid();
    //     that.submitting = false;
    //     //that.spinner.hide();   
    //   });

  }

  private disableGrid() {
    if (this.grid.element.classList.contains('disablegrid') == false) {
      this.grid.element.classList.add('disablegrid');
      document.getElementById("GridParent").classList.add('wrapper');
    }
  }

  private enableGrid() {
    if (this.grid.element.classList.contains('disablegrid')) {
      this.grid.element.classList.remove('disablegrid');
      document.getElementById("GridParent").classList.remove('wrapper');
    }
  }

}
export class  PreferencesModel { 
    public lowBalanceAmount: number;  
    public pushNotification: boolean;  
    public textNotification: boolean;  
    public emailNotification: boolean;  
    public lbaNotification: boolean;  
    public orderNotification: boolean;  
    public depositNotification: boolean;  
    public alertNotification: boolean;  
    public notifyParent: boolean;  
    public canAcceptOffers: boolean;
}

import { UserPurchasesModel } from '@app/core/models/usermodels/userpurchases.model';
import { AppState } from '../index';
import { UserState } from './user.state';
import { createSelector } from 'reselect';

import { UserModel } from '@app/core/models/usermodels/user.model';
import { VendorModel } from '@app/core/models/vendormodels/vendor.model';

// Base product state selector function
const getUserState = (state: AppState): UserState => state.user;

const fetchUser = (state: UserState) => (state.userData as unknown) as UserModel;
const fetchUserPurchases = (state: UserState) => (state.purchasesData as unknown) as UserPurchasesModel;
const fetchLinkedAccounts = (state: UserState) => (state.linkedAccounts as unknown) as UserModel[];
const fetchVendors = (state: UserState) => (state.vendors as unknown) as VendorModel[];

// *************************** PUBLIC API's ****************************
export const getUser = createSelector(
  getUserState,
  fetchUser
);

export const getUserPurchases = createSelector(
  getUserState,
  fetchUserPurchases
);

export const getLinkedAccounts = createSelector(
  getUserState,
  fetchLinkedAccounts
);

export const getVendors = createSelector(
  getUserState,
  fetchVendors
);

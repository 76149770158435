import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { DateModel } from "../models/datemodel";
import { MenuModel } from "../models/preorder/menu.model";

@Injectable()
export class DayDateService implements OnInit, OnDestroy {

  public days: Array<DateModel> = [];
  public menus: Array<MenuModel> = [];

  private subscribed: boolean;
  private subscription: Subscription;
  private dateSubject = new BehaviorSubject<Array<DateModel>>(null);
  private daySubject = new BehaviorSubject<DateModel>(null);
  private menuSubject = new BehaviorSubject<Array<MenuModel>>(null);
  private reloadSubject = new BehaviorSubject<boolean>(null);


  constructor() {

  }

  ngOnInit() {
    // console.log("Loading DateService");
  }

  ngOnDestroy() {
    if (this.subscription) {
       this.subscription.unsubscribe();
    }
  }

  public initialize(dateFrom: Date, days: number) {

    this.days = [];
    var endDate = new Date();
    var startDate = this.addDays(dateFrom, -1);
    endDate = this.addDays(endDate, days);
    var loop = new Date(startDate);
    var index = -1;
    while (loop <= endDate) {
      var newDate = loop.setDate(loop.getDate() + 1);
      index = index + 1;
      var dm = new DateModel();
      dm.index = index;
      dm.dayDate = new Date(newDate);
      dm.selected = false;
      this.days.push(dm);
    }
    this.dateSubject.next(this.days);
  }

  private addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }


  public connectDays(): Observable<Array<DateModel>> {
    return this.dateSubject;
  }

  public connectMenus(): Observable<Array<MenuModel>> {
    return this.menuSubject;
  }


  public connectDay(): Observable<DateModel> {
    return this.daySubject;
  }


  public connectReload(): Observable<boolean> {
    return this.reloadSubject;
  }

  public updateMenus(data: Array<MenuModel>) {
    this.menus = data;
    this.menuSubject.next(data);
  }

  public update(data: DateModel) {
    const that = this;
    this.days.forEach(element => {
      if (element.index == data.index) {
        
      } else {
        element.selected = false;
      }      
      element.menus = that.menus;
      return element;
    });
    this.dateSubject.next(this.days);
    this.daySubject.next(data);
  }

  public reload(){
    this.dateSubject.next(null);
    this.daySubject.next(null);
    this.reloadSubject.next(true);
  }
}

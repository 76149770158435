import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { EJ_REPORTVIEWER_COMPONENTS } from '@syncfusion/reporting-angular/src/ej/reportviewer.component';
import { EJ_REPORTDESIGNER_COMPONENTS } from '@syncfusion/reporting-angular/src/ej/reportdesigner.component';

@NgModule({
    imports: [
    ],
    declarations: [
      EJ_REPORTVIEWER_COMPONENTS,
      EJ_REPORTDESIGNER_COMPONENTS
    ],
    exports:[
      EJ_REPORTVIEWER_COMPONENTS,
      EJ_REPORTDESIGNER_COMPONENTS
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
  })
export class ReportViewerModule {}
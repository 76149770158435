import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import * as clientStore from '@app/core/store/client'

import { UserService } from '@app/core/services/user.service';
import { UserModel } from '../../../core/models/usermodels/user.model';
import { DietModel } from '../../../core/models/usermodels/diet.model';
import { PermissionsModel } from '../../../core/models/usermodels/permissions.model';
import * as moment from "moment";

import { Toast } from 'ngx-toastr';
import { ClientModel } from '@app/core/models/clientmodels/client.model';

@Component({
  selector: 'account-diet',
  templateUrl: './account-diet.component.html',
  styleUrls: ['./account-diet.component.css']
})
export class AccountDietComponent implements OnInit, OnDestroy {

  userData: UserModel;
  clientData: ClientModel;
  userPermissions: PermissionsModel;
  loading: boolean = true;
  dietForm: FormGroup;
  dietData: DietModel = null;
  readOnly: boolean = false;
  dietaryRestrictionsEnabled = false;
  public dietaryRestrictionType: string  = 'Basic';

  @Input('accountdata') set accountdata(value) {
    if (!value)
      return;
    this.userData = value;
    this.loadDiet();
  }

  @Input('permissions') set permissions(value) {
    if (!value)
      return;
    this.userPermissions = value;
    this.readOnly = this.userPermissions.canEditDiet;
  }

  constructor(private toastr: ToastrService,
    private fb: FormBuilder,
    private userService: UserService,
    private store: Store<any>) {
    this.dietForm = this.createDietForm();
  }

  ngOnInit() {
    let that = this;
    this.store.pipe(
      select(clientStore.getClient)
    )
      .subscribe(
        (data: ClientModel) => {
          if (data == null){
            return;
          }
          that.clientData = data;
          that.dietaryRestrictionsEnabled = that.clientData.dietaryRestrictionsEnabled == true;
          that.dietaryRestrictionType = that.clientData.dietaryRestrictionType;
        });
  }

  ngOnDestroy() {
  }



  createDietForm(): FormGroup {
    return this.fb.group(
      {
        dietNotes: [""],
        dietSpend: [""],
        dietKosher: [""],
        dietHalal: [""],
        dietOther: [""],
        dietGluten: [""],
        dietNuts: [""],
        dietSoy: [""],
        dietSesame: [""],
        dietWheat: [""],
        dietMilk: [""],
        dietEgg: [""],
        dietFish: [""],
        dietShellfish: [""],
        dietSugar: [""],
      }
    );
  }

  get f() {
    return this.dietForm.controls;
  }

  loadDiet() {
    let that = this;
    this.userService.fetchDiet(this.userData.userId)
      .subscribe(data => {
        that.dietData = data;
        that.loadForm();
      })

  }

  loadForm() {
    this.f.dietNotes.setValue(this.dietData.dietNotes);
    this.f.dietSpend.setValue(this.dietData.dietSpend);
    if (this.dietaryRestrictionsEnabled) {
      this.f.dietKosher.setValue(this.dietData.dietKosher);
      this.f.dietHalal.setValue(this.dietData.dietHalal);
      this.f.dietOther.setValue(this.dietData.dietOther);
      this.f.dietGluten.setValue(this.dietData.dietGluten);
      this.f.dietNuts.setValue(this.dietData.dietNuts);
      this.f.dietSoy.setValue(this.dietData.dietSoy);
      this.f.dietSesame.setValue(this.dietData.dietSesame);
      this.f.dietWheat.setValue(this.dietData.dietWheat);
      this.f.dietMilk.setValue(this.dietData.dietMilk);
      this.f.dietEgg.setValue(this.dietData.dietEgg);
      this.f.dietFish.setValue(this.dietData.dietFish);
      this.f.dietShellfish.setValue(this.dietData.dietShellfish);
      this.f.dietSugar.setValue(this.dietData.dietSugar);
    }
  }

  submitForm($event) {
    let userDiet = new DietModel();
    userDiet.accountId = this.userData.userId;
    userDiet.dietNotes = this.f.dietNotes.value;
    userDiet.dietSpend = this.f.dietSpend.value;
    if (this.dietaryRestrictionsEnabled) {
      userDiet.dietKosher = this.f.dietKosher.value;
      userDiet.dietHalal = this.f.dietHalal.value;
      userDiet.dietOther = this.f.dietOther.value;
      userDiet.dietGluten = this.f.dietGluten.value;
      userDiet.dietNuts = this.f.dietNuts.value;
      userDiet.dietSoy = this.f.dietSoy.value;
      userDiet.dietSesame = this.f.dietSesame.value;
      userDiet.dietWheat = this.f.dietWheat.value;
      userDiet.dietMilk = this.f.dietMilk.value;
      userDiet.dietEgg = this.f.dietEgg.value;
      userDiet.dietFish = this.f.dietFish.value;
      userDiet.dietShellfish = this.f.dietShellfish.value;
      userDiet.dietSugar = this.f.dietSugar.value;
    }

    this.userService.updateDiet(userDiet).subscribe(
      data => {
        this.toastr.success("Updated diet!", "Congratulations");
      },
      errorResponse => {
        this.toastr.error("Update failed!", "Oh No!");
      });
  }

}

export class PermissionsModel {
  public accountId: string;
  public canUnlinkAccount: boolean;
  public canSetPermissions: boolean;
  public canEditProfile: boolean;
  public canViewProfile: boolean;
  public canEditDiet: boolean;
  public balanceVisible: boolean;
  public canAcceptOffers: boolean;

  public constructor() {
    this.canUnlinkAccount = false;
    this.canSetPermissions = false;
    this.canEditProfile = false;
    this.canViewProfile = false;
    this.canEditDiet = false;
    this.balanceVisible = false;
    this.canAcceptOffers = false;
  }
}
import { Injectable } from "@angular/core";

import { Observable } from 'rxjs';
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store, Action } from '@ngrx/store';
import { switchMap, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";

import { AuthActionTypes } from "@app/core/store/auth";
import { ClientActions } from "./client.actions";

import { ClientService } from "../../services/client.service";


@Injectable()
export class ClientEffects {

  
   @Effect()
   GetClientStart$: Observable<Action> = this.actions$.pipe(
     ofType(AuthActionTypes.AuthInit),
     switchMap(res =>[this.clientActions.getClient()])
   );

  @Effect()
  UnloadClientStart$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.LogoutAction),
    switchMap(res =>[]),
    map((data:any) => this.clientActions.unloadClient())
  );

  @Effect()
  GetClient$: Observable<Action> = this.actions$.pipe(
    ofType(ClientActions.GET_CLIENT),
    switchMap((action: any) => this.clientService.getClient()),
    map((data: any) => this.clientActions.getClientSuccess({ clientState: data }))
  );


  @Effect()
  GetClientRoles$: Observable<Action> = this.actions$.pipe(
    ofType(ClientActions.GET_CLIENT_ROLES),
    switchMap((action: any) => this.clientService.geClientRoles()),
    map((data: any) => {
      return this.clientActions.getClientRolesSuccess({ clientRoles : data })
    })
  );

  @Effect()
  GetClientGroups$: Observable<Action> = this.actions$.pipe(
    ofType(ClientActions.GET_CLIENT_GROUPS),
    switchMap((action: any) => this.clientService.geClientGroups()),
    map((data: any) => {
      return this.clientActions.getClientGroupsSuccess({ clientGroups : data })
    })
  );

  constructor(private actions$: Actions,
              private store: Store<any>,
              private clientActions: ClientActions,
              private clientService: ClientService) {
  }
}

import { CollectionOrderModel } from './../models/usermodels/collection-order.model';

import { UserModel } from '../models/usermodels/user.model';
import { MessageTemplateModel } from '../models/accountmodels/messagetemplate.model';
import { NotificationModel } from '../models/accountmodels/notification.model';
import { UserGroupModel } from '../models/clientmodels/usergroup.model';
import { VendorModel } from '../models/vendormodels/vendor.model';
import { ProductModel } from '../models/productmodels/product.model';
import { LocationModel } from '../models/common/location.model';
import { DepartmentModel } from '../models/department/department.model';
import { VendorRegistrationStateModel } from '../models/ecomonymodels/vendorregistrationstate.model';
import { VendorDetailModel } from "../models/vendormodels/vendordetail.model";
import { ProductCategoryModel } from '../models/product/productcategory.model';
import { ClientModel } from '../models/clientmodels/client.model';
import { DeviceModel } from '../models/device/device.model';
import { ClientVendorModel } from '../models/clientmodels/clientvendor.model';
import { DiscountModel } from '../models/discountmodels/discount.model';
import { MenuModel } from '../models/preorder/menu.model';
import { VoucherModel } from '../models/vouchermodels/voucher.model';
import { VendorWorkflowModel } from '../models/vendormodels/vendorworkflow.model';
import { SystemDocumentModel } from '../models/system/systemdocument.model';
import { TransactionOrderModel } from '../models/transaction/transaction_order.model';
import { PromotionModel } from '../models/promotions/promotion.model';
import { PrinterModel } from '../models/printermodels/printer.model';
import { BasicTaskModel } from '../models/tasks/basictask.model';
import { VenueModel } from '../models/venuemodels/venue.model';
import { SeatingComponent } from '@app/features/vendor/venues/seating/seating.component';
import { SeatConfigModel } from '../models/venuemodels/seatconfig.model';
import { EventModel } from '../models/events/event.model';
import { EventTicketModel } from '../models/events/eventticket.model';
import { EventEmitter } from '@angular/core';
import { VendorSettlementModel } from '../models/vendormodels/vendorsettlement.model';
import { List } from 'immutable';
import { VendorOrderResponseModel } from '../models/cart/vendor-order-response.model';
import { OrderResponseModel } from '../models/cart/order-response.model';
import { TimeSlotInventoryDataModel } from '../models/inventory/timeslotinventorydata.model';

export class DataService{
    public userData: UserModel;
    public preorderUserData: UserModel;
    public preorderVendorData: ClientVendorModel;
    public messageTemplate: MessageTemplateModel;
    public notification: NotificationModel;
    public userGroup: UserGroupModel;
    public clientUserGroups: Array<UserGroupModel>;
    public client: ClientModel;
    public vendor: VendorModel;
    public vendorDetail: VendorDetailModel;
    public product: ProductModel;
    public category: ProductCategoryModel;
    public discount: DiscountModel;
    public location: LocationModel;
    public department: DepartmentModel;
    public workflow: VendorWorkflowModel;
    public vendorRegistrationRequest: VendorRegistrationStateModel;
    public collectionsData: Array<CollectionOrderModel>;
    public deviceData: DeviceModel;
    public menuData: MenuModel;
    public preorderMode: string;
    public voucherData: VoucherModel;
    public documents: Array<SystemDocumentModel>;
    public order: TransactionOrderModel;
    public promotion: PromotionModel;
    public printerData: PrinterModel;
    public taskData: BasicTaskModel;
    public vendorId: number;
    public categoryId: number;
    public locationId: string;
    public venue: VenueModel;    
    public event: EventModel;    
    public ticket: EventTicketModel;    
    public seating: SeatConfigModel;
    public purchaseCompleteEvent: EventEmitter<any>;
    public lastOrder: List<VendorOrderResponseModel>;
    public lastStoreOrder: OrderResponseModel;
    public settlementData: VendorSettlementModel;
    public timeSlotInventory: TimeSlotInventoryDataModel;
}
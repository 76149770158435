import { ErrorHandler, Injectable, NgZone } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  constructor(
    //private errorDialogService: ErrorDialogService,
    private zone: NgZone
  ) {}


  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    console.log("Got The Error...................................." + error.message);
    console.log("Got The Error...................................." + error.stack);
    if (chunkFailedMessage.test(error.message)) {
      console.log("Got The Error....................................");
      window.location.reload();

    }
  }
}
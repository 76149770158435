import { DateModel } from '@app/core/models/datemodel';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, EMPTY } from 'rxjs';

import { config } from "@app/core/smartadmin.config";

import { ClientService } from './client.service';
import { DataService } from './data.service';

import { MenuProductModel } from '../models/preorder/menuproduct.model';
import { MenuOrderModel } from '../models/preorder/menuorder.model';
import { MenuModel } from '../models/preorder/menu.model';
import { PreorderCartOrderModel } from '../models/preorder-cart/preordercartorder.model';
import { POMenuOrderModel } from '../models/preorder-cart/pomenuorder.model';
import { OrderResponseModel } from '../models/cart/order-response.model';
import { PreorderPlaceOrderModel } from '../models/preorder/preorderplaceorder-model';


@Injectable()
export class PreorderService {

  dayArray: Array<MenuOrderModel> = [];
  orders: Subject<Array<MenuOrderModel>> = null;
  success: any;
  // tslint:disable-next-line:member-ordering
  error: any;

  constructor(private dataService: DataService,
    private clientService: ClientService,
    private http: HttpClient) {
    this.orders = new Subject<Array<MenuOrderModel>>();
  }

  addToOrder(dateData: DateModel, menuData: MenuModel, productData: MenuProductModel, quantity: number) {
    var tempDayMenu = this.findDay(dateData);
    //var orderList = this.findMenu(tempDayMenu, productData.)

  }

  removeFromOrder(dateData: DateModel, productData: MenuProductModel, quantity: number) {
  }

  findDay(dateData): MenuOrderModel {
    var result = this.dayArray.find(data => data.dateData.dayDate == dateData.dayDate);
    if (result == null) {
      var newDayData = new MenuOrderModel();
      newDayData.dateData.dayDate = dateData.dayDate;
      this.dayArray.push(newDayData);
      return newDayData;
    }
    else {
      return result;
    }
  }


  getCart(userId: string): Observable<Array<POMenuOrderModel>> {
    var clientData = this.clientService.currentClient;
    return this.http.get<Array<POMenuOrderModel>>(config.TRIOSAPI + '/api/preorder/getcarts?clientId=' + clientData.clientId + "&userId=" + userId)
      .pipe(
        catchError((error) => {
          // console.log("Error", error);
          return EMPTY
        }),
        map((resp) => {
          // console.log(resp);
        //   var array: Array<{menuId,PreorderCartOrderModel}> = [];
        //   try {
        //     resp.carts.forEach((cartOrder) => {
        //       array.push(cartOrder);
        //     });
        //   }
        //   catch (ex) {

        //   }
        //   //resp.carts = array;
           return resp;
         })
      );
  }



  addItemToCart(item: any): Observable<PreorderCartOrderModel> {
    var clientData = this.clientService.currentClient;
    return this.http.post<PreorderCartOrderModel>(config.TRIOSAPI + '/api/preorder/additemtocart?clientId=' + clientData.clientId, item)
      .pipe(
        //  catchError((error) => {
        //    // console.log("Error", error);
        //    //this.toastr.warning(error, "Add to cart error!");
        //    alert(error);
           
        //    return EMPTY;           
        // }),
        map((resp) => {
          // var array: Array<PreorderCartLineItem> = [];
          // resp.order.items.forEach((lineItem) => {
          //   array.push(lineItem);
          // });
          // resp.order.items = array;
          return resp;
        })
      );
  }

  removeItemFromCart(item: any): Observable<PreorderCartOrderModel> {
    var clientData = this.clientService.currentClient;
    return this.http.post<PreorderCartOrderModel>(config.TRIOSAPI + '/api/preorder/removeitemfromcart?clientId=' + clientData.clientId, item)
      .pipe(
        catchError((error) => {
          // console.log("Error", error);
          return EMPTY
        }),
        map((resp) => {
          // var array: Array<PreorderCartLineItem> = [];
          // resp.order.items.forEach((lineItem) => {
          //   array.push(lineItem);
          // });
          // resp.order.items = array;
          return resp;
        })
      );
  }


  placeWebOrder(data: PreorderPlaceOrderModel): Observable<OrderResponseModel> {
    var clientData = this.clientService.currentClient;
    return this.http.post<any>(config.TRIOSAPI + '/api/preorder/placeweborder?clientId=' + clientData.clientId, data)
      .pipe(
        // catchError((error) => {
        //   alert(error);
        //   return EMPTY
        // }),
        map((resp) => {
          return resp;
        })
      );
  }


  placeCardOrder(): Observable<OrderResponseModel> {
    var clientData = this.clientService.currentClient;
    return this.http.post<any>(config.TRIOSAPI + '/api/preorder/placecardorder?clientId=' + clientData.clientId, null)
      .pipe(
        catchError((error) => {
          // console.log("Error", error);
          return EMPTY
        }),
        map((resp) => {
          return resp;
        })
      );
  }

  emptyCart(userId: string): Observable<any> {
    var clientData = this.clientService.currentClient;
    return this.http.post<any>(config.TRIOSAPI + '/api/preorder/emptyCart?clientId=' + clientData.clientId + "&userId=" + userId, null)
      .pipe(
        catchError((error) => {
          alert(error);
          // console.log("Error", error);
          return EMPTY
        }),
        map((resp) => {
          return resp;
        })
      );
  }


  cancelOrder(userId: string, orderId: string){
    var clientData = this.clientService.currentClient;
    return this.http.post<any>(config.TRIOSAPI + '/api/preorder/cancelOrder?clientId=' + clientData.clientId + "&userId=" + userId + "&orderId=" + orderId, null)
      .pipe(
        map((resp) => {
          return resp;
        })
      )      
  }
}





